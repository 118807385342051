import React, { useEffect, useState, useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CommonModal from '../../../Components/Models/commonModal';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: '0px 20px',
    },
    paper: {
      maxWidth: '400px',
      maxHeight: '1200px',
      minWidth: '200px',
      minHeight: '600px',
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    buttons:{
      marginTop:"150px"
    }
  }));
  
  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

export const TransferTable = (props) => {
  const {generateReport, setReports,updateList} = props
  const addnewField = useRef()
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([
      {
        title:'Candidate Name',
        key:'name'
      },
      {
        title:'Primary Mobile No',
        key:'primary_mobile'
      },  {
        title:'Seconday Contact Number',
        key:'secondary_mobile'
      },  {
        title:'Primary Email ID',
        key:'primary_email'
      },  {
        title:'Secondary Email ID',
        key:'secondary_email'
      },  {
        title:'Country',
        key:'country'
      },  {
        title:'City',
        key:'city'
      },  {
        title:'State',
        key:'state'
      },  {
        title:'Identity Card Name',
        key:'identity_card_name'
      },  {
        title:'Identity Card No',
        key:'identity_card_number'
      },{
        title:'Company Name',
        key:'Company_name'
      }, {
        title:'Experience in Years',
        key:'exp_years'
      },  {
        title:'Experience in Months',
        key:'exp_months'
      },  
      {
        title:'Current CTC',
        key:'current_ctc'
      }, 
      {
        title:'Expected CTC',
        key:'expected_ctc'
      },
      {
        title:'Fixed CTC',
        key:'fixed_ctc'
      },
      {
        title:'Variable CTC',
        key:'variable_ctc'
      },
      {
        title:'Stock Options (P.A)',
        key:'stocks'
      },
      {
        title:'Notice Period ',
        key:'notice_period'
      },
    
    ]);
    const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [updateStatusModal,setUpdateStatusModal] = useState(false)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  useEffect(()=>{
    setReports(right)
  },[right])

  useEffect(()=>{
    handleAllLeft()
  },[updateList])

  const addNewField = () =>{
    
    let value = addnewField.current.value 
    if(value){
        let obj =  {
          title:value,
          key:value
        }
        setRight([...right,obj])
    }
    setUpdateStatusModal(false)
  }


  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.key}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)} style={{backgroundColor:checked.indexOf(value) !== -1?"red":"transparent"}}>
              {/* <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon> */}
              <ListItemText id={labelId} primary={value.title} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return(
    <>
    <Grid
    container
    spacing={2}
    // justifyContent="center"
    // alignItems="center"
    className={classes.root}
  >
    <Grid item>{customList(left)}</Grid>
    <Grid item>
      <Grid container 
      direction="column" 
      alignItems="center" 
      justifyContent="center"
      className={classes.buttons}
     >
        {/* <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllRight}
          disabled={left.length === 0}
          aria-label="move all right"
        >
          ≫
        </Button> */}
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
      <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllLeft}
          disabled={right.length === 0}
          aria-label="move all left"
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={()=>setUpdateStatusModal(true)}
          aria-label="move all left"
        >
          Add
        </Button>

        <Button
        variant="outlined"
        size="small"
        className={classes.button}
        onClick={generateReport}
        disabled={right.length === 0}
        aria-label="move all left"
      >
        Generate
      </Button>
        
      </Grid>
    </Grid>
    <Grid item>{customList(right)}</Grid>
  </Grid>
   <CommonModal title={"Add New"} handleClose={() => setUpdateStatusModal(false)} open={updateStatusModal} button={"Add"} btnfunc={addNewField}>
   <div style={{width: "200px"}}>
     <input type="text" ref={addnewField}/>

  
     </div>
 </CommonModal>
</>
   )
  }