import React,{useState, useEffect} from 'react'
import {routes} from '../../routes'
import { useSelector} from "react-redux";
import {Link} from 'react-router-dom'
import './sidenav.scss'

const Sidenav = (props) => {

  const [activeclass,setActiveclass] = useState([])
  const cadidateRoute = useSelector(state=>state.PageReduser.cadidateRoute)
    useEffect(()=>{
    let array = []
    let path = window.location.pathname
    if(props.category !== "Cadidates"){
      routes.map((prop)=>{
        if(prop.moduleName === props.category){
          if(path===(prop.category+prop.path)){
            array.push(true)
          }else{
            array.push(false)
          }
        }else{
          array.push(false)
        }
      })
      setActiveclass(array)
    }
    else if(props.category === "Cadidates"){
      cadidateRoute.map((prop)=>{
          if(path===prop.link){
            array.push(true)
          }else{
            array.push(false)
          }
      })
      setActiveclass(array)
    }
    getRoutes()
  },[])

  const getRoutes=()=>{
    let moduleroutes = routes.filter((route)=>{
      return route.moduleName === props.category
    })
   
  }

  return(
   <div className="sideNav custom-nav" >  
      <ul className="p-0 m-0">
        {routes.map((prop,index)=>{
          if(prop.moduleName === props.category){
            return <Link  to={prop.category+prop.path} key={prop.path} >
             <li  key={prop.name} className={activeclass[index]?"active-link":""}>
            {prop.name}
          </li>
          </Link>
          }
        })}
        
        {(props.category === "Cadidates")&& cadidateRoute.map((prop,index)=>{
          if(prop.show){
            return <Link  to={prop.link} key={prop.link} >
            <li  key={prop.name} className={activeclass[index]?"active-link":""}>
           {prop.name}
         </li>
         </Link>
          }else{
            return null
          }
        })}

      </ul>  
    </div>
   )
  }


export default React.memo(Sidenav)