import React,{ useEffect,useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { fetchUrl} from '../../ApiActions/Actions'

import {
 DATATABLE_CLIENTS_URL, DTRESUME_ACTIVE_JOBS_DETAILS
} from '../../Configurations/ConfigApi';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin: "0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const SelectClientInputs = (props) => {
  const {setjobchange , setclientchange, selectedclient ,selectedjob,Jobdetails} = props

  const classes = useStyles();
  const [clientdetails, setclientdetails] = useState([]);

  useEffect(() => {
    fetchUrl(`${DATATABLE_CLIENTS_URL}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data
        setclientdetails(data)
      }
    })
  }, []);

  // const setclientchange = (value) => {
    // setselectedclient(value);
    // fetchUrl(`${DTRESUME_ACTIVE_JOBS_DETAILS}/${value}`, ({ status, response }) => {
      // if (status) {
        // let data = response.data.data;
        // setJobdetails(data)
      // }
    // })
  // }

  return(
    <>
   
       <div className="col-md-3">
   <FormControl className={classes.formControl} fullWidth>
     <InputLabel className="w-100" id="demo-controlled-open-select-label">Select Client</
InputLabel>
     <Select
       labelId="demo-controlled-open-select-label"
       id="demo-controlled-open-select"
       className="w-100"
       minRows={10}
       value={selectedclient}
       onChange={(e) => setclientchange(e.target.value)}
     >
       {clientdetails.map((role) => {
         return <MenuItem value={role.encrypted_id}>
           {role.client_short_name}
         </MenuItem>
       })}
     </Select>
   </FormControl>
 </div>
 <div className="col-md-4">
   <FormControl className={classes.formControl} fullWidth>
     <InputLabel className="w-100" id="demo-controlled-open-select-label">Select Active Job
Order</InputLabel>
     <Select
       labelId="demo-controlled-open-select-label"
       id="demo-controlled-open-select"
       className="w-100"
       minRows={10}
       value={selectedjob}
       onChange={(e) => setjobchange(e.target.value)}
     >
       {Jobdetails.map((role) => {
         return <MenuItem value={role.job_id}>
           {role.job_title}
         </MenuItem>
       })}
     </Select>
   </FormControl>
 </div>
    </>
   )
  }
