import React,{useState,useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import {GET_CLIENT_GST_DETAILS_API_URL } from '../../../Configurations/ConfigApi'
import { apiPost,apiDelete ,apiEdit} from '../../../ApiActions/Actions'
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import { useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin:"0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const GstClient = (props) => {
     const classes = useStyles();
    const [updateModal,setUpdatemodal] = useState(false);
    const [showBts,setShowbts] = useState(true);
    const [fieldserror,setFieldErros] = useState(false)
    const [errors,setErrors] = useState({})
    const [open, setOpen] = useState(false);
    const [openStartDate,setOpenStartDate] = useState(false)
    const dispatch = useDispatch()
  
    // '2014-08-18T21:11:54' format
    const [gstDetails,setGstDetails] = useState({
        gst_id:"",
        gst_state_code:"",
        gst_state_name:"",
        client_pan_no: "",
        client_tan_no:"",
        client_office_short_name: "",
        client_office_address1:"",
        client_office_address2:"",
        client_office_address3:"",
        client_office_address4: "",
        gst_exemption_eligibility:"",
        approval_from_gst_enddate:"",
        encrypted_id:'',
    });
    const [editEncrptId,setEditEncrptId] = useState("")
    const [modalmsg,setModalmsg] = useState("")
    const [deleteModal,setDeleteModal] = useState(false); 
  
    const handleClose = () => {
      setUpdatemodal(false);
      setDeleteModal(false)
    };


const clearvalues = ()=>{
 
  props.deleteCount()
  dispatch({type:"DeleteClientGst",payload:props.index})
}

  useEffect(()=>{
    if(props.edit){
        let data = props.data
        setGstDetails({
        gst_id: data.gst_id,
        gst_state_code: data.gst_state_code,
        gst_state_name: data.gst_state_name,
        client_pan_no: data.client_pan_no,
        client_tan_no: data.client_tan_no,
        client_office_short_name: data.client_office_short_name,
        client_office_address1: data.client_office_address1,
        client_office_address2: data.client_office_address2,
        client_office_address3: data.client_office_address3,
        client_office_address4: data.client_office_address4,
        gst_exemption_eligibility: data.gst_exemption_eligibility,
        approval_from_gst_enddate: data.approval_from_gst_enddate,
        encrypted_id: data.encrypted_id, 
        }) 
        setEditEncrptId(data.encrypted_id)
    }else{
      dispatch({ type: "AddClientGst"});
    }
  },[])

  useEffect(()=>{
    if(props.edit){
      dispatch({
        type: "checkUpdateClientGst",
        payload: [props.index, gstDetails],
      });
    }else{
   
      dispatch({
        type: "UpdateClientGst",
        payload: [props.index, gstDetails],
      });
    }
  },[gstDetails])

  const deleteDetails = () =>{
    apiDelete(`${GET_CLIENT_GST_DETAILS_API_URL}/${editEncrptId}`, ({ status, response }) => {
      if (status) {
        setDeleteModal(false)
        // setModalmsg("Gst deleted successfully")
        // setUpdatemodal(true);
        dispatch({type:"DeleteUpdatedClientGstItem",payload:props.index})
        // dispatch({type:"EditClientId",payload:""})
        // dispatch({type:"EditClientId",payload:props.id})
      }
  })
  }

  
  
    return(
            <>

        <div className="row px-4">
          <div className="col-md-6">
            <table className="table table-bordered">
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="GST ID (Legal)W"
                    type="text"
                    className="w-100"
                    value={gstDetails.gst_id}
                    onChange={(e)=>setGstDetails({...gstDetails,gst_id:e.target.value,update:true})}
                  />
                </td>
              </tr>
            
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="GST State Name"
                    type="text"
                    className="w-100"
                    value={gstDetails.gst_state_name}
                    onChange={(e)=>setGstDetails({...gstDetails,gst_state_name:e.target.value,update:true})}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Clinet TAN NO."
                    type="text"
                    className="w-100"
                    value={gstDetails.client_tan_no}
                    onChange={(e)=>setGstDetails({...gstDetails,client_tan_no:e.target.value,update:true})}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Office Address 1"
                    type="text"
                    className="w-100"
                    value={gstDetails.client_office_address1}
                    onChange={(e)=>setGstDetails({...gstDetails,client_office_address1:e.target.value,update:true})}
                  />
                </td>
              </tr>
             
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Office Address 3"
                    type="text"
                    className="w-100"
                    value={gstDetails.client_office_address3}
                    onChange={(e)=>setGstDetails({...gstDetails,client_office_address3:e.target.value,update:true})}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                 
                  <FormControl className={classes.formControl} fullWidth>
                <InputLabel className="w-100" id="demo-controlled-open-select-label">GST Exemption Eligibility</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={()=>setOpen(false)}
                  onOpen={()=>setOpen(true)}
                  className="w-100"
                  minRows={20}
                  value={gstDetails.gst_exemption_eligibility}
                  onChange={(e)=>setGstDetails({...gstDetails,gst_exemption_eligibility:e.target.value,update:true})}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="YES">
                    <em>YES</em>
                  </MenuItem>
                  <MenuItem value="NO">
                    <em>NO</em>
                  </MenuItem>
                 
                </Select>
                </FormControl>
                </td>
              </tr>
             
            </table>
          </div>

          <div className="col-md-6">
          <table className="table table-bordered">
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="GST State Code"
                    type="text"
                    className="w-100"
                    value={gstDetails.gst_state_code}
                    onChange={(e)=>setGstDetails({...gstDetails,gst_state_code:e.target.value,update:true})}
                  />
                </td>
              </tr> 

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client PAN No"
                    type="text"
                    className="w-100"
                    value={gstDetails.client_pan_no}
                    onChange={(e)=>setGstDetails({...gstDetails,client_pan_no:e.target.value,update:true})}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Office Short Name"
                    type="text"
                    className="w-100"
                    value={gstDetails.client_office_short_name}
                    onChange={(e)=>setGstDetails({...gstDetails,client_office_short_name:e.target.value,update:true})}
                  />
                </td>
              </tr>
            
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Office Address 2"
                    type="text"
                    className="w-100"
                    value={gstDetails.client_office_address2}
                    onChange={(e)=>setGstDetails({...gstDetails,client_office_address2:e.target.value,update:true})}
                  />
                </td>
              </tr>
             
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Office Address 4"
                    type="text"
                    className="w-100"
                    value={gstDetails.client_office_address4}
                    onChange={(e)=>setGstDetails({...gstDetails,client_office_address4:e.target.value,update:true})}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                 
                    {
                  gstDetails.approval_from_gst_enddate ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mb-0 pb-0" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >GST Exemption End Date </label> : ""
                }
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              views={["year", "month",'date']}
                              format="dd-MMM-yy"
                              className={`w-100 mt-0 ${gstDetails.approval_from_gst_enddate && 'pt-0'}`}
                              margin="normal"
                              autoOk={true}
                              error={false}
                              minDate={moment().toDate()}
                              id="date-picker-inline"
                              label={gstDetails.approval_from_gst_enddate  ? "" : 'GST Exemption End Date'}
                              value={gstDetails.approval_from_gst_enddate}
                              open={openStartDate}
                              onClose={()=>setOpenStartDate(false)}
                              TextFieldComponent={(props) => (
                                <TextField {...props} onClick={(e) => setOpenStartDate(true)} />
                              )}
                              onChange={(date)=>{
                                setOpenStartDate(false)
                                setGstDetails({...gstDetails,approval_from_gst_enddate:date})
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              disabled={gstDetails.gst_exemption_eligibility==="NO"}
                            />
                          </MuiPickersUtilsProvider>
                      
                </td>
              </tr>

             
            </table>
          </div>

          
        </div>
    
    <div class="row px-4">
      <div class="col-md-12 d-flex justify-content-end">

      {showBts && <>
      {/* <button class="btn btn-xs btn-violet text-white mx-2" onClick={props.edit ?Editdetails : addGst}>
          {props.edit ?"Save":"Add"} 
        </button> */}
        <button class="btn btn-xs btn-green-dark text-white" onClick={
           props.edit ? ()=>{setDeleteModal(true)} : clearvalues
        }>Delete</button>
      </>}
      </div>
    </div>

    <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"ok"} btnfunc={handleClose}>
        {modalmsg}
    </CommonModal>

      
    <CommonModal title={"delete"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
    </CommonModal>


      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>

     </>
   )
  }

  export default GstClient
