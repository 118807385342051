import React, {  useState , useEffect} from "react";
import { getMonth, getYear } from 'date-fns';
import Checkbox from "@material-ui/core/Checkbox";
import "./AddCandidate.scss";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import CommonModal from '../../Components/Models/commonModal';
import { fetchUrl, apiPost,apiPatch } from '../../ApiActions/Actions';
import { ADD_CANDIDATES_API_URL,
  UPDATE_CANDIDATE_ABOUT_API_URL,
  ADD_CANDIDATE_EDUCATION_API_URL,
  ADD_CANDIDATE_EXPERIRNCE_API_URL,
  GET_CADNDIDATES_JOB_ORDERS_API_URL,
  GET_PARSING_FILE_DATA,
  GET_CANDIDATE_JOB_ORDERS_STATUS,
  UPDATE_CANDIDATE_PROFILEPIC_API_URL} from '../../Configurations/ConfigApi';
import SnakBar from "../../Components/Models/snakbar";
import { withRouter } from "react-router";
import { CandidateDetails } from "./candidateDetails";
import {candidateCheckfileds} from '../../Constants/candidateField'
import { useSelector,useDispatch } from "react-redux";
import  AddExperience  from "./Experience/addExperience";
import AddEducation from "./Education/Addeducation";
import AddNewJobOrder from "./joborder/jobOrder";
import {EditorState,convertToRaw, ContentState} from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CandidateEditor from "../../Components/TextEditor/candidateEditor";
import WysiwygEditor  from "../../Components/TextEditor/WysiwygEditor";
import logoEmblem from "../../Assets/Images/emblem.svg";

const AddCandidate = (props) => {
  let id = sessionStorage.getItem("editId");
  const dispatch = useDispatch()
  const [profile_pic, setProfilePic] = useState("");
  const [resume_name, setResume] = useState("");
  const [parsingfile, setParsingfile] = useState("");
  const [loader,setLoader] = useState(false)
  const [skills,setSkills] = useState([]);
  const [errors,setErrors] = useState({})
  const [addExperience,setAddExperience] = useState(["add"])
  const [addEducation,setAddEducation] = useState(["add"])
  const [parsEduData,setParsEdudata] = useState([{}])
  const [parsExpData,setParsExpdata] = useState([{}])
  const [addJobOrder,setJobOrder] = useState(["add"])
  const [update_Encrypted_id,setUpdate_Encrypted_id] = useState("")
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [dropIcons,setDropIcons] = useState("fa-info-circle text-warning")
  const [componentUpdate,setComponentUpdate] = useState(false)
  const [formSubmitted , setFormSubmitted] = useState(false)
  const [aboutCandidate,setaboutCandidate] = useState('')
  const [expDelete,setExpDelete] = useState(true)
  const [eduDelete,setEduDelete] = useState(true)
  const [isParsing,setParsing] = useState(false)
  const[candidateSkills,setcandidateSkills] = useState([])
  const [candidateFields,setCandidateFields] = useState({
    country:"",
    state:"",
    city:"",
    name:"",
    primary_mobile:"",
    secondary_mobile:"",
    primary_email:"",
    secondary_email:"",
    identity_card_name:"",
    identity_card_number:"",
    address:"test",
    exp_years:"",
    exp_months:"",
    current_ctc:"",
    expected_ctc:"",
    fixed_ctc:"",
    variable_ctc:"",
    stocks:"",
    notice_period:"",
    resume_source:"",
    resume_subsource:"",
    profile_pic_url:"",
    free_pool:"0",
  });
  const CandidateExpList = useSelector(state=>state.Edu_Exp_Reduser.CandidateExpList)
  const CandidateEduList = useSelector(state=>state.Edu_Exp_Reduser.CandidateEduList)
  const CandidateJOList = useSelector(state=>state.Edu_Exp_Reduser.CandidateJOList)
  const [fieldserror,setFieldErros] = useState(false)
  const [imgchanged,setimgchanged] = useState(false)
  
    
  let html =  "<p></p>";

  useEffect(()=>{
    dispatch({type:"EditCandidateId",payload:""})
    dispatch({type:"candidateprofileId",payload:""})
    dispatch({type:"RemoveUpdatedCandidateEduData"})
  },[])
 
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorStates = EditorState.createWithContent(contentState);

  const checkValidation = () => {
    let checkFields = { current_ctc: ["The current ctc field is required."],
    exp_months: ["The exp months field is required."],
    exp_years: ["The exp years field is required."],
    expected_ctc: ["The expected ctc field is required."],
    name: ["The name field is required."],
    notice_period: ["The notice period field is required."],
    primary_email: ["The primary email field is required."],
    primary_mobile: ["The primary mobile field is required."],
  }
    
    for (const key in candidateFields) {
      if(candidateFields[key] || candidateFields['exp_months'] == 0){
        delete checkFields[key]
      }
    }

    let filterData = CandidateExpList.filter((list)=>{
      if(list['company_name'] || list['job_title'] || list['company_name'] ){
          return true
      } 
    })

    for (let i = 0; i < filterData.length; i++) {
     if(!filterData[i]["company_name"]){
      checkFields.company_name= ["The company name field is required in Experience."]
     }else{
       delete checkFields.company_name
     }
     if(!filterData[i]["job_title"]){
      checkFields.job_title = ["The job title field is required in Experience."]
     }else{
      delete checkFields.job_title
     }
     if(filterData[i]["company_name"] && filterData[i]["company_name"].trim().length >= 20){
      checkFields.company_name_limit = ["The company name must not be greater than 20 characters."]
     }else{
      // delete checkFields.company_name_limit
     }

     if(!filterData[i]["start_date"]){
      checkFields.start_date = ["The From Date field is required in Experience."]
     }

     if(!filterData[i]["end_date"] && filterData[i]["still_working"] == 0){
      checkFields.end_date = ["The To Date field is required in Experience."]
     }
    
    }
   
    let filterEduData = CandidateEduList.filter((list)=>{
      if(list['university'] || list['qualification'] ){
          return true
      } 
    })

    for (let i = 0; i < filterEduData.length; i++) {
      if(!filterEduData[i]["university"]){
       checkFields.passout_percentage= ["The university field is required In Education."]
      }else{
        delete checkFields.passout_percentage
      }
      if(filterEduData[i]["still_studying"] === '0' &&  !filterEduData[i]["passout_year"]){
       checkFields.passout_year = ["The passout year field is required in Education."]
      }else{
       delete checkFields.passout_year
      }
      if(!filterEduData[i]["qualification"]){
        checkFields.qualification= ["The qualification field is required In Education."]
       }else{
         delete checkFields.qualification
       }
     
       if(filterEduData[i]["still_studying"] === '0' && filterEduData[i]["passout_percentage"] && filterEduData[i]["passout_percentage"] < 2){
        
        checkFields.passout_percentage_limit = ["The passout percentage must be at least 2."]
       }
     }


     if(candidateFields?.free_pool === '0'){
     for (let i = 0; i < CandidateJOList.length; i++) {
      if(!CandidateJOList[i]["job_id"]){
       checkFields.job_id= ["The job Order Name field is required In Job Orders."]
      }else{
        delete checkFields.job_id
      }
      if(!CandidateJOList[i]["user_id"]){
       checkFields.user_id = ["The role field is required in Job Orders."]
      }else{
       delete checkFields.user_id
      }
     }
     }else{
      delete checkFields.job_id;
      delete checkFields.user_id
    }

    if(Object.keys(checkFields).length > 0){
        setErrors(checkFields)
        setFieldErros(true)
        setDropIcons("fa-times-circle text-danger")
        setTimeout(()=>{
          setErrors({})
          setFieldErros(false)
        },2000)
      return false
    }else{
      return true
    }
  }


  const saveCandidate = ()  =>{
   
    let data = new FormData()
    data.append("country",candidateFields.country)
    data.append("state",candidateFields.state)
    data.append("city",candidateFields.city)
    data.append("parsing_file_name",parsingfile)
    data.append("resume_name",resume_name)
    data.append("name",candidateFields.name)
    data.append("primary_mobile",candidateFields.primary_mobile)
    data.append("secondary_mobile",candidateFields.secondary_mobile)
    data.append("primary_email",candidateFields.primary_email)
    data.append("secondary_email",candidateFields.secondary_email)
    data.append("identity_card_name",candidateFields.identity_card_name)
    data.append("identity_card_number",candidateFields.identity_card_number)
    data.append("address","test")
    data.append("exp_years",candidateFields.exp_years)
    data.append("exp_months",candidateFields.exp_months)
    data.append("current_ctc",candidateFields.current_ctc)
    data.append("expected_ctc",candidateFields.expected_ctc)
    data.append("fixed_ctc",candidateFields.fixed_ctc)
    data.append("variable_ctc",candidateFields.variable_ctc)
    data.append("stocks",candidateFields.stocks)
    data.append("notice_period",candidateFields.notice_period)
    data.append("resume_source",candidateFields.resume_source)
    data.append("resume_subsource",candidateFields.resume_subsource)
    data.append("profile_pic",profile_pic) 
    // if(!profile_pic && candidateFields.profile_pic_url){
    //   data.append("profile_pic_url",candidateFields.profile_pic_url)
    // }
    // if(profile_pic|| (!profile_pic && !candidateFields.profile_pic_url)){
    //   data.append("profile_pic",profile_pic) 
    // }
    data.append('free_pool',candidateFields.free_pool)
  
    for (let i = 0; i < skills.length; i++) {
      data.append(`skill[${i}]`,skills[i])
    }

    let check = checkValidation()

    if(check ){
      setLoader(true)
      setFormSubmitted(false)
      setErrors({});
      apiPost(ADD_CANDIDATES_API_URL, data, ({ status, response }) => {
  
        if (status) {
          if(response.data?.status){
            setSuccessMsg({notes:[response.data?.message]})
            setSnakBarOpen(true)
            let res = response.data
            setUpdate_Encrypted_id(res.data.encrypted_id)
            setDropIcons("fa-check-circle text-success")
          
          for (let i = 0; i < CandidateEduList.length; i++) {
            let data = CandidateEduList[i]
            if(data['university'] || data['qualification']){
              data.candidate_id = res.data.encrypted_id
              data.passout_year = (data.still_studying === '0') ? data.passout_year : new Date().getFullYear()
              data.passout_percentage = (data.still_studying === '0') ? data.passout_percentage : 2
                addEducationApi(data)
            }
          }

          for (let i = 0; i < CandidateExpList.length; i++) {
            let data = CandidateExpList[i]
            if(data['company_name'] || data['job_title'] || data['company_name']){
              let todateformat = new Date(CandidateExpList[i].start_date)
              let fromdateformat = new Date(CandidateExpList[i].end_date)
              data.start_date = CandidateExpList[i].start_date ? `${getYear(todateformat)}-${getMonth(todateformat)+1}-1` : ''
              data.end_date = (CandidateExpList[i].end_date && CandidateExpList[i].still_working == '0') ? `${getYear(fromdateformat)}-${getMonth(fromdateformat)+1}-1` : ''
              data.candidate_id =res.data.encrypted_id
                addExperienceApi(data)
            }
           
          }

          if(candidateFields?.free_pool === '0'){
            for (let i = 0; i < CandidateJOList.length; i++) {
              let data = CandidateJOList[i]
              data.candidate_id = res.data.encrypted_id
              if(data.job_id && data.user_id){
                  addJobOrderApi(data)
              }
            }
          }

          if(!profile_pic && candidateFields.profile_pic_url){
            let picdata = {
              candidate_id:  res.data.encrypted_id,
              profile_pic_url:candidateFields.profile_pic_url
            }
            apiPost(UPDATE_CANDIDATE_PROFILEPIC_API_URL, picdata, ({ status, response }) => {
              
            })
            //  data.append("profile_pic_url",candidateFields.profile_pic_url)
          }

           saveAboutCandidate(res.data.encrypted_id)

           setTimeout(()=>{
            setFormSubmitted(true)
          },3000) 
        }else{
           let errMsg = {
            err_msg: [response.data?.message]
           } 
           setErrors(errMsg)
           setFieldErros(true)
           setTimeout(()=>{
             setErrors({})
             setFieldErros(false)
           },2000)
        }         
        }else{

          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
          setDropIcons("fa-times-circle text-danger")
        }
        setLoader(false)
      })
    }
  }

  useEffect(()=>{
    
    if(formSubmitted && Object.keys(errors).length === 0){
      cancelfun()
    }else if(formSubmitted && Object.keys(errors).length >= 0){
      
      dispatch({ type: "candidateprofileId" , payload: ''});
      dispatch({ type: "EditCandidateId" , payload: update_Encrypted_id});
      props.changetab(2)
    }
  },[errors,formSubmitted])

  const cancelfun = () =>{
    dispatch({ type: "candidateprofileId" , payload: update_Encrypted_id});
    dispatch({ type: "EditCandidateId" , payload: ''});
    props.changetab(2)
  }

  const addEducationApi = (data) =>{

      apiPost(ADD_CANDIDATE_EDUCATION_API_URL, data, ({ status, response }) => {
        if (status) {
         
          
        }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
         // setRedirect(true)
        }
    })
  }

  const addExperienceApi = (data) =>{
 
    apiPost(ADD_CANDIDATE_EXPERIRNCE_API_URL, data, ({ status, response }) => {
      if (status) {

      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
        // setRedirect(true)
      }
 })

} 

const addJobOrderApi = (data) => {

  apiPost(GET_CADNDIDATES_JOB_ORDERS_API_URL, data, ({ status, response }) => {
    if (status) {
      let jobOrdersData={
        candidate_id:data.candidate_id,
        job_id: data.job_id,
        recruiter_id: data.user_id,
        mainstatus_id:'P',
        mainsubstatus_id:'103',
      }
      apiPost(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
        if (status) {
        
        }else{
            
        }
    })
    } else {
      let msg = JSON.parse(response.request.response).errors
      setErrors(msg)
      setFieldErros(true)
      // setRedirect(true)
    }
  })
}

  const addMoreExperience = ()=>{
    setAddExperience([...addExperience,`add${addExperience.length+1}`])
    setParsExpdata([...parsExpData,{}])
  }

  const saveAboutCandidate =(id)=>{
    let childData = aboutCandidate;//draftToHtml(convertToRaw(editorState.getCurrentContent()))
    let data = {
      candidate_id: id,
      about: childData,
    }
   
    if(childData){
      apiPatch(UPDATE_CANDIDATE_ABOUT_API_URL, data, ({ status, response }) => {
        if (status) {
         
        }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
   })

    }
   
  }

  const deleteEducationCount=(index,type)=>{
    if(type==="education"){
      let data = addEducation
      let eduData = parsEduData
      data.splice(index,1)
      eduData.splice(index,1)
     setAddEducation(data)
     setParsEdudata(eduData)
     if( parsEduData.length > 0 && Object.keys(parsEduData[0]).length > 0){
      setEduDelete(!expDelete)
    }else{
      setComponentUpdate(!componentUpdate)
    }
    }else if (type==="experience"){
      let data = addExperience
      let expdata = parsExpData
      expdata.splice(index,1)
      data.splice(index,1)
      setParsExpdata(expdata)
   
      setAddExperience(data)
      if( parsExpData.length > 0 && Object.keys(parsExpData[0]).length > 0){
        setExpDelete(!expDelete)
      }else{
        setComponentUpdate(!componentUpdate)
      }
    }else{
      let data = addJobOrder
      data.splice(index,1)
     setJobOrder(data)
     setComponentUpdate(!componentUpdate)
    }
  }

  useEffect(()=>{
    return()=>{
      dispatch({type:"RemoveAllCandidateExp"})
      dispatch({type:"RemoveAllCandidateEdu"})
      dispatch({type:"RemoveAllCandidateJO"})

    }
  },[])

  useEffect(()=>{
    if(parsingfile){
      setLoader(true)
      let  formdata = new FormData()
      formdata.append("parsing_file_name",parsingfile)
      apiPost(GET_PARSING_FILE_DATA, formdata, ({ status, response }) => {
     
        if (status) {
          if(response?.data?.status){
            let data = response?.data?.data
            setCandidateFields({
              ...candidateFields,
             city: data?.current_location,
             name: data?.username,
             primary_mobile: data?.primary_mobile,
             primary_email: data?.primary_email,
             secondary_email: data?.secondary_email,
             exp_years: data?.exp_in_years,
             exp_months: data?.exp_in_months,
             current_ctc: data?.ctc,
             notice_period: data?.notice_period,
             expected_joining_date: new Date(),
             profile_pic_url: data?.profile_pic
           })
           let exp = data?.exp_details 
           let education = data?.education_details
           let eduAddblock = []
           let eduAdddata = []
           let expAddblock = []
           let expAdddata = []
           if(exp.length > 0){
             setAddExperience([])
             dispatch({type:"RemoveAllCandidateExp"})
           }
           if(education.length > 0){
             setAddEducation([])
             dispatch({type:"RemoveAllCandidateEdu"})
           }
   
           for (let e = 0; e < education.length; e++) {
             eduAddblock.push("add")
             eduAdddata.push(education[e])
           }
           for (let n = 0; n < exp.length; n++) {
             expAddblock.push("add")
             expAdddata.push(exp[n])
           }
           let skillData = data?.secondary_skills
           let primarySkillData = data?.primary_skills
           let allData = skillData.concat(primarySkillData)
           let skillList = allData?.map((res)=>{
               return {skill_name: res, title: res}
           })
           
           setSkills(allData)
           setcandidateSkills(skillList)
           setParsing(true)
           setParsEdudata(eduAdddata)
           setParsExpdata(expAdddata)
           setAddExperience(expAddblock)
           setAddEducation(eduAddblock)
   
          }else{
            setErrors({msg:[response?.data?.message]})
            setFieldErros(true)
            setParsingfile('')
          }  
        }
        setLoader(false)
      })
    }
  },[parsingfile])


  return (
    <>
       {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid px-2 px-md-3 bg-none">
      <div className="row no-gutters">
        <div className="col-md-12 pt-md-3 px-md-0">
        <div className="  px-md-0">
          <div className="row mb-5">
            <div className="col-12">
              <div className="upload-file-section">
                <div className="row d-flex justify-content-between">
                  <div className="col-md-3">
                    <div className="card ">
                      <div className="card-header text-center">
                        <h5 className="pb-0 mb-0">Select Resume </h5>
                      </div>
                      <div className="card-body text-center">
                        {/* <input type="file" onChange={(e)=>console.log(e.target.value)}/> */}
                        <DragAndDrop
                          id={"resume"}
                          onselect={setResume}
                          header={"resume"}
                          data={resume_name?.name}
                        />

                        <div className="text-center">
                          <i className={`fas ${dropIcons} fa-2x `}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card ">
                      <div className="card-header text-center">
                        <h5 className="pb-0 mb-0">Select Parsing File</h5>
                       
                      </div>
                      <div className="card-body text-center">
                        <DragAndDrop
                          id={"parsingfile"}
                          onselect={setParsingfile}
                          header={"parsing"}
                          data={parsingfile?.name}                          
                        />
                        <div className="text-center">
                          <i className={`fas ${dropIcons} fa-2x `}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card ">
                      <div className="card-header text-center">
                        <h5 className="pb-0 mb-0">Select Profile Pic</h5>
                      </div>
                      <div className="card-body text-center">
                        <DragAndDrop
                          id={"profile"}
                          onselect={setProfilePic}
                          header={"photo"}
                          data={profile_pic?.name}
                          image={true}
                          setProfilePic={setParsingfile}
                          setimgchanged={setimgchanged}
                        />
                        <div className="text-center">
                          <i className={`fas ${dropIcons} fa-2x `}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CandidateDetails 
          candidateFields={candidateFields} 
          setCandidateFields={setCandidateFields} 
          saveCandidate={saveCandidate}  
          setSkills={setSkills}
          Skills={candidateSkills}
          edit={false}
          isParsing={isParsing}
          /> 

              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3 mb-4">
                    <div className="row px-4">
                      <div className="col-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold  pb-0 mb-0">Experience</h4>
                        </div>
                      </div>
                      <div className="col-5 text-right">
                        <div className="text-center d-flex justify-content-end">
                          <button class="btn btn-xs btn-violet text-white" onClick={addMoreExperience}>Add More
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr />
                    {!expDelete &&addExperience.map((data,index)=>{
                      return <>
                      {index > 0 && <><hr/><br/></>}
                       <AddExperience  id={update_Encrypted_id} deleteCount={()=>deleteEducationCount(index,"experience")} index={index} edit={false} data={parsExpData[index]}/>
                       </>
                    }) }
                    {expDelete &&addExperience.map((data,index)=>{
                  return <>
                  {index > 0 && <><hr/><br/></>}
                    <AddExperience  id={update_Encrypted_id} deleteCount={()=>deleteEducationCount(index,"experience")} index={index} edit={false} data={parsExpData[index]}/>
                    </>
                }) }
                  </div>
                </div>
              </div>
          
              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pb-3 mb-4 pt-3">
                    <div className="row  px-4">
                      <div className="col-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">Education</h4>
                        </div>
                      </div>
                      <div className="col-5 text-right">
                        <div className="text-center d-flex justify-content-end">
                          <button class="btn btn-xs btn-violet text-white " onClick={()=>
                            {
                              setAddEducation([...addEducation,`add${addEducation.length+1}`])
                              setParsEdudata([...parsEduData,{}])
                            }}>Add More</button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {!eduDelete && addEducation.map((data,index)=>{
                      return <>
                      {index > 0 && <><hr/><br/></>}
                        <AddEducation id={update_Encrypted_id} deleteCount={()=>deleteEducationCount(index,"education")} index={index} edit={false} data={parsEduData[index]}/>
                       </>
                    })}
                     {eduDelete && addEducation.map((data,index)=>{
                      return <>
                      {index > 0 && <><hr/><br/></>}
                        <AddEducation id={update_Encrypted_id} deleteCount={()=>deleteEducationCount(index,"education")} index={index} edit={false} data={parsEduData[index]}/>
                       </>
                    })}
                  </div>
                </div>
              </div>

         
    {/* <TextEditor saveCandidate={saveCandidate} title={"About Candidate"} edit={false} data={""}/> */}

    {/* <CandidateEditor editorState={editorState} onEditorStateChange={onEditorStateChange} title={"About Candidate"}/> */}

    <WysiwygEditor   editDetails={setaboutCandidate} title={"About Candidate"}/>
    


    <div className="row  mb-5">
    <div className="col-md-12">
      <div className="col-md-12 pb-4 text-center">
        <div className="custom-control custom-checkbox ">
          <Checkbox
            name="checkedB"
            color="primary"
            className="p-0 m-0 align-middle font-weight-bold"
            onChange={(e)=>setCandidateFields({...candidateFields,free_pool:e.target.checked ? "1": "0"})}
          />{" "}
          &nbsp; <strong>Add this profile to free pool</strong>
        </div>
      </div>
      <div className="row  table-custom">

        <div className= {`col-md-12 ${candidateFields?.free_pool === "1" ? "d-none" : ""}`}>
          <div className="card p-4 pt-3 pb-3 mb-0">
          <div className="row pb-0 px-md-4">
          <div className="col-7">
            <div className=" d-flex justify-content-end">
              <h4 className="text-center font-weight-bold pb-0 mb-0">
                Add To Job Order
              </h4>
            </div>
          </div>
          <div className="col-5 text-right ">
            <div className="text-center d-flex justify-content-end">
              <button class="btn btn-xs btn-violet text-white" onClick={()=>setJobOrder([...addJobOrder,"add"])}>
                Add More
              </button>
            </div>
          </div>
        </div>
       <hr/>
           
           {addJobOrder.map((data,index)=>{
              return <>
              {index > 0 && <><hr/><br/></>}
                <AddNewJobOrder id={update_Encrypted_id} 
                deleteCount={()=>deleteEducationCount(index,"joborder")} 
                index={index}/>
                
                </>
            }) }

          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="text-center">
        <div className=" d-inline-block my-3">
          <button class="btn btn-sm btn-green-dark text-white " onClick={()=> props.changetab(0)}>
            Cancel
          </button>
        </div>
        <div className=" d-inline-block mx-2">
          <button class="btn btn-sm btn-orange text-white" onClick={saveCandidate}>Save</button>
        </div>
      </div>


          </div>
        </div>
      </div>
    </div>


      {/* <CommonModal title={"Success"} handleClose={handleClose} open={cadidateSuccess} button={"ok"} btnfunc={handleClose}>
        {successmsgs}
      </CommonModal> */}
         <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
         <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success"/>
       
    </>
  );
};

export default withRouter(AddCandidate);
