import React, { useEffect, useState } from 'react'
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchUrl } from '../../ApiActions/Actions';
import { GET_JOB_QUALIFICATIONS, GET_QULIFICATION_API } from '../../Configurations/ConfigApi';


export const Autocompletejobs = (props) => {

  const [Jobname, setJobName] = useState("")
  const [Joblist, setJoblist] = useState([]);
  const [defaultData,setDefaultData] = useState([])   


  const getjobsList = (value) => {
    setJobName(value)     
    fetchUrl(`${GET_QULIFICATION_API}?q=${value}`, ({ status, response }) => {
      if (status) {
        if (response?.data?.status === true) {
       
          let { data } = response.data
          for (let i = 0; i < data.length; i++) {
            data[i].title = data[i].name
          }
          setJoblist(data);
        }else{
         let newData = [{title:value}]
        
         setJoblist(newData);
        }
      }
    })
  }


  const addjob = (JobList) => {
    let data = []
    for (let i = 0; i < JobList.length; i++) {
      data.push(JobList[i])       
    }    
    props.setQualifications(data); 
  }


  useEffect(()=>{
    if(props.edit){
      let jobdata  = [...props.updatequalifications]      
      if(jobdata.length > 0){         
        setDefaultData(jobdata);         
      }
    }  
  },[props])

  useEffect(()=>{
    
      fetchUrl(`${GET_QULIFICATION_API}`, ({ status, response }) => {
        if (status) {
          if (response?.data?.status === true) {
            let { data } = response.data            
            for (let i = 0; i < data.length; i++) {
              data[i].title = data[i].name
            }
            setJoblist(data)
          }
        }
      })
    
  },[])

  return (
    <>
      <div className="d-flex align-items-end">
        {(defaultData.length > 0 && props.edit)  && <Autocomplete
          multiple={true}
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={Joblist}
          autoSelect
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.title === value.title }          
          defaultValue = {defaultData}
          onChange={(event, newValue) => {
            addjob(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getjobsList(e.target.value)}
            />
          )}
        />}

        {(!props.edit ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={Joblist}
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            addjob(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getjobsList(e.target.value)}
            />
          )}
        />}

      {(defaultData.length === 0 && props.edit ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={Joblist}
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            addjob(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getjobsList(e.target.value)}
            />
          )}
        />}
       
        <span >{" "}*</span>

      </div>
    </>
  )
}
