import React, { useState, useEffect } from "react";
import "./AddCandidate.scss";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import TextEditor from "../../Components/TextEditor/TextEditor";
import Checkbox from "@material-ui/core/Checkbox";
import CommonModal from "../../Components/Models/commonModal";
import File_img from '../../Assets/Images/file_img.png'
import { fetchUrl, apiPost, apiPatch , apiEdit } from "../../ApiActions/Actions";
import {
  GET_SKILLS_API_URL,
  GET_CADNDIDATES_API_URL,
  UPDATE_CANDIDATE_API_URL,
  ADD_CANDIDATE_EDUCATION_API_URL,
  ADD_CANDIDATE_EXPERIRNCE_API_URL,
  UPDATE_CANDIDATE_ABOUT_API_URL,
  GET_CADNDIDATES_JOB_ORDERS_API_URL,
  GET_CADNDIDATES_SKILLS_API_URL,
  GET_UPDATE_CANDIDATE_PROFILE_PIC,
  GET_UPDATE_CANDIDATE_RESUME,
  GET_CANDIDATE_JOB_ORDERS_STATUS,
  EDIT_CANDIDATE_EXPERIRNCE_API_URL,
  EDIT_CANDIDATE_EDUCATION_API_URL,
} from "../../Configurations/ConfigApi";
import { useSelector, useDispatch } from "react-redux";
import  AddExperience  from "./Experience/addExperience";
import AddEducation from "./Education/Addeducation";
import AddNewJobOrder from "./joborder/jobOrder";
import SnakBar from "../../Components/Models/snakbar";
import  Experience  from "./Experience";
import Education from "./Education";
import { withRouter } from "react-router";
import { CandidateDetails } from "./candidateDetails";
import AddJobOrder from "./AddJobOrder";
import { getMonth, getYear , getDate} from "date-fns";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {WysiwygCandidateupdate} from "../../Components/TextEditor/wysiwygCandidateupdate";
import logoEmblem from "../../Assets/Images/emblem.svg";

const UpdateCandidate = (props) => {
  const dispatch = useDispatch();
  const [emails, setEmails] = useState([]);
  const [profile_pic, setProfilePic] = useState("");
  const [resume_name, setResume] = useState("");
  const [parsingfile, setParsingfile] = useState("");
  const [addSkillsModal, setAddSkillsModal] = useState(false);
  const [cadidateSuccess, setCadidateSuccess] = useState(false);
  const [successmsgs, setSuccessmsgs] = useState("");
  const [skills, setSkills] = useState([]);
  const [errors, setErrors] = useState({});
  const [addExperience, setAddExperience] = useState([]);
  const [addEducation, setAddEducation] = useState([]);
  const [addJobOrder, setJobOrder] = useState([]);
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [jobOrders, setJobOrders] = useState([]);
  const[candidateSkills,setcandidateSkills] = useState([])
  const [loader,setLoader] = useState(false)

  const [dropIcons, setDropIcons] = useState("fa-info-circle text-warning");
  const [formSubmitted , setFormSubmitted] = useState(false)
  const [imgchanged , setimgchanged] = useState(false)
  const [aboutcandidate , setaboutcandidate] = useState('')

  const update_Encrypted_id = useSelector(
    (state) => state.PageReduser.EditCandidateId
  );
  const CandidateExpList = useSelector(
    (state) => state.Edu_Exp_Reduser.CandidateExpList
  );
  const CandidateEduList = useSelector(
    (state) => state.Edu_Exp_Reduser.CandidateEduList
  );
  const CandidateJOList = useSelector(
    (state) => state.Edu_Exp_Reduser.CandidateJOList
  );
  const updatedCandidateExpData = useSelector(
    (state)=>state.Edu_Exp_Reduser.updatedCandidateExpData
  );

  const updatedCandidateEduData = useSelector(
    (state)=>state.Edu_Exp_Reduser.updatedCandidateEduData
  );

  const updateCandidateJOdata = useSelector(
    (state)=>state.Edu_Exp_Reduser.updateCandidateJOdata
  );
 
  const [componentUpdate, setComponentUpdate] = useState(false);
  const [candidateFields, setCandidateFields] = useState({
    country: "",
    state: "",
    city: "",
    name: "",
    primary_mobile: "",
    secondary_mobile: "",
    primary_email: "",
    secondary_email: "",
    identity_card_name: "",
    identity_card_number: "",
    address: "test",
    exp_years: "",
    exp_months: "",
    current_ctc: "",
    expected_ctc: "",
    fixed_ctc: "",
    variable_ctc: "",
    stocks: "",
    notice_period: "",
    offered_ctc: "",
    expected_joining_date: new Date(),
    about_candidate: "",
    resume_source: "",
    resume_subsource: "",
    free_pool:"0",
    resume_name:"",
    resume_url:"",
    profile_pic:"",
    profile_pic_url:""
  });

  const [testEditorData, setTextEditorData] = useState(false);

  let html = candidateFields?.about_candidate
    ? candidateFields?.about_candidate
    : "";

  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorStates = EditorState.createWithContent(contentState);

  const [editorState, setEditorState] = useState(editorStates);

  const onEditorStateChange = (state) => {
    setEditorState(state);
  };

  const [skillname, setSkillName] = useState("");

  const [fieldserror, setFieldErros] = useState(false);

  // '2014-08-18T21:11:54' format



  const handleClose = () => {
    setAddSkillsModal(false);
    setCadidateSuccess(false);
  };





  const checkValidation = () => {
    
    let checkFields = { current_ctc: ["The current ctc field is required."],
    exp_months: ["The exp months field is required."],
    exp_years: ["The exp years field is required."],
    expected_ctc: ["The expected ctc field is required."],
    name: ["The name field is required."],
    notice_period: ["The notice period field is required."],
    primary_email: ["The primary email field is required."],
    primary_mobile: ["The primary mobile field is required."],
    expected_ctc: ["The expected ctc field is required."]
  };

    for (const key in candidateFields) {
      if (candidateFields[key] || candidateFields['exp_months'] == 0) {
        delete checkFields[key];
      }
    }
   
    let filterData = updatedCandidateExpData.filter((list)=>{
      if(list['company_name'] || list['job_title'] || list['company_name'] ){
          return true
      } 
    }) 
    filterData.forEach((res)=>{
      if (!res["company_name"]) {
        checkFields.company_name = [
          "The company name field is required in Experience.",
        ];
      } 
      if (!res["job_title"]) {
        checkFields.job_title = [
          "The job title field is required in Experience.",
        ];
      }
      if(res["company_name"] && res["company_name"].trim().length >= 20){
        checkFields.company_name_limit = ["The company name must not be greater than 20 characters."]
       }
  
       if(!res["start_date"]){
        checkFields.start_date = ["The From Date field is required in Experience."]
       }

       if(!res["end_date"] && res["still_working"] == '0'){
        checkFields.end_date = ["The To date field is required in Experience."]
       }
    })

    let filterexpData = CandidateExpList.filter((list)=>{
      if(list['company_name'] || list['job_title'] || list['company_name'] ){
          return true
      } 
    }) 
    filterexpData.forEach((res)=>{
      if (!res["company_name"]) {
        checkFields.company_name = [
          "The company name field is required in Experience.",
        ];
      } 
      if (!res["job_title"]) {
        checkFields.job_title = [
          "The job title field is required in Experience.",
        ];
      }
      if(res["company_name"] && res["company_name"].trim().length >= 20){
        checkFields.company_name_limit = ["The company name must not be greater than 20 characters."]
       }
  
       if(!res["start_date"]){
        checkFields.start_date = ["The From Date field is required in Experience."]
       }

       if(!res["end_date"] && res["still_working"] == '0'){
        checkFields.end_date = ["The To date field is required in Experience."]
       }
    })
  

    for (let i = 0; i < CandidateEduList.length; i++) {
     
      if (!CandidateEduList[i]["university"]) {
        checkFields.passout_percentage = [
          "The university field is required In Education.",
        ];
      } else {
        delete checkFields.passout_percentage;
      }
      if (CandidateEduList[i]["still_studying"] === '0' && !CandidateEduList[i]["passout_year"]) {
        checkFields.passout_year = [
          "The passout year field is required in Education.",
        ];
      } else {
        delete checkFields.passout_year;
      }
      if (!CandidateEduList[i]["qualification"]) {
        checkFields.qualification = [
          "The qualification field is required In Education.",
        ];
      } else {
        delete checkFields.qualification;
      }
      // if (CandidateEduList[i]["still_studying"] === '0' && !CandidateEduList[i]["passout_percentage"]) {
      //   checkFields.university = [
      //     "The passout percentage is required in Education.",
      //   ];
      // } else {
      //   delete checkFields.university;
      // }

      if(CandidateEduList[i]["still_studying"] === '0' && CandidateEduList[i]["passout_percentage"] && CandidateEduList[i]["passout_percentage"] < 2){
      
        checkFields.passout_percentage_limit = ["The passout percentage must be at least 2."]
       }
    }

    if(candidateFields?.free_pool === '0'){
      for (let i = 0; i < CandidateJOList.length; i++) {
       if(!CandidateJOList[i]["job_id"]){
        checkFields.job_id= ["The job Order Name field is required In Job Orders."]
       }else{
         delete checkFields.job_id
       }
       if(!CandidateJOList[i]["user_id"]){
        checkFields.user_id = ["The role field is required in Job Orders."]
       }else{
        delete checkFields.user_id
       }
      }
    }
   
    if (Object.keys(checkFields).length > 0) {
      setErrors(checkFields);
      setFieldErros(true);
      setDropIcons("fa-times-circle text-danger");
      setTimeout(() => {
        setErrors([]);
        setFieldErros(false);
      }, 2000);
      return false;
    } else {
      return true;
    }
  };

  const saveCandidate = () => {
    setCadidateSuccess(false);
    setSuccessmsgs({});
    let data = new FormData();
    data.append("country", candidateFields.country);
    data.append("state", candidateFields.state);
    data.append("city", candidateFields.city);
    data.append("parsing_file_name", parsingfile);
    data.append("name", candidateFields.name);
    data.append("primary_mobile", candidateFields.primary_mobile);
    data.append("secondary_mobile", candidateFields.secondary_mobile);
    data.append("primary_email", candidateFields.primary_email);
    data.append("secondary_email", candidateFields.secondary_email);
    data.append("identity_card_name", candidateFields.identity_card_name);
    data.append("identity_card_number", candidateFields.identity_card_number);
    data.append("address", "test");
    data.append("exp_years", candidateFields.exp_years);
    data.append("exp_months", candidateFields.exp_months);
    data.append("current_ctc", candidateFields.current_ctc);
    data.append("expected_ctc", candidateFields.expected_ctc);
    data.append("fixed_ctc", candidateFields.fixed_ctc);
    data.append("variable_ctc", candidateFields.variable_ctc);
    data.append("stocks", candidateFields.stocks);
    data.append("notice_period", candidateFields.notice_period);
    data.append("encrypted_id", update_Encrypted_id);
    data.append("resume_source", candidateFields.resume_source);
    data.append("resume_subsource", candidateFields.resume_subsource);
    data.append("free_pool", candidateFields.free_pool);

    for (let i = 0; i < skills.length; i++) {
      data.append(`skill[${i}]`, skills[i]);
    }

    let check = checkValidation();
    
    if (check) {
      setFormSubmitted(false)
      setErrors({});
      apiPost(UPDATE_CANDIDATE_API_URL, data, ({ status, response }) => {
        if (status) {

          saveAboutCandidate(aboutcandidate);
         
          let res = response.data;
          setDropIcons("fa-check-circle text-success");

          for (let i = 0; i < updatedCandidateExpData.length; i++) {
            let data = updatedCandidateExpData[i];
            if(data?.update){
              data.candidate_id = update_Encrypted_id;
              delete data.update
              EditExpdetails(data);
            }
          }

          for (let i = 0; i < updatedCandidateEduData.length; i++) {
            let data = updatedCandidateEduData[i];
            if(data?.update){
              data.candidate_id = update_Encrypted_id;
              delete data.update
              EditEdudetails(data);
            }
          } 
          
          for (let i = 0; i < updateCandidateJOdata.length; i++) {
            let data = updateCandidateJOdata[i];
            if(data?.update){
              data.candidate_id = update_Encrypted_id;
              let newjoiningDate = new Date(data?.joiningDate);
              data.expected_joining_date = `${getYear(newjoiningDate)}-${
                getMonth(newjoiningDate) + 1
              }-${getDate(newjoiningDate)}`;
          
              delete data.update
              delete data.joiningDate
              EditJodetails(data);
            }
          }
          for (let i = 0; i < CandidateJOList.length; i++) {
            let data = CandidateJOList[i]
            data.candidate_id = update_Encrypted_id
            if(data.job_id && data.user_id){
              addJobOrderApi(data)
            }
          }

          setTimeout(()=>{
            for (let i = 0; i < CandidateEduList.length; i++) {
              let data = CandidateEduList[i];
              data.candidate_id = update_Encrypted_id;
              data.passout_year = (data.still_studying === '0') ? data.passout_year : new Date().getFullYear()
              data.passout_percentage = (data.still_studying === '0') ? data.passout_percentage : 2
              if(!data.update){
                addEducationApi(data,i);
              }
            }
          },1000)

          setTimeout(()=>{
            for (let i = 0; i < CandidateExpList.length; i++) {
              let data = CandidateExpList[i];
              let todateformat = new Date(CandidateExpList[i].start_date) ;
              let fromdateformat = new Date(CandidateExpList[i].end_date);
              data.start_date =  CandidateExpList[i].start_date ? `${getYear(todateformat)}-${
                getMonth(todateformat) + 1
              }-1` : '';
              data.end_date = (CandidateExpList[i].end_date && CandidateExpList[i].still_working == '0') ? `${getYear(fromdateformat)}-${
                getMonth(fromdateformat) + 1
              }-1` : '';
              data.candidate_id = update_Encrypted_id;
            
              if(!data.update && data.company_name && data.job_title){
                addExperienceApi(data,i);
              }
            }
          },500)

          setTimeout(()=>{
            setCadidateSuccess(true);
            // setSuccessmsgs(response?.data?.message);
            setSuccessmsgs({msg:[response.data?.message]})
          },2000)
         
          setTimeout(()=>{
            setFormSubmitted(true)
          },4000)

        } else {
          let msg = JSON.parse(response?.request?.response).errors;
          setErrors(msg);
          setFieldErros(true);
          setDropIcons("fa-times-circle text-danger");
        }
      });
    }

    setTextEditorData(true);
  };

  useEffect(()=>{
  
    if(formSubmitted && Object.keys(errors).length === 0){
      cancelfun()
    }else{
     
    }
  },[errors,formSubmitted])

  const EditEdudetails = (data)=>{
    let editdata = data
    apiPost(EDIT_CANDIDATE_EDUCATION_API_URL, editdata, ({ status, response }) => {
      if (status) {

        // setModalmsg("Education Updated successfully")
        // setUpdatemodal(true);
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
  })
  }

  const EditExpdetails = (data)=>{
    let editdata = data
    let todateformat = new Date(data.start_date)
    let fromdateformat = new Date(data.end_date)
    editdata.start_date = `${getYear(todateformat)}-${getMonth(todateformat)+1}-1` 
    editdata.end_date =  `${getYear(fromdateformat)}-${getMonth(fromdateformat)+1}-1`
    apiPost(EDIT_CANDIDATE_EXPERIRNCE_API_URL, editdata, ({ status, response }) => {
      if (status) {
        // setModalmsg("Experience Updated successfully")
        // setUpdatemodal(true);
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
  })
  }

    const EditJodetails = (data) => {

    apiEdit(GET_CADNDIDATES_JOB_ORDERS_API_URL, data, ({ status, response }) => {
      if (status) {
        // setModalmsg("Experience Updated successfully")
        // setUpdatemodal(true);
      } else {
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
    })
  }


  const addEducationApi = (data,index) => {
    delete data.update
    apiPost(ADD_CANDIDATE_EDUCATION_API_URL, data, ({ status, response }) => {
      if (status) {
         // setEdusuccessMsg(response?.data?.message);
        setSuccessmsgs({...successmsgs, Edumsg:[response.data?.message]})
        dispatch({type:"UpdateCandidateEdu",payload:[index,{...data,update:true}]})
      } else {
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
        dispatch({type:"UpdateCandidateEdu",payload:[index,{...data,update:false}]})
      }
    });
  };

  const addExperienceApi = (data,index) => {
    delete data.update
    apiPost(ADD_CANDIDATE_EXPERIRNCE_API_URL, data, ({ status, response }) => {
      if (status) {
        // setExpSuccessMsg(response?.data?.message);
        setSuccessmsgs({...successmsgs, Expmsg:[response.data?.message]})
        dispatch({
          type: "UpdateCandidateExp",
          payload: [index, {...data,update:true}],
        });
      } else {
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
        dispatch({
          type: "UpdateCandidateExp",
          payload: [index, {...data,update:false}],
        });
      }
    });
  };

  const addJobOrderApi = (data) => {
    apiPost(
      GET_CADNDIDATES_JOB_ORDERS_API_URL,
      data,
      ({ status, response }) => {
        if (status) {
          if(response.data?.status){
            setSuccessmsgs({...successmsgs, jobmsg:[response.data?.message]})
            let jobOrdersData={
              candidate_id:data.candidate_id,
              job_id: data.job_id,
              recruiter_id: data.user_id,
              mainstatus_id:'P',
              mainsubstatus_id:'103',
            }
            apiPost(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
              if (status) {
              
              }else{
                  
              }
            })
          }else{
            setErrors({notes:[response?.data?.message]})
            setFieldErros(true)
          }
        
        } else {
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
      }
    );
  };

  const addSkill = () => {
    if (skillname) {
      let data = {
        name: skillname,
      };
      apiPost(GET_SKILLS_API_URL, data, ({ status, response }) => {
        if (status) {
          let data = skills;
          data.push(skillname);
          setSkills(data);
          setSkillName("");
        }
      });
    }
  };

  const addMoreExperience = () => {
    setAddExperience([...addExperience, "add"]);
  };

  const saveAboutCandidate = (childData) => {     
    let data = {
      candidate_id: update_Encrypted_id,
      about: childData,
    };
    if(childData){
      apiPatch(UPDATE_CANDIDATE_ABOUT_API_URL, data, ({ status, response }) => {
        if (status) {
        
        } else {
          let msg = JSON.parse(response.request.response).errors;
        }
      });
    }
   
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "RemoveAllCandidateExp" });
      dispatch({ type: "RemoveAllCandidateEdu" });
      dispatch({ type: "RemoveAllCandidateJO" });
      dispatch({ type: 'RemoveUpdatedCandidateEduData'});
      dispatch({ type: 'RemoveUpdatedCandidateExpData'});
      dispatch({ type: 'RemoveUpdatedCandidateJOData'});
    };
  }, []);

 
  useEffect(() => {
    if (update_Encrypted_id) {
      setLoader(true)
      fetchUrl(
        `${GET_CADNDIDATES_API_URL}/${update_Encrypted_id}`,
        ({ status, response }) => {
          if (status) {
            let data = response?.data?.data[0];
            setCandidateFields({
              country: data.country_name,
              state: data.state_name,
              city: data.city_name,
              name: data.name,
              primary_mobile: data.primary_mobile,
              secondary_mobile: data.secondary_mobile == '0' ? '' : data.secondary_mobile,
              primary_email: data.primary_email,
              secondary_email: data.secondary_email,
              identity_card_name: data.identity_card_name,
              identity_card_number: data.identity_card_number == '0' ? '' : data.identity_card_number,
              address: data.address,
              exp_years: data.exp_years,
              exp_months: data.exp_months,
              current_ctc: data.current_ctc,
              expected_ctc: data.expected_ctc,
              fixed_ctc: data.fixed_ctc,
              variable_ctc: data.variable_ctc,
              stocks: data.stocks,
              notice_period: data.notice_period,
              offered_ctc: data.offered_ctc,
              expected_joining_date: data.expected_joining_date,
              about_candidate: data.about_candidate,
              resume_source: data.resume_source,
              resume_subsource: data.resume_subsource,
              free_pool: data?.free_pool,
              resume_name:data?.resume_name,
              resume_url:data?.resume_url,
              profile_pic:data?.profile_pic,
              profile_pic_url:data?.profile_pic_url
            });
            setParsingfile(data.parsing_file_name);
            setLoader(false)
          }
        }
      );
      setLoader(true)
      fetchUrl(
        `${ADD_CANDIDATE_EDUCATION_API_URL}/${update_Encrypted_id}`,
        ({ status, response }) => {
          if (status) {
            let data = response?.data?.data;
            // setEducation(data);
            dispatch({ type: "updatedCandidateEduData",payload:data });
          }
          setLoader(false)
        }
      );
      setLoader(true)
      fetchUrl(
        `${ADD_CANDIDATE_EXPERIRNCE_API_URL}/${update_Encrypted_id}`,
        ({ status, response }) => {
          if (status) {
            let data = response?.data?.data;
            // setExperience(data);
            dispatch({ type: "updatedCandidateExpData",payload:data });
          }
          setLoader(false)
        }
      );
      setLoader(true)
      fetchUrl(
        `${GET_CADNDIDATES_JOB_ORDERS_API_URL}?candidate_id=${update_Encrypted_id}`,
        ({ status, response }) => {
          if (status) {
            let data = response?.data?.data;
            setJobOrders(data);
          }
          setLoader(false)
        }
      );
      setLoader(true)
      fetchUrl(
        `${GET_CADNDIDATES_SKILLS_API_URL}/${update_Encrypted_id}`,
        ({ status, response }) => {
          if (status) {
            let data = response?.data?.skills;
            setcandidateSkills(data)
            let Skilldata = []
            for (let i = 0; i < data.length; i++) {
              Skilldata.push(data[i].skill_name)
            }
            setSkills(Skilldata)
          }
          setLoader(false)
        }
      );
    }
  }, [update_Encrypted_id]);

  const deleteEducationCount = (index, type) => {
    if (type === "education") {
      let data = addEducation;
      data.splice(index, 1);
      setAddEducation(data);
      setComponentUpdate(!componentUpdate);
    } else if(type === 'experience'){
      let data = addExperience;
      data.splice(index, 1);
      setAddExperience(data);
      setComponentUpdate(!componentUpdate);
    }else if (type === 'joborder'){
      let data = addJobOrder;
      data.splice(index, 1);
      setJobOrder(data);
      setComponentUpdate(!componentUpdate);
    }
  };

  useEffect(()=>{
    if(resume_name){
      let data = new FormData();
      data.append('candidate_id',update_Encrypted_id)
      data.append('resume_name',resume_name)
    apiPost(
      GET_UPDATE_CANDIDATE_RESUME,
      data,
      ({ status, response }) => {
        if (status) {
          setCadidateSuccess(true)
         // setJobOrderSuccessMsg(response?.data?.message);
          setSuccessmsgs({...successmsgs, resumemsg:[response.data?.message]})
        } else {
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
      }
    );
    }
  },[resume_name])

  useEffect(()=>{     
    if(profile_pic && imgchanged === true){
      setimgchanged(false);
    let data = new FormData();
    data.append('candidate_id',update_Encrypted_id)
    data.append('profile_pic',profile_pic)
   
    apiPost(
      GET_UPDATE_CANDIDATE_PROFILE_PIC,
      data,
      ({ status, response }) => {
        if (status) {
          setCadidateSuccess(true)
          setSuccessmsgs({...successmsgs, profilemsg:[response.data?.message]})
        } else {
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
      }
    );
    }
  },[imgchanged])

  const cancelfun = () =>{
    dispatch({ type: "candidateprofileId" , payload: update_Encrypted_id});
    dispatch({ type: "EditCandidateId" , payload: ''});
    props.changetab(2)
  }

  return (
    <>
     {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="container-fluid bg-none px-3">
        <div className="row no-gutters">
          <div className="col-md-12 pt-3 px-0">
            <div className=" px-0">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="upload-file-section px-3">
                    <div className="row d-flex justify-content-around">
                      <div className="col-md-3">
                        <div className="card ">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">Update Resume </h5>
                          </div>
                          <div className="card-body text-center">
                            <DragAndDrop
                              id={"resume"}
                              onselect={setResume}
                              header={"resume"}
                              data={resume_name?.name}
                              url={candidateFields?.resume_url? File_img: ''}
                            />
                            
                            <div className="text-center">
                              <i className={`fas ${dropIcons} fa-2x `}></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="card ">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">Update Profile Pic</h5>
                          </div>
                          <div className="card-body text-center">
                            <DragAndDrop
                              id={"profile"}
                              onselect={setProfilePic}
                              header={"photo"}
                              data={profile_pic?.name}
                              url={candidateFields?.profile_pic_url }
                              image={true}
                              setimgchanged={setimgchanged}
                              setProfilePic={setProfilePic}
                            />
                            <div className="text-center">
                              <i className={`fas ${dropIcons} fa-2x `}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CandidateDetails
                candidateFields={candidateFields}
                setCandidateFields={setCandidateFields}
                saveCandidate={saveCandidate}
                setSkills={setSkills}
                Skills={candidateSkills}
                edit={true}
              />

              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3 mb-4">
                    <div className="row px-4">
                      <div className="col-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold  pb-0 mb-0">
                            Experience
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-5 text-right">
                        <div className="text-center d-flex justify-content-end">
                          <button
                            class="btn btn-xs btn-violet text-white"
                            onClick={addMoreExperience}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr />
                    {updatedCandidateExpData.length > 0
                      ? updatedCandidateExpData.map((data, index) => {
                          return (
                            <>
                              <Experience
                                data={data}
                                id={update_Encrypted_id}
                                edit={true}
                                index = {index}
                              />
                              <hr />
                              <br />
                            </>
                          );
                        })
                      : ""}

                    {addExperience.map((data, index) => {
                      return (
                        <>
                          <AddExperience
                            id={update_Encrypted_id}
                            deleteCount={() =>
                              deleteEducationCount(index, "experience")
                            }
                            index={index}
                          />
                          <hr />
                          <br />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pb-3 mb-4 pt-3">
                    <div className="row  px-4">
                      <div className="col-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Education
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-5 text-right">
                        <div className="text-center d-flex justify-content-end">
                          <button
                            class="btn btn-xs btn-violet text-white "
                            onClick={() =>
                              setAddEducation([...addEducation, "add"])
                            }
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {updatedCandidateEduData.length > 0
                      ? updatedCandidateEduData.map((data, index) => {
                          return (
                            <>
                              <Education
                                data={data}
                                id={update_Encrypted_id}
                                edit={true}
                                index={index}
                              />
                              <hr />
                              <br />
                            </>
                          );
                        })
                      : ""}
                    {addEducation.map((data, index) => {
                      return (
                        <>
                          <AddEducation
                            id={update_Encrypted_id}
                            deleteCount={() =>
                              deleteEducationCount(index, "education")
                            }
                            index={index}
                          />
                          <hr />
                          <br />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {candidateFields?.about_candidate ? (
                <WysiwygCandidateupdate
                  saveCandidate={saveAboutCandidate}
                  title={"About Candidate"}
                  edit={true}
                  data={candidateFields?.about_candidate}
                  setFields={setCandidateFields}
                  allFieldsData={candidateFields}
                  testEditorData={testEditorData}
                  editDetails={setaboutcandidate}
                />
              ) : (
                ""
              )}

              {!candidateFields?.about_candidate ? (
                <WysiwygCandidateupdate
                  saveCandidate={saveAboutCandidate}
                  title={"About Candidate"}
                  edit={true}
                  data={candidateFields?.about_candidate}
                  setFields={setCandidateFields}
                  allFieldsData={candidateFields}
                  testEditorData={testEditorData}
                  editDetails={setaboutcandidate}
                />
              ) : (
                ""
              )}

              <div className="row  mb-5">
                <div className="col-md-12">
                  <div className="col-md-12 pb-4 text-center">
                    <div className="custom-control custom-checkbox ">
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        className="p-0 m-0 align-middle font-weight-bold"
                        checked={candidateFields?.free_pool === "1" ? true : false}
                        disabled={(jobOrders.length) > 0 ? true : false}
                        onChange={(e)=>setCandidateFields({...candidateFields,free_pool:e.target.checked ? "1": "0"})}
                      />{" "}
                      &nbsp; <strong>Add this profile to free pool</strong>
                    </div>
                  </div>
                  <div className="row  table-custom">
                    <div className={`col-md-12 ${candidateFields?.free_pool === "1" ? "d-none" : ""}`}>
                      <div className="card p-4 pt-3 pb-3 mb-0">
                        <div className="row pb-0 px-4">
                          <div className="col-7">
                            <div className=" d-flex justify-content-end">
                              <h4 className="text-center font-weight-bold pb-0 mb-0">
                                Add To Job Order
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-5 text-right ">
                            <div className="text-center d-flex justify-content-end">
                              <button
                                class="btn btn-xs btn-violet text-white "
                                onClick={() =>
                                  setJobOrder([...addJobOrder, "add"])
                                }
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {jobOrders.length > 0
                          ? jobOrders.map((data, index) => {
                              return (
                                <>
                                  {index > 0 && (
                                    <>
                                      <hr />
                                      <br />
                                    </>
                                  )}
                                  <AddJobOrder
                                    data={data}
                                    id={update_Encrypted_id}
                                    edit={true}
                                    index={index}
                                  />

                              <hr />
                              <br />
                                </>
                              );
                            })
                          : ""}

                        {addJobOrder.map((data, index) => {
                          return (
                            <>
                              {index > 0 && (
                                <>
                                  <hr />
                                  <br />
                                </>
                              )}
                              <AddNewJobOrder
                                id={update_Encrypted_id}
                                deleteCount={() =>
                                  deleteEducationCount(index, "joborder")
                                }
                                index={index}
                              />
                                  <hr />
                          <br />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <div className=" d-inline-block my-3">
                  <button class="btn btn-xs btn-green-dark text-white " onClick={cancelfun}>
                    Cancel
                  </button>
                </div>
                <div className=" d-inline-block mx-2">
                  <button
                    class="btn btn-xs btn-orange text-white"
                    onClick={saveCandidate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommonModal
        title={"Add Skill"}
        handleClose={handleClose}
        open={addSkillsModal}
        button={"Add"}
        btnfunc={addSkill}
      >
        <input
          type="text"
          className="form-control"
          value={skillname}
          onChange={(e) => setSkillName(e.target.value)}
        />
      </CommonModal>

      <SnakBar
        error={errors}
        open={fieldserror}
        onClose={() => setFieldErros(false)}
      />

      {/* <CommonModal
        title={"Success"}
        handleClose={handleClose}
        open={cadidateSuccess && Object.keys(errors).length === 0}
        button={"ok"}
        btnfunc={handleClose}
      >
        {successmsgs}<br/>
        {edusuccesMsg}<br/>
        {expSuccessMsg}<br/>
        {jobOrderSuccessMsg}
      </CommonModal> */}

      <SnakBar error={successmsgs} open={cadidateSuccess && Object.keys(errors).length === 0} onClose={() => setSnakBarOpen(false)} type="success"/>

    
    </>
  );
};

export default withRouter(UpdateCandidate);
