import React,{useState,useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import {GET_CLIENT_PAYMENT_STATUS } from '../../../Configurations/ConfigApi';
import { apiPost,apiDelete,apiEdit } from '../../../ApiActions/Actions';
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import { useDispatch } from "react-redux";

 const Payment = (props) => {

  const [updateModal,setUpdatemodal] = useState(false);
  const [showBts,setShowbts] = useState(true);
  const [fieldserror,setFieldErros] = useState(false)
  const [errors,setErrors] = useState({})
  const dispatch = useDispatch()

  const [payment,setPayment] = useState({
    client_id: "",
    criteria_name:"",
    charges:"",
    remarks:"",
    encrypted_id:'',
  });
  
const [editEncrptId,setEditEncrptId] = useState("")
const [modalmsg,setModalmsg] = useState("")
const [deleteModal,setDeleteModal] = useState(false); 

const handleClose = () => {
  setUpdatemodal(false);
  setDeleteModal(false)
};

const clearvalues = ()=>{
  props.deleteCount()
  dispatch({type:"DeleteClientPayment",payload:props.index})
}

useEffect(()=>{
if(props.edit){
    let data = props.data
    setPayment({
    criteria_name: data.criteria_name,
    charges: data.charges,
    remarks: data.remarks,
    encrypted_id: data.encrypted_id, 
    }) 
    setEditEncrptId(data.encrypted_id)
}else{
  dispatch({ type: "AddClientPayment"});
}
},[])

useEffect(()=>{
  if(props.edit){
    dispatch({
      type: "CheckUpdateClientPayment",
      payload: [props.index, payment],
    });
  }else{
    dispatch({
      type: "UpdateClientPayment",
      payload: [props.index, payment],
    });
  }
},[payment])

const deleteDetails = () =>{
apiDelete(`${GET_CLIENT_PAYMENT_STATUS}/${editEncrptId}`, ({ status, response }) => {
  if (status) {
    setDeleteModal(false)
    // setModalmsg("Payment deleted successfully")
    // setUpdatemodal(true);
    // dispatch({type:"EditClientId",payload:""})
    // dispatch({type:"EditClientId",payload:props.id})
    dispatch({type:"DeleteUpdatedClientPayment",payload:props.index})
  }
})
}



  return(
    <>

        <div className="row px-4">
        
          <div className="col-md-12">
          <table className="table table-bordered">
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Criteria Name"
                    type="text"
                    className="w-100"
                    value={payment.criteria_name}
                    onChange={(e)=>setPayment({...payment,criteria_name:e.target.value,update:true})}
                  />
                </td>
              </tr> 

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Charges(%) "
                    type="text"
                    className="w-100"
                    value={payment.charges}
                    onChange={(e)=>setPayment({...payment,charges:e.target.value,update:true})}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Remarks "
                    type="text"
                    className="w-100"
                    value={payment.remarks}
                    onChange={(e)=>setPayment({...payment,remarks:e.target.value,update:true})}
                  />
                </td>
              </tr>
             
             
            </table>
          </div>

          
        </div>
        <div class="row px-4">
      <div class="col-md-12 d-flex justify-content-end">
      
      {showBts && <>
      {/* <button class="btn btn-xs btn-violet text-white mx-2" onClick={props.edit ? Editdetails :addPayment}>
      {props.edit ?"Save":"Add"} 
        </button> */}
        <button class="btn btn-xs btn-green-dark text-white" onClick={
          props.edit ? ()=>{setDeleteModal(true)} : clearvalues
        }>Delete</button>
        </>}
      </div>
    </div>

    <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"ok"} btnfunc={handleClose}>
    {modalmsg}
    </CommonModal>

    <CommonModal title={"Delete"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
      </CommonModal>

    <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>

    </>
     
   )
  }

  export default Payment
