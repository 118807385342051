import React, { useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GET_JOB_ATTACHMENT_API_URL ,GET_JOB_NOTES_API_URL} from "../../../Configurations/ConfigApi";

// Image Iocns
import t1 from "../../../Assets/Images/profile-t-icons/t1.svg";
import t2 from "../../../Assets/Images/profile-t-icons/t2.svg";
import t3 from "../../../Assets/Images/profile-t-icons/t3.svg";
import t4 from "../../../Assets/Images/profile-t-icons/t4.svg";
import t5 from "../../../Assets/Images/profile-t-icons/t5.svg";
import t6 from "../../../Assets/Images/profile-t-icons/t6.svg";
import t7 from "../../../Assets/Images/profile-t-icons/t7.svg";
import t8 from "../../../Assets/Images/profile-t-icons/t8.svg";

// Job Order
import "./job-order.scss";
import Snapshot from "./sidenavTabs/snapshot";
// import {Notes} from "./sidenavTabs/notes";
import {Notes} from "../../../Components/sidenavTabs/notes";
import {Attachments} from "../../../Components/sidenavTabs/attachments";
import Interview from "./sidenavTabs/interview";
import Activity from "./sidenavTabs/activity";


/**
 * @author
 * @function
 **/

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Sidenav = (props) => {
    const [value, setValue] = React.useState(0);
    const [addnotes, setAddNotes] = useState(false)
    const [addInterview, setAddInterview] = useState(false)
    const [jobOrder, setJobOrder] = useState(false)
    const [attachments, setAttachments] = useState(false)
    const [msgCandidates, setMsgCandidates] = useState(false)

    const jobOrderId = sessionStorage.getItem("JobOrderId")

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="small-tabs override-tabs-size">
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab icon={<img src={t1} className="tab-img-h" />} {...a11yProps(0)} />
                    <Tab icon={<img src={t2} className="tab-img-h" />} {...a11yProps(1)} />
                    <Tab icon={<img src={t5} className="tab-img-h" />} {...a11yProps(2)} />
                    <Tab icon={<img src={t3} className="tab-img-h" />} {...a11yProps(3)} />
                    <Tab icon={<img src={t8} className="tab-img-h" />} {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className="border">
                <Snapshot id={jobOrderId} />
            </TabPanel>
            <TabPanel value={value} index={1} className="border">
                <Notes id={jobOrderId}  api={`${GET_JOB_NOTES_API_URL}`} apiext={`?job_id=${jobOrderId}`} module={"joborder"}/>
            </TabPanel>
            <TabPanel value={value} index={2} className="border">
                <Attachments id={jobOrderId} api={GET_JOB_ATTACHMENT_API_URL} apiext={`?job_id=${jobOrderId}`} module={"joborder"}/>
            </TabPanel>
            <TabPanel value={value} index={3} className="border">
                <Interview />
            </TabPanel>
            <TabPanel value={value} index={4} className="border">

                <Activity />
            </TabPanel>

        </div>
    );
};

export default Sidenav;

