import React, { useState } from "react";
import { GET_CANDIDATE_NOTES_API_URL,GET_CANDIDATE_ATTACHMENT_API_URL } from "../../Configurations/ConfigApi";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useSelector} from 'react-redux'

// Image Iocns
import t1 from "../../Assets/Images/profile-t-icons/t1.svg";
import t2 from "../../Assets/Images/profile-t-icons/t2.svg";
import t3 from "../../Assets/Images/profile-t-icons/t3.svg";
import t4 from "../../Assets/Images/profile-t-icons/t4.svg";
import t5 from "../../Assets/Images/profile-t-icons/t5.svg";
import t6 from "../../Assets/Images/profile-t-icons/t6.svg";
import t7 from "../../Assets/Images/profile-t-icons/t7.svg";
import t8 from "../../Assets/Images/profile-t-icons/t8.svg";
// import { Notes } from "./sideNavtabs/notes";
import {Notes} from "../../Components/sidenavTabs/notes";
import { Interview } from "./sideNavtabs/interview";
import JobOrder  from "./sideNavtabs/jobOrders";
import { Attachments } from "../../Components/sidenavTabs/attachments";
import { AdditionalDetails } from "./sideNavtabs/additionalDetails";
import { MassageCandidates } from "./sideNavtabs/massageCandidates";
import { Activity } from "./sideNavtabs/activity";
import SnapShot  from "./sideNavtabs/snapshot";

/**
 * @author
 * @function
 **/

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DetailsSidenav = (props) => {
  const [value, setValue] = useState(0);
  const update_Encrypted_id = useSelector(state=>state.PageReduser.candidateprofileId)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="small-tabs candidatesidetab">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab icon={<img src={t1} className="tab-img-h"/>} {...a11yProps(0)} />
          <Tab icon={<img src={t2} className="tab-img-h"/>} {...a11yProps(1)} />
          <Tab icon={<img src={t3} className="tab-img-h"/>} {...a11yProps(2)} />
          <Tab icon={<img src={t4} className="tab-img-h"/>} {...a11yProps(3)} />
          <Tab icon={<img src={t5} className="tab-img-h"/>} {...a11yProps(4)} />
          <Tab icon={<img src={t6} className="tab-img-h"/>} {...a11yProps(5)} />
          <Tab icon={<img src={t7} className="tab-img-h"/>} {...a11yProps(6)} />
          <Tab icon={<img src={t8} className="tab-img-h"/>} {...a11yProps(7)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className="border">
        <SnapShot id={update_Encrypted_id}/>
      </TabPanel>
      <TabPanel value={value} index={1} className="border">
      <Notes id={update_Encrypted_id}  api={`${GET_CANDIDATE_NOTES_API_URL}`} apiext={`?candidate_id=${update_Encrypted_id}`} module={"candidate"}/>
       </TabPanel>
      <TabPanel value={value} index={2} className="border">
        <Interview showaddmessageonly={false}/>
      </TabPanel>
      <TabPanel value={value} index={3} className="border">
        <JobOrder/>
      </TabPanel>
      <TabPanel value={value} index={4} className="border">
        <Attachments id={update_Encrypted_id}  api={`${GET_CANDIDATE_ATTACHMENT_API_URL}`} apiext={`?candidate_id=${update_Encrypted_id}`} module={"candidate"}/>
      </TabPanel>
      <TabPanel value={value} index={5} className="border">
        <AdditionalDetails cadidateData={props.cadidateData}/>
      </TabPanel>
      <TabPanel value={value} index={6} className="border">
        <MassageCandidates Showaddmessageonly={false}/>
      </TabPanel>
      <TabPanel value={value} index={7} className="border">
        <Activity/>
      </TabPanel>
    </div>
  );
};

export default DetailsSidenav;
