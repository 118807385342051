import React,{useEffect, useState} from 'react'
import { fetchUrl , apiEdit} from "../../../ApiActions/Actions";
import { GET_CANDIDATE_NOTES_API_URL,GET_CANDIDATE_JOB_ORDERS_STATUS,GET_CANDIDATE_INTERVIEW_API_URL,GET_JOB_MAINSUBSTATUS_API_URL,GET_JOB_MAINSTATUS_API_URL } from "../../../Configurations/ConfigApi";
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

 const SnapShot = (props) => {
    const dispatch = useDispatch()
    const [notesData,setNotesData] = useState([])
    const [notesUpdate,setNotesUpdate] = useState(false)
    const [fieldserror,setFieldErros] = useState(false)
    const [errors,setErrors] = useState({})
    const [jobOrdersList,setJobOrdersList] = useState([])
    const [offeredJobOrder,setOfferedJobOrder] = useState([])
    const [interviewData, setInterviewData] = useState([])
    const [updateStatusModal,setUpdateStatusModal] = useState(false)
    const [mainSubStatusData,setMainSubStatusData] = useState([])
    const [updateMainStatusId,setUpdateMainStatusId] = useState('')
    const [subStatatusId,setSubStatusId] = useState('')
    const [updateEncriptedId,setUpdateEncriptedId] = useState('')
    const [mainStatusData,setMainStatusData] = useState([])
    const [mainStatus,setMainStatus] = useState('')
    const [updateModal, setUpdatemodal] = useState(false);
    const [successMsg,setSuccessMsg] = useState({})

    useEffect(()=>{
     
      if(props.id){
        fetchUrl(`${GET_CANDIDATE_NOTES_API_URL}?candidate_id=${props.id}`, ({ status, response }) => {
          if (status) {
             let data = response?.data?.data
             if(data.length>0){
              if(data.length>1){
                let data = response?.data?.data?.reverse()
                setNotesData([data[0],data[1]])
              }else{
                let data = response?.data?.data?.reverse()
                setNotesData([data[0]])
              }
             }
          }else{
              let msg = JSON.parse(response.request.response).errors
              setErrors(msg)
              setFieldErros(true)
          }
      })
      }
 
  fetchUrl(`${GET_CANDIDATE_INTERVIEW_API_URL}?candidate_id=${props.id}`, ({ status, response }) => {
    if (status) {
      let data = response?.data?.data 
      if(data.length>0){
        setInterviewData(data)
       }
    } else {
      let msg = JSON.parse(response.request.response).errors
      setErrors(msg)
      setFieldErros(true)
    }
  })
        
    },[])

    useEffect(()=>{

      fetchUrl(`${GET_CANDIDATE_JOB_ORDERS_STATUS}?candidate_id=${props.id}`, ({ status, response }) => {
        if (status) {
        
         let data = response?.data?.data
         if(data.length>0){
          setJobOrdersList(data)
         }
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })

    fetchUrl(`${GET_CANDIDATE_JOB_ORDERS_STATUS}?candidate_id=${props.id}&mainstatus_id=R`, ({ status, response }) => {
      if (status) {
       let data = response?.data?.data
       if(data.length>0){
        setOfferedJobOrder([data[0]])
       }else{
        setOfferedJobOrder([])
       }
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
    },[notesUpdate])

    function formatDate(value) {
      if ( value === undefined || value === null || value === '' )
      return '';
      let date = new Date(value);
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: '2-digit' });
      return day + '-' + month + '-' + year;
  }

  useEffect(()=>{
    fetchUrl(`${GET_JOB_MAINSTATUS_API_URL}`, ({ status, response }) => {
      if (status) {
       setMainStatusData(response.data?.data)
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  },[])

  const updateMainStatus = (data) => {
    setUpdateStatusModal(true)
    getsubStatus(data?.main_status_id)
    setUpdateMainStatusId(data?.main_status_id)
    setSubStatusId(data?.main_substatus_id)
    setUpdateEncriptedId(data?.encrypted_id)
  }

  const updateCandidateJobOrderStatus = () => {
    let jobOrdersData={
      encrypted_id: updateEncriptedId,
      mainstatus_id:updateMainStatusId,
      mainsubstatus_id:subStatatusId,
    }
    if(updateMainStatusId && subStatatusId){
      apiEdit(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
        if (status) {
          setSuccessMsg({notes:["Updated Successfully"]})
          setUpdatemodal(true)
          setNotesUpdate(!notesUpdate)
          setUpdateStatusModal(false)
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })
    }
   
  }

  const getsubStatus=(value)=>{
    setMainStatus(value)
    fetchUrl(`${GET_JOB_MAINSUBSTATUS_API_URL}?main_status_id=${value}`, ({ status, response }) => {
      if (status) {

       setMainSubStatusData(response.data?.data)
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  }

  return(
    <>
         <div className="inner-content">
          <h6 className="text-underline pb-3"> Snapshot</h6>
          <h5 className="f-13 pb-0 mb-1 font-weight-bold  text-center">
            Offered
          </h5>
          <hr className="p-0 m-0 mb-2" />
          {offeredJobOrder.map((data)=>{
            return  <ul type="none" className="p-0 m-0 mb-2 list-style-4">
            <li><strong className='prefix-tag pe-2'>Job Order:</strong>
            <a className="text-underline" onClick={()=>{
                dispatch({type:"Viewjoborder",payload:data?.job_id})
                sessionStorage.setItem('redirectToJobOrder',true)
                 props.history.push('/joborders')
              }}>
              {data?.job_title} 
               </a>
            </li>
            <li><strong className='prefix-tag pe-2'>Recruiter: </strong> {data?.username}</li>
            <li><strong className='prefix-tag pe-2'>Main Status: </strong> <a className="text-underline" onClick={()=>updateMainStatus(data)}>{data?.main_status}</a></li>
            <li><strong className='prefix-tag pe-2'>Sub Status:</strong> {data?.main_substatus
            }</li>
          </ul>
          })}
          <hr className="p-0 m-0 mb-3" />
        </div>

        <div className="inner-content">
          <h5 className="f-13 pb-0 mb-1 font-weight-bold  text-center">
            Active Job Orders
          </h5>
          <hr className="p-0 m-0 mb-2" />
          {jobOrdersList.map((data)=>{
            return  <ul type="none" className="p-0 m-0 mb-2 list-style-4">
            <li><strong className='prefix-tag pe-2'>Job Order:</strong>
             <a className="text-underline" onClick={()=>{
                dispatch({type:"Viewjoborder",payload:data?.job_id})
                sessionStorage.setItem('redirectToJobOrder',true)
                 props.history.push('/joborders')
              }}>
              {data?.job_title} 
               </a>
             </li>
            <li><strong className='prefix-tag pe-2'> Recruiter:</strong> {data?.username}</li>
            <li><strong className='prefix-tag pe-2'>Main Status: </strong> <a className="text-underline" onClick={()=>updateMainStatus(data)}>{data?.main_status}</a></li>
            <li><strong className='prefix-tag pe-2'> Sub Status: </strong> {data?.main_substatus
            }</li>
            
            <hr className="p-0 m-0 mb-2" />
          </ul>
          })}
          <ul className="p-0 m-0 mb-2 list-style-4">
          {
              interviewData.map((data)=>{
                return  <li className="text-center text-pink">
                Interview Scheduled on {formatDate(data?.date)} at {data?.hour}:{data?.minutes} {data?.timeformat}
              </li>
              })
            }
         </ul>
          <hr className="p-0 m-0 mb-3" />
        </div>
        <div className="inner-content">
          <h5 className="f-13 pb-0 mb-1 font-weight-bold  text-center">
            Recent Notes
          </h5>
          <hr className="p-0 m-0 mb-2" />
          

          {
              notesData.map((data)=>{
                      let date = new Date(data?.created_at)
                      return <div>
                      <div className="row">
                          <div className="col-md-6">
                          <h6 className="f-13 ">{formatDate(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate())}</h6>
                          </div>
                          <div className="col-md-6  justify-content-end d-flex">
                              <h6 className="f-13">

                              </h6>
                          </div>
                      </div>
                      <p className="f-13 mb-1">
                         {data?.notes}        
                    </p>
                      <div className="text-end">
                          <h6 className="text-end f-13">{data.username}</h6>
                          <hr className="p-0 m-0 mb-2" />
                      </div>
                  </div>
    
                  })
              }
          <hr className="p-0 m-0 mb-3" />
        </div>

        <CommonModal title={"Change Status"} handleClose={() => setUpdateStatusModal(false)} open={updateStatusModal} button={"UPDATE"} btnfunc={()=> updateCandidateJobOrderStatus()}>
        <div style={{width: "200px"}}>
          <select name="" id=""  class="form-control pb-1 my-3"
            onChange={(e)=>{
              getsubStatus(e.target.value)
              setUpdateMainStatusId(e.target.value)
              setSubStatusId('')
            }} value={updateMainStatusId}>
            <option value="">-- Select Main Status  --</option>
            {mainStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>

          <select name="" id=""  class="form-control pb-1"
            onChange={(e)=>{
              setSubStatusId(e.target.value)
              }} value={subStatatusId}>
            <option value="">-- Select Sub Status --</option>
            {mainSubStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>
          </div>
      </CommonModal>
      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
      <SnakBar error={successMsg} open={updateModal} onClose={() => setUpdatemodal(false)} type="success"/>
    </>
   )
  }

  export default withRouter(SnapShot);
