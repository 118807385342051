import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CADNDIDATES_API_URL, DELETE_CANDIDATE_API_URL, DATATABLE_CLIENTS_URL
  , DTRESUME_ACTIVE_JOBS_DETAILS, DTRESUME_JOBS_DATATABLE,DTRESUME_JOB_CANDIDATES_DETAILS
} from '../../Configurations/ConfigApi';
import { fetchUrl, apiDelete, apiPost } from '../../ApiActions/Actions'
import { AutocompleteCandidates } from "../../Components/AutoCompletes/AutocompleteCandidates";
import SnakBar from "../../Components/Models/snakbar";
import { withRouter } from 'react-router';
import CommonModal from '../../Components/Models/commonModal';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import logoEmblem from "../../Assets/Images/emblem.svg";
import { DataTable } from './ResumeComponents/dataTable';
import { SelectClientInputs } from './selectClientInputs';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin: "0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const Resumedetails = (props) => {
  const classes = useStyles();

  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' }, { columnName: 'name', direction: 'asc' }]);

  const [currentPage, setCurrentPage] = useState(0);

  const [successMsg, setSuccessMsg] = useState({})
  const [snakBarOpen, setSnakBarOpen] = useState(false)
  const [usersData, setUsersData] = useState([])

  const [usersCount, setUsersCount] = useState(0)
  const [updateModal, setUpdatemodal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [clientdetails, setclientdetails] = useState([]);
  const [selectedclient, setselectedclient] = useState("")
  const [Jobdetails, setJobdetails] = useState([]);
  const [selectedjob, setselectedjob] = useState("")
  const [candidateslist, setcandidateslist] = useState([]);
  const [selectedcandidates, setselectedcandidates] = useState([])
  const [Isjobchange, setIsjobchange] = useState(false)
  const [loader,setLoader] = useState(false)
  
  const textRef = useRef(null);

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteUserId("");
  };

  useEffect(() => {
    setLoader(true)
    fetchUrl(`${DATATABLE_CLIENTS_URL}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data
        setclientdetails(data)
        setLoader(false)
      }
    })
  }, []);

  const setclientchange = (value) => {
    setselectedclient(value);
    setLoader(true)
    fetchUrl(`${DTRESUME_ACTIVE_JOBS_DETAILS}/${value}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data;
        setJobdetails(data)
        setLoader(false)
      }
    })
  }

  const setjobchange = (value) => {
    setselectedjob(value);   
    setselectedcandidates([]);
    setIsjobchange(true);
    fetchUrl(`${DTRESUME_JOB_CANDIDATES_DETAILS}/${value}/source_screening`, ({ status, response }) => {
      if (status) {       
        let data = response.data.data;
        setcandidateslist(data)
        setIsjobchange(false);
      }
    })
  }

  const GenerateClick = () => {
    let data = new FormData()
    data.append('client_id', selectedclient)
    data.append('job_id', selectedjob)
    for (let i = 0; i < selectedcandidates.length; i++) {     
      data.append(`candidate_id[${i}]`, selectedcandidates[i].candidate_id)     
    }
    apiPost(DTRESUME_JOBS_DATATABLE, data, ({ status, response }) => {
      if (status) {
        let { data, all_data_cnt, filter_data_cnt } = response.data
        filter_data_cnt === all_data_cnt ? setUsersCount(all_data_cnt) : setUsersCount(filter_data_cnt)
        setUsersData(response.data.data)
      }
    })

  }

  const deleteCandidate = () => {
    apiDelete(`${DELETE_CANDIDATE_API_URL}${deleteUserId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false)
        setSuccessMsg({ notes: ["Deleted Successfully"] })
        setSnakBarOpen(true)
      }
    })
  }


  return (
    <>
    {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid pr-0 pl-0 px-0 ">
       <textarea
        ref={textRef}
        style={{ opacity: 0, position: "absolute", top: "-200px" }}
      ></textarea>
      <div className="row mb-5">

        <div className="col-md-12">
          <div className="row px-md-4 pt-3">
          <SelectClientInputs 
            setjobchange={setjobchange} 
            setclientchange={setclientchange}
            selectedclient={selectedclient} 
            selectedjob={selectedjob} 
            Jobdetails={Jobdetails}/>

            <div className="col-md-4">
              <FormControl className={classes.formControl} fullWidth>                 
                <AutocompleteCandidates 
                          candidateslist={candidateslist} 
                          label={"Select Candidates"}                           
                          setselectedcandidates={setselectedcandidates}
                          Isjobchange={Isjobchange}
                          selectedcandidates={selectedcandidates}
                          />                 
                <small>(Only Sourcing & Screening Status Candidates)</small>
              </FormControl>
            </div>
            <div className="col-md-1 d-flex align-self-center justify-content-center">
              <button class="btn btn-xs btn-violet text-white" onClick={GenerateClick}>Generate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row  data-tables">
        <div className="col-md-12 table-card"> 
          <DataTable usersData={usersData } />        
        </div>
      </div>

      <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteCandidate}>
        <span>Are you sure you want to delete candidate ?</span>
       
      </CommonModal>
      <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success" />
    </div>
    </>
  )
}


export default withRouter(Resumedetails)