import React from 'react'
import {Route} from "react-router-dom"

import ManageRoles from './pages/Admin/ManageRols'
import Details from './pages/CandidateProfile/Details'
import AddInformation from './pages/CandidateProfile/AddInforamtion'
import Resume from './pages/CandidateProfile/Resume'
import WorkExperience from './pages/CandidateProfile/WorkExperience'
import { useSelector ,useDispatch } from "react-redux";
import Skills from './pages/Settings/Skills'
import ManageLogins from './pages/Admin/ManageLogins'
import Candidates from './pages/Candidates/cadidates'
import Joborders from './pages/Joborders/Joborders'
import Client from './pages/clients/clients'
import Datatables from './pages/Datatables/Datatables'

export const routes = [
{
    name:"Roles",
    path:"/manageroles",
    category:"",
    component: ManageRoles,
    moduleName:"Roles"
},  

{
    name:"List",
    path:"/managelogins",
    category:"",
    component: ManageLogins,
    moduleName:"Admin"
},

{
    name:"Details",
    path:"/details",
    category:"/candidate",
    component: Details,
    moduleName:"CandidateProfile",
},
{
    name:"Resume",
    path:"/resume",
    category:"/candidate",
    component: Resume,
    moduleName:"CandidateProfile",
},
{
    name:"Work Experience",
    path:"/workexperience",
    category:"/candidate",
    component: WorkExperience,
    moduleName:"CandidateProfile",
},
{
    name:"Additional Information",
    path:"/information",
    category:"/candidate",
    component: AddInformation,
    moduleName:"CandidateProfile",
},
{
    name:"Skills",
    path:"/skills",
    category:"/settings",
    component: Skills,
    moduleName:"Settings",
    show:true
},
{
    name:"Profile",
    path:"/profile",
    category:"/candidate",
    component: Candidates,
    moduleName:"Cadidates"
}
]

// const cadidateRoutes = [{
//     name:"List",
//     path:"/list",
//     category:"/candidate",
//     component: Candidates,
//     moduleName:"Cadidates"
// },
// {
//     name:"Add",
//     path:"/add",
//     category:"/candidate",
//     component: Candidates,
//     moduleName:"Cadidates"
// },
// ]

// const jobOrdersRoutes = [{
//     name:"List",
//     path:"/list",
//     category:"/joborders",
//     component: Joborders,
//     moduleName:"joborders"
// }
// ]

const clientRoute = [{
    name:"List",
    path:"",
    category:"/clients",
    component: Client,
    moduleName:"client"
}
]

const Routes = (props) => {
    const dispatch = useDispatch()
    const cadidateRoute = useSelector(state=>state.PageReduser.cadidateRoute)
    
  return(
      <>
    {
        routes.map((prop)=>{
           return <Route path={prop.category+prop.path} component={prop.component} key={prop.path}/>
        })
    }
    {/* {
       cadidateRoute.map((prop,index)=>{
        if(prop.show){
            let route = cadidateRoutes[index]
            return <Route path={route.category+route.path} component={route.component} key={route.path}/>
        }
        
     })  
    } */}

    {/* {
       jobOrdersRoutes.map((prop,index)=>{  
        return <Route path={prop.category+prop.path} component={prop.component} key={prop.path}/>
     })  
    } */}

    {
       clientRoute.map((prop,index)=>{  
        return <Route path={prop.category+prop.path} component={prop.component} key={prop.path}/>
     })  
    }

    {/* {
       settingsRoutes.map((prop,index)=>{
        if(prop.show){
            let route = settingsRoutes[index]
            return <Route path={route.category+route.path} component={route.component} key={route.path}/>
        }
     })  
    } */}
    </>
   )

 }

export default Routes