import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from 'react-dom'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import './styles.scss'
import getCroppedImg from './getCroppedImg'

const Cropimg   = (props) =>   {
   
  const imgcheck = new Image(); 
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [ProfileImg, setProfileImg] = useState(props.src)
  const [Minheight,setMinheight] =useState(360);
  const [Minwidth,setMinwidth] =useState(360);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  
  useEffect(() => {
    imgcheck.src =props.src;
    props.setiscroped(false);
  },[]);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        ProfileImg,
        croppedAreaPixels,
        rotation
      )
      
      setCroppedImage(croppedImage)
      props.setCropimgUrl(croppedImage);
      props.setopencorpimage(false);
      props.setiscroped(true);             
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  const onImageChange = (event) => {
    
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
       let bloburl = URL.createObjectURL(img);         
      imgcheck.src =bloburl;
    }
  };

  imgcheck.onload = () => {
    if (Minheight > imgcheck.height || Minwidth > imgcheck.width){
      alert('Image dieemnsion should be Height:'+Minheight+' px'+' Width:'+Minheight +' px');      
      setProfileImg('');
      props.setCropimgUrl('');
      props.setopencorpimage(false);
    }
    else{
      // props.setCropimgUrl(imgcheck.src);
      setProfileImg(imgcheck.src);
    }     
  };
 
    return (
      <div className="notes-section crop-here">
        <div className="crop-container">
        <Cropper
          image={ProfileImg}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          // aspect={4 / 3}
          aspect={16 / 9}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}          
        />
        </div>
        <div className="controls">
        
        <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"            
            onChange={(e, zoom) => setZoom(zoom)}
          />
          

          <button class="btn btn-sm btn-green-dark text-white mt-3" onClick={showCroppedImage} >
                    SELECT
                  </button>
        
                  {/* <input type="file" name="myImage" onChange={(event) => { onImageChange(event);
        }} /> */}
        </div>
        
      </div>
    )
}
export default Cropimg