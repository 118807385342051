import React from "react";
import Sidenav from "../../Components/Sidenav/Sidenav";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ActiveUsers from "./ActiveUsers";
import MyAccount from "./myAccount";
import Adduser from "./Adduser";
import Edituser from "./Edituser";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ManageLogins = (props) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <>
            <div className="container-fluid pr-0 pl-0 pt-4">
                <div className="row card-design px-4 mb-5 ">
                    <div className="row text-center">
                        <div className="col-12">
                           <h6><u> Manage Logins</u></h6>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        <div className={classes.root} id="shadow-custom">
                            <AppBar position="static">
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                    <Tab label="My Account" {...a11yProps(0)} />
                                    <Tab label="Users" {...a11yProps(1)} />
                                    <Tab label="Add User" {...a11yProps(2)} />
                                    <Tab label="Edit User" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <MyAccount/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ActiveUsers changeTab={setValue}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Adduser changeTab={setValue}/>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Edituser/>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
       
        </>
    );
};

export default ManageLogins;
