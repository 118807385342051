import React,{useEffect, useState} from 'react'
import {Route,withRouter} from 'react-router-dom'
import DashBoard from  './pages/DashBoard/Dashboard'
import Login from './pages/Login_Register/login';
import Routes from './routes';
import UserLogin from './pages/Admin/userLogin';
import MyAccount from './pages/Admin/myAccount';
import Header from './Components/Header/Header';
import ActiveUsers from './pages/Admin/ActiveUsers';
import Candidates from './pages/Candidates/cadidates'
import Joborders from './pages/Joborders/Joborders'
 import './Styles/common.scss';
import { Footer } from './Components/Footer/footer';
import Datatables from './pages/Datatables/Datatables'

function App(props) {
 let idleTimer = null

  const IDLE_TIMEOUT = 900000; // 15 minutes

  
  // const logout = () => {
  //   // Implement your logout logic here
  //   console.log('User has been automatically logged out.');

  //   sessionStorage.setItem('timeOut',true)
  //         window.location.replace('/')
  //         localStorage.removeItem('token')
  //         localStorage.removeItem('user')
  //         sessionStorage.removeItem('user')
   
  // };

  // function resetIdleTimer() {
  //   clearTimeout(idleTimer); // Clear the previous timer
  //   idleTimer = setTimeout(logout, IDLE_TIMEOUT); // Set a new timer
  // }


  // useEffect(() => {
  //   // Event listeners to reset the timer on user activity
  //   window.addEventListener("mousemove", resetIdleTimer); 
  //   window.addEventListener("keydown", resetIdleTimer); 

  //   resetIdleTimer();

  //   // Clean up event listeners on component unmount
  //   return () => {
  //     window.removeEventListener('mousemove', resetIdleTimer);
  //     window.removeEventListener('keydown', resetIdleTimer);
  //   };
  // }, []);

  return (
    <>
  
      {(props.location.pathname==="/")?null: <Header/>}
      <Route path ="/" exact component={Login}/>
      <Route path="/dashboard" component={DashBoard}/>
      <Route path="/userLogin" component={UserLogin}/>
      <Route path="/myaccount" component={MyAccount}/>
      <Route path="/candidates" component={Candidates}/>
      <Route path="/joborders" component={Joborders}/>
      <Route path="/datatables" component={Datatables}/>
      <Route path={"/admin/activeusers"} component={ActiveUsers}/>
      <Routes/>
      {(props.location.pathname==="/")?null:  <Footer/>}
     
    </>
  );
}

export default withRouter(App);
