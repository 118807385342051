import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Link} from 'react-router-dom'
import {apiPost} from '../../ApiActions/Actions'
import {LOGOUT_API_URL} from '../../Configurations/ConfigApi'

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#fff",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "black",
      },
    },
  },
}))(MenuItem);

 function Menulinks(props) {

  const logout= ()=>{
    let redirectToLogin = ()=>{
      window.location.replace("/")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        sessionStorage.removeItem('user')
    }
    apiPost(LOGOUT_API_URL,{}, ({ status, response }) => {
      if (status) {
        redirectToLogin()
      } else {
        if(response?.response?.data?.message==='Unauthenticated.'){
          redirectToLogin()
        }
      }
    })
  }

  return (
    <div className="dropdown-custom-menu">
      <a
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        id="Dashboard"
        className={`nav-link ${props.activeClass}`}
        onClick={props.handleClick}
        // onMouseLeave={props.handleClose}
        endicon={<ChevronRightIcon />}
        
      >
        {props.title} 
        {/* <i className="fas fa-chevron-down px-2 pe-3"></i> */}
      </a>
      <StyledMenu
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
      >
        {props.elements.map((res, index) => {
          return (
            <StyledMenuItem key={`list${index}`}>
              {(res.name !== "Logout") ? <Link primary={res.name}  onClick={props.handleClose} to={res.link}>
                {res.name}
              </Link>: <div onClick={logout}>
                {res.name}
              </div>
              }
              
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}

export default Menulinks
