import React,{useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getMonth, getYear } from 'date-fns';
import DateFnsUtils from "@date-io/date-fns";
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import moment from 'moment'
import { useDispatch , useSelector} from "react-redux";

 const AddEducation = (props) => {

  const dispatch = useDispatch()
  const [education,setEducation] = useState({
    candidate_id: props.id,
    university: "",
    qualification: "",
    passout_year: "",
    passout_percentage: "",
    still_studying: "0",
  })

  const [updateModal,setUpdatemodal] = useState(false);
  
  const [showBts,setShowbts] = useState(true);
  const [fieldserror,setFieldErros] = useState(false)
  const [errors,setErrors] = useState({})
  
  const [modalmsg,setModalmsg] = useState("")

  const CandidateEduList = useSelector(state=>state.Edu_Exp_Reduser.CandidateEduList)
  const updatedCandidateEduData = useSelector(state=>state.Edu_Exp_Reduser.updatedCandidateEduData)
  const [checkDisabeld,setCheckDisabeld] = useState(false)

  const [getyear,setGetyear] = useState('')
  const [open,setOpen] = useState(false)

  const handleClose = () => {
    setUpdatemodal(false);
  
  };

  const clearvalues = ()=>{
    // setEducation({
    //   candidate_id:"",
    //   university:"",
    //   qualification:"",
    //   passout_year:"",
    //   passout_percentage:"",
    //   still_studying:"0",
    // })
    props.deleteCount()
    dispatch({type:"DeleteCandidateEdu",payload:props.index})
  }


  useEffect(()=>{

    if(props.data){
      let data = props.data
      setEducation({
        candidate_id:"",
        university: data?.['university:'],
        qualification: data?.qualitifcation,
        passout_year: data?.year_of_pass,
        passout_percentage:"",
        still_studying:"0",
      })
      if(data?.year_of_pass){
        setGetyear(`01-05-${data?.year_of_pass}`)
      }  
    }
    dispatch({type:"AddCandidateEdu",payload:props.index})
},[])

useEffect(()=>{
    dispatch({type:"UpdateCandidateEdu",payload:[props.index,education]})
},[education])

useEffect(()=>{
  let check = false
  let checkindex = null
  CandidateEduList.map((data,index)=>{
    if(data?.still_studying === '1'){
      check = true
      checkindex =  index
    }
  })
  if(check && (props.index !== checkindex)){
    setCheckDisabeld(true)
  }else{
    setCheckDisabeld(false)
  }

  let newCheck = false
  updatedCandidateEduData.map((data,index)=>{
    if(data?.still_studying === '1'){
      newCheck = true
    }
  })
  if(newCheck){
    setCheckDisabeld(true)

  }
},[CandidateEduList,updatedCandidateEduData])

const limitLength = (e,value,limit) => {
  if(value!= undefined && value.toString().length >= limit){
    e.preventDefault()
  }
}

  return(
    <>
        <div className="row px-4">
          <div className="col-md-6">
            <div className="row">
          <div className="col-md-12 mb-3">
            <TextField
              id="standard-search"
              label="Institution *"
              type="text"
              className="w-100"
              value={education.university}
              onChange={(e)=>setEducation({...education,university:e.target.value})}
            />
          </div>
          <div className="col-md-12 mb-3">
            {
              getyear ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Passout year * </label> : ""
            }
            <MuiPickersUtilsProvider utils={DateFnsUtils} id={"new pass"}>
              <KeyboardDatePicker
                 disableToolbar
                id="passyear"
                label={getyear ? '' : "Passout year *"}
                views={["year"]}
                value={getyear}
                maxDate={moment().toDate()}
                error={false}
                className="w-100"
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(date) => {
               
                  let fromdateformat = new Date(date)
                  let year = getYear(fromdateformat)
                  setEducation({...education,passout_year:year});
                  setGetyear(date)
                }}
                TextFieldComponent={(props) => (
                  <TextField {...props} onClick={(e) => setOpen(education.still_studying === "1" ? false : true)} />
                )}
                open={open}
                //allowKeyboardControl={false}
                disabled={education.still_studying === "1"}
                // onAccept={(date)=>console.log(date)}
                // onFocus={()=>setOpen(true)}
                // open={open}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
               autoOk={true}
              />
            </MuiPickersUtilsProvider>

           
          </div>
          </div>
          </div>
          <div className="col-md-6">
            <div className="row">
          
          <div className="col-md-6 mb-3">
            <TextField
              id="standard-search"
              label="Degree *"
              type="text"
              className="w-100"
              value={education.qualification}
              onChange={(e)=>setEducation({...education,qualification:e.target.value})}
            />
            <br />
            <Checkbox
              name="checkedB"
              color="primary"
              className="p-0 m-0 font-weight-bold"
              disabled={checkDisabeld}
              checked={education.still_studying === "1" ? true : false}
              onChange={(e)=>setEducation({...education,still_studying:e.target.checked ? "1": "0"})}
            />{" "}
            Still Studying
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              id="standard-search"
              label="Pass out % "
              type="number"
              className="w-100"
              onKeyPress={(e)=>limitLength(e,e.target.value,2)}
              value={education.passout_percentage}
              disabled={education.still_studying === "1"}
              onChange={(e)=>setEducation({...education,passout_percentage:e.target.value})}
            />
          </div>
          </div>
          </div>
        </div>
        <div className="row px-4">
          <div className="col-md-12 d-flex justify-content-end">
          {showBts && <>
            
            <button class="btn btn-xs btn-green-dark text-white" onClick={clearvalues}>
              Delete 
            </button>
            </>}
          </div>
        </div>
     
      <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"OK"} btnfunc={handleClose}>
        <span>{modalmsg}</span>
      </CommonModal>

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
    </>
   )
  }

  export default React.memo(AddEducation)
