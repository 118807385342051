import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const DeleteActiveUser = (props) => {
    
  return(
    <div className="custom-modal-delete">
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3">
         <div className="text-center modal-buttons">
          <Button onClick={props.btnfunc} className="modal-yellow-btn me-5">
          {props.button}
          </Button>
          <Button onClick={props.handleClose}  className="modal-green-btn" autoFocus>
            Cancel
          </Button>
          </div>
        </DialogActions>
      </Dialog>
      </div>
   )

 }

export default DeleteActiveUser