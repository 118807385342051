import React,{useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {ADD_CANDIDATE_EDUCATION_API_URL,EDIT_CANDIDATE_EDUCATION_API_URL,DELETE_CANDIDATE_EDUCATION_API_URL } from '../../Configurations/ConfigApi';
import { apiPost,apiDelete } from '../../ApiActions/Actions';
import CommonModal from '../../Components/Models/commonModal';
import SnakBar from "../../Components/Models/snakbar";
import { useDispatch , useSelector} from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getMonth, getYear } from 'date-fns';
import moment from 'moment';

 const Education = (props) => {

  const dispatch = useDispatch()
  const [education,setEducation] = useState({
    candidate_id: props.id,
    university: "",
    qualification: "",
    passout_year: "",
    passout_percentage: "",
    still_studying: "0",
    encrypted_id:'',
    update:false
  })

  const [updateModal,setUpdatemodal] = useState(false);
  const [deleteModal,setDeleteModal] = useState(false); 
  const [showBts,setShowbts] = useState(true);
  const [fieldserror,setFieldErros] = useState(false)
  const [errors,setErrors] = useState({})
  const [editEncrptId,setEditEncrptId] = useState("")
  const [modalmsg,setModalmsg] = useState("")
  const [getyear,setGetyear] = useState('')
  const [checkDisabeld,setCheckDisabeld] = useState(false)
  const [open,setOpen] = useState(false)
  const updatedCandidateEduData = useSelector(state=>state.Edu_Exp_Reduser.updatedCandidateEduData)
  const CandidateEduList = useSelector(state=>state.Edu_Exp_Reduser.CandidateEduList)

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteModal(false)
  };

  const clearvalues = ()=>{
    // setEducation({
    //   candidate_id:"",
    //   university:"",
    //   qualification:"",
    //   passout_year:"",
    //   passout_percentage:"",
    //   still_studying:"0",
    // })
    props.deleteCount()
  }

  useEffect(()=>{
    if(props.edit){
        let data = props.data
         data.passout_year = data.still_studying === '1' ? '' : data.passout_year 
        setEducation({
          candidate_id: data.candidate_id,
          university:data.university,
          qualification:data.qualification,
          passout_year:data.passout_year,
          passout_percentage:data.passout_percentage,
          still_studying: data.still_studying,
          encrypted_id:data.encrypted_id,
          update:false
        }) 
        setEditEncrptId(data.encrypted_id)
        
      if(data?.passout_year){
        setGetyear(new Date(`01-05-${data.passout_year}`))
      }
        
    }
  },[])

  useEffect(()=>{
    dispatch({type:"checkUpdateCandidateEduData",payload:[props.index,education]})
  },[education])

  useEffect( ()=>{
    let check = false
    let checkindex = null
    updatedCandidateEduData.map((data,index)=>{
      if(data?.still_studying === '1'){
        check = true
        checkindex =  index
      }
    })
    if(check && (props.index !== checkindex)){
      setCheckDisabeld(true)
    }else{
      setCheckDisabeld(false)
    }
  
},[updatedCandidateEduData])

useEffect( ()=>{
 
let newCheck = false
CandidateEduList.map((data,index)=>{
  if(data?.still_studying === '1'){
    newCheck = true
  }
})
if(newCheck){
  setCheckDisabeld(true)
}

},[CandidateEduList])


  const deleteDetails = () =>{
    apiDelete(`${DELETE_CANDIDATE_EDUCATION_API_URL}${editEncrptId}`, ({ status, response }) => {
      if (status) {
        setDeleteModal(false)
        dispatch({type:"DeleteUpdatedCandidateEduDataItem",payload:props.index})
      }
  })
  }

  const limitLength = (e,value,limit) => {
    if(value!= undefined && value.toString().length >= limit){
      e.preventDefault()
    }
  }

  return(
    <>
        <div className="row px-4">
          <div className="col-md-6">
            <div className="row">
          <div className="col-md-12 mb-3">
            <TextField
              id="standard-search"
              label="Institution *"
              type="text"
              className="w-100"
              value={education.university}
              onChange={(e)=>setEducation({...education,university:e.target.value,update:true})}
            />
          </div>
          <div className="col-md-12 mb-3">

            {
              getyear ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Passout year * </label> : ""
            }
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                id="date-picker-inline"
                label={getyear ? '' : "Passout year *"}
                views={["year"]}
                value={getyear}
                error={false}
                emptyLabel={""}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                maxDate={moment().toDate()}
                className="w-100"
                disabled={education.still_studying === "1" ? true : false}
                onChange={(date) => {
                  let fromdateformat = new Date(date)
                  let year = getYear(fromdateformat)
                  setEducation({...education,passout_year:year,update:true});
                  setGetyear(date)
                }}
                TextFieldComponent={(props) => (
                  <TextField {...props} onClick={(e) => setOpen(true)} />
                )}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                open={open}
                autoOk={true}
                // className="passyear"
              />
            </MuiPickersUtilsProvider>
          </div>
          </div>
          </div>
          <div className="col-md-6">
            <div className="row">
          
          <div className="col-md-6 mb-3">
            <TextField
              id="standard-search"
              label="Degree *"
              type="text"
              className="w-100"
              value={education.qualification}
              onChange={(e)=>setEducation({...education,qualification:e.target.value,update:true})}
            />
            <br />
            <Checkbox
              name="checkedB"
              color="primary"
              className="p-0 m-0 font-weight-bold"
              disabled={checkDisabeld}
              checked={education.still_studying === "1" ? true : false}
              onChange={(e)=>setEducation({...education,still_studying:e.target.checked ? "1": "0",update:true})}
            />{" "}
            Still Studying
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              id="standard-search"
              label="Pass out %"
              type="number"
              className="w-100"
              onKeyPress={(e)=>limitLength(e,e.target.value,2)}
              disabled={education.still_studying === "1"}
              value={(education.passout_percentage !== 2 && education.still_studying !== "1") ? education.passout_percentage : ''}
              onChange={(e)=>setEducation({...education,passout_percentage:e.target.value,update:true})}
            />
          </div>
          </div>
          </div>
        </div>
        <div className="row px-4">
          <div className="col-md-12 d-flex justify-content-end">
          {showBts && <>
            <button class="btn btn-xs btn-green-dark text-white" onClick={props.edit ? ()=>{setDeleteModal(true)} : clearvalues}>
              Delete 
            </button>
            </>}
          </div>
        </div>
     
      <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"OK"} btnfunc={handleClose}>
        <span>{modalmsg}</span>
      </CommonModal>

      <CommonModal title={"Delete"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
      </CommonModal>

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
    </>
   )
  }

  export default React.memo(Education)
