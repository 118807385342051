import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { fetchUrl,apiDelete } from "../../ApiActions/Actions";
import { GET_CADNDIDATES_API_URL ,DELETE_CANDIDATE_API_URL} from "../../Configurations/ConfigApi";
import Parser from 'html-react-parser';
import { useDispatch , useSelector} from "react-redux";
import SnakBar from "../../Components/Models/snakbar";
import logoEmblem from "../../Assets/Images/emblem.svg";

// Login css
import "./profile.scss";
import DetailsSidenav from "./detailsSidenav";


// Image Iocns
import CompanyName from "../../Assets/Images/profile-t-icons/CompanyName.svg";
import CandidateName from "../../Assets/Images/profile-t-icons/CandidateName.svg";
import Designation from "../../Assets/Images/profile-t-icons/Designation.svg";
import Experience from "../../Assets/Images/profile-t-icons/Experience.svg";
import Download from "../../Assets/Images/profile-t-icons/Download.svg";
import Edit from "../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../Assets/Images/profile-t-icons/Delete.svg";
import Phone from "../../Assets/Images/profile-t-icons/Phone.svg";
import email from "../../Assets/Images/profile-t-icons/email.svg";
import Location from "../../Assets/Images/profile-t-icons/Location.svg";
import CommonModal from '../../Components/Models/commonModal';
import Experiencetab from "./tabs/experience";
import { saveAs } from 'file-saver';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Details = (props) => {
  const [value, setValue] = useState(0);
  const [cadidateData, setCandidateData] = useState({});
  const [updateModal,setUpdatemodal] = useState(false);
  const [deleteUserId,setDeleteUserId] = useState("");
  const candidateprofileId = useSelector(state=>state.PageReduser.candidateprofileId)
  const roles_data =  useSelector(state=>state.PageReduser.roles_data);
  const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Candidates')
  const [loader,setLoader] = useState(false)
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    let gototcandidate = sessionStorage.getItem("gototcandidate");
    if (gototcandidate ==="true"){ 
    sessionStorage.removeItem("profileId");
sessionStorage.removeItem("gototcandidate");
    }
    window.scroll(0,0)
    setLoader(true)
    fetchUrl(`${GET_CADNDIDATES_API_URL}/${candidateprofileId}`, ({ status, response }) => {
      if (status) {
        setCandidateData(response?.data?.data[0]);
      }
      setLoader(false)
    });
  }, []);

  const deleteCandidate =()=>{
    setLoader(true)
    apiDelete(`${DELETE_CANDIDATE_API_URL}${deleteUserId}`, ({ status, response }) => {
      if (status) {
        sessionStorage.removeItem("profileId");
        dispatch({type:"candidateprofileId",payload:""})
        setUpdatemodal(false)
       props.changetab(0)
      }
      setLoader(false)
  })
  }
  let  n = cadidateData?.primary_mobile || 7995737870
  let primary = ''
  if(n.length >= 10)
  {
   primary = n.replace(/(\d{4})(\d{3})(\d{3})/, "$1-$2-$3")
  }else
  {
  let r = n;
  }


  const downloadFile = () => {
    const link = cadidateData?.resume_url;
    if (link) {
      saveAs(link, cadidateData?.resume_name);
    } else {
      setSuccessMsg({notes:["Currently resume is not availabe for this candidate, please upload the resume"]})
      setSnakBarOpen(true)
  
    }
  }
      
      
  return (
    <>
     {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid px-0 bg-custom-light">
      <div className="row g-0 pt-0 profile-section">

        <div className="col-md-8 pe-2">
          <div className="card-design">
            <div className="card radius-0 p-1 ps-3 border box-shadow-none">
              <div className="row">
                <div className="col-md-7 border-blue pe-0">
                  <div className="row g-0">
                    <div className="col-md-4 cand-img-candidate">
                      <div className="profile-img pe-0 cand-img text-center">
                        <img
                          className="img-fluid radius-profile"
                          src={cadidateData?.profile_pic_url||logoEmblem}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <ul type="none" className="p-0 m-0 list-style-2 ps-2">
                        <li>
                          <img src={CandidateName} className="tab-img-h2 align-top me-2"/>
                          <strong>{cadidateData?.name}</strong>
                        </li>
                        <li>
                        <img src={CompanyName} className="tab-img-h2 align-top  me-2"/> 
                        {cadidateData?.company_name || 'Currently Unemployed'}
                        </li>
                        <li>
                        <img src={Designation} className="tab-img-h2 align-top  me-2"/> 
                        {cadidateData?.designation || 'Currently Unemployed'}
                        </li>
                        <li>
                        <img src={Experience} className="tab-img-h2 align-top  me-1"/>  {cadidateData?.exp_years} Years {cadidateData?.exp_months}  
                        {" "}  Months{" "}
                        </li>
                      </ul>

                      <div className="positin-ui  pe-0 px-0 flex-column d-flex justify-content-end align-content-end pt-2">
                      <ul
                        type="none"
                        className="p-0 m-0 d-flex justify-content-between"
                      >
                        <li>
                          <img src={Download} className="tab-img-h2 align-top  me-2"
                            onClick={downloadFile} style={{ cursor: "pointer" }}
                          /> 
                        </li>
                        <li>
                        {(filter_role_data.length > 0 && filter_role_data[0]["m_edit"]) ?
                          <a>
                          <img src={Edit} className="tab-img-h2 align-top  me-2" style={{cursor:'pointer'}}  onClick={()=>{
                          dispatch({type:"candidateprofileId",payload:""})
                          dispatch({type:"EditCandidateId",payload:cadidateData?.encrypted_id})
                          props.changetab(2)
                          }
                          }/> 
                          </a>
                          : null }
                        </li>
                        <li>
                        {(filter_role_data.length > 0 && filter_role_data[0]["m_delete"]) ?
                          <a>
                          <img src={Delete} className="tab-img-h2 align-top  me-2" style={{cursor:'pointer'}}  onClick={()=>{
                            setUpdatemodal(true);
                            setDeleteUserId(cadidateData?.encrypted_id)
                          }}/> 
                          </a>
                          : null }
                        </li>
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex align-self-center">
                  <ul type="none" className="p-0 m-0 list-style-3 pt-1">
                    <li className="mb-3">
                    <img src={Phone} className="tab-img-h2 align-top  me-2"/>{" "}
                     
                      <span className=" align-middle">
                        {cadidateData?.primary_mobile && cadidateData?.primary_mobile.replace(/(\d{4})(\d{3})(\d{3})/, "$1-$2-$3")}
                      </span>
                    </li>
                    <li className="mb-3">
                    <img src={email} className="tab-img-h2 align-top  me-2"/> {" "}
                     
                      <span className=" align-middle">
                        {" "}
                        {cadidateData?.primary_email}{" "}
                      </span>
                    </li>
                    <li >
                    <img src={Location} className="tab-img-h2 align-top  me-2"/>  <span className="align-middle"> {cadidateData?.city_name !== 'undefined' && cadidateData?.city_name}  - {cadidateData?.state_name}  </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bg-dark-gray font-weight-headings">
              <div className="row text-center g-0">
                <div className="col-md-2">Current CTC</div>
                <div className="col-md-2">Expected CTC</div>
                <div className="col-md-2">Fixed CTC</div>
                <div className="col-md-2">Variable CTC</div>
                <div className="col-md-2">Stocks</div>
                <div className="col-md-2">Notice Period</div>
              </div>
            </div>
            <div className="bg-light-gray font-weight-headings">
              <div className="row g-0 text-center">
                <div className="col-md-2">
                  <i class="fas fa-rupee-sign"></i> {cadidateData?.current_ctc}
                </div>
                <div className="col-md-2">
                  <i class="fas fa-rupee-sign"></i> {cadidateData?.expected_ctc}
                </div>
                <div className="col-md-2">
                  <i class="fas fa-rupee-sign"></i> {cadidateData?.fixed_ctc}
                </div>
                <div className="col-md-2">
                  <i class="fas fa-rupee-sign"></i> {cadidateData?.variable_ctc}
                </div>
                <div className="col-md-2">{cadidateData?.stocks}</div>
                <div className="col-md-2"> 
                {(cadidateData?.notice_period && cadidateData?.notice_period.length < 4) ? `${cadidateData?.notice_period} Days` : cadidateData?.notice_period}
                
                </div>
              </div>
            </div>
            <div className="mt-3">
              <AppBar position="static" className="border">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Resume" {...a11yProps(0)} />
                  <Tab label="About" {...a11yProps(1)} />
                  <Tab label="Experience" {...a11yProps(2)} />
                  <Tab label="Mail" {...a11yProps(3)} />  
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} className="border">
                {
                  cadidateData?.resume_url?.indexOf('doc')!= -1 ?
                  <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${cadidateData?.resume_url}`} width='100%' height="600px" frameborder='0'></iframe>
                  : <iframe src={cadidateData?.resume_url} width="100%" height="600px"/> 
                }
              </TabPanel>
              <TabPanel value={value} index={1} className="border">
              {cadidateData?.about_candidate ? Parser(cadidateData?.about_candidate):""}
              </TabPanel>
              <TabPanel value={value} index={2} className="border">
                {/* Timeline */}
                <Experiencetab/>
              </TabPanel>
              <TabPanel value={value} index={3} className="border">
                <h1 className="text-center">Coming Soon..</h1>
              </TabPanel>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <DetailsSidenav cadidateData={cadidateData}/>
        </div>
      </div>
      <CommonModal title={"Delete"} handleClose={()=>setUpdatemodal(false)} open={updateModal} button={"DELETE"} btnfunc={deleteCandidate}>
        <span>Are you sure you want to delete candidate ?</span>
      </CommonModal>

      <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)}/>
    </div>
    </>
  );
};

export default Details;
