import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchUrl } from "../../../../ApiActions/Actions";
import { GET_CLIENT_POINT_CONTACT_DETAILS_STATUS } from "../../../../Configurations/ConfigApi";

const PointOfDetails = (props) => {
    const [pointofConatct,setPointofConatct] = useState([]);
  const update_Encrypted_id = useSelector(
    (state) => state.PageReduser.ViewClientId
  );

  useEffect(() => {
    fetchUrl(
      `${GET_CLIENT_POINT_CONTACT_DETAILS_STATUS}/${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
          let data = response?.data?.data;
          setPointofConatct(data)
        }
      }
    );
  }, []);

 
  return (
    <>
 <div className="row">
                    <div className="col-md-12">
                      <div className="gray-card2 py-3">
                          {pointofConatct.map((data)=>{
                              return <>
                               <div className="row px-4">
                              <div className="col-12 border-right">
                             <ol className="p-0 m-0 step-list-style" type="none">
                               <li className="pb-3">Contact Name: <span>{data.client_contact_name}</span></li>
                               <li className="pb-3">Contact Number: { data.client_contact_phone}</li>
                               <li className="pb-3">Alternate Contact Name: {data.client_contact_alternate_phone}</li>
                               <li className="pb-3">Contact Email: {data.client_contact_email}</li>
                               </ol> 
                              </div>
                            </div>
                            <hr />
                            </>
                          })}
                      </div>
                    </div>
                    
                  </div>
   
    </>
  );
};
export default PointOfDetails;
