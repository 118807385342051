import React,{useState,useEffect} from 'react'
import { useDispatch , useSelector} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles'; 
import Resumedetails  from './Resumedetails';
import  InterviewDetails  from './InterviewDetails';
import { CustomDetails } from './CustomDetails';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

 const Datatables = (props) => {
    
   
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const roles_data =  useSelector(state=>state.PageReduser.roles_data);
  const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Candidates')

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  
  return(
      <div className="container-fluid px-4 pl-5 pt-4 card-design">
          <div className="row px-4">
              <div className="col-md-12 px-4">
                  <div className="row g-0">
                      <div className="col-md-6 bg-white px-4 pt-3 MuiPaper-elevation4">
                          <strong className="position-up">Data Tables</strong>
                      </div>
                      <div className="col-md-6 tab-end">
                          <AppBar position="static" >
                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                  <Tab label="Resume Details" {...a11yProps(0)} />
                                  <Tab label="Interview Details" {...a11yProps(1)} />
                                  <Tab label="Custom Details" {...a11yProps(3)} />
                                                              
                              </Tabs>
                          </AppBar>
                      </div>
                  </div>
                  <TabPanel value={value} index={0}  className="card-0 a-b-0-0">
                    <Resumedetails rolesdata={filter_role_data} />
                  </TabPanel>
                  <TabPanel value={value} index={1} className="card-0 a-b-0">
                      <InterviewDetails changetab={setValue}/>
                  </TabPanel>
                  <TabPanel value={value} index={2} className="cand-p0 a-b-0">
                      <CustomDetails changetab={setValue} />
                  </TabPanel>                   
              </div>
          </div>
      </div>

   )
  }

  export default Datatables