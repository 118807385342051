import React from 'react'
import Sidenav from "../../Components/Sidenav/Sidenav";

const Resume = (props) => {
  return(
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col-md-1 p-0 m-0">
          <Sidenav category={"CandidateProfile"} />
        </div>
        <div className="col-md-11 pt-3">
         
        </div>
      </div>
    </div>
   )
  }


export default Resume