import React,{useState,useEffect} from 'react'
import { useDispatch , useSelector} from "react-redux";
import {GET_JOBORDERS_API_URL } from '../../Configurations/ConfigApi'
import { fetchUrl, apiDelete } from '../../ApiActions/Actions'
import {
  GroupingState,
  SortingState,
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import view from "../../Assets/Images/profile-t-icons/view.svg";
import Edit from "../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../Assets/Images/profile-t-icons/Delete.svg";
import SnakBar from "../../Components/Models/snakbar";
import logoEmblem from "../../Assets/Images/emblem.svg";

import {
  Grid,
  VirtualTable,
  Table,
  TableHeaderRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";


import { withRouter } from 'react-router';
import CommonModal from '../../Components/Models/commonModal';

const getRowId = (row) => {
  return row.id;
};

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box p={3}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

const JobordersList = (props) => {

  const dispatch = useDispatch()
  const [columns] = useState([
    // { name: "status", title: "Job Status" },
    { name: "job_title", title: "Job Title" },
    { name: "client_name", title: "Client Name" },
    { name: "contact_name", title: "TAContact" },
    { name: "office_landmark", title: "Job Location" },
    { name: "actions", title: "Actions" },
  ]);

  const [value, setValue] = useState(0);

  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' },{ columnName: 'status', direction: 'asc' },{ columnName: 'job_title', direction: 'asc' }]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(20);
  const [actionsColumns] = useState(["actions"]);
  const [joblocationClolumns] = useState(["office_landmark"]);
  const [creatAtColumns] = useState(["created_at"])
  const [usersData,setUsersData] = useState([])
  const [inactiveusersData,setinactiveusersData] = useState([])
  const [usersCount,setUsersCount] = useState(0)
  const [inactiveusersCount,setinactiveusersCount] = useState(0)
  const [searchValue, setSearchState] = useState('');
  const [updateModal,setUpdatemodal] = useState(false);
  const [deleteUserId,setDeleteUserId] = useState("");
  const [active,setActive]  = useState("active");
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [loader,setLoader] = useState(false)

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteUserId("");
  };

  useEffect(()=>{
    dispatch({type:"EditJobId",payload:""});
    dispatch({type:"Viewjoborder",payload:""})
    getUserlist()
  },[currentPage,sorting,searchValue,active])

  const getUserlist=()=>{
    setLoader(true)
    let sortingKey = sorting[0].columnName
    let sortingDirection = sorting[0].direction

    fetchUrl(`${GET_JOBORDERS_API_URL}?sortby=${sortingKey}&direction=${sortingDirection}&page=${currentPage}&limit=${pageSize}&q=${searchValue}&status=${active}`, ({ status, response }) => {
      if (status) {
        if(response?.data?.status === true){
          console.clear();

          let {data,all_data_cnt,filter_data_cnt} = response.data          
          filter_data_cnt <= all_data_cnt ?  setUsersCount(all_data_cnt): setUsersCount(filter_data_cnt)
          let active =[];
          let inactive =[];           
          for (let i = 0; i < data.length; i++) {
            if (data[i].status.toLowerCase() === 'active')
            active.push(data[i]);
            else
            inactive.push(data[i]);
          }
          setUsersData(active)
          setinactiveusersData(inactive)  
          setinactiveusersCount(inactive.length);         
        }
      }
      setLoader(false)
  })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue==0){
      setActive("active")
    }else{
      setActive("inactive")
    }
};

  const StatusFormatter = ({ value, row }) => (
    <>
     <a className="link text-link cursor-pointer px-1" onClick={()=>{
        dispatch({type:"Viewjoborder",payload:row.encrypted_id})
        sessionStorage.setItem("JobOrderId",row.encrypted_id)
        sessionStorage.setItem("Jobtitle",row.job_title)
        props.changetab(4)
        }
        }>
          {/* <VisibilityIcon fontSize="small" className="text-dark"/> */}
          <img src={view} className="icon-img me-4"/> 
      </a>

      <a className="link text-link cursor-pointer pe-1" onClick={()=>{
        dispatch({type:"EditJobId",payload:row.encrypted_id})
        sessionStorage.setItem("editId",row.encrypted_id)
        props.changetab(3)
        }
        }>
         {/* <EditIcon fontSize="small" className="text-dark"/>  */}
         <img src={Edit} className="icon-img me-4"/> 
      </a>

      <a className="link text-link cursor-pointer px-1" onClick={()=>{
        setUpdatemodal(true);
        setDeleteUserId(row.encrypted_id)
      }
        }>
        {/* <DeleteIcon fontSize="small" className="text-dark" />  */}
        <img src={Delete} className="icon-img"/> 
      </a>
    </>
  );

  const JobLocationFormatter = ({ value, row }) => (
    <>
    <span>{row?.city_name}</span>,{" "}
      <span>{row?.office_landmark}</span>.
    </>
  );

  const ActionsTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );

  const JobLocationProvider = (props) => (
    <DataTypeProvider formatterComponent={JobLocationFormatter} {...props} />
  );

  const dateFormatter = ({ value, row })=>{
    let date = new Date(value)
    return <span>
      {date.getDate()+ "-" + date.getMonth()+"-"+ date.getFullYear()}
    </span>
  }
  const CreateDateTypeProvider  = (props) => (
    <DataTypeProvider formatterComponent={dateFormatter} {...props} />
  );

  const deleteCandidate =()=>{
    apiDelete(`${GET_JOBORDERS_API_URL}/${deleteUserId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false)
        setSuccessMsg({notes:["Deleted Successfully"]})
        setSnakBarOpen(true)
        getUserlist()
      }
  })
  }

  return(
    <>
       {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="container-fluid px-1 pl-4 pt-0 card-design card-p0">
        <div className="row px-0">
          <div className="col-md-12 px-2">
            <div className="row g-0  border-bottom-0 ">
              <div className="col-md-8 bg-white px-4 pt-3 MuiPaper-elevation4">
                <strong className="position-up">List</strong>
              </div>
              <div className="col-md-4 tab-end">
                <AppBar position="static">
                  <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Active" {...a11yProps(1)} />
                    <Tab label="InActive" {...a11yProps(0)} />

                    {/* <Tab label="Profile" {...a11yProps(3)} /> */}
                  </Tabs>
                </AppBar>
              </div>
            </div>
            <TabPanel value={value} index={0} className="border-top-0 clients card-0 pt-4 mt-1">
              <div className="container-fluid pr-0 pl-0 px-0">
                <div className="row no-gutters">

                  <div className="col-md-12 table-card">
                    <div className="card card-shadow-none table-center">
                      <Grid rows={usersData} columns={columns} getRowId={getRowId}>
                        <SortingState
                          sorting={sorting}
                          onSortingChange={setSorting}
                        />

                        <SearchState className="d-none"
                          value={searchValue}
                          onValueChange={setSearchState} />


                        <PagingState
                          currentPage={currentPage} // show current page, first time it's set to 1
                          pageSize={pageSize} // set page limit to show in one page
                          onCurrentPageChange={(currentPage) => setCurrentPage(currentPage)}
                        />
                        <CustomPaging totalCount={usersCount} />

                        <ActionsTypeProvider for={actionsColumns} />
                        <JobLocationProvider for={joblocationClolumns} />
                        <Table noData={"no data found"} className="group" />

                        <Toolbar />
                        <SearchPanel />
                        <GroupingState />
                        <TableHeaderRow
                          showSortingControls // to control the sorting in each fields
                        />

                        <PagingPanel />
                      </Grid>

                    </div>
                  </div>
                </div>
              </div>

            </TabPanel>
            <TabPanel value={value} index={1} className="border border-top-0 card-0">
            <div className="container-fluid pr-0 pl-0 px-0">
                <div className="row no-gutters">

                  <div className="col-md-12 table-card">
                    <div className="card card-shadow-none table-center">
                      <Grid rows={inactiveusersData} columns={columns} getRowId={getRowId}>
                        <SortingState
                          sorting={sorting}
                          onSortingChange={setSorting}
                        />

                        <SearchState className="d-none"
                          value={searchValue}
                          onValueChange={setSearchState} />


                        <PagingState
                          currentPage={currentPage} // show current page, first time it's set to 1
                          pageSize={pageSize} // set page limit to show in one page
                          onCurrentPageChange={setCurrentPage}
                        />
                        <CustomPaging totalCount={inactiveusersCount} />
                        <CreateDateTypeProvider for={creatAtColumns} />
                        <ActionsTypeProvider for={actionsColumns} />
                        <JobLocationProvider for={joblocationClolumns} />
                        <Table noData={"no data found"} className="group" />

                        <Toolbar />
                        <SearchPanel />
                        <GroupingState />
                        <TableHeaderRow
                          showSortingControls // to control the sorting in each fields
                        />

                        <PagingPanel />
                      </Grid>

                    </div>
                  </div>
                </div>
              </div>

            </TabPanel>
          </div>
        </div>
      </div>
      
      <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteCandidate}>
        <span>Are you sure you want to delete Job Order ?</span>
      </CommonModal>

      <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success"/>
    </>
   )
  }


export default withRouter(JobordersList)