import React, { useState, useEffect } from "react";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import { fetchUrl, apiPost } from '../../ApiActions/Actions';
import { GET_CLIENTS_URL ,GET_CLIENT_GST_DETAILS_API_URL,GET_CLIENT_POINT_CONTACT_DETAILS_STATUS,GET_CLIENT_PAYMENT_STATUS,GET_CANDIDATE_INVOICE_PROCESS_STATUS} from '../../Configurations/ConfigApi';
import SnakBar from "../../Components/Models/snakbar";
import { withRouter } from "react-router";
import { AddClientsForm } from "./addClientForm";
import JobTextEditor from "../../Components/TextEditor/JoTextEditor";
import GstClient from "./addClientForms/gstclient";
import PointOfContact from "./addClientForms/pointofConatct";
import Payment from "./addClientForms/payment";
import  Invoice  from "./addClientForms/invoice";
import { useSelector ,useDispatch } from "react-redux";
import  WysiwygEditor  from "../../Components/TextEditor/WysiwygEditor";
import logoEmblem from "../../Assets/Images/emblem.svg";

const AddClients = (props) => {
  
  const dispatch = useDispatch()
  const [aboutClient, setAboutClient] = useState("");
  const [addInfo, setAddInfo] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [jonAddsuccess, setjonAddsuccess] = useState(false)
  const [update_Encrypted_id, setUpdate_Encrypted_id] = useState("")
  const [addGst, setAddGst] = useState(["add"])
  const [addPointOfContact, setAddPointOfContact] = useState(["add"])
  const [addPayment, setAddPayment] = useState(["add"])
  const [addInvoice, setAddInvoice] = useState(["add"])
  const [componentUpdate, setComponentUpdate] = useState(false)
  const [errors, setErrors] = useState({})
  const [dropIcons, setDropIcons] = useState("fa-info-circle text-warning")
  const [successMsg,setSuccessMsg] = useState({})
  const [imgchanged,setimgchanged] = useState(false)
  const [loader,setLoader] = useState(false)
  const [clientFields, setClientFields] = useState({
    client_legal_name: "",
    client_short_name: "",
    website_url: "",
    replacement_duration: "",
    invoice_payment_duration: "",
    agreement_start_date: '',
    agreement_end_date: '',
    status: "active",
    clientlogo: "",
  });
  const ClientGstList = useSelector(state=>state.Client_Reduser.ClientGstList)
  const ClientPointOfContactList = useSelector(state=>state.Client_Reduser.ClientPointOfContactList)
  const ClientPaymentList = useSelector(state=>state.Client_Reduser.ClientPaymentList)
  const ClientInvoiceList = useSelector(state=>state.Client_Reduser.ClientInvoiceList)

  useEffect(()=>{
    dispatch({type:"EditClientId",payload:""})
    dispatch({type:"ViewClientId",payload:""})

    return()=>{
      dispatch({type:"RemoveUpdatedClientGst"})
      dispatch({type:"RemoveAllUpdatedClientPOC"})
      dispatch({type:"RemoveAllUpdatedClientPayment"})
      dispatch({type:"RemoveAllUpdatedClientInvoice"})
      dispatch({type:"RemoveAllClientGst"})
      dispatch({type:"RemoveAllClientPOC"})
      dispatch({type:"RemoveAllClientPayment"})
      dispatch({type:"RemoveAllClientInvoice"})
    }
  },[]);

  const [fieldserror, setFieldErros] = useState(false)

  const handleClose = () => {
  
    setjonAddsuccess(false);
  };

  const checkValidation = ()=>{
    let checkFields = { 
    
      client_legal_name: ["The client legal name field is required."],
      client_short_name: ["The client short name field is required."],

  }
    
    for (const key in clientFields) {
      if(clientFields[key]){
        delete checkFields[key]
      }
    }

    
    if(Object.keys(checkFields).length > 0){
      setErrors(checkFields)
      setFieldErros(true)
      setDropIcons("fa-times-circle text-danger")
      setTimeout(()=>{
        setErrors({})
        setFieldErros(false)
      },2000)
    return false
  }else{
    return true
  }
  }

  function gottoclientdetails(id){
    setTimeout(() => {
      if(Object.keys(errors).length === 0){
        dispatch({type:"EditClientId",payload:""})
        dispatch({type:"ViewClientId",payload:id})
        props.changetab(2);
      }else{
        dispatch({type:"EditClientId",payload:id})
        dispatch({type:"ViewClientId",payload:''})
        props.changetab(2);
      }
    }, 3000);    
  }

  const saveClient = () => {


    let agreement_start_date = ""
    let agreement_end_date = ""

    if (clientFields.agreement_start_date && clientFields.agreement_end_date) {
      let newdate = new Date(clientFields.agreement_start_date)
      agreement_start_date = newdate.getFullYear() + "-" + (newdate.getMonth() + 1) + "-" + newdate.getDate()

      let newdate1 = new Date(clientFields.agreement_end_date)
      agreement_end_date = newdate1.getFullYear() + "-" + (newdate1.getMonth() + 1) + "-" + newdate1.getDate()
    }

    let data = new FormData()
    data.append("client_legal_name", clientFields.client_legal_name)
    data.append("client_short_name", clientFields.client_short_name)
    data.append("website_url", clientFields.website_url)
    data.append("replacement_duration", clientFields.replacement_duration)
    data.append("invoice_payment_duration", clientFields.invoice_payment_duration)
    data.append("agreement_start_date", agreement_start_date)
    data.append("agreement_end_date", agreement_end_date)
    data.append("about_client", aboutClient)
    data.append("additional_information", addInfo)
    data.append("status", "active")
    data.append("clientlogo", clientLogo)

    if(checkValidation()){
      setLoader(true)
      apiPost(GET_CLIENTS_URL, data, ({ status, response }) => {
        if (status) {
          setSuccessMsg({notes:["Client Added Successfully"]})
          setjonAddsuccess(true)
          let res = response.data
          setUpdate_Encrypted_id(res.data.encrypted_id)
          setDropIcons("fa-check-circle text-success")

          for (let i = 0; i < ClientGstList.length; i++) {
            if(ClientGstList[i].update){
            addGstData(ClientGstList[i],res.data.encrypted_id)
            }
          }

          for (let i = 0; i < ClientPointOfContactList.length; i++) {
            if(ClientPointOfContactList[i].update){
            addPointOfContactData(ClientPointOfContactList[i],res.data.encrypted_id)
            }
          }

          for (let i = 0; i < ClientPaymentList.length; i++) {
            if(ClientPaymentList[i].update){
            addPaymentData(ClientPaymentList[i],res.data.encrypted_id)
            }
          }

          for (let i = 0; i < ClientInvoiceList.length; i++) {
            if(ClientInvoiceList[i].update){
              addInvoiceData(ClientInvoiceList[i],res.data.encrypted_id)
            }
          }

          gottoclientdetails(res.data.encrypted_id)
        } else {
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
          setDropIcons("fa-times-circle text-danger")
        }
        setLoader(false)
      })
    }
   
  }

  const deleteEducationCount = (index, type) => {
    if (type === "gst") {
      let data = addGst
      data.splice(index, 1)
      setAddGst(data)
      setComponentUpdate(!componentUpdate)
    } else if (type === "pointofcontact") {
      let data = addPointOfContact
      data.splice(index, 1)
      setAddPointOfContact(data)
      setComponentUpdate(!componentUpdate)
    } else if (type === "payment") {
      let data = addPayment
      data.splice(index, 1)
      setAddPayment(data)
      setComponentUpdate(!componentUpdate)
    }
    else {
      let data = addInvoice
      data.splice(index, 1)
      setAddInvoice(data)
      setComponentUpdate(!componentUpdate)
    }
  }

  const addGstData = (data,Clientid) =>{
    let gstData = data
    gstData.client_id = Clientid
    delete gstData.encrypted_id
    delete gstData.update
    setLoader(true)
    apiPost(GET_CLIENT_GST_DETAILS_API_URL, gstData, ({ status, response }) => {
      if (status) {
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
      setLoader(false)
  })
  } 

const addPointOfContactData = (data,Clientid) =>{
  let pointOfcontctData = data
  pointOfcontctData.client_id = Clientid
  delete pointOfcontctData.encrypted_id
      delete pointOfcontctData.update
      setLoader(true)
  apiPost(GET_CLIENT_POINT_CONTACT_DETAILS_STATUS, pointOfcontctData, ({ status, response }) => {
    if (status) {
    }else{
      let msg = JSON.parse(response.request.response).errors
      setErrors(msg)
      setFieldErros(true)
    }
    setLoader(false)
  })
  } 

  const addPaymentData = (data,Clientid) =>{
    let paymentData = data
    paymentData.client_id = Clientid
   
    delete paymentData.encrypted_id
    delete paymentData.update
    setLoader(true)
    apiPost(GET_CLIENT_PAYMENT_STATUS, paymentData, ({ status, response }) => {
      if (status) {
    
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
      setLoader(false)
    })
    } 

    const addInvoiceData = (data,Clientid) =>{
      let invoiceData = data
      invoiceData.client_id = Clientid
      delete invoiceData.encrypted_id
      delete invoiceData.update
      setLoader(true)
      apiPost(GET_CANDIDATE_INVOICE_PROCESS_STATUS, invoiceData, ({ status, response }) => {
        if (status) {
    
        }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
        setLoader(false)
      })
      
      } 

  const addgstToForm = () => {
    setAddGst([...addGst, "add"])
  }

  const addPointOfConatctToForm = () => {
    setAddPointOfContact([...addPointOfContact, "add"])
  }

  const addpaymentToForm = () => {
    setAddPayment([...addPayment, "add"])
  }

  const addInvoiceToForm = () => {
    setAddInvoice([...addInvoice, "add"])
  }

  return (
    <>
       {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="container-fluid bg-none clients px-3">
        <div className="row no-gutters">
          <div className="col-md-12 pt-3 px-0">
            <div className=" px-0">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="upload-file-section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-5">
                        <div className="card custom-min-heght">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">Select Client Logo </h5>
                          </div>
                          <div className="card-body text-center">
                            {/* <input type="file" onChange={(e)=>console.log(e.target.value)}/> */}
                            <DragAndDrop
                              id={"Clientlogo"}
                              onselect={setClientLogo}
                              header={"Client's Logo "}
                              data={clientLogo?.name}
                              image={true}
                              setProfilePic={setClientLogo}
                              setimgchanged={setimgchanged}
                            />
                            <div className="text-center">
                              <i className={`fas ${dropIcons} fa-2x `}></i>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <AddClientsForm clientFields={clientFields} setclientFields={setClientFields} />

              <WysiwygEditor editDetails={setAboutClient} title={"About Client"} />

              <WysiwygEditor editDetails={setAddInfo} title={"Additional Information"} />

              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            GST Details
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addgstToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />
                    {addGst.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <GstClient id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "gst")} edit={false} index={index}/>
                      </>
                    })}
                  </div>
                </div>
              </div>


              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Point Of Contact
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addPointOfConatctToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />
                    {addPointOfContact.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <PointOfContact id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "pointofcontact")} index={index}/>
                      </>
                    })}
                  </div>
                </div>
              </div>


              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Payment terms
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addpaymentToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />
                    {addPayment.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <Payment id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "payment")} index={index}/>
                      </>
                    })}
                  </div>
                </div>
              </div>

              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Invoice Process Stages
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addInvoiceToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />

                    {addInvoice.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <Invoice id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "invoice")} index={index}/>
                      </>
                    })}

                  </div>
                </div>
              </div>

            
          <div className="text-center mb-5 pt-2">
            <div className=" d-inline-block me-3">
              <button class="btn btn-sm btn-green-dark text-white " onClick={()=>props.changetab(0)}>
                Cancel
              </button>
            </div>
            <div className=" d-inline-block mx-2">
              <button class="btn btn-sm btn-orange text-white" onClick={saveClient}>Save</button>
            </div>
          </div>
            

            </div>
          </div>
        </div>
      </div>


      {/* <CommonModal title={"Success"} handleClose={handleClose} open={jonAddsuccess} button={"ok"} btnfunc={handleClose}>
        Client Added Successfully
      </CommonModal> */}

      <SnakBar error={successMsg} open={jonAddsuccess} onClose={handleClose} type="success"/>

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)} />
    </>
  );
};

export default withRouter(AddClients);
