import React, { useEffect, useState } from "react";
import { useDispatch , useSelector} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ClientsList from './clientList';
import AddClients from './addClient';
import Details from './clientDetails/details';
import UpdateClients from './updateClient'

import "./clients.scss";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

 const Client = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const EditClientId = useSelector(state=>state.PageReduser.EditClientId);
    const ViewClientId = useSelector(state=>state.PageReduser.ViewClientId);

    const roles_data =  useSelector(state=>state.PageReduser.roles_data);  
    const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Clients')
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  
  return(
      <div className="container-fluid px-4 pl-5 pt-4 card-design">
          <div className="row px-4">
              <div className="col-md-12 px-4">
                  <div className="row g-0">
                      <div className="col-md-7 bg-white px-4 pt-3  MuiPaper-elevation4">
                          <strong className="position-up">Clients</strong>
                      </div>
                      <div className="col-md-5 tab-end">
                          <AppBar position="static" className="card-0 clients">
                              <Tabs className="clients" value={value} onChange={handleChange} aria-label="simple tabs example">
                                  <Tab label="List" {...a11yProps(0)} />
                                  {(filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ?
                                    <Tab label="Add" {...a11yProps(1)} /> : null
                                  }
                                  {EditClientId ?
                                  <Tab label="Edit" {...a11yProps(2)} /> :""}
                                  {ViewClientId ?
                                  <Tab label="Client" {...a11yProps((filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2: 1)}  /> :""}                
                              </Tabs>
                          </AppBar>
                      </div>
                  </div>
                  <TabPanel value={value} index={0} className="card-0 clients">
                    <ClientsList changetab={setValue}/>
                  </TabPanel>
                  {(filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ?
                  <TabPanel value={value} index={1} className="card-0 clients">
                     <AddClients changetab={setValue} />
                  </TabPanel>
                    : null}
                  {EditClientId ?
                    <TabPanel value={value} index={2} className="clients ">
                    <UpdateClients changetab={setValue} />
                </TabPanel> :""}
                    
                {ViewClientId ?
                     <TabPanel value={value} index={(filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2: 1} className="cand-p02">
                     <Details changetab={setValue} />
                  </TabPanel> :""} 
                 
                 
                 
              </div>
          </div>
      </div>

   )
  }

  export default Client