import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchUrl } from "../../../../ApiActions/Actions";
import { GET_CANDIDATE_INVOICE_PROCESS_STATUS } from "../../../../Configurations/ConfigApi";

const InvoiceDetails = (props) => {
    const [invoice,setInvoice] = useState([]);
  const update_Encrypted_id = useSelector(
    (state) => state.PageReduser.ViewClientId
  );

  useEffect(() => {
    fetchUrl(
      `${GET_CANDIDATE_INVOICE_PROCESS_STATUS}/${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
          let data = response?.data?.data;
          setInvoice(data)
        }
      }
    );
  }, []);

 
  return (
    <>

<div className="client-steps">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                      {invoice.map((data,index)=>{
                      return  <tr>
                      <th className="text-aqua-light step-width">Step {index+1} </th>
                      <td>&nbsp;{data.steps}</td>
                    </tr>
                    })}                     
                      </tbody>
                    </table>
                    </div>
</div>
   
    </>
  );
};
export default InvoiceDetails;
