import React, { useEffect, useState } from 'react';
// Timeline
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { fetchUrl } from "../../../ApiActions/Actions";
import { ADD_CANDIDATE_EXPERIRNCE_API_URL } from "../../../Configurations/ConfigApi";
import { useSelector } from "react-redux";

 const Experiencetab = (props) => {
    const [experienceData,setExperienceData] = useState([])
    const candidateprofileId = useSelector(state=>state.PageReduser.candidateprofileId)
    useEffect(()=>{
        fetchUrl(`${ADD_CANDIDATE_EXPERIRNCE_API_URL}/${candidateprofileId}`, ({ status, response }) => {
            if (status) {
                setExperienceData(response?.data?.data)
            }else{
                
            }
        })
    },[])

    const  monthDiff = (dateFrom, dateTo) =>{
      return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }
  return(
    <Timeline align="left">     
        {
            experienceData.map((res,index)=>{
                return <TimelineItem>
                <TimelineSeparator>
                <TimelineDot variant="outlined" color="primary" />
                {index === (experienceData.length-1) ? "":<TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                <div className="card bg-light p-2">
                    <div className="card-body pb-0 p-2">
                      <div className="row">
                        <div className="col-12">
                          <p className="f-13 pb-1 mb-1">{res.company_name}</p>
                          <p className="f-13 pb-1 mb-1">{res.job_title}  </p>
                          <p className="f-13 pb-0 mb-0">  <span className="d-inline-block pr-2">From: {res?.start_date?.substring(0, res?.start_date?.length - 3)}</span>  <span className="d-inline-block pr-2"> {res?.still_working == "0" && `To: ${res?.end_date?.substring(0, res?.end_date?.length - 3)}`}</span> </p>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                      <p className="f-13 pb-0 mb-0"> {res?.still_working == "0" ? `${monthDiff(new Date(res?.start_date),new Date(res?.end_date))} Months` : "Still Working"} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            }) 
        }
    </Timeline>       
   )

 }

 export default Experiencetab