import React, { useEffect, useState } from "react";
import {GET_JOBORDER_STATUSCOUNT, TODAY_CANDIDATES_JOB_INTERVIEW, RECENT_CANDIDATES_JOB_INTERVIEW } from "../../../../Configurations/ConfigApi";
import { fetchUrl, apiEdit } from "../../../../ApiActions/Actions";
import { useSelector } from "react-redux";

const Snapshot = (props) => {

  const [JobStatuscount, setJobStatuscount] = useState([]);
  const [TodayInterviews, setTodayInterviews] = useState([]);
  const [RecentInterviews, setRecentInterviews] = useState([]);
  const Viewjoborder = useSelector(state=>state.PageReduser.Viewjoborder);
  useEffect(() => {  
    fetchUrl(`${GET_JOBORDER_STATUSCOUNT}/${Viewjoborder}`, ({ status, response }) => {
      if (status) {
        let stautscnt =[];             
        let data = response?.data[0];         
         if (response?.data.length > 0){           
          stautscnt.push({name:"Joined",count:data.Joined}); 
          stautscnt.push({name:"Active",count:data.Active}); 
          stautscnt.push({name:"Offered",count:data.Offered});  
          stautscnt.push({name:"Processed",count:data.Processed}); 
          stautscnt.push({name:"Prospective",count:data.Prospective});   
          setJobStatuscount(stautscnt);        
         }                        
                  
        }
      });      

      fetchUrl(`${TODAY_CANDIDATES_JOB_INTERVIEW}/${Viewjoborder}`, ({ status, response }) => {
        if (status) {          
          let data = response?.data.data;          
          setTodayInterviews(data);                               
          }
        });

        fetchUrl(`${RECENT_CANDIDATES_JOB_INTERVIEW}/${Viewjoborder}${'?page=0&limit=5'}`, ({ status, response }) => {
          if (status) {                 
            let data = response?.data.data;            
            setRecentInterviews(data);                    
            }
          });
  },[]);

  function formatDate(value) {    
    if ( value === undefined || value === null || value === '' )
        return '';
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });
    return day + '-' + month + '-' + year;
}

  return(
    <div className="inner-content">
    <h6 className="text-underline pb-3"> Snapshot</h6>
    <h5 className="f-13 pb-0 mb-1 font-weight-bold  text-center">Overall Status</h5>
    <hr className="p-0 m-0" />
    <table className="table table-striped  table-hover mb-4">
      <tbody>
      {JobStatuscount.map((data, index) => {
                  return (
                    <tr>
                    <td>{data.name}</td>
                    <td className="text-center"><strong className="text-aqua-light">{data.count}</strong></td>
                  </tr>
                    );
                })}         
        </tbody>
    </table>

    <div className="mb-4"> 
    <h5 className="f-13 pb-0 mb-1 font-weight-bold  text-center">Recent Interviews</h5>
    <hr className="p-0 m-0" />
    <ul type="none" className="p-0 m-0 mb-2 list-style-4 pt-3">
      {/* <li> <a className="text-aqua-light">Candidate Name</a> on <a className="text-aqua-light">Date</a> at <a className="text-aqua-light">Time</a> </li> */}
      {TodayInterviews.map((data, index) => { 
          return (
        <li> <a className="text-aqua-light">{data.candidate_name}</a> on <a className="text-aqua-light">{formatDate(data.schedule_time)}</a> at <a className="text-aqua-light">{data.hour} 
      :{data.minutes}  {data.timeformat}</a> </li> ); })} 
      </ul>
    </div>

    <div className="mb-3"> 
    <h5 className="f-13 pb-0 mb-1 font-weight-bold  text-center">Recent Interviews</h5>
    <hr className="p-0 m-0" />
    <ul type="none" className="p-0 m-0 mb-2 list-style-4 pt-3">
    {RecentInterviews.map((data, index) => {  
                  return (
                    <li> <a className="text-aqua-light">{data.candidate_name}</a> on <a className="text-aqua-light">{formatDate(data.schedule_time)}</a> at  
                    <a className="text-aqua-light"> {data.hour} 
      :{data.minutes}  {data.timeformat}</a> </li>
                    );
                  })} 
      {/* <li> <a className="text-aqua-light">Candidate Name</a> on <a className="text-aqua-light">Date</a> at <a className="text-aqua-light">Time</a> </li>
      <li> <a className="text-aqua-light">Candidate Name</a> on <a clas sName="text-aqua-light">Date</a> at <a className="text-aqua-light">Time</a> </li>
      <li> <a className="text-aqua-light">Candidate Name</a> on <a className="text-aqua-light">Date</a> at <a className="text-aqua-light">Time</a> </li>
      <li> <a className="text-aqua-light">Candidate Name</a> on <a className="text-aqua-light">Date</a> at <a className="text-aqua-light">Time</a> </li>
      <li> <a className="text-aqua-light">Candidate Name</a> on <a className="text-aqua-light">Date</a> at <a className="text-aqua-light">Time</a> </li> */}
      </ul>
    </div>
      </div>

   )
  }

  
export default Snapshot
