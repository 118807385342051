import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  GroupingState,
  SortingState,
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import DeleteActiveUser from "../../Components/Models/deleteActiveuser";
import { GET_ALL_USERS_API_URL, DELETE_USER_API_URL } from '../../Configurations/ConfigApi'
import { fetchUrl, apiDelete } from '../../ApiActions/Actions'
// Login css
import "./register.scss";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SnakBar from "../../Components/Models/snakbar";

const getRowId = (row) => {

};

const ActiveUsers = (props) => {

  const dispatch = useDispatch()

  const [columns] = useState([
    { name: "first_name", title: "User Name" },
    { name: "status", title: "Status" },
    { name: "email", title: "Email ID" },
    { name: "role_name", title: "Role" },
    { name: "mobile", title: "Mobile No" },
    { name: "actions", title: "Actions" },
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'first_name', direction: 'asc' }, { columnName: 'email', direction: 'asc' }]);

  const [imageColumns] = useState(["actions"]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(7);
  const [openDeletepop, setOpenDeletePop] = useState(false)
  const [usersData, setUsersData] = useState([])
  const [usersCount, setUsersCount] = useState(0)
  const [deleteId, setDeleteId] = useState("")
  const [searchValue, setSearchState] = useState('');
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)

  const handleClose = () => {
    setOpenDeletePop(false);
    setDeleteId("");
  };

  useEffect(() => {
    getUserlist()
  }, [currentPage, sorting, searchValue])

  const getUserlist = () => {
    let sortingKey = sorting[0].columnName
    let sortingDirection = sorting[0].direction

    fetchUrl(`${GET_ALL_USERS_API_URL}sortby=${sortingKey}&direction=${sortingDirection}&page=${currentPage}&limit=${pageSize}&q=${searchValue}`, ({ status, response }) => {
      if (status) {
        if (response?.data?.status === true) {
          let { data, all_data_cnt, filter_data_cnt } = response.data
          filter_data_cnt === all_data_cnt ? setUsersCount(all_data_cnt) : setUsersCount(filter_data_cnt)
          setUsersData(data)
        }
      }
    })
  }


  const StatusFormatter = ({ value, row }) => (
    <>
      <a className="link text-link cursor-pointer pe-1" onClick={() => {
        dispatch({ type: "EditUserId", payload: row.email })
        props.changeTab(3)
      }}>
        <EditIcon fontSize="small" className="text-dark" />
      </a>
      <a className="link text-link cursor-pointer px-1" onClick={() => {
        setDeleteId(row.encrypted_id)
        setOpenDeletePop(true)
      }
      }>
        <DeleteIcon fontSize="small" className="text-dark" />
      </a>
    </>
  );

  const ImageTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );

  const deleteUser = () => {

    apiDelete(`${DELETE_USER_API_URL}${deleteId}`, ({ status, response }) => {
      if (status) {
        setSuccessMsg({notes:["Deleted Successfully"]})
        setSnakBarOpen(true)
        handleClose()
        getUserlist()

      }
    })

  }

  return (
    <>
      <div className="container-fluid pr-0 pl-0">
        <div className="row no-gutters px-2 px-md-0">
          {/* <div className="col-md-1 p-0 m-0">
        <Sidenav category={"Admin"}/>
        </div> */}
          <div className="col-md-12  table-card">
            <div className="card card-shadow-none">
              <Grid rows={usersData} columns={columns} getRowId={getRowId}>
                <SortingState
                  sorting={sorting}
                  onSortingChange={setSorting}
                />

                <SearchState className="d-none"
                  value={searchValue}
                  onValueChange={setSearchState} />

                {/* <DataTypeProvider
          for={nameColumns}
          availableFilterOperations={nameFilterOperations}
        /> */}
                <PagingState
                  currentPage={currentPage} // show current page, first time it's set to 1
                  pageSize={pageSize} // set page limit to show in one page
                  onCurrentPageChange={setCurrentPage}
                />
                <CustomPaging totalCount={usersCount} />
                {/* <DataTypeProvider
          for={emailColumns}
          availableFilterOperations={nameFilterOperations}
        /> */}

                <ImageTypeProvider for={imageColumns} />

                <Table noData={"no data found"} className="group" />

                <Toolbar />
                <SearchPanel />
                <GroupingState />
                <TableHeaderRow
                  showSortingControls // to control the sorting in each fields
                />

                <PagingPanel />
              </Grid>
            </div>

            <DeleteActiveUser open={openDeletepop} handleClose={handleClose} title={"Delete User"} button={"Delete"} btnfunc={deleteUser}>
              <span className="py-3"> Are you sure you want to Delete User?.</span>
            </DeleteActiveUser>

            <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveUsers;
