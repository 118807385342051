import React, { useState , useEffect} from "react";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import { fetchUrl, apiPost,apiEdit } from '../../ApiActions/Actions';
import { GET_CLIENTS_URL ,GET_CLIENT_GST_DETAILS_API_URL,GET_CANDIDATE_INVOICE_PROCESS_STATUS,GET_CLIENT_PAYMENT_STATUS,GET_CLIENT_POINT_CONTACT_DETAILS_STATUS,GET_CLIENT_LOGO_UPDATE_API} from '../../Configurations/ConfigApi';
import SnakBar from "../../Components/Models/snakbar";
import { useSelector ,useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { AddClientsForm } from "./addClientForm";
import JobTextEditor from "../../Components/TextEditor/JoTextEditor";
import GstClient from "./addClientForms/gstclient";
import PointOfContact from "./addClientForms/pointofConatct";
import Payment from "./addClientForms/payment";
import Invoice  from "./addClientForms/invoice";
import  WysiwygEditor  from "../../Components/TextEditor/WysiwygEditor";
import logoEmblem from "../../Assets/Images/emblem.svg";

const UpdateClients = (props) => {

  const dispatch = useDispatch()
  const [aboutClient, setAboutClient] = useState("");
  const [addInfo, setAddInfo] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [jonAddsuccess, setjonAddsuccess] = useState(false)
  const [addGst, setAddGst] = useState([])
  const [addPointOfContact, setAddPointOfContact] = useState([])
  const [addPayment, setAddPayment] = useState([])
  const [addInvoice, setAddInvoice] = useState([])
  const [componentUpdate, setComponentUpdate] = useState(false)
  const [modalmsg,setModalmsg] =  useState("")
  const [errors, setErrors] = useState({})
  const [dropIcons, setDropIcons] = useState("fa-info-circle text-warning")
  const [successMsg,setSuccessMsg] = useState({})
  const [imgchanged,setimgchanged] = useState(false)
  const [loader,setLoader] = useState(false)
  const [clientFields, setClientFields] = useState({
    client_legal_name: "",
    client_short_name: "",
    website_url: "",
    replacement_duration: "",
    invoice_payment_duration: "",
    agreement_start_date: new Date(),
    agreement_end_date: new Date(),
    status: "active",
    clientlogo: "",
    clientlogo_file_url:'',
  });
  const [fieldserror, setFieldErros] = useState(false)

  const update_Encrypted_id = useSelector(state=>state.PageReduser.EditClientId)
  const ClientGstList = useSelector(state=>state.Client_Reduser.ClientGstList)
  const ClientPointOfContactList = useSelector(state=>state.Client_Reduser.ClientPointOfContactList)
  const ClientPaymentList = useSelector(state=>state.Client_Reduser.ClientPaymentList)
  const ClientInvoiceList = useSelector(state=>state.Client_Reduser.ClientInvoiceList)
  const ClientUpdateGstList = useSelector(state=>state.Client_Reduser.ClientUpdateGstList)
  const ClientUpdatePOCList = useSelector(state=>state.Client_Reduser.ClientUpdatePOCList)
  const ClientUpdatePaymentList = useSelector(state=>state.Client_Reduser.ClientUpdatePaymentList)
  const ClientUpdatedInvoiceList = useSelector(state=>state.Client_Reduser.ClientUpdatedInvoiceList)

  const handleClose = () => {
    setjonAddsuccess(false);
  };

  function gottoclientdetails(){
    setTimeout(() => {
      if(Object.keys(errors).length === 0){
        dispatch({type:"EditClientId",payload:""})
        dispatch({type:"ViewClientId",payload:update_Encrypted_id})
        props.changetab(2);
      }
    }, 3000);    
  }

  const saveClient = () => {
    let agreement_start_date = ""
    let agreement_end_date = ""

    if (clientFields.agreement_start_date && clientFields.agreement_end_date) {
      let newdate = new Date(clientFields.agreement_start_date)
      agreement_start_date = newdate.getFullYear() + "-" + (newdate.getMonth() + 1) + "-" + newdate.getDate()

      let newdate1 = new Date(clientFields.agreement_end_date)
      agreement_end_date = newdate1.getFullYear() + "-" + (newdate1.getMonth() + 1) + "-" + newdate1.getDate()
    }

    let data = {
      encrypted_id:update_Encrypted_id,
      client_legal_name: clientFields.client_legal_name,
      client_short_name: clientFields.client_short_name,
      website_url: clientFields.website_url,
      replacement_duration: clientFields.replacement_duration,
      invoice_payment_duration: clientFields.invoice_payment_duration,
      agreement_start_date: agreement_start_date,
      agreement_end_date: agreement_end_date,
      status: "active",
      about_client:aboutClient,
      additional_information:addInfo
    }
    setLoader(true)
    apiEdit(GET_CLIENTS_URL, data, ({ status, response }) => {
     
      if (status) {
        setSuccessMsg({notes:["Client Updated Successfully"]})
        setjonAddsuccess(true)
        //setModalmsg(" Client Updated Successfully")
        setDropIcons("fa-check-circle text-success");

        for (let i = 0; i < ClientGstList.length; i++) {
          if(ClientGstList[i].update){
          addGstData(ClientGstList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientPointOfContactList.length; i++) {
          if(ClientPointOfContactList[i].update){
          addPointOfContactData(ClientPointOfContactList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientPaymentList.length; i++) {
          if(ClientPaymentList[i].update){
          addPaymentData(ClientPaymentList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientInvoiceList.length; i++) {
          if(ClientInvoiceList[i].update){
          addInvoiceData(ClientInvoiceList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientUpdateGstList.length; i++) {
          if(ClientUpdateGstList[i].update){
            EditGstdetails(ClientUpdateGstList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientUpdatePOCList.length; i++) {
          if(ClientUpdatePOCList[i].update){
            EditPOCdetails(ClientUpdatePOCList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientUpdatePaymentList.length; i++) {
          if(ClientUpdatePaymentList[i].update){
            EditPaymentdetails(ClientUpdatePaymentList[i],update_Encrypted_id)
          }
        }

        for (let i = 0; i < ClientUpdatedInvoiceList.length; i++) {
          if(ClientUpdatedInvoiceList[i].update){
            EditInvoicedetails(ClientUpdatedInvoiceList[i],update_Encrypted_id)
          }
        }
       gottoclientdetails();
      } else {
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
        setDropIcons("fa-times-circle text-danger")
      }
      setLoader(false)
    })
  }

  useEffect(()=>{
    if(clientLogo){
      let data = new FormData()
      data.append("client_id",update_Encrypted_id)
      data.append("clientlogo",clientLogo)
      setLoader(true)
      apiPost(GET_CLIENT_LOGO_UPDATE_API, data, ({ status, response }) => {
    
        if (status) {
          setSuccessMsg({notes:["Client Logo Updated Successfully"]})
          setjonAddsuccess(true)
         // setModalmsg(" Client Logo Updated Successfully")
      
          // let res = response.data
          // setUpdate_Encrypted_id(res.data.encrypted_id)
          setDropIcons("fa-check-circle text-success")
        } else {
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
          setDropIcons("fa-times-circle text-danger")
        }
        setLoader(false)
      })
    }

  },[clientLogo])

  useEffect(()=>{
    dispatch({type:"RemoveAllClientGst"})
    dispatch({type:"RemoveAllClientPointOfContact"})
    dispatch({type:"RemoveAllClientPayment"})
    dispatch({type:"RemoveAllClientInvoice"})
    return()=>{
      dispatch({type:"RemoveUpdatedClientGst"})
      dispatch({type:"RemoveAllUpdatedClientPOC"})
      dispatch({type:"RemoveAllUpdatedClientPayment"})
      dispatch({type:"RemoveAllUpdatedClientInvoice"})
      dispatch({type:"RemoveAllClientGst"})
      dispatch({type:"RemoveAllClientPointOfContact"})
      dispatch({type:"RemoveAllClientPayment"})
      dispatch({type:"RemoveAllClientInvoice"})
    }
  },[]);


  useEffect(()=>{
   
    if(update_Encrypted_id){
      setLoader(true)
        fetchUrl(`${GET_CLIENTS_URL}/${update_Encrypted_id}`, ({ status, response }) => {
          if (status) {
           let data =  response?.data?.data[0]
         
           setClientFields({
            client_legal_name: data.client_legal_name,
            client_short_name: data.client_short_name,
            website_url: data.website_url,
            replacement_duration: data.replacement_duration,
            invoice_payment_duration: data.invoice_payment_duration,
            agreement_start_date: data.agreement_start_date,
            agreement_end_date: data.agreement_end_date,
            status: "active",
            clientlogo_file_url:data.clientlogo_file_url
           })
           setAboutClient(data.about_client)
           setAddInfo(data.additional_information)
          }
          setLoader(false)
      })
      setLoader(true)
      fetchUrl(`${GET_CLIENT_GST_DETAILS_API_URL}/${update_Encrypted_id}`, ({ status, response }) => {
          if(status){
            let data =  response?.data?.data
            dispatch({ type: "updatedClientGstList",payload:response?.data?.data});
           // setGst(data)
          }
          setLoader(false)
      })
      setLoader(true)
      fetchUrl(`${GET_CLIENT_POINT_CONTACT_DETAILS_STATUS}/${update_Encrypted_id}`, ({ status, response }) => {
        if(status){
          let data =  response?.data?.data
          dispatch({ type: "updatedClientPOCList",payload:response?.data?.data});
         // setPointofConatct(data)
        }
        setLoader(false)
    })
    setLoader(true)
      fetchUrl(`${GET_CANDIDATE_INVOICE_PROCESS_STATUS}/${update_Encrypted_id}`, ({ status, response }) => {
          if(status){
            let data =  response?.data?.data
            dispatch({ type: "updtedClientInvoiceList",payload:response?.data?.data});
           // setInvoice(data)
          }
      setLoader(false)
      })
      setLoader(true)
      fetchUrl(`${GET_CLIENT_PAYMENT_STATUS}/${update_Encrypted_id}`, ({ status, response }) => {
        if(status){
          let data =  response?.data?.data
          dispatch({ type: "updatedClientPaymentList",payload:response?.data?.data});
         // setPayment(data)
        }
        setLoader(false)
    })
    }
  },[update_Encrypted_id])


  const deleteEducationCount = (index, type) => {
    if (type === "gst") {
      let data = addGst
      data.splice(index, 1)
      setAddGst(data)
      setComponentUpdate(!componentUpdate)
    } else if (type === "pointofcontact") {
      let data = addPointOfContact
      data.splice(index, 1)
      setAddPointOfContact(data)
      setComponentUpdate(!componentUpdate)
    } else if (type === "payment") {
      let data = addPayment
      data.splice(index, 1)
      setAddPayment(data)
      setComponentUpdate(!componentUpdate)
    }
    else {
      let data = addInvoice
      data.splice(index, 1)
      setAddInvoice(data)
      setComponentUpdate(!componentUpdate)
    }
  }

  const addGstData = (data,Clientid) =>{
    let gstData = data
    gstData.client_id = Clientid
    delete gstData.encrypted_id
    setLoader(true)
    apiPost(GET_CLIENT_GST_DETAILS_API_URL, gstData, ({ status, response }) => {
      if (status) {
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
      setLoader(false)
  })
  } 

  const EditGstdetails = (data,Clientid)=>{
    let gstData = data
    gstData.client_id = Clientid
    delete gstData.update
    setLoader(true)
    apiEdit(GET_CLIENT_GST_DETAILS_API_URL, gstData, ({ status, response }) => {
      if (status) {
        // setModalmsg("Gst Updated successfully")
        // setUpdatemodal(true);
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
      setLoader(false)
  })
  }

const addPointOfContactData = (data,Clientid) =>{
  let pointOfcontctData = data
  pointOfcontctData.client_id = Clientid
  delete pointOfcontctData.encrypted_id
  setLoader(true)
  apiPost(GET_CLIENT_POINT_CONTACT_DETAILS_STATUS, pointOfcontctData, ({ status, response }) => {
    if (status) {
    }else{
      let msg = JSON.parse(response.request.response).errors
      setErrors(msg)
      setFieldErros(true)
    }
    setLoader(false)
  })
  } 

  const EditPOCdetails = (data,Clientid)=>{
    let pointOfcontctData = data
    pointOfcontctData.client_id = Clientid
    delete pointOfcontctData.update
    setLoader(true)
    apiEdit(GET_CLIENT_POINT_CONTACT_DETAILS_STATUS, pointOfcontctData, ({ status, response }) => {
      if (status) {
 
        // setModalmsg("Point of contact Updated successfully")
       // setUpdatemodal(true);
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
      setLoader(false)
  })
  }

  const addPaymentData = (data,Clientid) =>{
    let paymentData = data
    paymentData.client_id = Clientid
    delete paymentData.encrypted_id
    setLoader(true)
    apiPost(GET_CLIENT_PAYMENT_STATUS, paymentData, ({ status, response }) => {
      if (status) {
    
      }else{
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
      setLoader(false)
    })
    } 

    const EditPaymentdetails = (data,Clientid)=>{
      let paymentData = data
    paymentData.client_id = Clientid
    delete paymentData.update
    setLoader(true)
      apiEdit(GET_CLIENT_PAYMENT_STATUS, paymentData, ({ status, response }) => {
        if (status) {
       
          // setModalmsg("Payment Updated successfully")
          // setUpdatemodal(true);
        }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
        setLoader(false)
    })
    }

    const addInvoiceData = (data,Clientid) =>{
      let invoiceData = data
      invoiceData.client_id = Clientid
      delete invoiceData.encrypted_id
      delete invoiceData.update
      setLoader(true)
      apiPost(GET_CANDIDATE_INVOICE_PROCESS_STATUS, invoiceData, ({ status, response }) => {
        if (status) {
    
        }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
        setLoader(false)
      })
      }

    const EditInvoicedetails = (data,Clientid)=>{
      let invoiceData = data
      invoiceData.client_id = Clientid
      delete invoiceData.update
      setLoader(true)
      apiEdit(GET_CANDIDATE_INVOICE_PROCESS_STATUS, invoiceData, ({ status, response }) => {
        if (status) {
          // setModalmsg("Invoice Updated successfully")
          // setUpdatemodal(true);
        }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
        setLoader(false)
    })
    } 


  const addgstToForm = () => {
    setAddGst([...addGst, "add"])
  }

  const addPointOfConatctToForm = () => {
    setAddPointOfContact([...addPointOfContact, "add"])
  }

  const addpaymentToForm = () => {
    setAddPayment([...addPayment, "add"])
  }

  const addInvoiceToForm = () => {
    setAddInvoice([...addInvoice, "add"])
  }


  return (
    <>
    {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="container-fluid bg-none  px-3">
        <div className="row no-gutters">
          <div className="col-md-12 pt-3 px-0">
            <div className=" px-0">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="upload-file-section px-3 mx-2">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-5">
                        <div className="card custom-min-heght">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">Update Client Logo </h5>
                          </div>
                          <div className="card-body text-center">
                            {/* <input type="file" onChange={(e)=>console.log(e.target.value)}/> */}
                            <DragAndDrop
                              id={"Clientlogo"}
                              onselect={setClientLogo}
                              header={"Client's Logo "}
                              data={clientLogo?.name}
                              url={clientFields?.clientlogo_file_url}
                              image={true}
                              setProfilePic={setClientLogo}
                              setimgchanged={setimgchanged}
                            />
                            <div className="text-center">
                              <i className={`fas ${dropIcons} fa-2x `}></i>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <AddClientsForm clientFields={clientFields} setclientFields={setClientFields} />

            {
              aboutClient ?  <WysiwygEditor   title={"About Client"} edit={true} data={aboutClient}  editDetails={setAboutClient} /> : ""
            }

            {
              !aboutClient ?  <WysiwygEditor  title={"About Client"} edit={true} data={aboutClient} editDetails={setAboutClient}/> : ""
            } 

            {
              addInfo ?  <WysiwygEditor   title={"Additional Information"} edit={true} data={addInfo}  editDetails={setAddInfo}/> : ""
            }

            {
              !addInfo ?  <WysiwygEditor title={"Additional Information"} edit={true} data={addInfo} editDetails={setAddInfo}/> : ""
            } 

              {/* <JobTextEditor editDetails={setAboutClient}  /> */}

              {/* <JobTextEditor editDetails={setAddInfo} title={"Additional Information"} /> */}


              <div className="row  table-custom mb-5 mt-3">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            GST Details
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addgstToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />
                {(ClientUpdateGstList.length >0)?
                    ClientUpdateGstList.map((data,index)=>{
                    return <>
                      <GstClient  data={data} id={update_Encrypted_id} edit={true} index={index}/>
                      <hr/><br/>
                      </>
                  }) 
                :
                ""
                }
                
                {addGst.map((data, index) => {
                  return <>
                    {index > 0 && <><hr /><br /></>}
                    <GstClient id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "gst")} edit={false} index={index} />
                  </>
                })}
                  </div>
                </div>
              </div>


              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Point Of Contact
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addPointOfConatctToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />
                    {(ClientUpdatePOCList.length >0)?
                    ClientUpdatePOCList.map((data,index)=>{
                    return <>
                      <PointOfContact  data={data} id={update_Encrypted_id} edit={true} index={index}/>
                      <hr/><br/>
                      </>
                  }) 
                :
                ""
                }

                    {addPointOfContact.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <PointOfContact id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "pointofcontact")}  edit={false} index={index}/>
                      </>
                    })}
                  </div>
                </div>
              </div>


              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Payment terms
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addpaymentToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />

                    {(ClientUpdatePaymentList.length >0)?
                    ClientUpdatePaymentList.map((data,index)=>{
                    return <>
                      <Payment  data={data} id={update_Encrypted_id} edit={true} index={index}/>
                      <hr/><br/>
                      </>
                  }) 
                    :
                    ""
                    }

                    {addPayment.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <Payment id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "payment")} edit={false} index={index}/>
                      </>
                    })}
                  </div>
                </div>
              </div>

              <div className="row  table-custom mb-5">
                <div className="col-md-12">
                  <div className="card pt-3 pb-3">
                    <div className="row pb-0 px-4">
                      <div className="col-md-7">
                        <div className=" d-flex justify-content-start">
                          <h4 className="text-center font-weight-bold pb-0 mb-0">
                            Invoice Process Stages
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-5 text-right">
                        <div class="text-center d-flex justify-content-end"><button class="btn btn-xs btn-violet text-white" onClick={addInvoiceToForm}>Add More</button></div>
                      </div>
                    </div>
                    <hr />
                    
                    {(ClientUpdatedInvoiceList.length >0)?
                    ClientUpdatedInvoiceList.map((data,index)=>{
                    return <>
                      <Invoice  data={data} id={update_Encrypted_id} edit={true} index={index}/>
                      <hr/><br/>
                      </>
                  }) 
                    :
                    ""
                    }

                    {addInvoice.map((data, index) => {
                      return <>
                        {index > 0 && <><hr /><br /></>}
                        <Invoice id={update_Encrypted_id} deleteCount={() => deleteEducationCount(index, "invoice")} edit={false} index={index}/>
                      </>
                    })}

                  </div>
                </div>
              </div>

              <div className="text-center mb-2 pt-2">
  <div className=" d-inline-block me-3">
    <button class="btn btn-xs btn-green-dark text-white" onClick={()=>props.changetab(0)}>
      Cancel
    </button>
  </div>
  <div className=" d-inline-block mx-2">
    <button class="btn btn-xs btn-orange text-white" onClick={saveClient}>Save</button>
  </div>
</div>
            

            </div>
          </div>
        </div>
      </div>


      {/* <CommonModal title={"success"} handleClose={handleClose} open={jonAddsuccess} button={"ok"} btnfunc={handleClose}>
       
        {modalmsg}
      </CommonModal> */}

      <SnakBar error={successMsg} open={jonAddsuccess} onClose={handleClose} type="success"/>

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)} />
    </>
  );
};

export default withRouter(UpdateClients);
