import React,{useState, useEffect} from 'react'
import { DefaultEditor } from "react-simple-wysiwyg";
 
 
const WysiwygEditor = (props) => {
    const [html, setHtml] = React.useState('');

    useEffect(()=>{
      if(props.edit){
        setHtml(props.data);   
      }
    },[])
     
    function onChange(e) {      
      setHtml(e.target.value);
       props.editDetails(e.target.value);
    };
  
    return (
      <div className="row mb-5">
       <div className="row  table-custom top-radius mb-0 px-0 mx-0">
        <div className="col-md-12">
          <div className="card pb-3 mb-0 pt-3">
            <div className="row  px-4">
              <div className="col-7">
                <div className=" d-flex justify-content-start">
                  <h4 className="text-center font-weight-bold pb-0 mb-0">{props.title}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="my-1 mt-0 mb-4"> 
      <DefaultEditor editorStyle={{ border: "1px solid #C0C0C0" }} placeholder="Type something...." value={html} onChange={onChange} />
      </div>
      </div>
    </div>
    );
  }
  export default WysiwygEditor