import React,{useState,useEffect} from 'react'
import { useDispatch , useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { fetchUrl, apiEdit } from "../../ApiActions/Actions";
// Image Iocns
import Phone from "../../Assets/Images/profile-t-icons/Phone.svg";
import t7 from "../../Assets/Images/profile-t-icons/t7.svg";
import t2 from "../../Assets/Images/profile-t-icons/t2.svg";
import t3 from "../../Assets/Images/profile-t-icons/t3.svg";
import Email from "../../Assets/Images/profile-t-icons/email.svg";
import CommonModal from '../../Components/Models/commonModal';
import {
  GET_ALLJOBORDER_DATA,
  GET_JOBCANDIDATESTATUS_DATA,
  GET_CANDIDATE_NOTES_API_URL,
  GET_CANDIDATE_JOB_ORDERS_STATUS,
  GET_JOB_MAINSTATUS_API_URL,
  GET_JOB_MAINSUBSTATUS_API_URL,
} from "../../Configurations/ConfigApi";
import { date, dateTime } from "date-fns/locale/zh-TW/index.js";
import {Notes} from "../../Components/sidenavTabs/notes";
import { Interview } from "../../pages/CandidateProfile/sideNavtabs/interview";
import { MassageCandidates } from "../../pages/CandidateProfile/sideNavtabs/massageCandidates";
import SnakBar from "../../Components/Models/snakbar";
import logoEmblem from "../../Assets/Images/emblem.svg";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Actionable = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [AllJobIds, setAllJobIds] = useState([]);
  const [updateModal,setUpdatemodal] = useState(false);
  const [candidate_id, setcandidate_id]=useState("");
  let [AllJoborderData, setAllJoborderData] = useState([]);
  const [CandidateData, setCandidateData] = useState([]);
  const [AllCandidateData, setAllCandidateData] = useState([]);
  const [Showinterview, setShowinterview] = useState(false);
  const [Shownotes, setShownotes] = useState(false);
  const [Showmessage, setShowmessage] = useState(false);
  const [Popuptitle, setPopuptitle] = useState("");
  const [updateStatusModal,setUpdateStatusModal] = useState(false)
  const [updateMainStatusId,setUpdateMainStatusId] = useState('')
  const [subStatatusId,setSubStatusId] = useState('')
  const [updateEncriptedId,setUpdateEncriptedId] = useState('')
  const [mainStatusData,setMainStatusData] = useState([])
  const [mainStatus,setMainStatus] = useState('')
  const [mainSubStatusData,setMainSubStatusData] = useState([])
  const [errors, setErrors] = useState({})
  const [successMsg,setSuccessMsg] = useState({})
  const [fieldserror, setFieldErros] = useState(false)
  const [selectedcandidate, setselectedcandidate] = useState('0')
  const [loader,setLoader] = useState(false)
 
  useEffect(() => {
    dispatch({type:"EditJobId",payload:""});
    if(sessionStorage.getItem('redirectToJobOrder')){
      props.changetab(4)
    }else{
      dispatch({type:"Viewjoborder",payload:""})
    }
    // if (AllJoborderData.length ===0 ){
      setLoader(true)
    fetchUrl(`${GET_ALLJOBORDER_DATA}`, ({ status, response }) => {
      if (status) {        
        setAllJoborderData(response.data);                        
        }
      });
     
      setAllCandidateData([]);
      setLoader(true)
        fetchUrl(`${GET_JOBCANDIDATESTATUS_DATA}`, ({ status, response }) => {
          if (status) {
         
            setAllCandidateData(response.data);                    
            }
          });
          setLoader(false)
          setLoader(true)
          fetchUrl(`${GET_JOB_MAINSTATUS_API_URL}`, ({ status, response }) => {
            if (status) {
            
             setMainStatusData(response.data?.data)
            }else{
                let msg = JSON.parse(response.request.response).errors
                setErrors(msg)
                setFieldErros(true)
            }
            setLoader(false)
        })
    // }
  },[]);
  const Gotocandidateprofile = (candidate_id,event) => {
    event.stopPropagation();     
    sessionStorage.setItem("profileId",candidate_id)
    dispatch({type:"candidateprofileId",payload:candidate_id})
    sessionStorage.setItem("gototcandidate","true");
    window.open("/candidates")      
  }
  const updateMainStatus = (candidate_id) => {
    fetchUrl(`${GET_CANDIDATE_JOB_ORDERS_STATUS}?candidate_id=${candidate_id}`, ({ status, response }) => {
      if (status) {                        
        setUpdateStatusModal(true) 
        let filterData = response?.data?.data
        let obj 
       
        if(selectedcandidate === '0'){
          console.log('selectedcandidate',selectedcandidate)
          obj = [...filterData]
        }else{
           obj = filterData.filter((res)=> res.job_id === selectedcandidate)
        }
       console.log('obj',obj)
      
        getsubStatus(obj[0]?.main_status_id)
        setUpdateMainStatusId(obj[0]?.main_status_id)
        setSubStatusId(obj[0]?.main_substatus_id)
        setUpdateEncriptedId(obj[0]?.encrypted_id)
        // setAllJoborderData(response.data.data[0]);                        
        }
      });    
  }

  const getsubStatus=(value)=>{
    setMainStatus(value)
    setLoader(true)
    fetchUrl(`${GET_JOB_MAINSUBSTATUS_API_URL}?main_status_id=${value}`, ({ status, response }) => {
      if (status) {

       setMainSubStatusData(response.data?.data)
       setLoader(false)
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
          setLoader(false)
      }
  })
  }

  const updateCandidateJobOrderStatus = () => {
    let jobOrdersData={
      encrypted_id: updateEncriptedId,
      mainstatus_id:updateMainStatusId,
      mainsubstatus_id:subStatatusId,
    }
    if(updateMainStatusId && subStatatusId){
      apiEdit(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
        if (status) {          
          setUpdateStatusModal(false)
          Reloadcandidates();
        
          if(response?.data?.status){
            setSuccessMsg({notes:[response?.data?.message]})
           
          }
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })
    }
   
  }

  const handleClose = () => {
    setUpdatemodal(false);
    setShowinterview(false);
    setShownotes(false);
    setShowmessage(false);
    Reloadcandidates();
  };

  const Setcandidatedetails = (job_id) => {
    setselectedcandidate(job_id);
    setCandidateData([]);
    fetchUrl(
      `${GET_JOBCANDIDATESTATUS_DATA}/${job_id}`,
      ({ status, response }) => {
        if (status) {
      
          setCandidateData(response.data);
        }
      }
    );   
  };

  const Reloadcandidates = () => {
if (selectedcandidate === '0')
Bindalljoborders()
else
Setcandidatedetails(selectedcandidate)
  }

  const Bindalljoborders = () => {
    setselectedcandidate('0');
    fetchUrl(`${GET_JOBCANDIDATESTATUS_DATA}`, ({ status, response }) => {
          if (status) {             
            setAllCandidateData(response.data);                    
            }
          });
  };

  const Getcurrentdatetime = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return parseDate(date + " " + time);
  };

  function parseDate(input) {
    var parts = input.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  const Getcntdetails = (cnd_int_details) => {
    if (cnd_int_details.length === 0) return "";
    return (
      formatDate(cnd_int_details[0].date) +
      " " +
      cnd_int_details[0].hour +
      ":" +
      cnd_int_details[0].minutes +
      " " +
      cnd_int_details[0].timeformat
    );
  };

  const Getnoticepeiod = (input) => {
    return isNaN(input) ?input  :input+' Days';
  }

  const Getcreatedtimedetails = (sub_status_created_at) => {
    let currentdate = Getcurrentdatetime();
    let stausdate = parseDate(sub_status_created_at);
    let time_difference = currentdate.getTime() - stausdate.getTime();
    let days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24));

    if (days_difference > 0)
      return " ( " + days_difference.toString() + " Days ago )";

    if (Math.floor(time_difference) > 0)
      return " ( " + Math.floor(time_difference).toString() + " Hours ago )";

    var minutes = Math.floor(time_difference / 1000 / 60);

    if (minutes > 0) return " " + minutes.toString() + " Minutes ago )";

    return " ( Just now )";
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  
  };

  function Composemail(emailto){
    window.location.href = "mailto:"+emailto+"?subject=Subject&body=messag";
  }

  function formatDate(value) {    
    if ( value === undefined || value === null || value === '' )
        return '';
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });
    return day + '-' + month + '-' + year;
}

 
  return (
    <>
      {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="row g-0 bg-custom-change mt-0 pt-0">
        <div className="col-md-3 p-3 ps-0 pt-0 mt-0">
          <div className="bg-light text-left-custom min-height-actionable">
            <AppBar position="static" className="">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                orientation="vertical"
              >
                <Tab
                  label="All Job Orders"
                  {...a11yProps(0)}
                  onClick={() => Bindalljoborders()}
                />
                {AllJoborderData.map((data, index) => {
                  return (
                    <Tab
                      label={
                        <>
                      <a className='text-underline' onClick={()=>{
                        dispatch({type:"Viewjoborder",payload:data.job_id})
                        props.changetab(4)
                      }}> {data.job_title} ({data.cnd_count}) </a> 
                       <span className="text-violet" >{data.company_name}</span> 
                      </>
                      }
                      {...a11yProps(index+1)}
                      onClick={() => Setcandidatedetails(data.job_id)}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          </div>
        </div>
        <div className="col-md-9 py-3 card-decrease pb-0 mb-0 pt-0 mt-0">
          <TabPanel value={value} index={0} className="">
            <div className="p-3 bg-custom-light">
              {AllCandidateData.map((data, index) => {
                return (
                  <div className="row candidate-list">
                    <div className="col-md-9">
                      <p className="f-13 mb-1">
                        Candidate Name:&nbsp; <a  className="text-underline font-weight-bold" onClick={(event)=>Gotocandidateprofile(data.candidate_id,event)} >{data.candidate_name}</a>
                      </p>
                      <p className="f-13 mb-1">
                      Sub Status:&nbsp; <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.sub_status}</a> {Getcreatedtimedetails(data.sub_status_created_at)}  </p>
                      <p className="f-13 mb-1">
                        Scheduled on:&nbsp; {Getcntdetails(data.cnd_int_details)}
                      </p>
                      <p className="f-13 mb-1">
                        Notice Period:&nbsp; {Getnoticepeiod(data.notice_period)} 
                      </p>
                      <p className="f-13 mb-1">
                        Recent Notes:&nbsp; {data.candidates_notes}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="f-13 mb-3 text-center">
                        {data.recruiter_name}
                      </p>
                      <ul
                        type="none"
                        className="p-0 m-0 d-flex justify-content-center flex-wrap"
                      >
                        <li title="Mail to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick = {()=> Composemail("user@example.com")}
                              src={Email}
                              className="tab-img-h2 align-top pe-2"
                            />
                          </a>
                        </li>
                        <li title="Notes" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setcandidate_id(data.candidate_id); setPopuptitle("Add/View Notes"); setShownotes(true); setUpdatemodal(true); } }
                              src={t2}
                              className="tab-img-h2 align-top pe-2"
                            />
                          </a>
                        </li>
                        <li className="me-2 cursor-pointer">
                          <a>
                            <img
                              src={Phone}
                              className="tab-img-h2 align-top   pe-2"
                            />
                          </a>
                        </li>
                        <li title="Message to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                              src={t7}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                        <li title="Schedule Interview" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ 
                              sessionStorage.setItem("profileId",data.candidate_id);
                               setcandidate_id(data.candidate_id); 
                               dispatch({type:"candidateprofileId",payload:data.candidate_id}); setPopuptitle("Interview"); 
                               setShowinterview(true);
                               setUpdatemodal(true); } }
                              src={t3}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                 
                );
              })}

              
            </div>
          
          </TabPanel>
          
          {
            AllJoborderData.map((data,index)=>{
              return <TabPanel value={value} index={index+1} className="border">
              <div className="p-3 bg-custom-light">
                {CandidateData.map((data, index) => {
                  return (
                    <div className="row candidate-list">
                      <div className="col-md-9">
                        <p className="f-13 mb-1">
                        Candidate Name:&nbsp; <a   className="text-underline" 
                        onClick={(event)=>Gotocandidateprofile(data.candidate_id,event)} >{data.candidate_name}</a>
                        </p>
                        <p className="f-13 mb-1">
                        Sub Status:&nbsp; <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.sub_status}{" "}
                        </a>{Getcreatedtimedetails(data.sub_status_created_at)}  
                        </p>
                        <p className="f-13 mb-1">
                          Scheduled on:&nbsp; {Getcntdetails(data.cnd_int_details)}
                        </p>
                        <p className="f-13 mb-1">
                          Notice Period:&nbsp; {Getnoticepeiod(data.notice_period)}
                        </p>
                        <p className="f-13 mb-1">
                          Recent Notes:&nbsp; {data.candidates_notes}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p className="f-13 mb-3 text-center">
                          {data.recruiter_name}
                        </p>
                        <ul
                          type="none"
                          className="p-0 m-0 d-flex justify-content-between flex-wrap"
                        >
                          <li title="Mail to Candidate" className="me-2 cursor-pointer">
                            <a>
                              <img onClick = {()=> Composemail("user@example.com")}
                                src={Email}
                                className="tab-img-h2 align-top pe-2"
                              />
                            </a>
                          </li>
                          <li title="Notes" className="me-2 cursor-pointer">
                            <a>
                              <img onClick={()=>{ setcandidate_id(data.candidate_id); setPopuptitle("Add/View Notes"); setShownotes(true); setUpdatemodal(true); } }
                                src={t2}
                                className="tab-img-h2 align-top pe-2"
                              />
                            </a>
                          </li>
                          <li className="me-2 cursor-pointer">
                            <a>
                              <img
                                src={Phone}
                                className="tab-img-h2 align-top   pe-2"
                              />
                            </a>
                          </li>
                          <li title="Message to Candidate" className="me-2 cursor-pointer">
                            <a>
                              <img
                                src={t7} onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                                className="tab-img-h2 align-top  me-2"
                              />
                            </a>
                          </li>
                          <li title="Schedule Interview" className="me-2 cursor-pointer">
                            <a>
                              <img onClick={()=>{ sessionStorage.setItem("profileId",data.candidate_id); dispatch({type:"candidateprofileId",payload:data.candidate_id}); setcandidate_id(data.candidate_id); setPopuptitle("Interview"); setShowinterview(true); setUpdatemodal(true); } }
                                src={t3}
                                className="tab-img-h2 align-top  me-2"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}


              </div>

               

            </TabPanel>
            })
          }
         
                    <nav>
                  <ul class="pagination d-flex justify-content-center mt-3 mb-3">
                    <li class="page-item disabled">
                      <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active" aria-current="page">
                      <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#">Next</a>
                    </li>
                  </ul>
                </nav>
        </div>
        
         <CommonModal title={Popuptitle} handleClose={handleClose} open={updateModal}  btnfunc={handleClose} showonlycancel={true} >      
        
         {Shownotes && <Notes id={candidate_id} Showaddnotesonly={true} handleClose={handleClose}  api={`${GET_CANDIDATE_NOTES_API_URL}`} apiext={`?candidate_id=${candidate_id}`} module={"candidate"}/>}
        {Showinterview && <Interview showaddmessageonly={true} handleClose={handleClose} />}
        {Showmessage && <MassageCandidates Showaddmessageonly={true} handleClose={handleClose} />}
      </CommonModal>

      <CommonModal title={"Change Status"} handleClose={() => setUpdateStatusModal(false)} open={updateStatusModal} button={"UPDATE"} btnfunc={()=> updateCandidateJobOrderStatus()}>
        <div style={{width: "200px"}}>
          <select name="" id=""  class="form-control pb-1 my-3"
            onChange={(e)=>{
              getsubStatus(e.target.value)
              setUpdateMainStatusId(e.target.value)
              setSubStatusId('')
            }} value={updateMainStatusId}>
            <option value="">-- Select Main Status  --</option>
            {mainStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>

          <select name="" id=""  class="form-control pb-1"
            onChange={(e)=>{
              setSubStatusId(e.target.value)
              }} value={subStatatusId}>
            <option value="">-- Select Sub Status --</option>
            {mainSubStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>
          </div>
      </CommonModal>
      </div>
      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)} />
      <SnakBar error={successMsg} open={successMsg ? true : false} onClose={() => setUpdatemodal(false)} type="success"/>
    </>
  );
};
