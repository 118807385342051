import React,{useState} from "react";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export const AddClientsForm = (props) => {
  const [open, setOpen] = useState(false)
  const [openStartDate,setOpenStartDate] = useState(false)
 
  return(
      <>
        <div className="row  table-custom mb-5">
            <div className="col-md-12">
              <div className="card pt-3 pb-3">
              <div className="row pb-0 px-4">
                  <div className="col-md-12">
                    <div className=" d-flex justify-content-start">
                      <h4 className="text-center font-weight-bold pb-0 mb-0">
                       Client Details
                      </h4>
                    </div>
              </div>
             
                </div>
               <hr/>

                <div className="row px-4">
                  <div className="col-md-6">
                    <table className="table table-bordered">
                      <tr>
                        <td colSpan="2">
                          <TextField
                            id="standard-search"
                            label="Client Name (Legal)*"
                            type="text"
                            className="w-100"
                            value={props.clientFields.client_legal_name}
                            onChange={(e)=>props.setclientFields({...props.clientFields,client_legal_name:e.target.value})}
                          />
                        </td>
                      </tr>
                    
                      <tr>
                        <td colSpan="2">
                          <TextField
                            id="standard-search"
                            label="Website URL "
                            type="text"
                            className="w-100"
                            value={props.clientFields.website_url}
                            onChange={(e)=>props.setclientFields({...props.clientFields,website_url:e.target.value})}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <TextField
                            id="standard-search"
                            label="Replacement Duration "
                            type="number"
                            className="w-100"
                            value={props.clientFields.replacement_duration}
                            onChange={(e)=>props.setclientFields({...props.clientFields,replacement_duration:e.target.value})}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                    {
                  props.clientFields.agreement_end_date ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mb-0 pb-0" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Agreement End Date  </label> : ""
                }
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              views={["year", "month",'date']}
                          format="dd-MMM-yy"
                              className={`w-100 mt-0 ${props.clientFields.agreement_end_date && 'pt-0'}`}
                              margin="normal"
                              autoOk={true}
                              error={false}
                              // minDate={moment().toDate()}
                              minDate={new Date(props.clientFields.agreement_start_date)}
                              disabled={!props.clientFields.agreement_start_date}
                              id="date-picker-inline"
                              label="Agreement End Date "
                              label={props.clientFields.agreement_end_date  ? "" : 'Agreement End Date*'}
                              value={props.clientFields.agreement_end_date}
                              onChange={(date)=>{
                                setOpen(false)
                                props.setclientFields({...props.clientFields,agreement_end_date:date})
                              }}
                              open={open}
                              onClose={()=>setOpen(false)}
                              TextFieldComponent={(props) => (
                                <TextField {...props} onClick={(e) => setOpen(true)} />
                              )}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                      
                        </td>
                      </tr>
                     
                     
                    </table>
                  </div>

                  <div className="col-md-6">
                  <table className="table table-bordered">
                      <tr>
                        <td colSpan="2">
                          <TextField
                            id="standard-search"
                            label="Client Name (Short) *"
                            type="text"
                            className="w-100"
                            value={props.clientFields.client_short_name}
                            onChange={(e)=>props.setclientFields({...props.clientFields,client_short_name:e.target.value})}
                          />
                        </td>
                      </tr> 

                      <tr>
                        <td colSpan="2">
                          <TextField
                            id="standard-search"
                            label="Invoice Payment Duration (Days) "
                            type="number"
                            className="w-100"
                            value={props.clientFields.invoice_payment_duration}
                            onChange={(e)=>props.setclientFields({...props.clientFields,invoice_payment_duration:e.target.value})}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                  {
                  props.clientFields.agreement_start_date ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mb-0 pb-0" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Agreement Start Date </label> : ""
                }
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              views={["year", "month",'date']}
                              format="dd-MMM-yy"
                              className={`w-100 mt-0 ${props.clientFields.agreement_start_date && 'pt-0'}`}
                              margin="normal"
                              autoOk={true}
                              error={false}
                             
                              id="date-picker-inline"
                              label={props.clientFields.agreement_start_date  ? "" : 'Agreement Start Date'}
                              value={props.clientFields.agreement_start_date}
                              onChange={(date)=>{
                                setOpenStartDate(false)
                                props.setclientFields({...props.clientFields,agreement_start_date:date})
                              }}
                              open={openStartDate}
                              onClose={()=>setOpenStartDate(false)}
                              TextFieldComponent={(props) => (
                                <TextField {...props} onClick={(e) => setOpenStartDate(true)} />
                              )}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </td>
                      </tr>
                     
                     
                    </table>
                  </div>

                  
                </div>
              </div>
            </div>
        </div>


        
       </>
   )
  }
