import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CommonModal = (props) => {  
  return(
    <div className="custom-modal">
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3">
         <div className="text-center modal-buttons d-flex justify-content-center me-0">
           { props.showonlycancel ?  <><Button  onClick={props.handleClose}  className="btn  btn-sm btn-violet text-white my-3" autoFocus>
            Cancel</Button></> : <><Button onClick={props.btnfunc} className="modal-yellow-btn me-5">
                {props.button} </Button><Button onClick={props.handleClose} className="modal-green-btn" autoFocus> Cancel</Button></>
          }
          </div>
        </DialogActions>
      </Dialog>
      </div>
   )

 }

export default CommonModal