import React, { useState,useEffect } from 'react'
import { apiPost , fetchUrl } from '../../ApiActions/Actions'
import { GET_EMAILS_API_URL , GET_ALL_USERS_API_URL } from '../../Configurations/ConfigApi'
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';

export const AutocompleteUsers = (props) => {
  const [emails, setEmails] = useState([])
  const [userList, setUserList] = useState([]);
  const [defaultData,setDefaultData] = useState([])
  const [searchtext,setsearchtext] = useState('')

  const getEmails = (value) => {
      let searchData = {
        email: value
      }
      apiPost(GET_EMAILS_API_URL, searchData, ({ status, response }) => {
        if (status) {

          let users = response?.data?.user
          let Cloneformat = [];
          for (let i = 0; i < users.length; i++) {
            users[i].title = users[i].email            
          }
           setEmails(users);                             
        } else {

        }
      })
  }

  useEffect(()=>{
    fetchUrl(GET_ALL_USERS_API_URL,({ status, response }) => {
      if (status) {

        let users = response?.data?.data
        let Cloneformat = [];
        for (let i = 0; i < users.length; i++) {
          // users[i].title = users[i].email
          Cloneformat.push({id :i+1,email:users[i].email, user_id:users[i].encrypted_id,title:users[i].email});
        }         
        setEmails(Cloneformat);
        setUserList(Cloneformat);
      } else {

      }
    })
  },[])

  useEffect(()=>{
    if(props.edit){
      let usersdata  = [...props.users]
      let Cloneformat = [];
      
      for (let i = 0; i < usersdata.length; i++) {
        //  usersdata[i].title = usersdata[i].skill_name
         Cloneformat.push({id :i+1,email:usersdata[i].user_email, user_id:usersdata[i].encrypted_id,title:usersdata[i].user_email});
      }      
      if(usersdata.length > 0){
        // setUserList(Cloneformat)
        setDefaultData(Cloneformat)
        //  setEmails(Cloneformat);
      }
    }  
  },[props])

  const defaultProps = {
    options: emails,
    getOptionLabel: (option) => option.title,
  };

  const setinputValue = (input) =>{
    setsearchtext(input);
    if (input.length >=3){
      var ans = userList.filter( (v,i) =>{
        if(v.email.toLowerCase().indexOf(input) >=0) {
            return true;
        } else return false;
    });
    setEmails(ans);       
    } 
    else{
      setEmails([]);
    } 
  }

  const addUser = (newValue) => {
    let data = []
    for (let i = 0; i < newValue.length; i++) {
      data.push(newValue[i])
    }    
    console.clear();

    props.setMethod(data)
  }


  return (
    <>
    <div className="d-flex align-items-end">
    {(defaultData.length > 0 && props.edit)  && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          onInputChange={(e) => setinputValue(e.target.value)}
          options={searchtext.length >=3 ? emails : []}               
          defaultValue = {defaultData}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.title === value.title }          
          onChange={(event, newValue) => {
            addUser(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getEmails(e.target.value)}
            />
          )}
        />}
      {(!props.edit ) && <Autocomplete
        multiple
        id="size-small-standard-multi"
        size="user"
        style={{ width: "100%" }}
        onInputChange={(e) => setinputValue(e.target.value)}
        options={searchtext.length >=3 ? emails : []}
        getOptionLabel={(option) => option.title}        
          getOptionSelected={(option, value) => option.title === value.title } 
        // {...defaultProps}
        onChange={(event, newValue) => {
          addUser(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={props.label}
            placeholder=""
            onChange={(e) => getEmails(e.target.value)}
          />
        )}
      />}
      {(defaultData.length === 0 && props.edit ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          onInputChange={(e) => setinputValue(e.target.value)}
          options={searchtext.length >=3 ? emails : []} 
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            addUser(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getEmails(e.target.value)}
            />
          )}
        />}

<span >{" "}*</span>
      {/* <ul className="d-flex flex-wrap">
        {
        emailsList.map((name,index)=>{
            return <li className="skilsnames mx-2 mb-2">{name}<CloseIcon fontSize="small" onClick={()=>removeSkill(index)} style={{cursor:"pointer"}}/></li>
        })
        }
    </ul> */}
    </div>
    </>
  )
}
