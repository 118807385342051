import React, { useState, useEffect } from 'react'

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { fetchUrl, apiPost } from '../../ApiActions/Actions'
import { GET_ROLES_API_URL, ADD_USER_API_URL } from '../../Configurations/ConfigApi'
import { useEmailValidation } from '../../Hooks/useValidations'
import CommonModal from '../../Components/Models/commonModal';
import ErrorModal from '../../Components/Models/errorModal';
import SnakBar from "../../Components/Models/snakbar";
import FormControl from '@material-ui/core/FormControl';
import "./register.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin:"0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


// Login css



const Adduser = (props) => {

  const classes = useStyles();
  const [age, setAge] = useState('');
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [updateModal, setUpdatemodal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errormsg, setErrormsg] = useState("")
  const [fieldserrors, setFieldErros] = useState(false);
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [errors, setErrors] = useState({});
  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    role: "",
    password_confirmation: ""
  })
  const [fieldserror, setFieldsError] = useState(false)
  const [emailValidation, checkEmail] = useEmailValidation()
  const [submit, setSubmit] = useState(false)

  const handleClose = () => {
    setOpen(false);
    setUpdatemodal(false);
    setErrorModal(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  useEffect(() => {
    fetchUrl(GET_ROLES_API_URL, ({ status, response }) => {
      if (status) {
        setRoles(response.data)
      }
    })
  }, [])


  const Adduser = () => {
    const { first_name, email, role, password, password_confirmation, mobile } = registerData

    setSubmit(true)
    if (first_name && email && role && password && password_confirmation && mobile && emailValidation && (mobile.length === 10) && (password === password_confirmation)) {
      setFieldsError(false)
      apiPost(ADD_USER_API_URL, registerData, ({ status, response }) => {
        if (status) {
          setSuccessMsg({notes:["User Added Successfully"]})
          setSnakBarOpen(true)

          props.changeTab(1)
        } else {

          let msg = JSON.parse(response.request.response).errors;
          setErrors(msg);
          setFieldErros(true);
        }
      })
    } else {
      if (first_name && email && role && password && password_confirmation && mobile && !emailValidation) {
        setErrors({notes:["Invalid Email address!"]})
        setFieldsError(true)
        // setErrormsg("Invalid Email address!")
      } else if (first_name && email && role && password && password_confirmation && mobile && emailValidation && (mobile.length !== 10)) {
        setErrors({notes:["Mobile number must be 10 digits"]})
        setFieldsError(true)
      } else if (password !== password_confirmation) {
        setFieldsError(true)
        setErrormsg("Password mismatch")
      } else {
        setErrors({notes:["* Please Enter all fields"]})
       // setErrormsg("* Please Enter all fields")
        setFieldsError(true)
      }

    }
  }


  const { role, email } = registerData;

  const onStringCheck = (e, type) => {
    if (!/[^a-zA-Z]/.test(e.target.value)) {
      setRegisterData({ ...registerData, [type]: e.target.value })
    }
  }


  return (
    <>
      <div className="container-fluid pr-0 pl-0">
        <div className="row no-gutters card-design">
          {/* <div className="col-md-1 p-0 m-0">
        <Sidenav category={"Admin"}/>
        </div> */}
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <TextField className="w-100" id="standard-search" label="User Name" type="text" onChange={(e) => onStringCheck(e, "first_name")} value={registerData.first_name} />
              </div>
              <div className="col-12">
                <TextField id="standard-search" className="w-100" label="Email Id" type="text" onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })} onBlur={() => checkEmail(email)} />
              </div>
              {/* {(submit && !emailValidation) ? <div className="text-danger">* Please Enter Valid Email Address</div>:""} */}
              <div className="col-12">
                <TextField id="standard-search" className="w-100" label="Mobile No." type="number" onChange={(e) => setRegisterData({ ...registerData, mobile: e.target.value })} />
              </div>
              <div className="col-5">
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel className="w-100" id="demo-controlled-open-select-label">Select Role</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={role}
                  className="w-100"
                  minRows={20}
                  onChange={(e) => setRegisterData({ ...registerData, role: e.target.value })}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {roles.map((role) => {
                    return <MenuItem value={role.role_id}>
                      {role.role_name}
                    </MenuItem>
                  })}
                </Select>
                </FormControl>
              </div>
              <div className="col-12">
                <TextField id="standard-search" label="Password" type="password" onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })} />
              </div>
              <div className="col-12 pb-4">
                <TextField id="standard-search" label="Confirm pasword" type="password" onChange={(e) => setRegisterData({ ...registerData, password_confirmation: e.target.value })} />
              </div>
              {/* {fieldserror?<div className="text-danger">* Please Enter all fields</div>:""} */}
            </div>

          </div>

          <div className="col-12 text-center">
            <button className="btn btn-xs btn-green-dark text-white " onClick={Adduser}>Add</button>
          </div>
        </div>
      </div>
      {/* <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"OK"} btnfunc={handleClose}>
        <span>User Added Successfully</span>
      </CommonModal> */}

      <ErrorModal handleClose={handleClose} open={errorModal}>

      </ErrorModal>


      <SnakBar
        error={errors}
        open={fieldserrors}
        onClose={() => setFieldErros(false)}
      />

       <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success"/>


    </>
  )
}


export default Adduser