import React, { useState, useEffect } from "react";
import { fetchUrl } from "../../../ApiActions/Actions";
import {
  ADD_CANDIDATE_EDUCATION_API_URL,
  GET_CADNDIDATES_SKILLS_API_URL,
} from "../../../Configurations/ConfigApi";
import { useSelector } from 'react-redux'

export const AdditionalDetails = (props) => {
  const [education, setEducation] = useState([]);
  const [skills, setSkills] = useState('');

  const update_Encrypted_id = useSelector(state=>state.PageReduser.candidateprofileId)
  useEffect(() => {
    fetchUrl(
      `${ADD_CANDIDATE_EDUCATION_API_URL}/${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
          setEducation(response?.data?.data);
        } else {
        }
      }
    );

    fetchUrl(
      `${GET_CADNDIDATES_SKILLS_API_URL}/${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
          let primary = [];
              for (let i = 0; i < response.data.skills.length; i++) {
                primary.push(response.data.skills[i].skill_name);
              }
          setSkills(primary.join(", "));
          // setPrimaryskills(response?.data?.skills.join(", "));
        } else {
        }
      }
    );
  }, []);
  return (
    <div className="job-order-section">
      <div className="row">
        <div className="col-md-9">
          <h6 className="text-underline pb-3"> Additional Details</h6>
        </div>
      </div>

      <hr className="p-0 m-0 mb-3" />
      <div>
        <div className="row">
          <div className="col-md-5">
            <p className="text-underline pb-0 f-13 mb-1"> Education:</p>
            {education.map((res) => {
              return (
                <p className="f-13">
                  <strong>
                    {res.qualification} {res.still_studying==='1' ? '| Still Studying' : ` | ${res.passout_year} `}  {" "}
                    {res.still_studying==='1' ? '' : res?.passout_percentage ? `| ${res.passout_percentage} %` :""} 
                    {/* {res.still_studying==='1' ? '' : ` | ${res.university} `} */}
                    | {res.university}
                  </strong>
                </p>
              );
            })}
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-md-12">
            <p className="text-underline pb-0 f-13 mb-1"> Key Skills:</p>
            <p className="f-13">
              <strong>
                {skills}
              </strong>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <p className="text-underline pb-0 f-13 mb-1">
              {" "}
              Secondary Contact Number:
            </p>
            <p className="f-13">
              <a className="font-weight-bold text-aqua text-underline">
              {(props.cadidateData?.secondary_mobile && props.cadidateData?.secondary_mobile !== '0') && props.cadidateData?.secondary_mobile.replace(/(\d{4})(\d{3})(\d{3})/, "$1-$2-$3")}
              </a>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p className="text-underline pb-0 f-13 mb-1">
              {" "}
              Secondary Email ID:
            </p>
            <p className="f-13">
              <a className="font-weight-bold text-aqua text-underline">
                {props.cadidateData?.secondary_email}
              </a>
            </p>
          </div>
        </div>
      </div>
      <hr className="pt-0 mt-0" />

      <div className="row">
        <div className="col-md-12">
          <p className="text-underline pb-0 f-13 mb-1"> Identity Card Name:</p>
          <p className="f-13">
            <strong> {props.cadidateData?.identity_card_name}</strong>
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <p className="text-underline pb-0 f-13 mb-1"> Identify Card No:</p>
          <p className="f-13">
            <strong>{props.cadidateData?.identity_card_number !== '0' && props.cadidateData?.identity_card_number}</strong>
          </p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-12">
          <p className="text-underline pb-0 f-13 mb-1"> Resume Source:</p>
          <p className="f-13">
            <strong>{props.cadidateData?.resume_subsource && `${props.cadidateData?.resume_subsource} | `}&nbsp;{props.cadidateData?.resume_source}</strong>
          </p>
        </div>
      </div>

      <hr />
    </div>
  );
};
