import React, { useState, useEffect } from "react";
import { useDispatch , useSelector} from "react-redux";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CommonModal from "../../Components/Models/commonModal";
import { fetchUrl, apiPost, apiEdit } from "../../ApiActions/Actions";
import {
  GET_JOBORDERS_API_URL,
  GET_JORORDERS_SKILLS_API_URL,
  GET_JORORDERS_USERS_API_URL,
  GET_JOB_QUALIFICATIONS,
  GET_CLIENT_POINT_CONTACT_DETAILS_STATUS
} from "../../Configurations/ConfigApi";
import SnakBar from "../../Components/Models/snakbar";

import { withRouter } from "react-router";
import { AddjobOrdersForm } from "./addJobordersForm";
import logoEmblem from "../../Assets/Images/emblem.svg";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";

export const UpdateJobOrder = (props) => {
  const dispatch = useDispatch();
  const [positionDes, setPositionDes] = useState("");
  const [jobDes, setJobDes] = useState("");
  const [qualification, setQualification] = useState("");
  const [descriptionFile, setDescriptionFile] = useState("");
  const [addSkillsModal, setAddSkillsModal] = useState(false);
  const [jonAddsuccess, setjonAddsuccess] = useState(false);
  const [primaryskills, setPrimaryskill] = useState([]);
  const [secondaryskills, setSecondaryskills] = useState([]);
  const [updatePrimariSkills,setUpdatePrimarySkills] = useState([])
  const [updateSecondarySkills,setUpdateSecondarySkills] = useState([])
  const [recruiters,setRecruiters] = useState([])
  const [coordinators,setCoordinators] = useState([])
  const [managers,setManagers] = useState([])
  const [updateRecruiters,setUpdateRecruiters] = useState([])
  const [updateCoordinators,setUpdateCoordinators] = useState([])
  const [updateManagers,setUpdateManagers] = useState([])
  const [errors, setErrors] = useState({});
  const [dropIcons, setDropIcons] = useState("fa-info-circle text-warning");
  const [updatequalifications,setupdatequalifications] = useState([])
  const [qualifications, setQualifications] = useState([]);
  const [successMsg,setSuccessMsg] = useState({})
  const [contactdetails,setcontactdetails] = useState([])
  const [loader,setLoader] = useState(false)

  const update_Encrypted_id = useSelector(
    (state) => state.PageReduser.EditJobId
  );
  const [jobOrderFields, setJobOrderFields] = useState({
    country: "",
    state: "",
    city: "",
    name: "",
    client: "",
    contact: "",
    client_name: '',
    job_title: "",
    office_landmark: "",
    min_experience: "",
    max_experience: "",
    max_budget: "",
    no_positions: "",
    qualifications: "",
    open_outstation: "",
    position_status: "",
    demo: "",
    position_type: "",
    jobOrderFields:"",    
  });

  const [fieldserror, setFieldErros] = useState(false);

  const handleClose = () => {
    setAddSkillsModal(false);
    setjonAddsuccess(false);
  };

  const gotojoblist = ()=>{     
    props.changetab(1);
  }

  const saveCandidate = () => {
    let data = {
      encrypted_id: update_Encrypted_id,
      country: jobOrderFields.country,
      state: jobOrderFields.state,
      city: jobOrderFields.city,
      name: jobOrderFields.name,
      client: jobOrderFields.client,
      contact: jobOrderFields.contact,
      job_title: jobOrderFields.job_title,
      office_landmark: jobOrderFields.office_landmark,
      min_experience: jobOrderFields.min_experience,
      max_experience: jobOrderFields.max_experience,
      max_budget: jobOrderFields.max_budget,
      no_positions: jobOrderFields.no_positions,
      // qualifications: jobOrderFields.qualifications,
      open_outstation: jobOrderFields.open_outstation,
      position_status: jobOrderFields.position_status,
      job_description: jobDes,
      positions_description: positionDes,
      qualification_description: qualification,
      position_type:jobOrderFields.position_type,   
      skill_primary :[],
      user_recruiter:[],
      user_coordinator:[],
      user_manager:[],
      qualifications:[]
    };

    for (let i = 0; i < primaryskills.length; i++) {
      data.skill_primary.push(primaryskills[i]);      
    }

    for (let i = 0; i < secondaryskills.length; i++) {
      data[`skill_secondary[${i}]`] = secondaryskills[i]; 
    }

    for (let i = 0; i < recruiters.length; i++) {
      data.user_recruiter.push(recruiters[i]["user_id"]);      
    }

    for (let i = 0; i < coordinators.length; i++) {
      data.user_coordinator.push(coordinators[i]["user_id"]);      
    }
    
    for (let i = 0; i < managers.length; i++) {
      data.user_manager.push(managers[i]["user_id"]);      
    }    
    for (let i = 0; i < qualifications.length; i++) {
      data.qualifications.push(qualifications[i].title);      
    }
    console.clear();
    setLoader(true)
    apiEdit(GET_JOBORDERS_API_URL, data, ({ status, response }) => {
     
      if (status) {
        setSuccessMsg({notes:[" Job updated Successfully"]})
        setjonAddsuccess(true);
     
        let res = response.data;
        setDropIcons("fa-check-circle text-success");
        setTimeout(() => {
        dispatch({type:"Viewjoborder",payload:update_Encrypted_id})
        sessionStorage.setItem("JobOrderId",update_Encrypted_id)      
        sessionStorage.setItem("Jobtitle",jobOrderFields.job_title)
        props.changetab(4)
      }, 3000);

      } else {
        let msg = JSON.parse(response.request.response).errors;
        setErrors(msg);
        setFieldErros(true);
        setDropIcons("fa-times-circle text-danger");
      }
      setLoader(false)
    });
  };

  useEffect(() => {
    dispatch({type:"Viewjoborder",payload:''});
    if (update_Encrypted_id) {
      setLoader(true)
      fetchUrl(
        `${GET_JOBORDERS_API_URL}/${update_Encrypted_id}`,
        ({ status, response }) => {
          if (status) {
            let data = response?.data?.data[0];           
   
            setJobOrderFields({
              country: data.country_name,
              state: data.state_name,
              city: data.city_name,
              name: data.job_title,
              client: data.client_id,
              client_name: data.client_name,
              contact: data.contact_id,
              job_title: data.job_title,
              office_landmark: data.office_landmark,
              min_experience: data.min_experience,
              max_experience: data.max_experience,
              max_budget: data.max_budget,
              no_positions: data.no_positions,
              qualifications: data.qualifications,              
              position_status: capitalizeFirstLetter(data.status),
              demo: "",
              position_type:data.position_type,
              open_outstation:capitalizeFirstLetter(data.open_outstation)
            });
            setPositionDes(data.positions_description);
            setJobDes(data.job_description);
            setQualification(data.qualification_description);           

            fetchUrl(`${GET_CLIENT_POINT_CONTACT_DETAILS_STATUS}/${data.client_id}`, ({ status, response }) => {
              if (status) {                
                setcontactdetails(response.data?.data)
              }
            })
          }
          setLoader(false)
        }
      );

      fetchUrl(`${GET_JORORDERS_USERS_API_URL}/${update_Encrypted_id}`, ({ status, response }) => {
        if (status) {
     
          let usersdata = response?.data?.users;
          let recruiterdata = []
          let managerdata = []
          let coordinatordata = []
          for(let i = 0; i < usersdata.length ; i++){
            if(usersdata[i].user_type === 'recruiter'){
              usersdata[i].encrypted_id = usersdata[i].user_id
              recruiterdata.push(usersdata[i])
            }else if(usersdata[i].user_type === 'manager'){
              usersdata[i].encrypted_id = usersdata[i].user_id
              managerdata.push(usersdata[i])
            }else{
              usersdata[i].encrypted_id = usersdata[i].user_id
              coordinatordata.push(usersdata[i])
            }
          }
          setRecruiters(recruiterdata)
          setManagers(managerdata)
          setCoordinators(coordinatordata)
          setUpdateRecruiters(recruiterdata)
          setUpdateManagers(managerdata)
          setUpdateCoordinators(coordinatordata)
        }
      })      

      fetchUrl(`${GET_JOB_QUALIFICATIONS}/${update_Encrypted_id}`, ({ status, response }) => {
        if (status) {
          let pqual = []
          let data = response?.data?.qualifications;
          for(let i = 0; i < data.length ; i++){
            pqual.push({title:data[i].qualification_name})
          }          
          setupdatequalifications(pqual);
          setQualifications(pqual);
        }
        })

      fetchUrl(`${GET_JORORDERS_SKILLS_API_URL}/${update_Encrypted_id}`, ({ status, response }) => {
        if (status) {
          let pSkills = []
          let secSkills = []
          let pUpdateSkills = []
          let sUpdateSkills = []
          let data = response?.data?.skills;
          for(let i = 0; i < data.length ; i++){
            if(data[i].skill_type === 'primary'){
              pSkills.push(data[i].skill_name)
              pUpdateSkills.push(data[i])
            }else{
              secSkills.push(data[i].skill_name)
              sUpdateSkills.push(data[i])
            }
          }
          setPrimaryskill(pSkills)
          setSecondaryskills(secSkills)
          setUpdatePrimarySkills(pUpdateSkills)
          setUpdateSecondarySkills(sUpdateSkills)
        }
      })
    }
  }, [update_Encrypted_id]);
  function capitalizeFirstLetter(input){
    if (input === undefined)
    return input;
    if (input.length === 0)
    return input;
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  return (
    <>
      {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="container-fluid bg-none clients px-3">
        <div className="row no-gutters">
          <div className="col-md-12 pt-3 px-0">
            <div className=" px-0">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="upload-file-section px-3">
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-5">
                        <div className="card custom-min-heght">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">
                              Select Job Description File{" "}
                            </h5>
                          </div>
                          <div className="card-body text-center">
                            {/* <input type="file" onChange={(e)=>console.log(e.target.value)}/> */}
                            <DragAndDrop
                              id={"Description"}
                              onselect={setDescriptionFile}
                              header={"Description"}
                              data={descriptionFile?.name}
                            />
                            <div className="text-center">
                              <i className={`fas ${dropIcons} fa-2x `}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="card custom-min-heght">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">
                              Select Position Status
                            </h5>
                          </div>
                          <div className="card-body text-center ">
                            <Select
                              className="w-100"
                              minRows={20}
                              onChange={(e) =>
                                setJobOrderFields({
                                  ...jobOrderFields,
                                  position_status: e.target.value,
                                })
                              }
                              value={jobOrderFields.position_status}
                            >
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                            <div className="text-center mt-5">
                              <i className={`fas ${dropIcons} fa-2x `}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <AddjobOrdersForm
                jobOrderFields={jobOrderFields}
                setJobOrderFields={setJobOrderFields}
                setPrimaryskill={setPrimaryskill}
                setSecondaryskills={setSecondaryskills}
                updatePrimariSkills={updatePrimariSkills}
                updateSecondarySkills={updateSecondarySkills}
                edit={true}
                setRecruiters={setRecruiters} 
                setCoordinators={setCoordinators} 
                setManagers={setManagers}
                updateRecruiters={updateRecruiters}
                updateManagers={updateManagers}
                updateCoordinators={updateCoordinators}
                updatequalifications={updatequalifications}
                setQualifications={setQualifications}
                contactdetails={contactdetails}
              />

              {positionDes ? (
                <WysiwygEditor
                  title={"Position Inputs"}
                  edit={true}
                  data={positionDes}
                  editDetails={setPositionDes}
                />
              ) : (
                ""
              )}

              {!positionDes ? (
                <WysiwygEditor
                  title={"Position Description"}
                  edit={true}
                  data={positionDes}
                  editDetails={setPositionDes}
                />
              ) : (
                ""
              )}

              {jobDes ? (
                <WysiwygEditor
                  title={"Job Description"}
                  edit={true}
                  data={jobDes}
                  editDetails={setJobDes}
                />
              ) : (
                ""
              )}

              {!jobDes ? (
                <WysiwygEditor
                  title={"Job Description"}
                  edit={true}
                  data={jobDes}
                  editDetails={setJobDes}
                />
              ) : (
                ""
              )}
{/* 
              {qualification ? (
                <JobTextEditor
                  title={"Qualification"}
                  edit={true}
                  data={qualification}
                  editDetails={setQualification}
                />
              ) : (
                ""
              )}

              {!qualification ? (
                <JobTextEditor
                  title={"Qualification"}
                  edit={true}
                  data={qualification}
                  editDetails={setQualification}
                />
              ) : (
                ""
              )} */}

              <div className="text-center mb-2 pt-2">
                <div className=" d-inline-block me-3">
                  <button class="btn btn-xs btn-green-dark text-white " onClick={gotojoblist} >
                    Cancel
                  </button>
                </div>
                <div className=" d-inline-block mx-2">
                  <button
                    class="btn btn-xs btn-orange text-white"
                    onClick={saveCandidate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <CommonModal
        title={"success"}
        handleClose={handleClose}
        open={jonAddsuccess}
        button={"ok"}
        btnfunc={handleClose}
      >
        Job updated Successfully
      </CommonModal> */}

      <SnakBar error={successMsg} open={jonAddsuccess} onClose={handleClose} type="success"/>

      <SnakBar
        error={errors}
        open={fieldserror}
        onClose={() => setFieldErros(false)}
      />
    </>
  );
};
