import React, { useState, useEffect } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fetchUrl, apiPost, apiDelete } from "../../../../ApiActions/Actions";
import { GET_CANDIDATE_INTERVIEW_API_URL, JOB_ASSIGNED_CANDIDATES } from "../../../../Configurations/ConfigApi";
import CommonModal from '../../../../Components/Models/commonModal';
import SnakBar from "../../../../Components/Models/snakbar";
import { useSelector } from 'react-redux'
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

 const Interview = (props) => {
    const classes = useStyles();
    const [addInterview, setAddInterview] = useState(false)
    const [date, setDate] = useState('')
    const [open, setOpen] = useState(false)
    const [selectedDate, handleDateChange] = useState('');
    const [fieldserror, setFieldErros] = useState(false)
    const [errors, setErrors] = useState({})
    const [updateModal, setUpdatemodal] = useState(false);
    const [updateInterview, setUpdateInterview] = useState(false)
    const [interviewData, setInterviewData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [mode_interview, setMode_interview] = useState("")
    const [duration, setDuration] = useState("")
    const [jobCadidatedata, setjobCadidatedata] = useState([])
    const [Cadidate_id, setCadidate_id] = useState("")
    const [successMsg,setSuccessMsg] = useState({})

    const deleteInterview = () => {
        apiDelete(`${GET_CANDIDATE_INTERVIEW_API_URL}/${deleteId}`, ({ status, response }) => {
          if (status) {
            setSuccessMsg({notes:["Deleted Successfully"]})
            setUpdatemodal(true)
            setDeleteModal(false)
            setUpdateInterview(!updateInterview)
          } else {
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
          }
        })
      }
    

      function AddInterview() {
         
        
        let job_id = sessionStorage.getItem("JobOrderId");
        let id = Cadidate_id;
        let hour = ""
        let min = ""
        let timeformat = "AM"
        let interviewdate = ""
        if (selectedDate) {
          let date = new Date(selectedDate)
          hour = date.getHours()
          if (hour > 12) {
            hour = hour - 12
            timeformat = "PM"
          }
          min = date.getMinutes()
        }
        if (date) {
          let newdate = new Date(date)
          interviewdate = newdate.getFullYear() + "-" + (newdate.getMonth() + 1) + "-" + newdate.getDate()
        }
      
        let interviewData = {
          candidate_id: id,
          job_id: job_id,
          mode_interview: mode_interview,
          date: interviewdate,
          hour: hour,
          minutes: min,
          duration: duration,
          timeformat: timeformat
        }
        apiPost(GET_CANDIDATE_INTERVIEW_API_URL, interviewData, ({ status, response }) => {
          if (status) {            
            if (response.data.status === true){
              setAddInterview(false)
            setSuccessMsg({notes:["Added Successfully"]})
            setUpdatemodal(true)
            setUpdateInterview(!updateInterview)            
            setDate('')
            handleDateChange('')
            }
            else{
              let msg = response.data.message;
              setErrors({notes:[msg]})
              setFieldErros(true)
            }
          }
           else {
            let msg = "Candidate already added for this job";
            setErrors({notes:[msg]})
            setFieldErros(true)
          }
        })       

          apiPost(GET_CANDIDATE_INTERVIEW_API_URL, interviewData, ({ status, response }) => {
            if (status) {
              setUpdatemodal(true)
              setUpdateInterview(!updateInterview)
              setAddInterview(false)
            } else {
              let msg = JSON.parse(response.request.response).errors
              setErrors(msg)
              setFieldErros(true)
            }
          })
    
        
      }
  function addinterviewclick(){
    let job_id = sessionStorage.getItem("JobOrderId");     
    setAddInterview(!addInterview);
    setjobCadidatedata([]);
        fetchUrl(`${JOB_ASSIGNED_CANDIDATES}/${job_id}`, ({ status, response }) => {
            if (status) {               
              setjobCadidatedata(response.data?.data)    
            } else {
              let msg = JSON.parse(response.request.response).errors                
            }
          })
      
  }

  useEffect(() => {
    let job_id = sessionStorage.getItem("JobOrderId");
    fetchUrl(`${GET_CANDIDATE_INTERVIEW_API_URL}?job_id=${job_id}`, ({ status, response }) => {
      if (status) {
        let data = response?.data?.data          
        setInterviewData(data.reverse())     
      } else {
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
    })
  }, [updateInterview])

  return(
    <>
         <div className="interview-section">
        <div className="row">
            <div className="col-md-8">
                <h6 className="text-underline pb-3"> Schedule Interviews</h6>
            </div>
            <div className="col-md-4 px-4">
            <button class="btn btn-xs btn-green-dark text-white btn-violet f-13" onClick={() => addinterviewclick() }>
              {addInterview ? "Cancel" : "Add"}
            </button>
                    
                    </div>
        </div>
        {addInterview ?
          <div className={`row no-gutters card-design mb-3 ${addInterview ? "tabActive" : ""}`}>
            <div className="col-md-12">
              <div className="card box-shadow-none">
                <div className="card-header pb-0 text-left ">
                  <h5 className="text-center f-13">Interview Schedule </h5>
                </div>
                <div className="card-body pt-1 mt-1">
                  <div className="row">
                    <div className="col-12 resourseSelect" >                       
                      <FormControl className={classes.formControl}>
                      <InputLabel style={{ margin: "0px", padding: "0px" }} id="demo-controlled-open-select-label">-- Mode of interview --</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        onChange={(e) => setMode_interview(e.target.value)}
                        className="w-100"
                        minRows={20}
                      >
                        {new Array("Telephonic", "Online Test", "Video Conference", "Personal", "Others").map((res) => {
                          return <MenuItem value={res} key={res}>
                            <em>{res}</em>
                          </MenuItem>
                        })}
                      </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 mb-3 pt-2">
                  {
                  date ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Interview Date </label> : ""
                }
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          className="w-100 mt-0"
                          margin="normal"
                          id="date-picker-inline"
                          format="dd/MM/yyyy"
                          error={false}
                          minDate={moment().toDate()}
                          label={date  ? "" : 'Interview Date'}
                          value={date}
                          onChange={(newdate) => {
                            setDate(newdate)
                          }}
                          autoOk={true}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-12 mb-3 resourseSelect">                      
                       <FormControl className={classes.formControl}>
                      <InputLabel className="w-100" id="demo-controlled-open-select-label">-- Assign Candidate --</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        onChange={(e) => setCadidate_id(e.target.value)}
                        className="w-100"
                        minRows={20}
                      >
                        {jobCadidatedata.map((res) => {
                          return <MenuItem value={res.candidate_id} key={res.candidate_name}>
                            <em>{res.candidate_name}</em>
                          </MenuItem>
                        })}
                      </Select>
                      </FormControl>

                    </div>
                    <div className="col-12 mb-3 ">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          label={selectedDate  ? "" : 'Select Time'}
                          placeholder="08:00 AM"
                          mask="__:__ _M"
                          className="w-100"
                          value={selectedDate}
                          onChange={date => handleDateChange(date)}
                          autoOk={true}
                          error={false}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-12 mb-3 resourseSelect">
                    <FormControl className={classes.formControl}>
                      <InputLabel className="w-100" id="demo-controlled-open-select-label">-- Duriation --</InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        onChange={(e) => setDuration(e.target.value)}
                        className="w-100"
                        minRows={20}
                      >
                        {new Array(15, 30, 45, 60, 90).map((res) => {
                          return <MenuItem value={res} key={res}>
                            <em>{res}</em>
                          </MenuItem>
                        })}
                      </Select>
                      </FormControl>
                    </div>

                    <div className="col-12 text-center py-0 my-0 pb-0 mb-0">
                      <button class="btn btn-xs btn-orange text-white pe-4 px-4 mb-0 mx-4" onClick={AddInterview}>
                        Add
                      </button>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ""}
    </div>
    <div className="interview-section">
    <hr className="p-0 m-0 mb-3" />
        {interviewData.map((data) => {
          return <>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <p className="f-13 pb-0 mb-0">{data?.candidate_name}</p>
                </div>
                <div className="col-md-6  justify-content-end d-flex">
                  <h6 className="f-13">
                    <i class="far fa-trash-alt" onClick={() => {
                      setDeleteId(data?.encrypted_id)
                      setDeleteModal(true)
                    }}></i>
                  </h6>
                </div>
              </div>
              <p className="f-13 mb-1">{data?.mode_interview}</p>
              <p className="f-13 mb-1">NCR Corporation | {data?.duration} Minutes</p>
              <p className="f-13 mb-1">Date: {data?.date} | Time: {data?.hour}:{data?.minutes} {data?.timeformat}</p>
            </div>
            <hr />
          </>
        })}        
</div>
      {/* <CommonModal title={"Success"} handleClose={() => setUpdatemodal(false)} open={updateModal} button={"OK"} btnfunc={() => setUpdatemodal(false)}>
        <span>Interview Added successfully</span>
      </CommonModal> */}
      <CommonModal title={"Delete"} handleClose={() => setDeleteModal(false)} open={deleteModal} button={"DELETE"} btnfunc={deleteInterview}>
        <span>Are you sure you want to delete Interview ?</span>
      </CommonModal>
      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)} />
      <SnakBar error={successMsg} open={updateModal} onClose={() => setUpdatemodal(false)} type="success"/>
    </>
   )
  }

  export default Interview;