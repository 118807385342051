import React, { useEffect,useState } from "react";
import { useSelector ,useDispatch } from "react-redux";
import { fetchUrl } from '../../../../ApiActions/Actions';
import { GET_CLIENT_GST_DETAILS_API_URL} from '../../../../Configurations/ConfigApi';


 const GstDetils = (props) => {
    const [gst,setGst] = useState([]);
    const update_Encrypted_id = useSelector(state=>state.PageReduser.ViewClientId)


    useEffect(()=>{  
      fetchUrl(`${GET_CLIENT_GST_DETAILS_API_URL}/${update_Encrypted_id}`, ({ status, response }) => {
        if(status){
          let data =  response?.data?.data
          setGst(data)
        }
    })
    },[])

    function formatDate(value) {
      if ( value === undefined || value === null || value === '' )
      return '';
      let date = new Date(value);
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: '2-digit' });
      return day + '-' + month + '-' + year;
  }
  return(
    <div className="gst-details">

    {gst.map((data)=>{
        return <div className="mb-4">
        <div className="row g-0 ">
      <div className="col-md-6">
        <div className="gray-card ">
          <div className="row">
            <div className="col-7 border-right">
           <h6 className="text-aqua-light font-weight-bold"> Eligible For GST Exemption?</h6>  
            </div>
            <div className="col-5 ">
           <h6 className="font-weight-bold"> {data.gst_exemption_eligibility}</h6>  
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="gray-card ">
          <div className="row">
            <div className="col-7 border-right">
           <h6 className="text-aqua-light font-weight-bold"> GST Approval End Date?</h6>  
            </div>
            <div className="col-5 ">
           <h6 className="font-weight-bold"> {formatDate(data.approval_from_gst_enddate)}</h6>  
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row g-0">
      <div className="col-md-6 ">
        <div className="gray-card ">
          <div className="row">
            <div className="col-12 border-right">
           <h6 className="text-aqua-light font-weight-bold"> Office Short Name: {data.client_office_short_name}</h6> 
           <ol className="p-0 m-0 step-list-style pb-3" type="none">
             <li>Address 1 : {data.client_office_address1}</li>
             <li>Address 2 : {data.client_office_address2}</li>
             <li>Address 3 : {data.client_office_address3}</li>
             <li>Address 4 : {data.client_office_address4}</li>
             </ol> 
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="gray-card ">
        <div className="row">
            <div className="col-12 ">
           <h6 className="text-aqua-light font-weight-bold"> GST ID: {data.gst_id}</h6> 
           <ol className="p-0 m-0 step-list-style pb-3" type="none">
             <li>GST State Code: { data.gst_state_code}</li>
             <li>GST State Name: {data.gst_state_name}</li>
             <li>PAN No: {data.client_pan_no}</li>
             <li>TAN No.: {data.client_tan_no}</li>
             </ol> 
            </div>
          </div>
        </div>
      </div>
    </div>

        </div>
    })}
  </div>
   )
  }
  export default GstDetils
