import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchUrl } from "../../../../ApiActions/Actions";
import { GET_CLIENTS_NOTES_API , CLIENT_ACTIVE_JOBS } from "../../../../Configurations/ConfigApi";

const Snapshot = (props) => {

  const [notesData,setNotesData] = useState([]);
  const [activeJobs,setActiveJobs] = useState('')
  const update_Encrypted_id = useSelector(
    (state) => state.PageReduser.ViewClientId
  );

  useEffect(() => {
    fetchUrl(
      `${GET_CLIENTS_NOTES_API}?client_id=${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
          let data = response?.data?.data
          if(data.length>0){
           if(data.length>1){
             setNotesData([response?.data?.data[1],response?.data?.data[0]])
           }else{
             setNotesData([response?.data?.data[0]])
           }
          }
       }else{
          //  let msg = JSON.parse(response.request.response).errors
          //  setErrors(msg)
          //  setFieldErros(true)
       }
      }
    );

    fetchUrl(
      `${CLIENT_ACTIVE_JOBS}/${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
        setActiveJobs(response?.data?.count)
       }else{
         
       }
      }
    );
  }, []);

  function formatDate(value) {
    if ( value === undefined || value === null || value === '' )
    return '';
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });
    return day + '-' + month + '-' + year;
}
  
  return (
    <div className="inner-content">
      <h6 className="text-underline pb-3"> Snapshot</h6>
      <hr className="p-0 m-0" />
      <table className="table table-striped  text-center table-hover mb-4">
        <thead>
          <tr className="bg-dark-gray">
            <th>Total Active Job Order</th>
          </tr>

        </thead>
        <tbody>
          <tr>
            <td><strong>{activeJobs}</strong></td>
          </tr>
        </tbody>
      </table>

      <h6 className="text-underline mb-3"> Recent Notes</h6>
      {/* <hr className="p-0 m-0" /> */}
      {
                  notesData.map((data)=>{
                      let date = new Date(data?.created_at)
                      return <div>
                      <div className="row">
                          <div className="col-md-6">
                          <h6 className="f-13 ">{formatDate(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate())}</h6>
                          </div>
                          <div className="col-md-6  justify-content-end d-flex">
                              <h6 className="f-13">

                              </h6>
                          </div>
                      </div>
                      <p className="f-13 mb-1">
                         {data?.notes}        
                    </p>
                      <div className="text-end">
                          <h6 className="text-end f-13">{data.username}</h6>
                          <hr className="p-0 m-0 mb-2" />
                      </div>
                  </div>
    
                  })
              }
          <hr className="p-0 m-0 mb-3" />


    </div>

  )
}


export default Snapshot
