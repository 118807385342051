import React,{useState,useEffect} from 'react'
import Parser from 'html-react-parser';
/**
* @author
* @function DataTable
**/

export const DataTable = (props) => {

    const copyallTable = () => {
        const elTable = document.querySelector('#newtable');
        
        let range, sel;
        
        // Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {
        
          range = document.createRange();
          sel = window.getSelection();
          // unselect any element in the page
          sel.removeAllRanges();
        
          try {
            range.selectNodeContents(elTable);
            sel.addRange(range);
          } catch (e) {
            range.selectNode(elTable);
            sel.addRange(range);
          }
        
          document.execCommand('copy');
        }
        
        sel.removeAllRanges();
      }
  return(
    <><div>
        <div id="Mytable" className="card card-shadow-none table-center px-4">
           <table class="table table-bordered tpnone"  id="newtable">
              <thead>
              <tr>
                    <th style={{ border: '1px solid #606162', backgroundColor: '#606162', color: '#fff', padding: '5px', textAlign: 'center', fontSize: '16px' }} >Candidate</th>
                    <th style={{ border: '1px solid #606162', backgroundColor: '#606162', color: '#fff', padding: '5px', textAlign: 'center', fontSize: '16px' }} >Professional</th>
                    <th style={{ border: '1px solid #606162', backgroundColor: '#606162', color: '#fff', padding: '5px', textAlign: 'center', fontSize: '16px' }} >Compensation</th>
                    <th style={{ border: '1px solid #606162', backgroundColor: '#606162', color: '#fff', padding: '5px', textAlign: 'center', fontSize: '16px' }} >About</th>
                 </tr>
              </thead>
              <tbody>
                 {props.usersData.map((row) => {
                    return <tr>
                       <td style={{ border: '1px solid #ddd',  padding: '8px', textAlign: 'left' }}>
                          <div style={{ color: '#585959', textTransform: 'capitalize',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px', fontWeight: '600' }}>{row?.name}</div>
                          <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}>{row?.primary_mobile}</div>
                          <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><a className='a-link' href="mailto:{row?.primary_email}">{row?.primary_email}</a></div>
                          {/* <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Created On:</strong> {row?.created_at?.slice(0, 11)}</div> */}
                       </td>
                       <td style={{ border: '1px solid #ddd',  padding: '8px', textAlign: 'left', fontSize: '14px' }}>
                          <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Company: </strong>{row?.company_name}</div>
                          <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Location: </strong> {row?.city_name}</div>
                          <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Experience: </strong> {`${row?.exp_years} yrs ${row?.exp_months} Months`}</div>
                          <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Notice Period: </strong> {row?.notice_period}</div>
                       </td>
                       <td style={{ border: '1px solid #ddd',  padding: '8px', textAlign: 'left', fontSize: '14px' }}>
                          <span className='text-left'>
                             <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Current CTC:</strong> {row?.current_ctc}</div>
                             <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Expected CTC:</strong> {row?.expected_ctc}</div>
                             <div style={{ color: '#585959',  padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{fontWeight: '600' }}>Stocks:</strong> {row?.stocks}</div>
                          </span>
                       </td>
                       <td  style={{ border: '1px solid #ddd',  padding: '8px', textAlign: 'left', fontSize: '14px', color: '#585959' }}>
                          <span className='text-left' style={{ color: '#585959'}}>
                             <p style={{  padding: '0px', margin: '0px', marginBottom: '5px', color: '#585959', fontSize: '14px'}}>
                                {row?.about_candidate ? Parser(row?.about_candidate) : ""}
                                </p>
                          </span>
                       </td>
                    </tr>
                 })}
              </tbody>
           </table>
        </div>

     <div className="text-center mt-5 pt-3">
           <button class="btn btn-md btn-violet text-white" onClick={copyallTable}>Copy
           </button>
        </div>
        </div>
        </>
   
    
   )
  }
