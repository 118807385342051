let initialState = {
    CandidateExpList:[{
        candidate_id:"",
        job_title:"",
        company_name:"",
        start_date: new Date(),
        end_date: new Date(),
        still_working:0,
        update:false,
      }],
    CandidateEduList:[],
    CandidateJOList:[],
    updatedCandidateExpData:[],
    updatedCandidateEduData:[],
    updateCandidateJOdata:[]
}

const Edu_Exp_Reduser=(state= initialState,Actions)=>{
    switch (Actions.type){
         case "AddCandidateExp":
            return{
                ...state,
                CandidateExpList:[...state.CandidateExpList,{
                    candidate_id:"",
                    job_title:"",
                    company_name:"",
                    start_date: new Date(),
                    end_date: new Date(),
                    still_working:0,
                    update:false,
                  }]
            }
        case "DeleteCandidateExp":
            let deletedata = [...state.CandidateExpList]
            deletedata.splice(Actions.payload,1)
            return{
                ...state,
                CandidateExpList:[...deletedata]
            }

        case "RemoveAllCandidateExp":
            return{
                ...state,
                CandidateExpList:[]
            }

        case "UpdateCandidateExp":
            let index = Actions.payload[0]
            let upatedata = Actions.payload[1]
            let newdata = [...state.CandidateExpList]
            newdata[index] = upatedata
            return{
                ...state,
                CandidateExpList:[...newdata]
            }


            case "AddCandidateEdu":
                return{
                    ...state,
                    CandidateEduList:[...state.CandidateEduList,{
                        candidate_id:"",
                        university:"",
                        qualification:"",
                        passout_year:"",
                        passout_percentage:"",
                        still_studying:0,
                        update:false
                      }]
                }
            case "DeleteCandidateEdu":
                let deleteEdudata = [...state.CandidateEduList]
                deleteEdudata.splice(Actions.payload,1)
                return{
                    ...state,
                    CandidateEduList:[...deleteEdudata]
                }
    
            case "RemoveAllCandidateEdu":
                return{
                    ...state,
                    CandidateEduList:[]
                }
    
            case "UpdateCandidateEdu":
                let indexEdu = Actions.payload[0]
                let upateEdudata = Actions.payload[1]
                let newEdudata = [...state.CandidateEduList]
                newEdudata[indexEdu] = upateEdudata
                return{
                    ...state,
                    CandidateEduList:[...newEdudata]
                }

            case "AddCandidateJO":
                return{
                    ...state,
                    CandidateJOList:[...state.CandidateJOList,{
                        candidate_id: "",
                        job_id: "",
                        user_id: "",
                      }]
                }
            case "DeleteCandidateJO":
                let deleteJOdata = [...state.CandidateJOList]
                deleteJOdata.splice(Actions.payload,1)
                return{
                    ...state,
                    CandidateJOList:[...deleteJOdata]
                }
    
            case "RemoveAllCandidateJO":
                return{
                    ...state,
                    CandidateJOList:[]
                }
    
            case "UpdateCandidateJO":
                let indexJO = Actions.payload[0]
                let upateJOdata = Actions.payload[1]
                let newJOdata = [...state.CandidateJOList]
                newJOdata[indexJO] = upateJOdata
                return{
                    ...state,
                    CandidateJOList:[...newJOdata]
                }
            case 'updatedCandidateExpData':
                return {
                    ...state,
                    updatedCandidateExpData: [...Actions.payload]
                }

            case "DeleteUpdatedCandidateExpDataItem":
                let deleteUpdateExpdata = [...state.updatedCandidateExpData]
                deleteUpdateExpdata.splice(Actions.payload,1)
                return{
                    ...state,
                    updatedCandidateExpData:[...deleteUpdateExpdata]
                }
            
            case 'checkUpdateCandidateExpData':
                let updateExpindex = Actions.payload[0]
                let upateExpdata = Actions.payload[1]
                let newUpdatedata = [...state.updatedCandidateExpData]
                newUpdatedata[updateExpindex] = upateExpdata
                return{
                    ...state,
                    updatedCandidateExpData:[...newUpdatedata]
                }

            case "RemoveUpdatedCandidateExpData":
                return{
                    ...state,
                    updatedCandidateExpData:[]
                }

            case 'updatedCandidateEduData':
                return {
                    ...state,
                    updatedCandidateEduData: [...Actions.payload]
                 }
            
            case 'checkUpdateCandidateEduData':
                let updateEduindex = Actions.payload[0]
                let checkupateEdudata = Actions.payload[1]
                let newUpdateEdudata = [...state.updatedCandidateEduData]
                newUpdateEdudata[updateEduindex] = checkupateEdudata
                return{
                    ...state,
                    updatedCandidateEduData:[...newUpdateEdudata]
                }

            case "RemoveUpdatedCandidateEduData":
                return{
                    ...state,
                    updatedCandidateEduData:[]
                }

            case "DeleteUpdatedCandidateEduDataItem":
                let deleteUpdateEdudata = [...state.updatedCandidateEduData]
                deleteUpdateEdudata.splice(Actions.payload,1)
                return{
                    ...state,
                    updatedCandidateEduData:[...deleteUpdateEdudata]
                }
        
            case 'checkUpdateCandidateJOdata':
                let updateJOindex = Actions.payload[0]
                let checkupateJOdata = Actions.payload[1]
                let newUpdateJOdata = [...state.updateCandidateJOdata]
                newUpdateJOdata[updateJOindex] = checkupateJOdata
                return{
                    ...state,
                    updateCandidateJOdata:[...newUpdateJOdata]
                }

            case "RemoveUpdatedCandidateJOData":
                return{
                    ...state,
                    updateCandidateJOdata:[]
                }
        default:
      return state
    }
}

export default Edu_Exp_Reduser