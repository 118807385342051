import React, { useState } from 'react'
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// Image Iocns
import Phone from "../../../../Assets/Images/profile-t-icons/Phone.svg";
import t7 from "../../../../Assets/Images/profile-t-icons/t7.svg";
import t2 from "../../../../Assets/Images/profile-t-icons/t2.svg";
import t3 from "../../../../Assets/Images/profile-t-icons/t3.svg";
import Email from "../../../../Assets/Images/profile-t-icons/email.svg";



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Active = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="row g-0 bg-custom-change">
        <div className="col-md-4 p-3">
          <div className="bg-light text-left-custom">
            <AppBar position="static" className="border">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                orientation="vertical"
              >
                <Tab label="Joined (2)" {...a11yProps(0)} />
                <Tab label="Offered (4)" {...a11yProps(1)} />
                <Tab label="Active (16)" {...a11yProps(2)} />
                <Tab label="Prospective (2)" {...a11yProps(3)} />
                <Tab label="Processed (42)" {...a11yProps(4)} />
              </Tabs>
            </AppBar></div>
        </div>
        <div className="col-md-8 py-3">
          <TabPanel value={value} index={0} className="border">
            <div className="p-3 bg-custom-light">
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className="border">
          <div className="p-3 bg-custom-light">
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status</p>
                  <p className="f-13 mb-1">Joined on <strong>12-Sep-21</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>28,00,000</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} className="border">
          <div className="p-3 bg-custom-light">
              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status (4 Days ago)</p>
                  <p className="f-13 mb-1">Interview Scheduled on DATE at time.</p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status (4 Days ago)</p>
                  <p className="f-13 mb-1">Interview Scheduled on DATE at time.</p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status (4 Days ago)</p>
                  <p className="f-13 mb-1">Interview Scheduled on DATE at time.</p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              
              
            </div>
          </TabPanel>
          <TabPanel value={value} index={3} className="border">
          <div className="p-3 bg-custom-light">
          <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status (4 Days ago)</p>
                  <p className="f-13 mb-1">Interview Scheduled on DATE at time.</p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status (4 Days ago)</p>
                  <p className="f-13 mb-1">Interview Scheduled on DATE at time.</p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Sub Status (4 Days ago)</p>
                  <p className="f-13 mb-1">Interview Scheduled on DATE at time.</p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4} className="border">
          <div className="p-3 bg-custom-light">
          <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Main Status </p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Main Status </p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Main Status </p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row candidate-list">
                <div className="col-md-7">
                  <p className="f-13 mb-1">Candidate Name</p>
                  <p className="f-13 mb-1">Main Status </p>
                  <p className="f-13 mb-1">Notice Period</p>
                  <p className="f-13 mb-1">Recent Notes</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">Recruiter Name</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t7} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={t3} className="tab-img-h2 align-top  me-2" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  )
}
