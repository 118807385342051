export const BASE_API_URL = "https://api.ultis.co";

export const LOGIN_API_URL = `${BASE_API_URL}/api/login`;

export const LOGOUT_API_URL = `${BASE_API_URL}/api/logout`;

export const GET_ROLES_API_URL = `${BASE_API_URL}/api/roles`;

export const ROLES_PERMISSION_API_URL = `${BASE_API_URL}/api/roles_permissions`;

export const UPDATE_PERMISSION_API_URL = `${BASE_API_URL}/api/roles_permissions`;

export const GET_MODULES_API_URL = `${BASE_API_URL}/api/roles_modules`;

export const ADD_USER_API_URL = `${BASE_API_URL}/api/register`;

export const UPDATE_USER_API_URL = `${BASE_API_URL}/api/update_user`;

export const GET_USER_API_URL = `${BASE_API_URL}/api/get_user`;

export const GET_EMAILS_API_URL = `${BASE_API_URL}/api/get_emails`;

export const GET_ALL_USERS_API_URL = `${BASE_API_URL}/api/get_all_users?`;

export const DELETE_USER_API_URL = `${BASE_API_URL}/api/user/`;

export const GET_SKILLS_API_URL = `${BASE_API_URL}/api/skills`;

export const ADD_CANDIDATES_API_URL = `${BASE_API_URL}/api/candidates`;

export const REPLACE_SKILL_API_URL = `${BASE_API_URL}/api/update_skill`;

export const GET_CADNDIDATES_SKILLS_API_URL = `${BASE_API_URL}/api/get_candidate_skills`;

export const GET_CADNDIDATES_API_URL = `${BASE_API_URL}/api/candidates`;

export const ADD_CANDIDATE_EDUCATION_API_URL = `${BASE_API_URL}/api/candidates_education`;

export const ADD_CANDIDATE_EXPERIRNCE_API_URL = `${BASE_API_URL}/api/candidates_experience`;

export const DELETE_CANDIDATE_API_URL = `${BASE_API_URL}/api/candidate_delete/`;

export const UPDATE_CANDIDATE_API_URL = `${BASE_API_URL}/api/update_candidate`;

export const UPDATE_CANDIDATE_PROFILEPIC_API_URL = `${BASE_API_URL}/api/update_candidate_profilepic`;

export const EDIT_CANDIDATE_EDUCATION_API_URL = `${BASE_API_URL}/api/candidates_education_update`;

export const DELETE_CANDIDATE_EDUCATION_API_URL = `${BASE_API_URL}/api/candidates_education_delete/`;

export const EDIT_CANDIDATE_EXPERIRNCE_API_URL = `${BASE_API_URL}/api/candidates_experience_update`;

export const DELETE_CANDIDATE_EXPERIRNCE_API_URL = `${BASE_API_URL}/api/candidates_experience_delete/`;

export const UPDATE_CANDIDATE_ABOUT_API_URL = `${BASE_API_URL}/api/update_candidate_about`;

export const GET_JOBORDERS_API_URL = `${BASE_API_URL}/api/jobs`;

export const GET_JORORDERS_USERS_API_URL = `${BASE_API_URL}/api/job_users`;

export const GET_JORORDERS_SKILLS_API_URL = `${BASE_API_URL}/api/job_skills`;

export const GET_CANDIDATE_NOTES_API_URL = `${BASE_API_URL}/api/candidates_notes`;

export const GET_JOB_NOTES_API_URL = `${BASE_API_URL}/api/job_notes`; 

export const GET_CANDIDATE_INTERVIEW_API_URL = `${BASE_API_URL}/api/candidates_interview_schedule`;

export const GET_CANDIDATE_ATTACHMENT_API_URL = `${BASE_API_URL}/api/candidates_attachments`;

export const GET_JOB_ATTACHMENT_API_URL = `${BASE_API_URL}/api/job_attachments`; 

export const GET_JOB_RECRUITERS_API_URL = `${BASE_API_URL}/api/recruiter_jobs`;

export const GET_JOB_ASSIGNED_USERS_API_URL = `${BASE_API_URL}/api/job_assigned_users/`;

export const GET_CADNDIDATES_JOB_ORDERS_API_URL = `${BASE_API_URL}/api/candidates_job_orders`;

export const GET_JOB_MAINSTATUS_API_URL = `${BASE_API_URL}/api/job_mainstatus`;

export const GET_JOB_MAINSUBSTATUS_API_URL = `${BASE_API_URL}/api/job_mainsubstatus`;

export const GET_CANDIDATE_JOB_ORDERS_STATUS = `${BASE_API_URL}/api/candidates_job_orders_status`;

export const GET_CLIENTS_URL = `${BASE_API_URL}/api/clients`;

export const GET_UPDATE_CLIENT_API_URL = `${BASE_API_URL}/api/update_clientslogo`;

export const GET_CLIENT_NOTES_URL = `${BASE_API_URL}/api/clients_notes`;

export const GET_CLIENT_GST_DETAILS_API_URL = `${BASE_API_URL}/api/clients_gst_details`;

export const GET_CLIENT_POINT_CONTACT_DETAILS_STATUS = `${BASE_API_URL}/api/clients_point_contact_details`;

export const GET_CLIENT_PAYMENT_STATUS = `${BASE_API_URL}/api/clients_payment_terms_details`;

export const GET_CANDIDATE_INVOICE_PROCESS_STATUS = `${BASE_API_URL}/api/clients_invoice_process_stages_details`;

export const GET_CLIENT_LOGO_UPDATE_API = `${BASE_API_URL}/api/update_clientslogo`;

export const GET_CLIENTS_NOTES_API = `${BASE_API_URL}/api/clients_notes`;

export const GET_CLIENTS_ATTACHMENTS_API = `${BASE_API_URL}/api/clients_attachments`;

export const GET_QULIFICATION_API = `${BASE_API_URL}/api/qualifications`;

export const GET_PARSING_FILE_DATA = `${BASE_API_URL}/api/add_candidate_parsingfile`;

export const GET_JOBSKILLS_DATA = `${BASE_API_URL}/api/job_skills`;

export const GET_ALLJOBORDER_DATA = `${BASE_API_URL}/api/all_job_orders`;

export const GET_UPDATE_CANDIDATE_PROFILE_PIC = `${BASE_API_URL}/api/update_candidate_profilepic`;

export const GET_UPDATE_CANDIDATE_RESUME = `${BASE_API_URL}/api/update_candidate_resume`;

export const GET_JOBCANDIDATESTATUS_DATA = `${BASE_API_URL}/api/job_candidate_status`;

export const GET_JOB_QUALIFICATIONS = `${BASE_API_URL}/api/get_job_qualifications`;

export const GET_JOBORDER_STATUS = `${BASE_API_URL}/api/job_candidate_status`;

export const GET_JOBORDER_STATUSCOUNT = `${BASE_API_URL}/api/job_candidates_count`;

export const GET_JOB_CANDIDATES_DETAILS = `${BASE_API_URL}/api/job_candidates_details`;

export const TODAY_CANDIDATES_JOB_INTERVIEW = `${BASE_API_URL}/api/today_candidates_job_interview`;

export const RECENT_CANDIDATES_JOB_INTERVIEW = `${BASE_API_URL}/api/recent_candidates_job_interview`;

export const CLIENT_ACTIVE_JOBS = `${BASE_API_URL}/api/clients_active_jobs`;

export const DTRESUME_ACTIVE_JOBS_DETAILS = `${BASE_API_URL}/api/clients_active_jobs_details`; 

export const DTRESUME_JOBS_DATATABLE = `${BASE_API_URL}/api/resume_jobs_datatable`;

export const DTRESUME_JOB_CANDIDATES_DETAILS = `${BASE_API_URL}/api/job_candidates_details`;

export const DTINTERVIEW_JOBS_DATATABLE = `${BASE_API_URL}/api/interview_jobs_datatable`;

export const CLIENTS_ACTIVE_JOBS_INTERVIEW_DETAILS = `${BASE_API_URL}/api/clients_active_jobs_interview_details`;

export const JOB_ASSIGNED_CANDIDATES = `${BASE_API_URL}/api/job_assigned_candidates`;
 
export const DATATABLE_CLIENTS_URL = `${BASE_API_URL}/api/datatable_clients?status=active`;

// Dash borad apis list

export const DASHBOARD_LIST_API = `${BASE_API_URL}/api/dashboard`;
 
export const DASHBOARD_RECENT_JOBINTERVIEWS_LIST_API = `${BASE_API_URL}/api/dashboard_recent_job_interviews`;

export const DASHBOARD_FUTURE_JOBINTERVIEWS_LIST_API = `${BASE_API_URL}/api/dashboard_future_job_interviews`;
 
export const DASHBOARD_JOB_ORDERS_LIST_API = `${BASE_API_URL}/api/dashboard_job_orders`;

export const DASHBOARD_PLACED_OFFERED_LIST_API = `${BASE_API_URL}/api/dashboard_placed_offered_list`;

export const CUSTOM_TABLE_REPORTS_LIST_API = `${BASE_API_URL}/api/custom_reports`;

export const CUSTOM_TABLE_GET_ALL_REPORTS = `${BASE_API_URL}/api/all_custom_reports`;

export const CUSTOM_TABLE_DOWNLOAD_REPORTS = `${BASE_API_URL}/api/download_custom_reports`;