import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchUrl } from "../../../../ApiActions/Actions";
import { GET_CLIENT_PAYMENT_STATUS } from "../../../../Configurations/ConfigApi";

const PaymentDetails = (props) => {
    const [payment,setPayment] = useState([]);
  const update_Encrypted_id = useSelector(
    (state) => state.PageReduser.ViewClientId
  );

  useEffect(() => {
    fetchUrl(
      `${GET_CLIENT_PAYMENT_STATUS}/${update_Encrypted_id}`,
      ({ status, response }) => {
        if (status) {
          let data = response?.data?.data;
          setPayment(data)
        }
      }
    );
  }, []);

 
  return (
    <>
      <div className="row g-0 mb-3">
        <div className="col-md-6">
          <div className="gray-card ">
            <div className="row">
              <div className="col-8 border-right">
                <h6 className="text-aqua-light font-weight-bold">
                  {" "}
                  Invoice Payment Duration{" "}
                </h6>
              </div>
              <div className="col-4 ">
                <h6 className="font-weight-bold">{props.clientdata.invoice_payment_duration} Days</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="gray-card ">
            <div className="row">
              <div className="col-8 border-right">
                <h6 className="text-aqua-light font-weight-bold">
                  {" "}
                  Replacement Duration
                </h6>
              </div>
              <div className="col-4 ">
                <h6 className="font-weight-bold"> {props.clientdata.replacement_duration} Days</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    {payment.map((data)=>{
        return <>
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Criteria : {data.criteria_name}</td>
             <td> Charges : {data.charges} %</td>
              <td>Remarks : {data.remarks}</td>
            </tr>

          </tbody>
        </table>
      </div>
        </>
    })}
    </>
  );
};
export default PaymentDetails;
