import React from 'react'
import TextField from '@material-ui/core/TextField';
// Login css
import "./register.scss";


const MyAccount = (props) => {

  const userdetails = JSON.parse(localStorage.getItem("user"))

 
  return(
      <>
     
    <div className="row no-gutters card-design">
       
        <div className="col-md-6 pt-3">
          <div className="row">
        <div className="col-12 mb-4">
        <TextField className="w-100 mt-0" id="standard-search" label="User Name:" type="text" disabled defaultValue={userdetails?.first_name+" "+userdetails?.last_name}/> 
        </div>
        <div className="col-12 mb-4">
        <TextField className="w-100 mt-0" id="standard-search" label="Email ID:" type="text" defaultValue={userdetails?.email} disabled/> 
        </div>
        <div className="col-12 mb-4">
        <TextField className="w-100 mt-0" id="standard-search" label="Mobile No:" type="text" defaultValue={userdetails?.mobile} disabled/> 
        </div>
        <div className="col-12 mb-4">
        <TextField className="w-100 mt-0" id="standard-search" label="Current Role:" type="text" defaultValue={userdetails?.role} disabled/> 
        </div>
        <div className="col-12 pb-5">
        <TextField className="w-100 mt-0" id="standard-search" label="Status:" type="text" defaultValue={userdetails?.status} disabled/> 
        </div>
        {/* <div className="col-12 text-center py-5 my-5 pb-0 mb-0">
        <button className="btn btn-xs btn-primary theme-btn-light text-white">Login</button>
        </div> */}
        </div>
 
      </div>
    </div>
    </>
   )
  }


export default MyAccount