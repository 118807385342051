import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { fetchUrl, apiDelete } from "../../../ApiActions/Actions";
import { GET_JOBORDERS_API_URL, GET_CLIENTS_URL, GET_JOBSKILLS_DATA, 
  GET_JOB_QUALIFICATIONS, GET_JORORDERS_USERS_API_URL } from "../../../Configurations/ConfigApi";
import { useDispatch , useSelector } from "react-redux";
import CommonModal from '../../../Components/Models/commonModal';
import Parser from 'html-react-parser';
import t4 from "../../../Assets/Images/profile-t-icons/t4.svg";
import CompanyName from "../../../Assets/Images/profile-t-icons/CompanyName.svg";
import ProfileCheck from "../../../Assets/Images/profile-t-icons/user-check.svg";
import Download from "../../../Assets/Images/profile-t-icons/Download.svg";
import Edit from "../../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../../Assets/Images/profile-t-icons/Delete.svg";
import Location from "../../../Assets/Images/profile-t-icons/Location.svg";
import Sidenav from "./sidenav";
import { Active } from "./tabs/active";
import logoEmblem from "../../../Assets/Images/emblem.svg";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const JobDetails = (props) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const [JoborderData, setJoborderData] = useState({});
  const [ClientData, setClientData] = useState({});
  const [Primaryskills, setPrimaryskills] = useState('');
  const [Secondaryskills, setSecondaryskills] = useState('');
  const [updateModal,setUpdatemodal] = useState(false);
  const [deleteUserId,setDeleteUserId] = useState("");
  const [Jobtitle,setJobtitle] = useState("");
  const [Qualifications, setQualifications] = useState('');
  const [Recruiters, setRecruiters] = useState('');
  const [Managers, setManagers] = useState('');
  const [Coordinator, setCoordinator] = useState('');  
  const [JobId, setJobId] = useState([]);
  const Viewjoborder = useSelector(state=>state.PageReduser.Viewjoborder);
  const [loader,setLoader] = useState(false)
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteUserId("");
  };

  const deleteJoborder =()=>{
    apiDelete(`${GET_JOBORDERS_API_URL}/${deleteUserId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false)
        props.changetab(1)
      }
  })
  }
  
  useEffect(() => {
    window.scroll(0,0);
    sessionStorage.removeItem('redirectToJobOrder')
      // let id = sessionStorage.getItem("JobOrderId");
      setJobId(Viewjoborder); 
      console.log('Viewjoborder',Viewjoborder)
      let id = sessionStorage.getItem('JobOrderId')
      setJobtitle(sessionStorage.getItem("Jobtitle"));
      setLoader(true)
      fetchUrl(`${GET_JOBORDERS_API_URL}/${Viewjoborder||id}`, ({ status, response }) => {
      if (status) {
      setJoborderData(response?.data?.data[0]);      
      console.clear();
      let client_id = response?.data?.data[0].client_id;
      fetchUrl(`${GET_CLIENTS_URL}/${client_id}`, ({ status, response }) => {
      if (status) {
      setClientData(response?.data?.data[0]);
      
      }
      });
      }
      setLoader(false)
    });
    setLoader(true)
    fetchUrl(`${GET_JOBSKILLS_DATA}/${Viewjoborder||id}`, ({ status, response }) => {
      if (status) {      
      let primary = [];
      let secondary = [];
          for (let i = 0; i < response.data.skills.length; i++) {
            if (response.data.skills[i].skill_type.toLowerCase() === "primary")
            primary.push(response.data.skills[i].skill_name);

            if (response.data.skills[i].skill_type.toLowerCase() === "secondary")
            secondary.push(response.data.skills[i].skill_name);
          }
          setPrimaryskills(primary.join(", "));
          setSecondaryskills(secondary.join(", "));
        }
        setLoader(false)
      });
      setLoader(true)
      fetchUrl(`${GET_JOB_QUALIFICATIONS}/${Viewjoborder||id}`, ({ status, response }) => {
        if (status) {      
          let pqual = []
          let data = response?.data?.qualifications;
          for(let i = 0; i < data.length ; i++){
            pqual.push(data[i].qualification_name)
          }          
          setQualifications(pqual.join(", "));             
          }
          setLoader(false)
        });
       

        fetchUrl(`${GET_JORORDERS_USERS_API_URL}/${Viewjoborder||id}`, ({ status, response }) => {
          if (status) {      
            let usersdata = response?.data?.users;             
            let recruiterdata = []
            let managerdata = []
            let coordinatordata = []
            for(let i = 0; i < usersdata.length ; i++){
              if(usersdata[i].user_type === 'recruiter'){                 
                recruiterdata.push(usersdata[i].user_email)
              } 
              if(usersdata[i].user_type === 'manager'){                
                managerdata.push(usersdata[i].user_email)
              } 
              if(usersdata[i].user_type === 'coordinator'){                
                coordinatordata.push(usersdata[i].user_email)
              } 
            }        
            
            setRecruiters(recruiterdata.join(", ")); 
            setManagers(managerdata.join(", "));
            setCoordinator(coordinatordata.join(", "));                         
            }
          });
    

  }, []);
  const Getcurrentdatetime = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return parseDate(date + " " + time);
  };

  function parseDate(input) {
    var parts = input.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  const Getcntdetails = (cnd_int_details) => {
    if (cnd_int_details.length === 0) return "";
    return (
      formatDate(cnd_int_details[0].date) +
      " " +
      cnd_int_details[0].hour +
      ":" +
      cnd_int_details[0].minutes +
      " " +
      cnd_int_details[0].timeformat
    );
  };

  const Getcreatedtimedetails = (sub_status_created_at) => {
    let currentdate = Getcurrentdatetime();
    let stausdate = parseDate(sub_status_created_at);
    let time_difference = currentdate.getTime() - stausdate.getTime();
    let days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24));

    if (days_difference > 0)
      return " " + days_difference.toString() + " Days ago";

    if (Math.floor(time_difference) > 0)
      return " " + Math.floor(time_difference).toString() + " Hours ago";

    var minutes = Math.floor(time_difference / 1000 / 60);

    if (minutes > 0) return " " + minutes.toString() + " Minutes ago";

    return " Just now";
  };

  function Composemail(emailto){
    window.location.href = "mailto:"+emailto+"?subject=Subject&body=messag";
  }

  function formatDate(value) {    
    if ( value === undefined || value === null || value === '' )
        return '';
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });
    return day + '-' + month + '-' + year;
}

function capitalizeFirstLetter(input){
  if (input === undefined)
  return input;
  if (input.length === 0)
  return input;
  return input.charAt(0).toUpperCase() + input.slice(1);
}
  return (
    <>
      {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid px-0 bg-custom-light">
      <div className="row g-0 pt-0 profile-section">
        {/* <div className="col-md-1 p-0 m-0">
          <Sidenav category={"CandidateProfile"} />
        </div> */}
        {/* <div className="col-12">
          <div className="divider"></div>
        </div> */}
        <div className="col-md-8 pe-2">
          <div className="card-design">
            <div className="card radius-0 p-1 ps-3 border box-shadow-none">
              <div className="row">
                <div className="col-md-9">
                  <div className="row g-0">
                    <div className="col-md-3">
                      <div className="profile-img job-rder-img pe-0 text-center">
                        <img
                          className="img-fluid radius-profile"
                          src={ClientData?.clientlogo_file_url}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-9 ps-4">
                      <ul type="none" className="p-0 m-0 list-style-2">
                        <li className="pl-0">
                              <img src={t4} className="tab-img-h2 align-top me-2"/>
                          <span className="font-weight-bold">{JoborderData?.job_title}</span>
                        </li>
                        <li className="pl-0">
                        <img src={CompanyName} className="tab-img-h2 align-top  me-2"/>{ClientData?.client_short_name}
                        </li>
                        <li className="pl-0">
                        <img src={ProfileCheck} className="tab-img-h2 align-top  me-2"/>{capitalizeFirstLetter(JoborderData?.status)}
                        </li>
                        <li className="pl-0">
                        <img src={Location} className="tab-img-h2 align-top  me-1"/>  {JoborderData?.city_name} | {JoborderData?.office_landmark}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                <div className="col-md-12  px-2 flex-column d-flex justify-content-center align-self-center align-items-center  align-content-center">
                      <ul
                        type="none"
                        className="p-0 m-0 d-flex justify-content-center align-items-center align-self-center pt-5 mt-3"
                      >
                        <li className="cursor-pointer" >                          
                          <a   href={JoborderData?.jobdescription_file_url} download>
                          <img src={Download} className="tab-img-h2 align-top  me-2 "/> 
                          </a>
                        </li>
                        <li className="cursor-pointer">
                          <a>
                          <img src={Edit} className="tab-img-h2 align-top  me-2" style={{cursor:'pointer'}}  onClick={()=>{
                          dispatch({type:"EditJobId",payload:JoborderData?.encrypted_id})
                          sessionStorage.setItem("editId",JoborderData?.encrypted_id)
                          props.changetab(3)
                          }
                          }/> 
                          </a>
                        </li>
                        <li className="cursor-pointer">
                          <a onClick={()=>{ setUpdatemodal(true); setDeleteUserId(JoborderData?.encrypted_id) } } >
                          <img src={Delete} className="tab-img-h2 align-top  me-2" /> 
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div className="bg-dark-gray font-weight-headings">
              <div className="row text-center g-0">
                <div className="col-md-1">Positions</div>
                <div className="col-md-2">Job Id</div>
                <div className="col-md-2">Experience</div>
                <div className="col-md-2">Position Type</div>
                <div className="col-md-2">Max Budget</div>
                <div className="col-md-3">Customer Contact</div>
              </div>
            </div>
            <div className="bg-light-gray font-weight-headings">
              <div className="row g-0 text-center">
                <div className="col-md-1">
                {JoborderData?.no_positions}
                </div>
                <div className="col-md-2">
                {JoborderData?.job_id}
                </div>
                <div className="col-md-2">
                {JoborderData?.min_experience} to {JoborderData?.max_experience} Years
                </div>
                <div className="col-md-2">
             {JoborderData?.position_type}
                </div>
                <div className="col-md-2">
               <i class="fas fa-rupee-sign"></i> {JoborderData?.max_budget} 
                  </div>
                <div className="col-md-3">
                 <span className="text-nowrap w-100 text-truncate">{JoborderData?.contact_name}</span> 
                  </div>
              </div>
            </div>
         <br/>
            <div>
              <AppBar position="static" className="border">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Job Description" {...a11yProps(1)} />
                  <Tab label="Database Match" {...a11yProps(2)} />
                  <Tab label="Status" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} className="border fixed-h-w-scroll">
                <div className="details-section-2">
                  <div className="col-12 pb-3">
                    <div className="col-md-8">
                      <ul type="none" className="p-0 m-0  pt-2 list-space">
                        <li className="pl-0">
                        <strong>Primary Skills: </strong>{Primaryskills}
                        </li>
                        <li className="pl-0">
                        <strong>Secondary Skills:</strong> {Secondaryskills}
                        </li>
                        <li className="pl-0">
                        <strong>Qualifications:</strong> {Qualifications}
                        </li>
                        <li className="pl-0">
                        <strong>Open For Outstation?:</strong> {capitalizeFirstLetter(JoborderData?.open_outstation)}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">
                      <ul type="none" className="p-0 m-0  pt-2 list-space">
                <li><strong>Customer Contact Name:</strong> <span>{JoborderData?.contact_name}</span></li>
                <li><strong>Recruiter/s Assigned:</strong> <span>{Recruiters}</span></li>
                <li><strong>Manager/s Handling:</strong> <span>{Managers}</span></li>
                <li><strong>Coordinator/s:</strong> <span>{Coordinator}</span></li><br />
                <li><strong>Position Inputs:</strong></li>  
                </ul>
                </div>
                {JoborderData?.positions_description?Parser(JoborderData?.positions_description):""}              
                
                {/* <ul className="p-2 m-2 circle">
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                </ul> */}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} className="border fixed-h-w-scroll">
              {JoborderData?.job_description?Parser(JoborderData?.job_description):""}
                 </TabPanel>
              <TabPanel value={value} index={2} className="border fixed-h-w-scroll">
               
                </TabPanel>
              <TabPanel value={value}  index={3} className="border card-0 pad---none fixed-h-w-scroll">
                    <Active jobId={JobId} />
              </TabPanel>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          {/* <DetailsSidenav /> */}
          <Sidenav/>
        </div>
      </div>
      <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteJoborder}>
        <span>Are you sure you want to delete Job Order ?</span>
      </CommonModal>             
      </div>
      </>
      
  );
};

export default JobDetails;
