import React, { useState ,useEffect} from 'react'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import './dashboard.scss'
import UpComingInterviews from './tabs/upcomingInterviews';
import RecentInterviews from './tabs/recentInterviews';
import OfferedList from './tabs/offeredList';
import JobOrders from './tabs/jobOrders';
import { SideTabs } from './sideTabs/sidetabs';
import {DASHBOARD_LIST_API } from '../../Configurations/ConfigApi'
import { fetchUrl,  } from '../../ApiActions/Actions'
import logoEmblem from "../../Assets/Images/emblem.svg";


function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box p={3}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}



const Dashboard = () => {

  const [value, setValue] = useState(0);
  const [loader,setLoader] = useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
};

const [dashBoardData,setDashBoardData] = useState({})

useEffect(()=>{
  getDashBoardData()
},[])

const getDashBoardData=()=>{
  setLoader(true)
  fetchUrl(`${DASHBOARD_LIST_API}`, ({ status, response }) => {
    if (status) { 
      if(response?.data?.length === 1){
        setDashBoardData(response?.data[0])
      }
    }
    setLoader(false)
})
}


  return(
    <>
     {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid px-md-5 pl-5 pt-0 card-design mt-0">
      <div className="dashboard">
        <div className="row dashboard-cards">
          <div className="col-md-9">
            <div className="row mb-3">
              <div className="col-md-3">
                <div className="card dark-card text-center d-flex justify-content-around">
                  <div className="top-line d-flex justify-content-around p-1 py-3 pb-0">
                    <h5 className="text-dark">{dashBoardData?.resumes_submitted_last_30_days}</h5> <sub className="small text-dark">Last 30 days</sub>
                  </div>
                  <h6 className="text-dark">Resumes Submitted</h6>
                  <div className="bottom-line d-flex justify-content-around p-2 py-3 pb-3 pt-1">
                    <sub className="small pt-3 pos-1 text-dark">Last 7 days</sub> <h5 className="text-dark">{dashBoardData?.resumes_submitted_last_7_days}</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card dark-card text-center d-flex justify-content-around">
                  <div className="top-line d-flex justify-content-around p-1 py-3 pb-0">
                    <h5 className="text-dark">{dashBoardData?.offered_made_last_30_days}</h5> <sub className="small text-dark">Last 30 days</sub>
                  </div>
                  <h6 className="text-dark">Offers Made</h6>
                  <div className="bottom-line d-flex justify-content-around p-2 py-3 pb-3 pt-1">
                    <sub className="small pt-3 pos-1 text-dark">Last 7 days</sub> <h5 className="text-dark">{dashBoardData?.offered_made_last_7_days}</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card dark-card text-center d-flex justify-content-around">
                  <div className="top-line d-flex justify-content-around p-1 py-3 pb-0">
                    <h5 className="text-dark">{dashBoardData?.candidates_placed_last_30_days}</h5> <sub className="small text-dark">Last 30 days</sub>
                  </div>
                  <h6 className="text-dark">Candidates Placed </h6>
                  <div className="bottom-line d-flex justify-content-around p-2 py-3 pb-3 pt-1">
                    <sub className="small pt-3 pos-1 text-dark">Last 7 days</sub> <h5 className="text-dark">{dashBoardData?.candidates_placed_last_7_days}</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card dark-card text-center d-flex justify-content-around">
                  <div className="top-line d-flex justify-content-around p-1 py-3 pb-0">
                    <h5 className="text-dark">{dashBoardData?.offers_declines_last_30_days}</h5> <sub className="small text-dark">Last 30 days</sub>
                  </div>
                  <h6 className="text-dark">Offers Declines</h6>
                  <div className="bottom-line d-flex justify-content-around p-2 py-3 pb-3 pt-1">
                    <sub className="small pt-3 pos-1 text-dark">Last 7 days</sub> <h5 className="text-dark">{dashBoardData?.offers_declines_last_7_days}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row sub-list ">
              <div className="col-md-3">
                <div className="card d-flex justify-content-around my-3 orange">
                 <div className="row g-0">
                   <div className="col-8 d-flex align-items-center">
                  <h6 className="pb-0 mb-0 f-14">Candidates <br />Yet  To Join</h6>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center border-add">
                  <h6 className="num pb-0 mb-0 text-aqua ">{dashBoardData?.candidates_yet_to_join}</h6>
                  </div>
                </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card d-flex justify-content-around my-3 green">
                 <div className="row g-0">
                   <div className="col-8 d-flex align-items-center">
                  <h6 className="pb-0 mb-0 f-14">Interviews  <br /> Today</h6>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center border-add">
                  <h6 className="num pb-0 mb-0 text-aqua ">{dashBoardData?.today_interviews}</h6>
                  </div>
                </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card d-flex justify-content-around my-3 pink">
                 <div className="row g-0">
                   <div className="col-8 d-flex align-items-center">
                  <h6 className="pb-0 mb-0 f-14">Interviews <br /> Tomorrows</h6>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center border-add">
                  <h6 className="num pb-0 mb-0 text-aqua ">{dashBoardData?.tomorrow_interviews}</h6>
                  </div>
                </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card d-flex justify-content-around my-3 violet">
                 <div className="row g-0">
                   <div className="col-8 d-flex align-items-center">
                  <h6 className="pb-0 mb-0 f-14">Perspective Candidates</h6>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center border-add">
                  <h6 className="num pb-0 mb-0 text-aqua ">{dashBoardData?.prospective_candidates}</h6>
                  </div>
                </div>
                </div>
              </div>
            </div>

            <div className="card-design card-boder card-0 table-row-height">
              <div className="card dash-tables">
                <AppBar position="static" >
                  <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Upcoming Interviews" {...a11yProps(0)} />
                    <Tab label="Recent Interviews" {...a11yProps(1)} />
                    <Tab label="Job Orders" {...a11yProps(3)} />
                    <Tab label="Placed / Offered List" {...a11yProps(4)} />
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} >
                  <UpComingInterviews />
                </TabPanel>
                <TabPanel value={value} index={1}  >
                  <RecentInterviews />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <JobOrders />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <OfferedList />
                </TabPanel>


              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card-design card-boder">
              <SideTabs />
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </>
   )
  }


export default Dashboard