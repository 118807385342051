import React, { useState, useEffect } from 'react'
import TextField from "@material-ui/core/TextField";
import CommonModal from '../../Components/Models/commonModal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { GET_JOBORDERS_API_URL, GET_JOB_ASSIGNED_USERS_API_URL, GET_CADNDIDATES_JOB_ORDERS_API_URL } from '../../Configurations/ConfigApi'
import { apiEdit, fetchUrl, apiDelete } from '../../ApiActions/Actions'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { getMonth, getYear, getDate } from "date-fns";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin:"0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const AddJobOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [emails, setEmails] = useState([]);
  const [showBts, setShowbts] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [updateModal, setUpdatemodal] = useState(false);
  const [open,setOpen] = useState(false)
  const [jobOrdersData,setJobOrdersData] = useState({
    job_id: "",
    user_id: "",
    offered_ctc: "",
    encrypted_id:"",
    joiningDate: new Date(),
    update:false
  })

  const handleClose = () => {
    // setUpdatemodal(false);
    setDeleteModal(false)
  };

  const getEmails = (value) => {
      fetchUrl(`${GET_JOBORDERS_API_URL}?q=${value}`, ({ status, response }) => {
        if (status) {
          let jobs = response.data.data
          for (let i = 0; i < jobs.length; i++) {
            jobs[i].title = jobs[i]?.user_type ? `${jobs[i].job_title } (${jobs[i].user_type[0].toUpperCase() + jobs[i].user_type.slice(1)})` : jobs[i].job_title 
          }
          setEmails(jobs)
        } else {
          // setErrorModal(true)
        }
      })
  }

  const getJObrecruiters = (value) => {
    fetchUrl(`${GET_JOB_ASSIGNED_USERS_API_URL}${value}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data
        let usersData = []
        for (let i = 0; i < data.length; i++) {
          let obj = data[i]
          obj.title = data[i].username
          usersData.push(obj)
        }
        setUsers(usersData)
      } else {
        // setErrorModal(true)
      }
    })
  }

  const clearvalues = () => {

    props.deleteCount()
  }

  useEffect(() => {
    if (props.edit) {
      let data = props.data
      // setOfferedctc(data?.offered_ctc)
      // setJodId(data?.job_id)
      // setUsersId(data?.user_id)
      // setJoiningDate(new Date(data?.expected_joining_date))
      setEmails([{ title:  data?.user_type ?  `${data.job_title} (${data.user_type[0].toUpperCase() + data.user_type.slice(1)})` : data.job_title }])
      setJobOrdersData({
        job_id: data?.job_id,
        user_id: data?.user_id,
        offered_ctc: data?.offered_ctc,
        joiningDate: new Date(data?.expected_joining_date), 
        encrypted_id: data?.encrypted_id,
        update:false
      })
      // setEditEncrptId(data?.job_id)
    }

    fetchUrl(
      `${GET_JOB_ASSIGNED_USERS_API_URL}${props.data?.job_id}`,
      ({ status, response }) => {
        if (status) {
          let data = response.data.data;
          let usersData = [];
          for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            if(obj["user_type"] === "recruiter"){
              usersData.push(obj)
            }
          }
          setUsers(usersData);
        } else {
          // setErrorModal(true)
        }
      }
    );

    fetchUrl(`${GET_JOBORDERS_API_URL}?status=active`, ({ status, response }) => {
      if (status) {
        let jobs = response.data.data
        for (let i = 0; i < jobs.length; i++) {
          jobs[i].title = jobs[i]?.user_type ? `${jobs[i].job_title } (${jobs[i].user_type[0].toUpperCase() + jobs[i].user_type.slice(1)})` : jobs[i].job_title 
        }
        setEmails(jobs)
      } else {
        // setErrorModal(true)
      }
    })
    
  }, [])

  useEffect(()=>{
    dispatch({type:"checkUpdateCandidateJOdata",payload:[props.index,jobOrdersData]})
  },[jobOrdersData])


  const deleteDetails = () => {
    apiDelete(`${GET_CADNDIDATES_JOB_ORDERS_API_URL}/${props?.data?.encrypted_id}`, ({ status }) => {
      if (status) {
        setDeleteModal(false)
        // setModalmsg("Job Order deleted successfully")
        // setUpdatemodal(true);
        dispatch({type:"EditCandidateId",payload:""})
        dispatch({type:"EditCandidateId",payload:props.id})
      }
    })
  }


  let defaultProps = {}
  if (props.edit && emails.length > 0) {
    defaultProps = {
      options: emails,
      getOptionLabel: (option) => option.title,
      defaultValue: emails[0]
    };
  } else {
    defaultProps = {
      options: emails,
      getOptionLabel: (option) => option.title,

    };
  }

  return (
    <>
      <div className="row px-4">
        <div className="col-md-6">
          <div className="pb-0 mb-0 ">
            {
              (props.edit && emails.length > 0) ? <Autocomplete
                {...defaultProps}
                onChange={(event, newValue) => {
                 // setJodId(newValue?.encrypted_id);
                  setJobOrdersData({...jobOrdersData,job_id:newValue?.encrypted_id,update:true})
                  getJObrecruiters(newValue?.encrypted_id)
                }}
                renderInput={(params) => <TextField {...params} label="Select Job Order Name" className="w-100 mt-0" defaultValue={"dd"} onChange={(e) => getEmails(e.target.value)} />}
              /> : ""
            }

            {
              (!props.edit || (props.edit && emails.length === 0)) ? <Autocomplete
                {...defaultProps}
                onChange={(event, newValue) => {
                  //setJodId(newValue?.encrypted_id);
                  setJobOrdersData({...jobOrdersData,job_id:newValue?.encrypted_id,update:true})
                  getJObrecruiters(newValue?.encrypted_id)
                }}
                renderInput={(params) => <TextField {...params} label="Select Job Order Name" className="w-100 mt-0" defaultValue={"dd"} onChange={(e) => getEmails(e.target.value)} />}
              /> : ""
            }

          </div>
        </div>

        <div className="col-md-6">
      
        <FormControl className={classes.formControl} fullWidth>
            <InputLabel
              className="w-100"
              id="demo-controlled-open-select-label"
            >
              Select Recruiter
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              className="w-100"
              minRows={10}
              value={jobOrdersData?.user_id}
              onChange={(e) => {
               // setUsersId(e.target.value) 
                setJobOrdersData({...jobOrdersData,user_id:e.target.value,update:true})
              }
              }

            >
              {users.map((role) => {
                return <MenuItem value={role.user_id}>{role.username}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <br />

        </div>

        <div className="col-md-6 calender_date">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              // variant="inline"
            
              className="w-100"
              margin="normal"
              format="dd-MMM-yyyy"
              views={["year", "month",'date']}
              id="date-picker-inline"
              label="Expected Joining Date"
              // minDate={moment().toDate()}
              value={jobOrdersData?.joiningDate}
              error={false}
              onChange={(newdate) => {
              setOpen(false)
                setJobOrdersData({...jobOrdersData,joiningDate:newdate,update:true})
              }}
              TextFieldComponent={(props) => (
                <TextField {...props} onClick={(e) => setOpen(true)} />
              )}
             open={open}
              autoOk={true}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className="col-md-6  pt-3">
          <TextField
            label="Offered CTC *"
            type="text"
            className="w-100"
            value={jobOrdersData?.offered_ctc}
            onChange={(e) =>{
             // setOfferedctc(e.target.value)
              setJobOrdersData({...jobOrdersData,offered_ctc:e.target.value,update:true})
            }}
          />
        </div>

      </div>
      <div className="row px-4">
        <div className="col-md-12 d-flex justify-content-end">

          {showBts && <>
            {/* <button class="btn btn-xs btn-violet text-white mx-2" onClick={Editdetails}>
              {"save"}
            </button> */}
            <button class="btn btn-xs btn-green-dark text-white" onClick={props.edit ? () => { setDeleteModal(true) } : clearvalues}>
              Delete
            </button>
          </>}

        </div>



      </div>

      <CommonModal title={"delete"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
      </CommonModal>

      <CommonModal title={"Success"} handleClose={() => setUpdatemodal(false)} open={updateModal} button={"OK"} btnfunc={() => setUpdatemodal(false)}>
        <span>Candidate Job Orders Details Updated Successfully</span>
      </CommonModal>

    </>
  )
}

export default AddJobOrder
