
import { combineReducers } from "redux"
import PageReduser from '../pages/Redusers/PageReduser'
import Edu_Exp_Reduser from '../pages/Redusers/Edu&ExpReduser'
import Client_Reduser from '../pages/Redusers/ClientRedusers'

const Rootreduser = combineReducers ({
    PageReduser:PageReduser,
    Edu_Exp_Reduser:Edu_Exp_Reduser,
    Client_Reduser:Client_Reduser
})
 



export default Rootreduser