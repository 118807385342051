import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { fetchUrl, apiDelete } from "../../../ApiActions/Actions";
import { GET_CLIENTS_URL } from "../../../Configurations/ConfigApi";
import { useSelector ,useDispatch } from "react-redux";
import Parser from 'html-react-parser';
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";

import Edit from "../../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../../Assets/Images/profile-t-icons/Delete.svg";

import Sidenav from "./sidenav";
import { Active } from "./tabs/active";
import GstDetils from "./tabs/gstDetails";
import InvoiceDetails from './tabs/invoiceDetails'
import PaymentDetails from './tabs/paymentDetails'
import PointOfDetails from './tabs/pointOfContact'
import logoEmblem from "../../../Assets/Images/emblem.svg";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



const Details = (props) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const [clientdata, setclientdata] = useState({});
  const [updateModal,setUpdatemodal] = useState(false);
  const roles_data =  useSelector(state=>state.PageReduser.roles_data);
  const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Clients')
  const [loader,setLoader] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Editclients = () => {  
    props.changetab(2);
    dispatch({type:"EditClientId",payload:update_Encrypted_id});
    dispatch({type:"ViewClientId",payload:""});
}

const Deleteclientclick = () => {  
  setDeleteclientId(update_Encrypted_id);
  setUpdatemodal(true); 
}

  function formatDate(value) {
    if ( value === undefined || value === null || value === '' )
    return '';
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });
    return day + '-' + month + '-' + year;
}
  const [sideTabValue, setSideTabValue] = useState(0);
  const [DeleteclientId,setDeleteclientId] = useState("");
  const [successmodel,setsuccessmodel] = useState(false);
  const [successMsg,setSuccessMsg] = useState("");

  const update_Encrypted_id = useSelector(state=>state.PageReduser.ViewClientId)

  useEffect(() => {  
    dispatch({type:"EditClientId",payload:""});
    setLoader(true)
    fetchUrl(`${GET_CLIENTS_URL}/${update_Encrypted_id}`, ({ status, response }) => {
      if (status) {
        setclientdata(response?.data?.data[0]);
       
      }
      setLoader(false)
    });
  }, []);

  const deleteClient =()=>{    
    setLoader(true)
    apiDelete(`${GET_CLIENTS_URL}/${DeleteclientId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false);
        setSuccessMsg({notes:["Client deleted Successfully"]})
        setsuccessmodel(true);
        setTimeout(() => {
          props.changetab(0); 
        }, 3000);    
      }
      setLoader(false)
  })
  }

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteclientId("");
  };

  return (
    <>
       {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid px-0 bg-custom-light">
      <div className="row g-0 pt-0 profile-section">
        {/* <div className="col-md-1 p-0 m-0">
          <Sidenav category={"CandidateProfile"} />
        </div> */}
        {/* <div className="col-12">
          <div className="divider"></div>
        </div> */}
        <div className="col-md-8 pe-2">
          <div className="card-design">
            <div className="card radius-0 p-1 ps-3 border box-shadow-none">
              <div className="row">
                <div className="col-md-7">
                  <div className="row g-0">
                    <div className="col-md-4 pe-3">
                      <div className="profile-img pe-0 client-img text-center">
                        <img 
                          className="img-fluid radius-profile"
                          // src="https://upload.wikimedia.org/wikipedia/en/thumb/b/b1/NCR_Corporation_logo.svg/1200px-NCR_Corporation_logo.svg.png"
                          src={clientdata?.clientlogo_file_url}
                          alt={clientdata?.website_url}
                        />
                      </div>
                    </div>
                    <div className="col-md-8  d-flex align-self-center flex-column">
                      <ul type="none" className="p-0 m-0 list-style-2">
                        <li className="pl-0">
                          {/* <img src={t4} className="tab-img-h2 align-top me-3"/> */}
                          {/* <span className="font-weight-bold">Client Short Name</span> */}
                          <span className="font-weight-bold">{clientdata?.client_short_name}</span>
                          
                        </li>
                        <li className="pl-0">
                        {clientdata?.client_legal_name}
                        </li>
                        <li className="pl-0">
                        {clientdata?.website_url}
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 d-flex justify-content-end">
                  <div className="row">
                    <div className="col-md-12  px-2 flex-column d-flex justify-content-end align-content-bottom pt-5 mt-3">
                      <ul
                        type="none"
                        className="p-0 m-0 d-flex justify-content-between me-4"
                      >
                        {/* <li>
                          <a>
                          <img src={Download} className="tab-img-h2 align-top  me-2"/> 
                          </a>
                        </li> */}
                        <li>
                        {(filter_role_data.length > 0 && filter_role_data[0]["m_edit"]) ?
                          <a>
                            <img src={Edit} className="tab-img-h2 align-top  me-2"  style={{cursor:'pointer'}} onClick={Editclients} />
                          </a>
                            : null }
                        </li>
                        <li>
                        {(filter_role_data.length > 0 && filter_role_data[0]["m_delete"]) ?
                          <a>
                            <img src={Delete} className="tab-img-h2 align-top  me-2" onClick={Deleteclientclick} style={{cursor:'pointer'}} />
                          </a>
                          : null }
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-dark-gray font-weight-headings">
              <div className="row text-center g-0">
                <div className="col-md-6">Agreement Start Date</div>
                <div className="col-md-6">Agreement End Date</div>
              </div>
            </div>
            <div className="bg-light-gray font-weight-headings">
              <div className="row g-0 text-center">
                <div className="col-md-6">
                  <strong>{formatDate(clientdata?.agreement_start_date)}</strong>
                </div>
                <div className="col-md-6">
                  <strong>{formatDate(clientdata?.agreement_end_date)}</strong>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <AppBar position="static" className="border text-distance">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Client Details" {...a11yProps(0)} />
                  <Tab label="GST Details" {...a11yProps(1)} />
                  <Tab label="Payment Terms" {...a11yProps(2)} />
                  <Tab label="Invoice Process" {...a11yProps(3)} />
                  <Tab label="Point Of Contact" {...a11yProps(4)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} className="border">
                <div className="details-section-2 pb-3">
                  <h6><strong>Additional Information</strong></h6>
                  {clientdata?.additional_information?Parser(clientdata?.additional_information):""}
                </div>
                <div className="details-section-2">
                  <h6><strong>About Client</strong></h6>
                  
                   {clientdata?.about_client?Parser(clientdata?.about_client):""}
                 
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} className="border ">
                <GstDetils/>
              </TabPanel>
              <TabPanel value={value} index={2} className="border">
                <PaymentDetails clientdata={clientdata}/>
              </TabPanel>
              <TabPanel value={value} index={3} className="border">
                {/* <Active/> */}
                <InvoiceDetails/>
              </TabPanel>
              <TabPanel value={value} index={4} className="border">
                <PointOfDetails/>
              </TabPanel>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          {/* <DetailsSidenav /> */}
          <Sidenav />
        </div>
      </div>
      <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteClient}>
        <span>Are you sure you want to delete client ?</span>
      </CommonModal>
      <SnakBar error={successMsg} open={successmodel} onClose={() => setsuccessmodel(false)} type="success"/>
    </div>
    </>
  );
};

export default Details;
