
import React, { useState} from "react";
import { fetchUrl , apiPost } from '../../ApiActions/Actions';
import { DTRESUME_ACTIVE_JOBS_DETAILS , CUSTOM_TABLE_REPORTS_LIST_API } from '../../Configurations/ConfigApi';
import TextField from "@material-ui/core/TextField";
import { TransferTable } from "./customComponents/TransferTable";
import TableList from "./customComponents/TableList";
import { SelectClientInputs } from './selectClientInputs';
import SnakBar from "../../Components/Models/snakbar";
import logoEmblem from "../../Assets/Images/emblem.svg";

export const CustomDetails = (props) => {
  const [selectedclient, setselectedclient] = useState("")
  const [selectedjob, setselectedjob] = useState("")
  const [Jobdetails, setJobdetails] = useState([]);
  const [reportName,setReportName] = useState('')
  const [reports,setReports] = useState([])
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [snakBarErrorOpen,setSnakBarErrorOpen]  = useState(false)
  const [successMsg,setSuccessMsg] = useState({})
  const [errors,setErrors] = useState({})
  const [fieldserror,setFieldErros] = useState(false)
  const [updateList,setUpdateList] = useState(false) 
  const [loader,setLoader] = useState(false)

  const setjobchange = (value) => {
    setselectedjob(value);   
  }

  const setclientchange = (value) => {
    setLoader(true)
    setselectedclient(value);
    fetchUrl(`${DTRESUME_ACTIVE_JOBS_DETAILS}/${value}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data;
        setJobdetails(data)
        setLoader(false)
      }
    })
  }

  const generateReport = ()=>{
    setSnakBarOpen(false)
    setSnakBarErrorOpen(false)
    let formData = new FormData()
    formData.append('client_id',selectedclient)
    formData.append('job_id',selectedjob)
    formData.append('report_name',reportName)
    let data = {
      client_id: selectedclient,
      job_id: selectedjob,
      report_name: reportName,
    }
    reports.forEach((element,index) => {
      data[`columns_array[${index}]`] = element.key
      formData.append(`columns_array[${index}]`,element.key)
    });

    if(selectedclient && selectedjob && reportName){
      apiPost(CUSTOM_TABLE_REPORTS_LIST_API, formData, ({ status, response }) => {
        if (status) {
          if(response.data?.status){
            setSuccessMsg({notes:[response.data?.message]})
            setSnakBarOpen(true)
            setUpdateList(!updateList)
            setselectedclient('')
            setselectedjob('')
            setReportName('')
          }else{
            setLoader(false)
            setSuccessMsg({notes:[response.data?.message]})
            setSnakBarErrorOpen(true)
          }
        }else{
          setLoader(false)
          setSuccessMsg({notes:["download failed"]})
        }
      })
    }else{
      let checkFields = {} 
    !selectedclient && (checkFields.selectedclient = ["Client Is Required."])
    !selectedjob && (checkFields.selectedjob = ["Job Is Required."])
    !reportName && (checkFields.reportName = ["Report Name Is Required."])

    setErrors(checkFields)
    setFieldErros(true)
    }
    
  }
  return (
    <>
     {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
          <div className="container-fluid pr-0 pl-0 px-0 ">
        <div className="row">
          <div className="col-md-12">
            <div className="row px-md-4 pt-3">
            <SelectClientInputs 
              setjobchange={setjobchange} 
              setclientchange={setclientchange}
              selectedclient={selectedclient} 
              selectedjob={selectedjob} 
              Jobdetails={Jobdetails}
              updateList={updateList}
             
              />
              <div className="col-md-4">
                <TextField
                  id="standard-search"
                  label="Save Report As *"
                  type="text"
                  className="w-100"
                  value={reportName}
                  onChange={(e)=>setReportName(e.target.value)}
                />
              </div>

            </div>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-7">
            <div className="row">
              <TransferTable generateReport={generateReport} setReports={setReports} updateList={updateList}/>
            </div>
          </div>
          <div className="col-md-5 table-card">
          <div className="card card-shadow-none mx-3">

                <TableList updateList={updateList}/>
       </div>

          </div>
        </div>
      </div>

      {snakBarOpen && <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} 
        type="success"/>}
      {snakBarErrorOpen &&   <SnakBar error={successMsg} open={snakBarErrorOpen} onClose={() => setSnakBarErrorOpen(false)} /> }
      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
         </>
  );
};
 