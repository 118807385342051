import React,{useState,useEffect} from 'react';
import {apiPost,fetchUrl,apiDelete, apiEdit} from '../../../ApiActions/Actions';
import {
  GET_JOB_MAINSTATUS_API_URL,
  GET_JOB_MAINSUBSTATUS_API_URL,
  GET_CANDIDATE_JOB_ORDERS_STATUS,
  GET_JOB_ASSIGNED_USERS_API_URL,
  GET_JOBORDERS_API_URL} from '../../../Configurations/ConfigApi'
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';

 const JobOrder = (props) => {
    const [jobOrder,setJobOrder] = useState(false)
    const [jobOrdersData,setJobOrdersData] = useState([])
    const [mainStatusData,setMainStatusData] = useState([])
    const [mainStatus,setMainStatus] = useState('')
    const [mainSubStatusData,setMainSubStatusData] = useState([])
    const [jobRecruitersData,setJobRecruitersData] = useState([])
    const [job_id,setJob_id] = useState("")
    const [mainSubstatus,setMainSUbStatus] = useState("")
    const [jobRecruiters,setJobRecruiters] = useState("")
    const [updateModal,setUpdatemodal] = useState(false);
    const [fieldserror,setFieldErros] = useState(false)
    const [errors,setErrors] = useState({})
    const [jobOrdersList,setJobOrdersList] = useState([])
    const update_Encrypted_id = useSelector(state=>state.PageReduser.candidateprofileId)
    const [deleteModal, setDeleteModal] = useState(false)
    const [successMsg,setSuccessMsg] = useState({})
    const [updateStatusModal,setUpdateStatusModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [updateMainStatusId,setUpdateMainStatusId] = useState('')
    const [subStatatusId,setSubStatusId] = useState('')
    const [updateEncriptedId,setUpdateEncriptedId] = useState('')
    const dispatch = useDispatch()


    useEffect(()=>{

      fetchUrl(`${GET_JOBORDERS_API_URL}?status=active`, ({ status, response }) => {
          if (status) {
          let  data = response.data?.data
           setJobOrdersData(data)
          }else{
              let msg = JSON.parse(response.request.response).errors
              setErrors(msg)
              setFieldErros(true)
          }
      })

      fetchUrl(`${GET_JOB_MAINSTATUS_API_URL}`, ({ status, response }) => {
        if (status) {
        
         setMainStatusData(response.data?.data)
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })

    get_JobOrder_status()
  },[])

  const get_JobOrder_status = () =>{
    fetchUrl(`${GET_CANDIDATE_JOB_ORDERS_STATUS}?candidate_id=${update_Encrypted_id}`, ({ status, response }) => {
      if (status) {
        let  data = response.data?.data
       setJobOrdersList(data.reverse())
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  }

  const getsubStatus=(value)=>{
    setMainStatus(value)
    fetchUrl(`${GET_JOB_MAINSUBSTATUS_API_URL}?main_status_id=${value}`, ({ status, response }) => {
      if (status) {

       setMainSubStatusData(response.data?.data)
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  }

  const getRecruiters=(value)=>{
    setJob_id(value)
    fetchUrl(`${GET_JOB_ASSIGNED_USERS_API_URL}${value}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data;
        let usersData = [];
        for (let i = 0; i < data.length; i++) {
          let obj = data[i];
          if(obj["user_type"] === "recruiter"){
            usersData.push(obj)
          }
        }
      //  setMainSubStatusData(response.data?.data)
      setJobRecruitersData(usersData)
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  }

  const addJobOrders = ()=>{
    let jobOrdersData={
      candidate_id:update_Encrypted_id,
      job_id: job_id,
      recruiter_id:jobRecruiters,
      mainstatus_id:mainStatus,
      mainsubstatus_id:mainSubstatus,
    }
    apiPost(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
      if (status) {
      
        if(response?.data?.status){
          setSuccessMsg({notes:["Added Successfully"]})
          setUpdatemodal(true)
          setJobOrder(false)
          get_JobOrder_status()
        }else{
          setErrors({notes:[response?.data?.message]})
          setFieldErros(true)
        }
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  }

  
  const deleteJobOrderStatus = () => {
    apiDelete(`${GET_CANDIDATE_JOB_ORDERS_STATUS}/${deleteId}`, ({ status, response }) => {
      if (status) {
        setSuccessMsg({notes:["Deleted Successfully"]})
        setUpdatemodal(true)
        setDeleteModal(false)
        get_JobOrder_status()
      } else {
        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
      }
    })
  }  

  const updateMainStatus = (data) => {
    setUpdateStatusModal(true)
    getsubStatus(data?.main_status_id)
    setUpdateMainStatusId(data?.main_status_id)
    setSubStatusId(data?.main_substatus_id)
    setUpdateEncriptedId(data?.encrypted_id)
  }

  const updateCandidateJobOrderStatus = () => {
    let jobOrdersData={
      encrypted_id: updateEncriptedId,
      mainstatus_id:updateMainStatusId,
      mainsubstatus_id:subStatatusId,
    }
    if(updateMainStatusId && subStatatusId){
      apiEdit(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
        if (status) {
       
          get_JobOrder_status()
          setUpdateStatusModal(false)
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })
    }
   
  }

  return(
    <div className="job-order-section">
        <div className="row">
            <div className="col-md-8">
              <h6 className="text-underline pb-3"> Job Orders</h6>
            </div>
            <div className="col-md-4 px-4">
              <button class="btn btn-xs btn-green-dark text-white btn-violet f-13" onClick={()=>setJobOrder(!jobOrder)}>
              {jobOrder ? "Cancel" : "Add"} 
              </button>
            </div>
          </div>

          {jobOrder ? 
          <div className={`row no-gutters card-design mb-3 ${jobOrder ? "tabActice":""}`}>
            <div className="col-md-12">
              <div className="card box-shadow-none">
                <div className="card-header pb-0 text-left ">
                  <h5 className="text-center f-13">Job Order </h5>
                </div>
                <div className="card-body pt-1 mt-1">
                  <div className="row">
                    <div className="col-12 mb-3 ">
                      <select name="" id=""  class="form-control pb-1"
                        onChange={(e)=>getRecruiters(e.target.value)}>
                        <option value="">-- Select Job Order --</option>
                        {jobOrdersData.map((res)=>{
                          return <option value={res.encrypted_id}> {res.job_title} {res?.user_type && `(${res.user_type[0].toUpperCase() + res.user_type.slice(1)})`}</option>
                        })}
                      </select>
                      {/* <input
                        type="text"
                        class="form-control pb-1"
                        placeholder="-- Select Job Order --"
                      /> */}
                    </div>
                    <div className="col-12 mb-3 ">
                      <select name="" id=""  class="form-control pb-1"
                          onChange={(e)=>getsubStatus(e.target.value)}>
                          <option value="">-- Select Main Status  --</option>
                          {mainStatusData.map((res)=>{
                            return <option value={res.fixed_id}>{res.name}</option>
                          })}
                        </select>
                    </div>
                    <div className="col-12 mb-3 ">
                    <select name="" id=""  class="form-control pb-1"
                          onChange={(e)=>setMainSUbStatus(e.target.value)}>
                          <option value="">-- Select Sub Status --</option>
                          {mainSubStatusData.map((res)=>{
                            return <option value={res.fixed_id}>{res.name}</option>
                          })}
                        </select>
                    </div>
                    <div className="col-12 mb-3 ">
                      {/* <input
                        type="text"
                        class="form-control pb-1"
                        placeholder="-- Select Recruiter --"
                      /> */}
                        <select name="" id=""  class="form-control pb-1"
                          onChange={(e)=>setJobRecruiters(e.target.value)}>
                          <option value="">-- Select Recruiter --</option>
                          {jobRecruitersData.map((res)=>{
                            return <option value={res.user_id}>{res.username}</option>
                          })}
                        </select>
                    </div>

                    <div className="col-12 text-center py-0 my-0 pb-0 mb-0">
                      <button class="btn btn-xs btn-orange text-white pe-4 px-4 mb-0 mx-4" onClick={addJobOrders}>
                        Add
                      </button>
                      {/* <button className="btn btn-xs btn-green-dark text-white ">
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           : ""} 
       
         
          {/* Add Section */}
           <hr className="p-0 m-0 mb-3" />
           {jobOrdersList.map((data)=>{
             return <>
              <div>
            <div className="row">
              {/* <div className="col-md-6">
                <p className="f-13 pb-0 mb-0"></p>
              </div> */}
            
              <div className="col-md-10">
            <p className="f-13 mb-1">
              <a className="text-underline" onClick={()=>{
                dispatch({type:"Viewjoborder",payload:data?.job_id})
                sessionStorage.setItem('redirectToJobOrder',true)
                 props.history.push('/joborders')
              }}>
              {data?.job_title} 
               </a>
            </p>
            <p className="f-13 mb-1">
              <a className="text-underline" onClick={()=>updateMainStatus(data)}>{data?.main_status}</a>
            </p>
            <p className="f-13 mb-1">{data?.main_substatus}</p>
            <p className="f-13 mb-1">{data?.username}</p>
            </div>
            <div className="col-md-2  justify-content-end d-flex pe-3">
                <h6 className="f-13">
                  <i class="far fa-trash-alt" onClick={() => {
                      setDeleteId(data?.encrypted_id)
                      setDeleteModal(true)
                    }}></i>
                </h6>
              </div>
            </div>
          </div>
          <hr />
             </>
           })}
        
{/* 
          <CommonModal title={"success"} handleClose={()=>setUpdatemodal(false)} open={updateModal} button={"OK"} btnfunc={()=>setUpdatemodal(false)}>
                 <span>Job Order Added successfully</span>
          </CommonModal> */}

      <CommonModal title={"Delete"} handleClose={() => setDeleteModal(false)} open={deleteModal} button={"DELETE"} btnfunc={deleteJobOrderStatus}>
        <span>Are you sure you want to delete Job Order ?</span>
      </CommonModal>

      <CommonModal title={"Change Status"} handleClose={() => setUpdateStatusModal(false)} open={updateStatusModal} button={"UPDATE"} btnfunc={()=> updateCandidateJobOrderStatus()}>
        <div style={{width: "200px"}}>
          <select name="" id=""  class="form-control pb-1 my-3"
            onChange={(e)=>{
              getsubStatus(e.target.value)
              setUpdateMainStatusId(e.target.value)
              setSubStatusId('')
            }} value={updateMainStatusId}>
            <option value="">-- Select Main Status  --</option>
            {mainStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>

          <select name="" id=""  class="form-control pb-1"
            onChange={(e)=>{
              setSubStatusId(e.target.value)
              }} value={subStatatusId}>
            <option value="">-- Select Sub Status --</option>
            {mainSubStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>
          </div>
      </CommonModal>

          <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
          <SnakBar error={successMsg} open={updateModal} onClose={() => setUpdatemodal(false)} type="success"/>
        </div>
     
   )
  }
  export default withRouter(JobOrder)