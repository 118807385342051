import React, { useState, useEffect,useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CADNDIDATES_API_URL, DELETE_CANDIDATE_API_URL, DATATABLE_CLIENTS_URL
  , DTRESUME_ACTIVE_JOBS_DETAILS, DTINTERVIEW_JOBS_DATATABLE,DTRESUME_JOB_CANDIDATES_DETAILS,
  CLIENTS_ACTIVE_JOBS_INTERVIEW_DETAILS
} from '../../Configurations/ConfigApi';
import { fetchUrl, apiDelete, apiPost } from '../../ApiActions/Actions'
import Parser from 'html-react-parser';
import {
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import { AutocompleteCandidates } from "../../Components/AutoCompletes/AutocompleteCandidates";
import logoEmblem from "../../Assets/Images/emblem.svg";
import SnakBar from "../../Components/Models/snakbar";
import { withRouter } from 'react-router';
import CommonModal from '../../Components/Models/commonModal';

import view from "../../Assets/Images/profile-t-icons/view.svg";
import Edit from "../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../Assets/Images/profile-t-icons/Delete.svg";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Datatable } from './InterviewDetails/DataTable';
import { SelectClientInputs } from './selectClientInputs';

const getRowId = (row) => {
  return row.id;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin: "0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const InterviewDetails = (props) => {
  const classes = useStyles();
  const roles_data = useSelector(state => state.PageReduser.roles_data);
  const filter_role_data = roles_data.filter((res) => res?.module_name === 'Candidates')
  const dispatch = useDispatch()
  const [columns] = useState([
    { name: "name", title: "Candidate" },
    { name: "InterviewDetails", title: "Interview Details" }
   ]);

  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' }, { columnName: 'name', direction: 'asc' }]);

  const [currentPage, setCurrentPage] = useState(0);
   
  const [successMsg, setSuccessMsg] = useState({})
  const [snakBarOpen, setSnakBarOpen] = useState(false)
  const [usersData, setUsersData] = useState([])
  const [usersCount, setUsersCount] = useState(0)
  const [searchValue, setSearchState] = useState('');
  const [updateModal, setUpdatemodal] = useState(false);
  const [loader,setLoader] = useState(false)
  const setJobOrderFields = (e) => {

  }


  const [deleteUserId, setDeleteUserId] = useState("");
  const [clientdetails, setclientdetails] = useState([]);
  const [selectedclient, setselectedclient] = useState("")
  const [Jobdetails, setJobdetails] = useState([]);
  const [selectedjob, setselectedjob] = useState("")
  const [candidateslist, setcandidateslist] = useState([]);
  const [selectedcandidates, setselectedcandidates] = useState([])
  const [Isjobchange, setIsjobchange] = useState(false)
  const textRef = useRef(null);
  const tableRef = useRef(null);
  

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteUserId("");
  };



  useEffect(() => {
    getUserlist()
  }, [currentPage, sorting, searchValue])

  const getUserlist = () => {    
  }


  const StatusFormatter = ({ value, row }) => (
    <>
      <div className='justify-content-center text-center'>
        <a className="link text-link cursor-pointer px-1" onClick={() => {
          sessionStorage.setItem("profileId", row.encrypted_id)
          dispatch({ type: "candidateprofileId", payload: row.encrypted_id })

          props.changetab((filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2 : 1)
        }
        }>
          {/* <VisibilityIcon fontSize="small" className="text-dark me-2"/> */}
          <img src={view} className="icon-img me-4" />
        </a>

        {(filter_role_data.length > 0 && filter_role_data[0]["m_edit"]) ?
          <a className="link text-link cursor-pointer pe-1" onClick={() => {
            dispatch({ type: "EditCandidateId", payload: row.encrypted_id })
            props.changetab(2)
          }
          }>
            <img src={Edit} className="icon-img me-4" />
          </a> : null
        }

        {(filter_role_data.length > 0 && filter_role_data[0]["m_delete"]) ?
          <a className="link text-link cursor-pointer me-2" onClick={() => {
            setUpdatemodal(true);
            setDeleteUserId(row.encrypted_id)
          }
          }>

            <img src={Delete} className="icon-img " />
          </a> : null
        }
      </div>
    </>
  );
  const candidateFormatter = ({ value, row }) => {

    return <>
      <h5 className='font-weight-bold'>{value}</h5>
      <h6>{row?.primary_mobile}</h6>
      <h6><a className='a-link' href="mailto:{row?.primary_email}">{row?.primary_email}</a></h6>
      <h6><strong>Created On:</strong> {row?.created_at?.slice(0, 11)}</h6>
    </>
  }

  const aboutFormatter = ({ value, row }) => {
    return <>
      <spa>{Parser(row?.about_candidate)}</spa>
    </>
  }

  const professionalFormatter = ({ value, row }) => {
    return <>
      <h6><strong>Company:</strong>{value}</h6>
      <h6><strong>Location:</strong> {row?.city_name}</h6>
      <h6><strong>Experience:</strong> {`${row?.exp_years} yrs ${row?.exp_months} Months`}</h6>
      <h6><strong>Notice Period:</strong> {row?.notice_period}</h6>
    </>
  }

  const compensationFormatter = ({ value, row }) => {
    return <>
      <h6><strong>Current CTC:</strong> {value}</h6>
      <h6><strong>Expected CTC:</strong> {row?.expected_ctc}</h6>
      <h6><strong>Stocks:</strong> {row?.stocks}</h6>
    </>
  }


  useEffect(() => {
    setLoader(true)
    fetchUrl(`${DATATABLE_CLIENTS_URL}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data         
        setclientdetails(data)
        setLoader(false)
      }
    })
  }, []);

  const setclientchange = (value) => {
    setLoader(true)
    setselectedclient(value);
    fetchUrl(`${DTRESUME_ACTIVE_JOBS_DETAILS}/${value}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data;
        setJobdetails(data)
        setLoader(false)
      }
    })
  }

  const setjobchange = (value) => {
    setLoader(true)
    setselectedjob(value);   
    setselectedcandidates([]);
    setIsjobchange(true);
    fetchUrl(`${CLIENTS_ACTIVE_JOBS_INTERVIEW_DETAILS}/${value}`, ({ status, response }) => {
      if (status) {         
        let data = response.data.data;   
        setcandidateslist(data)
        setIsjobchange(false);
        setLoader(false)
      }
    })
  }

  const GenerateClick = () => {
    let data = new FormData()
    data.append('client_id', selectedclient)
    data.append('job_id', selectedjob)
    for (let i = 0; i < selectedcandidates.length; i++) {     
      data.append(`candidate_id[${i}]`, selectedcandidates[i].candidate_id)     
    }
    
    apiPost(DTINTERVIEW_JOBS_DATATABLE, data, ({ status, response }) => {
      console.clear();
      if (status) {
        let { data, all_data_cnt, filter_data_cnt } = response.data
        filter_data_cnt === all_data_cnt ? setUsersCount(all_data_cnt) : setUsersCount(filter_data_cnt)
        setUsersData(response.data.data)
      }
    })

  }

  const deleteCandidate = () => {
    apiDelete(`${DELETE_CANDIDATE_API_URL}${deleteUserId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false)
        setSuccessMsg({ notes: ["Deleted Successfully"] })
        setSnakBarOpen(true)
        getUserlist()
      }
    })
  }

  return (
    <>
       {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
   
    <div className="container-fluid pr-0 pl-0 px-0 ">
      <textarea
        ref={textRef}
        style={{ opacity: 0, position: "absolute", top: "-200px" }}
      ></textarea>
      <div className="row mb-5">

        <div className="col-md-12">
          <div className="row px-md-4 pt-3">
            <SelectClientInputs 
            setjobchange={setjobchange} 
            setclientchange={setclientchange}
            selectedclient={selectedclient} 
            selectedjob={selectedjob} 
            Jobdetails={Jobdetails}/>
            <div className="col-md-4 mb-2">
              <FormControl className={classes.formControl} fullWidth>                 
                <AutocompleteCandidates 
                          candidateslist={candidateslist} 
                          label={"Select Upcoming Interviews"}                           
                          setselectedcandidates={setselectedcandidates}
                          Isjobchange={Isjobchange}
                          selectedcandidates={selectedcandidates}
                          />                 
                {/* <small>(Only Sourcing & Screening Status Candidates)</small> */}
              </FormControl>
            </div>
            <div className="col-md-1 d-flex align-self-center justify-content-center">
              <button class="btn btn-xs btn-violet text-white" onClick={GenerateClick}>Generate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row  data-tables">
        <div className="col-md-12 table-card"> 
          <Datatable usersData={usersData.length > 0 ? usersData : [] } />        
        </div>
      </div>
      
      <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteCandidate}>
        <span>Are you sure you want to delete candidate ?</span>
      </CommonModal>
      <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success" />
    </div>
    </>
  )
}


export default withRouter(InterviewDetails)