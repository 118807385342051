import React, { useEffect, useState } from 'react'
import Sidenav from '../../Components/Sidenav/Sidenav'
import { fetchUrl, apiPost } from '../../ApiActions/Actions'
import { GET_ROLES_API_URL, ROLES_PERMISSION_API_URL, GET_MODULES_API_URL } from '../../Configurations/ConfigApi'
import Checkbox from '@material-ui/core/Checkbox';
import DeleteActiveUser from '../../Components/Models/deleteActiveuser';
import CommonModal from '../../Components/Models/commonModal';

const ManageRoles = (props) => {
    const [roles, setRoles] = useState([])
    const [modules, setModules] = useState([])
    const [rolename, setRolename] = useState("")
    const [roleid, setRoleid] = useState("")
    const [addrolemodal, setAddroleModal] = useState(false)
    const [updateModal, setUpdatemodal] = useState(false)
    const [modulePermissions, setModulePermissions] = useState([])
    const [role, setRole] = useState("")
    let permissions = []


    useEffect(() => {
        getRoles()
        getModules()
    }, [])

    const getRoles = () => {
        fetchUrl(GET_ROLES_API_URL, ({ status, response }) => {
            if (status) {
                setRoles(response.data)
            }
        })
    }

    const getModules = () => {
        fetchUrl(GET_MODULES_API_URL, ({ status, response }) => {
            if (status) {

                setModules(response.data)
            }
        })
    }

    const getModulePermissions = (id, role) => {
        fetchUrl(ROLES_PERMISSION_API_URL, ({ status, response }) => {
            if (status) {
                if (response.data.length > 0) {

                    let moduledata = response.data.filter(data => data.role_id === id)
                 
                    let data = []
                    for (let i = 0; i < modules.length; i++) {

                        if (moduledata[i]) {
                           
                        }
                    }

                    setRolename(role)
                    setRoleid(id)
                    setModulePermissions([])
                    setModulePermissions(moduledata)
                }
            }
        })
    }


    const changePermissions = (e, key, res) => {
   
        let obj = res
        delete obj["module_name"]
        delete obj["role_name"]
        obj[key] = e.target.checked ? 1 : 0
        let index = false
        if (permissions.length > 0) {
            for (let i = 0; i < permissions.length; i++) {
                if (permissions[i]["module_id"] === obj["module_id"]) {
                    index = i
                    break
                } else {
                    index = -1
                }
            }
            if (index >= 0) {
                permissions.splice(index, 1, obj)
            } else if (index < 0) {
                permissions.push(obj)
            }

        } else {
            permissions.push(obj)
        }
    }

    const changeEmptyModulePermissions = (e, key, res) => {
       
        if (permissions.length > 0) {
            let index = false
            for (let i = 0; i < permissions.length; i++) {
                if (permissions[i]["module_id"] === res) {
                    index = i
                    break
                } else {
                    index = -1
                }
            }
            if (index >= 0) {
                let obj = permissions[index]
                obj[key] = e.target.checked ? 1 : 0
                permissions.splice(index, 1, obj)
            } else if (index < 0) {
                let obj = {
                    "role_id": roleid,
                    "module_id": res,
                    "m_add": 0,
                    "m_edit": 0,
                    "m_delete": 0,
                    "m_view": 0
                }
                obj[key] = e.target.checked ? 1 : 0
                permissions.push(obj)
            }
        } else {
            let obj = {
                "role_id": roleid,
                "module_id": res,
                "m_add": 0,
                "m_edit": 0,
                "m_delete": 0,
                "m_view": 0
            }
            obj[key] = e.target.checked ? 1 : 0
            permissions.push(obj)
        }
    }

    const savePermission = () => {

        if (modulePermissions.length === 0) {
            for (let m = 0; m < modules.length; m++) {
                let index = false
                for (let i = 0; i < permissions.length; i++) {
                    if (modules[m]["module_id"] === permissions[i]["module_id"]) {
                        index = true
                    }
                }
                if (!index) {
                    let obj = {
                        "role_id": roleid,
                        "module_id": modules[m]["module_id"],
                        "m_add": 0,
                        "m_edit": 0,
                        "m_delete": 0,
                        "m_view": 0
                    }
                    permissions.unshift(obj)
                }
            }
        }

        // let data = [{m_add: 1,
        //     m_delete: 1,
        //     m_edit: 1,
        //     m_view: 0,
        //     module_id: 105,
        //     permission_id: 1,
        //     role_id: "admin"},{m_add: 1,
        //         m_delete: 1,
        //         m_edit: 1,
        //         m_view: 0,
        //         module_id: 106,
        //         permission_id: 1,
        //         role_id: "admin"}]

        apiPost(ROLES_PERMISSION_API_URL, permissions, ({ status, response }) => {
            if (status) {
                setUpdatemodal(true)
            }
        })
    }

    const handleClose = () => {
        setAddroleModal(false)
        setUpdatemodal(false)
    }


    const addRole = () => {
        let data = {
            name: role
        }
        if (role) {
            apiPost(GET_ROLES_API_URL, data, ({ status, response }) => {
                if (status) {
                    setRole("")
                    handleClose()
                    getRoles()
                }
            })
        }

    }

    return (
        <>
         <div className="container">
            <div className="row ">
                {/* <div className="col-md-1 p-0 m-0">
                    <Sidenav category={"Roles"} />
                </div> */}
                <div className="col-md-12 pt-3 table-card mb-5">
                    {!rolename ? <>
                    <div className="row mb-2">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn btn-xs pt-2 pb-2 btn-orange text-white pe-5 px-5 mb-0 " onClick={() => setAddroleModal(true)}>
                            Add Role
                        </button>
                        </div>
                        </div>
                        <CommonModal title={"Add New Role"} handleClose={handleClose} open={addrolemodal} button={"Add"} btnfunc={addRole}>
                            <input type="text" value={role} className="form-control" onChange={(e) => setRole(e.target.value)} />
                        </CommonModal>
                        <div className="card card-shadow-none table-custom-3">
                        <table className="MuiTable-root">
                            <thead className="MuiTableHead-root">
                                <tr className="MuiTableRow-root MuiTableRow-head text-white">
                                    <th className="px-3" scope="col">#</th>
                                    <th className="px-3"  scope="col">Roles</th>
                                    <th className="px-3"  scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="MuiTableBody-root">
                                {roles && roles.map((res, index) => {
                                    return <tr key={res.role_id} className="MuiTableRow-root MuiTableRow-head">
                                        <th  className="px-3"  scope="row">{index + 1}</th>
                                        <td className="px-2" >{res.role_name}</td>
                                        <td className="px-2" >
                                            <button className="btn btn-xs btn-green-dark text-white " onClick={() => getModulePermissions(res.role_id, res.role_name)}>
                                                get permission
                                            </button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table> 
                        </div>   </>
                        : ""}


                    {rolename ?
                        <>
                            <button className="rolebtn btn mb-4" onClick={() => setRolename("")} style={{color:"#fff"}}>
                                Get Roles
                            </button>

                            <h6>{rolename}</h6>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Modules</th>
                                        <th scope="col">Add</th>
                                        <th scope="col">Delete</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">View</th>
                                        {/* <th scope="col">Select All</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {modules && modules.map((res, index) => {
                                        return <tr key={res.module_name + index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{res.module_name}</td>
                                            <td>
                                                {
                                                    (modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={(modulePermissions[index] && modulePermissions[index]["m_add"] === 1) ? true : false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changePermissions(e, "m_add", modulePermissions[index])}
                                                    /> : null
                                                }
                                                {
                                                    (!modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changeEmptyModulePermissions(e, "m_add", res.module_id)}
                                                    /> : null
                                                }

                                            </td>
                                            <td>
                                                {
                                                    (modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={(modulePermissions[index] && modulePermissions[index]["m_delete"] === 1) ? true : false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changePermissions(e, "m_delete", modulePermissions[index])}
                                                    /> : null
                                                }

                                                {
                                                    (!modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changeEmptyModulePermissions(e, "m_delete", res.module_id)}
                                                    /> : null
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={(modulePermissions[index]["m_edit"] && modulePermissions[index]["m_edit"] === 1) ? true : false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changePermissions(e, "m_edit", modulePermissions[index])}
                                                    /> : null
                                                }

                                                {
                                                    (!modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changeEmptyModulePermissions(e, "m_edit", res.module_id)}
                                                    /> : null
                                                }

                                            </td>
                                            <td>
                                                {
                                                    (modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={(modulePermissions[index]["m_view"] && modulePermissions[index]["m_view"] === 1) ? true : false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changePermissions(e, "m_view", modulePermissions[index])}
                                                    /> : null
                                                }

                                                {
                                                    (!modulePermissions[index]) ? <Checkbox
                                                        defaultChecked={false}
                                                        color="default"
                                                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                                                        onChange={(e) => changeEmptyModulePermissions(e, "m_view", res.module_id)}
                                                    /> : null
                                                }
                                            </td>

                                            {/* <td>
                                        <Checkbox
                                            color="default"
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            onChange={e=>saveAllpermissions(e,index,modules)}
                                        />
                                    </td> */}
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr className="">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <button className="rolebtn btn" style={{color:"#fff"}} onClick={() => savePermission()}>
                                                save
                                            </button>
                                        </td>
                                        <td>
                                            <button className="rolebtn btn" style={{color:"#fff"}} onClick={() => setRolename(false)}>
                                                cancel
                                            </button>
                                        </td>

                                    </tr>
                                </tfoot>
                            </table>
                        </>
                        : ""}
                    <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"OK"} btnfunc={handleClose}>
                        <span>Updated Successfully</span>
                    </CommonModal>
                </div>
            </div>
            </div>
        </>
    )
}


export default ManageRoles