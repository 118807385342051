import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Tasks = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return(
    <div className={classes.root}>
     
     <div className="">
     <div className="d-flex img-profile mb-3">
       <img src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80" alt="" />
       
<div className="align-middle d-flex align-items-center">Jhon Doe</div>


     </div>
     <ul type='none' className="border-0 p-0 m-0 mb-0 pb-0  task-list-style">
       
             <li><i class="fas fa-sun"></i> My Day</li>
             <li><i class="far fa-star"></i> Important</li>
             <li><i class="far fa-clipboard"></i> Planned</li>
             <li><i class="far fa-user"></i> Assigned to you</li>
             <li><i class="fas fa-tasks"></i> Tasks</li>
           </ul>
     </div>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Household</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <ul type='none' className="border-left p-4 m-4 mb-0 pb-0 mt-2 pt-2 task-list-style">
             <li><i class="fas fa-list"></i> Family Chores <i class="fas fa-user-friends"></i></li>
             <li><i class="fas fa-list"></i> Grocery Shopping <i class="fas fa-user-friends"></i></li>
             <li><i class="fas fa-list"></i> Non-Food Shopping<i class="fas fa-user-friends"></i></li>
           </ul>
           <ul type='none' className="border-left p-2 m-2 mb-0 pb-0 mt-2 pt-2 task-list-style border-0">
             <li><i class="fas fa-list"></i> Collaborative Book Project</li>
             <li><i class="fas fa-list"></i> Podcast Team To-Dos</li>
           </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Personal</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ul type='none' className="border-left p-4 m-4 mt-2 pt-2 mb-0 pb-0 task-list-style">
             <li><i class="fas fa-list"></i> Family Chores <i class="fas fa-user-friends"></i></li>
             <li><i class="fas fa-list"></i> Grocery Shopping <i class="fas fa-user-friends"></i></li>
             <li><i class="fas fa-list"></i> Non-Food Shopping<i class="fas fa-user-friends"></i></li>
           </ul>
           <ul type='none' className="border-left p-2 m-2 mt-2 pt-2 task-list-style border-0">
             <li><i class="fas fa-list"></i> Collaborative Book Project</li>
             <li><i class="fas fa-list"></i> Podcast Team To-Dos</li>
           </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
   )
  }

export default Tasks;
