import React,{useState,useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import {GET_CLIENT_POINT_CONTACT_DETAILS_STATUS } from '../../../Configurations/ConfigApi';
import { apiPost,apiDelete ,apiEdit} from '../../../ApiActions/Actions'
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import { useDispatch } from "react-redux";

 const PointOfContact = (props) => {

  const [updateModal,setUpdatemodal] = useState(false);
  const [showBts,setShowbts] = useState(true);
  const [fieldserror,setFieldErros] = useState(false)
  const [errors,setErrors] = useState({})
  const dispatch = useDispatch()

  const [pointOfContact,setPointOfContact] = useState({
    client_id: "",
    client_contact_name:"",
    client_contact_email:"",
    client_contact_phone:"",
    client_contact_alternate_phone: "",
    encrypted_id:'',
  });
  
const [editEncrptId,setEditEncrptId] = useState("")
const [deleteModal,setDeleteModal] = useState(false); 

const handleClose = () => {
  setUpdatemodal(false);
  setDeleteModal(false)
};

const clearvalues = ()=>{
  props.deleteCount()
  dispatch({type:"DeleteClientPointOfContact",payload:props.index})
}

useEffect(()=>{
if(props.edit){
    let data = props.data
    setPointOfContact({
      client_contact_name: data.client_contact_name,
      client_contact_email: data.client_contact_email,
      client_contact_phone: data.client_contact_phone,
      client_contact_alternate_phone: data.client_contact_alternate_phone,
      encrypted_id: data.encrypted_id, 
    })
    setEditEncrptId(data.encrypted_id)
}else{
  dispatch({ type: "AddClientPointOfContact"});
}
},[])

useEffect(()=>{
  if(props.edit){
    dispatch({
      type: "checkUpdateClientPOC",
      payload: [props.index, pointOfContact],
    });
  }else{
    dispatch({
      type: "UpdateClientPointOfContact",
      payload: [props.index, pointOfContact],
    });
  }
},[pointOfContact])

const deleteDetails = () =>{
apiDelete(`${GET_CLIENT_POINT_CONTACT_DETAILS_STATUS}/${editEncrptId}`, ({ status, response }) => {
  if (status) {
    setDeleteModal(false)
    // setModalmsg("Point of contact deleted successfully")
    // setUpdatemodal(true);
    // dispatch({type:"EditClientId",payload:""})
    // dispatch({type:"EditClientId",payload:props.id})
    dispatch({type:"DeleteUpdatedClientPOC",payload:props.index})
  }
})
}



  return( 
  <>
        <div className="row px-4">
          <div className="col-md-6">
            <table className="table table-bordered">
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Contact Name"
                    type="text"
                    className="w-100"
                    value={pointOfContact.client_contact_name}
                    onChange={(e)=>setPointOfContact({...pointOfContact,client_contact_name:e.target.value,update:true})}
                  />
                </td>
              </tr>
            
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Contact Email ID"
                    type="text"
                    className="w-100"
                    value={pointOfContact.client_contact_email}
                    onChange={(e)=>setPointOfContact({...pointOfContact,client_contact_email:e.target.value,update:true})}
                  />
                </td>
              </tr>             
             
            </table>
          </div>

          <div className="col-md-6">
          <table className="table table-bordered">
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Contact Number"
                    type="text"
                    className="w-100"
                    value={pointOfContact.client_contact_phone}
                    onChange={(e)=>setPointOfContact({...pointOfContact,client_contact_phone:e.target.value,update:true})}
                  />
                </td>
              </tr> 

              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Client Contact Alternate Phone No"
                    type="text"
                    className="w-100"
                    value={pointOfContact.client_contact_alternate_phone}
                    onChange={(e)=>setPointOfContact({...pointOfContact,client_contact_alternate_phone:e.target.value,update:true})}
                  />
                </td>
              </tr>

            </table>
          </div>

          
        </div>
        <div class="row px-4">
      <div class="col-md-12 d-flex justify-content-end">
      {/* <button class="btn btn-xs btn-violet text-white mx-2" onClick={props.edit ?Editdetails : addPointOfContact}>
      {props.edit ?"Save":"Add"} 
        </button> */}
        <button class="btn btn-xs btn-green-dark text-white" onClick={
           props.edit ? ()=>{setDeleteModal(true)} : clearvalues
        }>Delete</button>
      </div>
    </div>

    <CommonModal title={"success"} handleClose={handleClose} open={updateModal} button={"ok"} btnfunc={handleClose}>
        Point Of Contact Added Successfully
      </CommonModal>

      <CommonModal title={"delete"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
    </CommonModal>

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>


     </>
   )
  }

  export default PointOfContact
