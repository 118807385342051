import React,{useState,useEffect} from 'react'
import { useDispatch , useSelector} from "react-redux";
import {GET_CLIENTS_URL } from '../../Configurations/ConfigApi'
import { fetchUrl, apiDelete } from '../../ApiActions/Actions'
import {
  GroupingState,
  SortingState,
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  VirtualTable,
  Table,
  TableHeaderRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import SnakBar from "../../Components/Models/snakbar";
import { withRouter } from 'react-router';
import CommonModal from '../../Components/Models/commonModal';

import view from "../../Assets/Images/profile-t-icons/view.svg";
import Edit from "../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../Assets/Images/profile-t-icons/Delete.svg";
import logoEmblem from "../../Assets/Images/emblem.svg";

const getRowId = (row) => {
  return row.id;
};




const ClientList = (props) => {

  const dispatch = useDispatch()
  const [columns] = useState([
    { name: "client_short_name", title: " Name" },
    // { name: "status", title: "Status" },
    { name: "actions", title:"Actions"}
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' },{ columnName: 'client_legal_name', direction: 'asc' }]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(7);
  const [actionsColumns] = useState(["actions"]);
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [loader,setLoader] = useState(false)
  const [usersData,setUsersData] = useState([])
  const [usersCount,setUsersCount] = useState(0)
  const roles_data =  useSelector(state=>state.PageReduser.roles_data);  
  const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Clients')
  const [searchValue, setSearchState] = useState('');
  const [updateModal,setUpdatemodal] = useState(false);
  const [deleteUserId,setDeleteUserId] = useState("");

  const formatDate = (value) => {
    if ( value === undefined || value === null || value === '' )
    return '';
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });
    return day + '-' + month + '-' + year;
  }

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteUserId("");
  };

  
  useEffect(()=>{
    dispatch({type:"EditClientId",payload:""})
    dispatch({type:"ViewClientId",payload:""})
  });

  useEffect(()=>{
    getUserlist()
  },[currentPage,sorting,searchValue])

  const getUserlist=()=>{
    setLoader(true)
    let sortingKey = sorting[0].columnName
    let sortingDirection = sorting[0].direction

    fetchUrl(`${GET_CLIENTS_URL}?sortby=${sortingKey}&direction=${sortingDirection}&page=${currentPage}&limit=${pageSize}&q=${searchValue}`, ({ status, response }) => {
      if (status) {
        if(response?.data?.status === true){
          let {data,all_data_cnt,filter_data_cnt} = response.data
          filter_data_cnt === all_data_cnt ?  setUsersCount(all_data_cnt): setUsersCount(filter_data_cnt)
          setUsersData(data)
        }
      }
      setLoader(false)
  })
  }


  const StatusFormatter = ({ value, row }) => (
    <>
     <a className="link text-link cursor-pointer px-1" onClick={()=>{        
         dispatch({type:"EditClientId",payload:''})
         dispatch({type:"ViewClientId",payload:row.encrypted_id})
        props.changetab((filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2: 1)
        }
        }>
          {/* <VisibilityIcon fontSize="small" className="text-dark"/> */}
          <img src={view} className="icon-img me-4"/> 
      </a>
      {(filter_role_data.length > 0 && filter_role_data[0]["m_edit"]) ? 
      <a className="link text-link cursor-pointer pe-1" onClick={()=>{
        dispatch({type:"EditClientId",payload:row.encrypted_id})
        dispatch({type:"ViewClientId",payload:''})
       props.changetab(2)
        }
        }>
         {/* <EditIcon fontSize="small" className="text-dark"/>  */}
         <img src={Edit} className="icon-img me-4"/> 
      </a> : null
      }

    {(filter_role_data.length > 0 && filter_role_data[0]["m_delete"]) ? 
      <a className="link text-link cursor-pointer px-1" onClick={()=>{
        setUpdatemodal(true);
        setDeleteUserId(row.encrypted_id)
      }
        }>
        {/* <DeleteIcon fontSize="small" className="text-dark" />  */}
        <img src={Delete} className="icon-img "/> 
      </a>: null
      }
    </>
  );



  const ActionsTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );


  const dateFormatter = ({ value, row })=>{
    let date = new Date(value)
    return <span>
      {/* {formatDate(value)} */}
      {  date.getDate()+ "-" + date.getMonth()+"-"+ date.getFullYear()}
    </span>
  }
  const CreateDateTypeProvider  = (props) => (
    <DataTypeProvider formatterComponent={dateFormatter} {...props} />
  );

  const deleteCandidate =()=>{
    apiDelete(`${GET_CLIENTS_URL}/${deleteUserId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false)
        setSuccessMsg({notes:["Deleted Successfully"]})
        setSnakBarOpen(true)
        getUserlist()
      }
  })
  }

  return(
    <>
     {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid pr-0 pl-0 px-0 client-list">
      <div className="row no-gutters"> 
        <div className="col-md-12 table-card pt-4">
       <div className="card card-shadow-none table-center">
       <Grid rows={usersData} columns={columns} getRowId={getRowId}>
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
        />

        <SearchState  className="d-none" 
        value={searchValue}
        onValueChange={setSearchState}/>

     
        <PagingState
          currentPage={currentPage} // show current page, first time it's set to 1
          pageSize={pageSize} // set page limit to show in one page
          onCurrentPageChange={setCurrentPage}
        />
         <CustomPaging totalCount={usersCount} />

         <ActionsTypeProvider className="text-center" for={actionsColumns} />
         {/* <CreateDateTypeProvider for={creatAtColumns} />
         <CreateDateTypeProvider for={start_date_Columns} />
         <CreateDateTypeProvider for={end_dateColumns} /> */}
        <Table noData={"no data found"} className="group" />
       
        <Toolbar />
        <SearchPanel/>
        <GroupingState />
        <TableHeaderRow
          showSortingControls // to control the sorting in each fields
        />

        <PagingPanel />
      </Grid>
    
       </div>
      </div>
      </div>

      <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteCandidate}>
        <span>Are you sure you want to delete client ?</span>
      </CommonModal>

      <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success"/>
      </div> 
      </>
   )
  }


export default withRouter(ClientList)