import React, { useState, useEffect } from "react";
// Login css
import "./login.scss";
// App Logo
import logo from "../../Assets/Images/logo.svg";
import { useEmailValidation } from '../../Hooks/useValidations'
import Axios from 'axios'
import { LOGIN_API_URL } from '../../Configurations/ConfigApi';
import { useDispatch } from "react-redux";
import SnakBar from "../../Components/Models/snakbar";



const Login = (props) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailValidation, checkEmail] = useEmailValidation()
  const [emailAlert, setEmailAlert] = useState(false)
  const [errors,setErrors] = useState({})
  const [invalidAlert,setInvalidAlert] = useState(false)

  const submit = () => {

    if (email && password) {
      const data = {
        email: email,
        password: password
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      }
      Axios.post(LOGIN_API_URL, data, config).then((res) => {
        localStorage.setItem("token", res.data.token)
        localStorage.setItem("user", JSON.stringify(res.data.user))
        sessionStorage.setItem("user", JSON.stringify(res.data.user))
        props.history.push("/dashboard")
        dispatch({ type: "userdetails", payload: res.data.user })
      }).catch((err) => {
    
        let msg = JSON.parse(err.request.response).message
        let errMsg = {
         err_msg: [msg]
        } 
        setErrors(errMsg)
         setInvalidAlert(true)
      })
    } 
  }

  useEffect(()=>{
    if(localStorage.getItem("token")){
      props.history.push("/dashboard")
    }
  },[])


  const submitLogin = (e) => {
    if(e.key==="Enter"){
      submit()
    }
  }


  return (
    <div className="login ">
      <div className="container">
        <div className="row">
          <div className="col-12 flex-column c-align logo-imge-new  flex-column d-flex justify-content-center align-content-center">
        <div className="row pb-5">
          <div className="col-lg-12">
            <div className="card custom-width">
              <div className="card-header text-center">
                <h5 className="pb-0 mb-0">Login to start hiring </h5>
              </div>
              <div className="card-body ">
                <div className="text-center pt-0 pb-1 mb-2">
                  <img src={logo} alt="" className="logo img-fluid" />
                </div>

                <div className="office-btn text-center mb-2 pb-2">
                  <a
                    href="/"
                    className="btn btn-primary theme-btn btn-block text-white"
                  >
                    Login With Office 365
                  </a>
                </div>

                <div className="or text-dark mb-2"> OR </div>

                <div className="login-from">

                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputEmail1">Work Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => checkEmail(email)}
                    />
                  <SnakBar error={errors} open={invalidAlert} onClose={() => setInvalidAlert(false)}/>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => emailValidation ? setEmailAlert(false) : setEmailAlert(true)}
                      onKeyPress={(e)=>submitLogin(e)}
                    />
                  </div>
                  <div className="login-btn text-center">
                    <button className="btn btn-primary theme-btn-light text-white" onClick={submit}>
                      Sign in
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 text-center">
          <div className="col-md-12  list-style-pages">
            <ul type="none" className="p-0 m-0 ">
              <li key="about">
                <a href="/">About</a>
              </li>
              <li key="contact">
                <a href="/">Contact</a>
              </li>
              <li key="condition">
                <a href="/">Terms & Conditions</a>
              </li>
              <li key="privacy">
                <a href="/" className="border-right-0">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default Login;
