import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import {
  GET_JOBORDERS_API_URL,
  GET_JOB_ASSIGNED_USERS_API_URL,
} from "../../../Configurations/ConfigApi";
import { fetchUrl } from "../../../ApiActions/Actions";
import { useDispatch } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getMonth, getYear, getDate } from "date-fns";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin:"0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddNewJobOrder = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [emails, setEmails] = useState([]);
  const [jodId, setJodId] = useState("");
  const [showBts, setShowbts] = useState(true);
  const [users, setUsers] = useState([]);
  const [open,setOpen] = useState(false);
  const [userId, setUsersId] = useState("");
  const [joiningDate, setJoiningDate] = useState('');
  const [offered_ctc, setOfferedctc] = useState("");

  const getEmails = (value) => {
   
      fetchUrl(
        `${GET_JOBORDERS_API_URL}?q=${value}`,
        ({ status, response }) => {
          if (status) {
            let jobs = response.data.data;
            for (let i = 0; i < jobs.length; i++) {
              jobs[i].title = jobs[i]?.user_type ? `${jobs[i].job_title } (${jobs[i].user_type[0].toUpperCase() + jobs[i].user_type.slice(1)})` : jobs[i].job_title 
            }
            setEmails(jobs);
          } else {
            // setErrorModal(true)
          }
        }
      );
    
  };

  const getJObrecruiters = (value) => {
    fetchUrl(
      `${GET_JOB_ASSIGNED_USERS_API_URL}${value}`,
      ({ status, response }) => {
        if (status) {
          let data = response.data.data;
          let usersData = [];
          for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            if(obj["user_type"] === "recruiter"){
              usersData.push(obj)
            }
          }
          setUsers(usersData);
        } else {
          // setErrorModal(true)
        }
      }
    );
  };

  const clearvalues = () => {
    props.deleteCount();
    dispatch({ type: "DeleteCandidateJO", payload: props.index });
  };

  useEffect(() => {
    dispatch({ type: "AddCandidateJO", payload: props.index });
    fetchUrl(
      `${GET_JOBORDERS_API_URL}?status=active`,
      ({ status, response }) => {
        if (status) {
          let jobs = response.data.data;
          for (let i = 0; i < jobs.length; i++) {
            jobs[i].title = jobs[i]?.user_type ? `${jobs[i].job_title } (${jobs[i].user_type[0].toUpperCase() + jobs[i].user_type.slice(1)})` : jobs[i].job_title 
          }
          setEmails(jobs);
        } else {
          // setErrorModal(true)
        }
      }
    );
  }, []);

  useEffect(() => {
    let data = {
      candidate_id: "",
      job_id: jodId,
      user_id: userId,
      offered_ctc: offered_ctc,
    };
    let newjoiningDate = new Date(joiningDate);
    data.expected_joining_date = `${getYear(newjoiningDate)}-${
      getMonth(newjoiningDate) + 1
    }-${getDate(newjoiningDate)}`;
    dispatch({ type: "UpdateCandidateJO", payload: [props.index, data] });
  }, [jodId, userId, offered_ctc, joiningDate]);

  const defaultProps = {
    options: emails,
    getOptionLabel: (option) => `${option.title }  (${option.client_name})`,
  };

  return (
    <>
      <div className="row px-md-4 mb-4 mb-md-0">
        <div className="col-md-6">
          <div className="pb-0 mb-0 ">
            {
              <Autocomplete
                {...defaultProps}
                onChange={(event, newValue) => {
                  setJodId(newValue?.encrypted_id);
                  getJObrecruiters(newValue?.encrypted_id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Job Order Name *"
                    className="w-100 mt-0"
                    defaultValue={"dd"}
                    onChange={(e) => getEmails(e.target.value)}
                  />
                )}
              />
            }
          </div>
          <br />
        </div>

        <div className="col-md-6">
      
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel
              className="w-100"
              id="demo-controlled-open-select-label"
            >
              Select Recruiter *
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              className="w-100"
              minRows={10}
              value={userId}
              onChange={(e) => setUsersId(e.target.value) }
            >
              {users.map((role) => {
                return <MenuItem value={role.user_id}>{role.username}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>

        <div className="col-md-6 AddjobOrders calender_date">
           {
              joiningDate ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Expected Joining Date</label> : ""
            }
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              format="dd-MMM-yyyy"
              className="w-100"
              margin="normal"
              id="date-picker-inline"
              views={["year", "month",'date']}
              label={joiningDate ? '' : "Expected Joining Date"}
              // minDate={moment().toDate()}
              value={joiningDate}
              error={false}
              onClose={()=>setOpen(false)}
              onChange={(newdate) => {
               setOpen(false)
                setJoiningDate(newdate);
              }}
              TextFieldComponent={(props) => (
                <TextField {...props} onClick={(e) => setOpen(true)} />
              )}
              open={open}
              autoOk={true}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className="col-md-6  pb-3 pt-2">
          <TextField
            label="Offered CTC"
            type="number"
            className="w-100"
            value={offered_ctc}
            onChange={(e) => setOfferedctc(e.target.value)}
          />
        </div>
      </div>
      <div className="row px-4">
        <div className="col-md-12 d-flex justify-content-end">
          {showBts && (
            <>
              <button
                class="btn btn-xs btn-green-dark text-white"
                onClick={clearvalues}
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddNewJobOrder;
