import React from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Login css
import "./register.scss";

const UserLogin = (props) => {
    
    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);
  
    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
  return(
      <>
    
    <div className="container-fluid pr-0 pl-0 ">
    <div className="row no-gutters card-design my-4">
        <div className="col-md-6 offset-md-3 pt-3">
         <div className="card " id="shadow-custom">
           <div className="card-header pb-0 text-left px-3">
        <h5>Login as user</h5>
        </div>
        <div className="card-body">
          <div className="row">
        <div className="col-12 pb-5 mb-5 my-4">
        <InputLabel id="demo-controlled-open-select-label">Select user email id</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          className="w-100"
          onChange={handleChange}
         minRows={20}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
        </div>
      
        <div className="col-12 text-center py-0 my-0 pb-5 mb-0">
        <button className="btn btn-xs btn-green-dark text-white ">Login</button>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
    </div>
      </>
   )
  }


export default UserLogin