import React, { useState } from "react";
import { GET_SKILLS_API_URL,REPLACE_SKILL_API_URL } from '../../Configurations/ConfigApi'
import CommonModal from '../../Components/Models/commonModal';
import {  apiPost , fetchUrl} from '../../ApiActions/Actions'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SkillsList from "./SkillsList";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Skills = (props) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [skills, setSkills] = useState('')

    const [replaceSkills, setReplaceSkills] = useState([])

    const [replaceSkillId, setReplaceSkillId]= useState('')

    const [openPopup,setOpenPopup] = useState(false)

    const [replacePOpup,setReplacePopup]= useState(false)

    const [newSkill,setNewSkill] = useState("")

    const addskill = ()=>{
        if(skills){
          let data = {
              name : skills
          }
            apiPost(GET_SKILLS_API_URL, data, ({ status, response }) => {
              if (status) {
                setOpenPopup(true)
                
              }
          })
        }
    }

    const defaultProps = {
        options: replaceSkills,
        getOptionLabel: (option) => option.title,
      };

      const getSkills=(value)=>{
        if(value.length>2){
          fetchUrl(`${GET_SKILLS_API_URL}?sortby=name&direction=asc&page=${1}&q=${value}`, ({ status, response }) => {
            if (status) {
                if (response?.data?.status === true) {
                    let { data } = response.data
                    for (let i = 0; i < data.length; i++) {
                        data[i]["title"]=data[i]["name"]
                    }
                    setReplaceSkills(data)
                }
            }
        })
         
        }else{
            setReplaceSkills([]) 
        }
      }

      const replaceSkillApi=()=>{
          const updatedSkillData ={
            name:newSkill,
            encrypted_id:replaceSkillId
          }
          if(newSkill && replaceSkillId){
            apiPost(REPLACE_SKILL_API_URL,updatedSkillData, ({ status, response }) => {
                if (status) {
                    setReplacePopup(true)
                }else{
                  
                }
            }) 
          }
        
      }

    return (
        <>
            <div className="container-fluid pr-0 pl-0 pt-4">
                <div className="row no-gutters card-design">
                    {/* <div className="col-md-1 p-0 m-0">
                        <Sidenav category={"Settings"} />
                    </div> */}
                    <div className="list-cards">
                        <div className={classes.root} id="shadow-custom">
                             <AppBar position="static">
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                    <Tab label="List" {...a11yProps(0)} />
                                    <Tab label="Add" {...a11yProps(1)} />
                                    <Tab label="Replace" {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <SkillsList/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="p-5 pt-3 text-center">
                                <h5 className="pb-4 text-aqua"><u>Add Skills</u></h5>

                                <input type="text" className="form-control mb-4" placeholder="---Type new Skills---" onChange={e => setSkills(e.target.value)} />
                             <h5 className="text-aqua ">Or</h5>
                                <input type="file" className="form-control mt-4 mb-5" />
                                <button className="btn btn-xs btn-orange text-white" onClick={addskill}>Add</button>
                                </div>
                            </TabPanel>
                            
                            <TabPanel value={value} index={2}>
                                <div className="p-5 pt-3 text-center">
                                <h5 className="pb-4 text-aqua"><u>Replace skills</u></h5>
                                {/* <input type="text" className="form-control mb-4" placeholder="type Existing skill Name" /> */}
                                <Autocomplete
                                {...defaultProps}
                                id="email"
                                debug
                                onChange={(event, newValue) => {
                                setReplaceSkillId(newValue?.encrypted_id);
                                }}

                                renderInput={(params) => <TextField {...params} label="type Existing skill Name"  className="w-100 mb-4"  onChange={(e)=>getSkills(e.target.value)} onFocus={()=>setReplaceSkills([])}/>}
                            />
                                <h5 className="text-aqua">With</h5>
                                <input type="text" className="form-control mt-4 mb-5" placeholder="type new skill Name" onChange={(e)=>setNewSkill(e.target.value)}/>
                                <button className="btn btn-xs btn-green-dark text-white" onClick={replaceSkillApi}>Replace</button>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        
        <CommonModal  open={openPopup} handleClose={()=>setOpenPopup(false)} title={"Success"} button={"Ok"} btnfunc={()=>setOpenPopup(false)}>
        <span className="py-3"> Skills added successfully</span>
      </CommonModal>

      <CommonModal  open={replacePOpup} handleClose={()=>setReplacePopup(false)} title={"Success"} button={"Ok"} btnfunc={()=>setReplacePopup(false)}>
        <span className="py-3"> Skills Replaced successfully</span>
      </CommonModal>

        </>
    );
};

export default Skills;
