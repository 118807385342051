import React,{useState,useEffect} from 'react'
import {
    GroupingState,
    SortingState,
    PagingState,
    CustomPaging,
    SearchState,
    DataTypeProvider,
} from "@devexpress/dx-react-grid";

import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    SearchPanel,
    Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import { GET_SKILLS_API_URL } from '../../Configurations/ConfigApi'
import CommonModal from '../../Components/Models/commonModal';
import DeleteActiveUser from "../../Components/Models/deleteActiveuser";
import { fetchUrl, apiPost,apiDelete } from '../../ApiActions/Actions'

 const SkillsList = (props) => {
    const [columns] = useState([
        { name: "name", title: "Skill Name" },
        { name: "actions", title: "Actions" },
    ]);  

    const [skills, setSkills] = useState("")

    const [sorting, setSorting] = useState([{ columnName: 'name', direction: 'asc' }, { columnName: 'email', direction: 'asc' }]);
    const [actionsColumns] = useState(["actions"]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(7);
    const [openDeletepop, setOpenDeletePop] = useState(false)

    const [usersData, setUsersData] = useState([])

    const [usersCount, setUsersCount] = useState(0)

    const [deleteId, setDeleteId] = useState("")

    const [openPopup,setOpenPopup] = useState(false)

    const [searchValue, setSearchState] = useState('');

    const StatusFormatter = ({ value, row }) => (
        <>
            <a className="link text-link cursor-pointer px-1" onClick={()=>{
        setDeleteId(row.encrypted_id)
        setOpenDeletePop(true)}
        }>Delete</a>
        </>
    );

    
    useEffect(() => {
        getUserlist()
    }, [currentPage, sorting, searchValue])

    const getUserlist = () => {
        let sortingKey = sorting[0].columnName
        let sortingDirection = sorting[0].direction

        fetchUrl(`${GET_SKILLS_API_URL}?sortby=${sortingKey}&direction=${sortingDirection}&page=${currentPage}&limit=${pageSize}&q=${searchValue}`, ({ status, response }) => {
            if (status) {
                if (response?.data?.status === true) {
                    let { data, all_data_cnt, filter_data_cnt } = response.data
                    filter_data_cnt === all_data_cnt ? setUsersCount(all_data_cnt) : setUsersCount(filter_data_cnt)
                    setUsersData(data)
                }
            }
        })
    }

    const ActionsTypeProvider = (props) => (
        <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
    );

    const deleteSkill =()=>{

        apiDelete(`${GET_SKILLS_API_URL}/${deleteId}`, ({ status, response }) => {
          if (status) {
            setOpenDeletePop(false)
            getUserlist()
      
          }
      })
    
      }

  return(
    <div className="table-card p-0 m-0">
    <div className="card card-shadow-none">
    <Grid rows={usersData} columns={columns} >
        <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
        />

        <SearchState className="d-none"
            value={searchValue}
            onValueChange={setSearchState} />

        <PagingState
            currentPage={currentPage} // show current page, first time it's set to 1
            pageSize={pageSize} // set page limit to show in one page
            onCurrentPageChange={setCurrentPage}
        />
        <CustomPaging totalCount={usersCount} />

        <ActionsTypeProvider for={actionsColumns} />

        <Table noData={"no data found"} className="group" />

        <Toolbar />
        <SearchPanel />
        <GroupingState />
        <TableHeaderRow
            showSortingControls // to control the sorting in each fields
        />

        <PagingPanel />
    </Grid>
    </div>
    <DeleteActiveUser  open={openDeletepop} handleClose={()=>setOpenDeletePop(false)} title={"Delete Skill"} button={"Delete"} btnfunc={deleteSkill}>
        <span className="py-3"> Are you sure you want to Delete Skill?.</span>
      </DeleteActiveUser>
    </div>

   )
  }

  export default SkillsList
