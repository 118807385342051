import React, { useEffect, useState} from "react";
import {
    GroupingState,
    SortingState,
    PagingState,
    CustomPaging,
    DataTypeProvider,
  } from "@devexpress/dx-react-grid";

  import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
  
  import {
    Grid as TableGrid,
    Table,
    TableHeaderRow,
    PagingPanel,
    SearchPanel,
    Toolbar,
  } from "@devexpress/dx-react-grid-material-ui";

  import logoEmblem from "../../../Assets/Images/emblem.svg";
import Delete from "../../../Assets/Images/profile-t-icons/Delete.svg";
import Download from "../../../Assets/Images/profile-t-icons/Download.svg";
import { fetchUrl , apiDelete } from '../../../ApiActions/Actions';
import SnakBar from "../../../Components/Models/snakbar";
import CommonModal from '../../../Components/Models/commonModal';

import { CUSTOM_TABLE_GET_ALL_REPORTS,CUSTOM_TABLE_REPORTS_LIST_API , CUSTOM_TABLE_DOWNLOAD_REPORTS} from '../../../Configurations/ConfigApi';

const getRowId = (row) => {
    return row.id;
  };

 const TableList = (props) => {
    const {updateList} = props
    const [columns] = useState([
        { name: "report_name", title: "Saved Reports" },
        { name: "actions", title:""}
      ]);
      const [snakBarOpen,setSnakBarOpen]  = useState(false)
      const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' },{ columnName: 'client_legal_name', direction: 'asc' }]);
      const [successMsg,setSuccessMsg] = useState({})
      const [currentPage, setCurrentPage] = useState(0);
      const [pageSize] = useState(10);
      const [actionsColumns] = useState(["actions"]);
      const [allReports,setAllReports] = useState([])
      const [totalCount,setTotalCount] = useState(0)
      const [loader,setLoader] = useState(false)
      const [updateModal,setUpdatemodal] = useState(false);
      const [deleteUserId,setDeleteUserId] = useState("");
  

      const handleClose = () => {
        setUpdatemodal(false);
        setDeleteUserId("");
      };
      
      const StatusFormatter = ({ value, row }) => (
        <>
          <a className="link text-link cursor-pointer px-1">
            <img src={Delete} className="icon-img " onClick={()=>{
              setDeleteUserId(row?.encrypted_id)
              setUpdatemodal(true)
              }}/> 
          </a>
          <a className="link text-link cursor-pointer px-1">
            <img src={Download} className="icon-img " onClick={()=>{
              downloadReport(row?.encrypted_id)
              }}/> 
          </a>
        </>
      );
    
      const ActionsTypeProvider = (props) => (
        <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
      );

      useEffect(()=>{
        getAllrecord()
      },[updateList,currentPage])

      const getAllrecord = ()=>{
        setLoader(true)
        fetchUrl(`${CUSTOM_TABLE_GET_ALL_REPORTS}?page=${currentPage}&limit=${pageSize}`, ({ status, response }) => {
          if (status) {
            let data = response.data.data;
           setAllReports(data)
           setLoader(false)
           setTotalCount( response.data.all_data_cnt)
          }
        })
      }

      const deleteReport = () => {
        handleClose()
        apiDelete(
          `${CUSTOM_TABLE_REPORTS_LIST_API}/${deleteUserId}`,
          ({ status, response }) => {
            if (status) {
              if(response.data?.status){
                setSuccessMsg({notes:[response.data?.message]})
                setSnakBarOpen(true)
                getAllrecord()
                //setUpdateList(!updateList)
              }

            } else {
              // let msg = JSON.parse(response.request.response).errors;
              // setErrors(msg);
              // setFieldErros(true);
            }
          }
        );
      };

      const downloadReport =  (id)=>{
        const list = document.getElementById("reportTable");
        if (list.hasChildNodes()) {
          list.removeChild(list.children[0]);
        }
        setLoader(true)
        fetchUrl(`${CUSTOM_TABLE_DOWNLOAD_REPORTS}/${id}`, ({ status, response }) => {
          if (status) {
            let data = response.data?.replace('<table ','<table id="table-to-xls"')
            console.log('response',response)
            
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, 'text/html');
             document.getElementById('reportTable').appendChild(doc.body)  
             document.getElementById('test-table-xls-button').click()
             setLoader(false)
          }
        })
      }

  return(
    <>
      {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    
    <TableGrid rows={allReports} columns={columns} getRowId={getRowId}>
    <SortingState
      sorting={sorting}
      onSortingChange={setSorting}
    />

    {/* <SearchState className="d-none"/> */}

 
    <PagingState
      currentPage={currentPage} // show current page, first time it's set to 1
      pageSize={pageSize} // set page limit to show in one page
      onCurrentPageChange={setCurrentPage}
    />
     <CustomPaging totalCount={totalCount}/>

     <ActionsTypeProvider className="text-center" for={actionsColumns} />
    <Table noData={"no data found"} className="group" />
   
    <Toolbar />
    <GroupingState />
    <TableHeaderRow
      showSortingControls // to control the sorting in each fields
    />
    <PagingPanel />
  </TableGrid>

        <div className="d-none">
        <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="report"
              sheet="tablexls"
              buttonText="Download as XLS"/>
               <div className="table" id="reportTable"></div>
        </div>
         
  
  <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteReport}>
          <span>Are you sure you want to delete report ?</span>
  </CommonModal>
  {snakBarOpen && <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} 
        type="success"/>}
  </>
   )
  }

  export default TableList