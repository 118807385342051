let initialState = {
    ClientGstList:[],
    ClientUpdateGstList:[],
    ClientPointOfContactList:[],
    ClientUpdatePOCList:[],
    ClientPaymentList:[],
    ClientUpdatePaymentList:[],
    ClientInvoiceList:[],
    ClientUpdatedInvoiceList:[],
}

const Client_Reduser=(state= initialState,Actions)=>{
    switch (Actions.type){
         case "AddClientGst":
            return{
                ...state,
                ClientGstList:[...state.ClientGstList,{
                    gst_id:"",
                    gst_state_code:"77",
                    gst_state_name:"",
                    client_pan_no: "",
                    client_tan_no:"",
                    client_office_short_name: "",
                    client_office_address1:"",
                    client_office_address2:"",
                    client_office_address3:"",
                    client_office_address4: "",
                    gst_exemption_eligibility:"",
                    approval_from_gst_enddate:"",
                    // update:false,
                  }]
            }
        case "DeleteClientGst":
            let deleteGstdata = [...state.ClientGstList]
            deleteGstdata.splice(Actions.payload,1)
            return{
                ...state,
                ClientGstList:[...deleteGstdata]
            }

        case "RemoveAllClientGst":
            return{
                ...state,
                ClientGstList:[]
            }

        case "UpdateClientGst":
            let indexGst = Actions.payload[0]
            let upateGstdata = Actions.payload[1]
            let newGstdata = [...state.ClientGstList]
            newGstdata[indexGst] = upateGstdata
            return{
                ...state,
                ClientGstList:[...newGstdata]
            }


        case 'updatedClientGstList':
            return {
                ...state,
                ClientUpdateGstList: [...Actions.payload]
            }
        
        case 'checkUpdateClientGst':
            let indexUpdateGst = Actions.payload[0]
            let upatedGstdata = Actions.payload[1]
            let newUpdatedGstdata = [...state.ClientUpdateGstList]
            newUpdatedGstdata[indexUpdateGst] = upatedGstdata
            return{
                ...state,
                ClientUpdateGstList:[...newUpdatedGstdata]
            }

        case "RemoveUpdatedClientGst":
            return{
                ...state,
                ClientUpdateGstList:[]
            }

        case "DeleteUpdatedClientGstItem":
            let deleteUpdatedGstdata = [...state.ClientUpdateGstList]
            deleteUpdatedGstdata.splice(Actions.payload,1)
            return{
                ...state,
                ClientUpdateGstList:[...deleteUpdatedGstdata]
            }
        
        case "AddClientPointOfContact":
        return{
            ...state,
            ClientPointOfContactList:[...state.ClientPointOfContactList,{
                client_contact_name:"",
                client_contact_email:"",
                client_contact_phone:"",
                client_contact_alternate_phone: "",
            }]
        }
        case "DeleteClientPointOfContact":
            let deletePOCdata = [...state.ClientPointOfContactList]
            deletePOCdata.splice(Actions.payload,1)
            return{
                ...state,
                ClientPointOfContactList:[...deletePOCdata]
            }

        case "RemoveAllClientPointOfContact":
            return{
                ...state,
                ClientPointOfContactList:[]
            }

        case "UpdateClientPointOfContact":
            let indexPOC = Actions.payload[0]
            let upatePOCdata = Actions.payload[1]
            let newPOCdata = [...state.ClientPointOfContactList]
            newPOCdata[indexPOC] = upatePOCdata
            
            return{
                ...state,
                ClientPointOfContactList:[...newPOCdata]
            }
        
        case "updatedClientPOCList":
            return{
                ...state,
                ClientUpdatePOCList: [...Actions.payload]
            }
        case "DeleteUpdatedClientPOC":
            let deleteUpdatePOCdata = [...state.ClientUpdatePOCList]
            deleteUpdatePOCdata.splice(Actions.payload,1)
            return{
                ...state,
                ClientUpdatePOCList:[...deleteUpdatePOCdata]
            }

        case "RemoveAllUpdatedClientPOC":
            return{
                ...state,
                ClientUpdatePOCList:[]
            }

        case "checkUpdateClientPOC":
            let indexUpdatePOC = Actions.payload[0]
            let upatedPOCdata = Actions.payload[1]
            let newUpdatePOCdata = [...state.ClientUpdatePOCList]
            newUpdatePOCdata[indexUpdatePOC] = upatedPOCdata
            
            return{
                ...state,
                ClientUpdatePOCList:[...newUpdatePOCdata]
            }
        
        case "AddClientPayment":
            return{
                ...state,
                ClientPaymentList:[...state.ClientPaymentList,{
                    criteria_name:"",
                    charges:"",
                    remarks:"",
                }]
            }
        case "DeleteClientPayment":
            let deletePaymentdata = [...state.ClientPaymentList]
            deletePaymentdata.splice(Actions.payload,1)
            return{
                ...state,
                ClientPaymentList:[...deletePaymentdata]
            }

        case "RemoveAllClientPayment":
            return{
                ...state,
                ClientPaymentList:[]
            }

        case "UpdateClientPayment":
            let indexPyment = Actions.payload[0]
            let upatePymentdata = Actions.payload[1]
            let newPaymentdata = [...state.ClientPaymentList]
            newPaymentdata[indexPyment] = upatePymentdata
            
            return{
                ...state,
                ClientPaymentList:[...newPaymentdata]
            }

        case "updatedClientPaymentList":
            return{
                ...state,
                ClientUpdatePaymentList: [...Actions.payload]
            }
        case "DeleteUpdatedClientPayment":
            let deleteUpdatePaymentdata = [...state.ClientUpdatePaymentList]
            deleteUpdatePaymentdata.splice(Actions.payload,1)
            return{
                ...state,
                ClientUpdatePaymentList:[...deleteUpdatePaymentdata]
            }

        case "RemoveAllUpdatedClientPayment":
            return{
                ...state,
                ClientUpdatePaymentList:[]
            }

        case "CheckUpdateClientPayment":
            let indexUpdatePyment = Actions.payload[0]
            let upateedPymentdata = Actions.payload[1]
            let newUpdatedPaymentdata = [...state.ClientUpdatePaymentList]
            newUpdatedPaymentdata[indexUpdatePyment] = upateedPymentdata
            
            return{
                ...state,
                ClientUpdatePaymentList:[...newUpdatedPaymentdata]
            }

        case "AddClientInvoice":
            return{
                ...state,
                ClientInvoiceList:[...state.ClientInvoiceList,{
                    steps:"",
                }]
            }
        case "DeleteClientInvoice":
            let deleteInvoicedata = [...state.ClientInvoiceList]
            deleteInvoicedata.splice(Actions.payload,1)
            return{
                ...state,
                ClientInvoiceList:[...deleteInvoicedata]
            }

        case "RemoveAllClientInvoice":
            return{
                ...state,
                ClientInvoiceList:[]
            }

        case "UpdateClientInvoice":
            let indexInvoice = Actions.payload[0]
            let upateInvoicedata = Actions.payload[1]
            let newInvoicedata = [...state.ClientInvoiceList]
            newInvoicedata[indexInvoice] = upateInvoicedata
         
            return{
                ...state,
                ClientInvoiceList:[...newInvoicedata]
            }

        
        case "updtedClientInvoiceList":
            return{
                ...state,
                ClientUpdatedInvoiceList:[...Actions.payload]
            }
        case "DeleteUpdatedClientInvoice":
            let deleteUpdateInvoicedata = [...state.ClientUpdatedInvoiceList]
            deleteUpdateInvoicedata.splice(Actions.payload,1)
            return{
                ...state,
                ClientUpdatedInvoiceList:[...deleteUpdateInvoicedata]
            }

        case "RemoveAllUpdatedClientInvoice":
            return{
                ...state,
                ClientUpdatedInvoiceList:[]
            }

        case "checkUpdateClientInvoice":
            let indexUpdateInvoice = Actions.payload[0]
            let upatedInvoicedata = Actions.payload[1]
            let newUpdateInvoicedata = [...state.ClientUpdatedInvoiceList]
            newUpdateInvoicedata[indexUpdateInvoice] = upatedInvoicedata
            
            return{
                ...state,
                ClientUpdatedInvoiceList:[...newUpdateInvoicedata]
            }

        default:
      return state
    }
}

export default Client_Reduser