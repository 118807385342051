import React, { useEffect, useState } from 'react'
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchUrl } from '../../ApiActions/Actions';
 


export const AutocompleteCandidates = (props) => {

  const [Jobname, setJobName] = useState("")
  const [Joblist, setJoblist] = useState([]);
  const [defaultData,setDefaultData] = useState([])   
  const [value, setValue] = React.useState(props.selectedcandidates);


  const getjobsList = (value) => {
    setJobName(value)     
    // fetchUrl(`${GET_QULIFICATION_API}?q=${value}`, ({ status, response }) => {
    //   if (status) {
    //     if (response?.data?.status === true) {
       
    //       let { data } = response.data
    //       for (let i = 0; i < data.length; i++) {
    //         data[i].title = data[i].name
    //       }
    //       setJoblist(data);
    //     }else{
    //      let newData = [{title:value}];        
    //      setJoblist(newData);
    //     }
    //   }
    // })
  }


  const addcandidates = (JobList) => {
    let data = []
    for (let i = 0; i < JobList.length; i++) {
      data.push(JobList[i])       
    }    
    setValue(data);
    props.setselectedcandidates(data); 
  }


  useEffect(()=>{    
      let jobdata  = [...props.candidateslist]          
      if(jobdata.length > 0){         
        setDefaultData(jobdata); 
        setJoblist(jobdata);    
      }
    
  },[props])

  useEffect(()=>{    
    if (props.Isjobchange === true){         
        // setJoblist([]);
        // props.setselectedcandidates([]); 
        // setValue([]);
    }
},[props.Isjobchange])

  
 

  return (
    <>
      <div className="d-flex align-items-end">
        {(defaultData.length > 0 && props.edit)  && <Autocomplete
          multiple={true}
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={Joblist}
          // value={value}
          autoSelect
          getOptionLabel={(option) => option.candidate_name}
          getOptionSelected={(option, value) => option.candidate_name === value.candidate_name }          
          defaultValue = {defaultData}
          onChange={(event, newValue) => {
            addcandidates(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getjobsList(e.target.value)}
            />
          )}
        />}

        {(!props.edit ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={Joblist}
          // value={value}
          getOptionLabel={(option) => option.candidate_name}
          onChange={(event, newValue) => {
            addcandidates(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getjobsList(e.target.value)}
            />
          )}
        />}

      {(defaultData.length === 0 && props.edit ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={Joblist}
          getOptionLabel={(option) => option.candidate_name}
          onChange={(event, newValue) => {
            addcandidates(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getjobsList(e.target.value)}
            />
          )}
        />}
       
        <span >{" "}*</span>

      </div>
    </>
  )
}
