import {  useState } from "react"

 export const useEmailValidation = () => {
    const  [emailValidation,setEmailvalidation]=useState(false)
    const checkEmail=(email)=>{
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (pattern.test(email)) {
        setEmailvalidation(true)
      }else{
        setEmailvalidation(false)
      }
    }
    return [emailValidation, checkEmail]
 }

