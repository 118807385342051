import React,{useEffect} from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 const SnakBar = (props) => {
  
  const notify = (msg) => {
   props?.type ? toast.success(msg) : toast.error(msg)
  };

  useEffect(()=>{
    for (const key in props.error) {
      notify(props.error[key][0])
    }
  },[props.error])
  return(
      <>
      <ToastContainer />
    </>
   )

 }

 export default SnakBar