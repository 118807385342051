import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import CommonModal from "../../../Components/Models/commonModal";
import SnakBar from "../../../Components/Models/snakbar";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";

const AddExperience = (props) => {
  const dispatch = useDispatch();
  const [updateModal, setUpdatemodal] = useState(false);
  const [showBts, setShowbts] = useState(true);
  const [fieldserror, setFieldErros] = useState(false);
  const [errors, setErrors] = useState({});
  const [openFromData,setOpenFromData] = useState(false)
  const [openEndData,setOpenEndData] = useState(false)
  const CandidateExpList = useSelector(
    (state) => state.Edu_Exp_Reduser.CandidateExpList
  );
  const updatedCandidateExpData = useSelector(state=>state.Edu_Exp_Reduser.updatedCandidateExpData)
  const [checkDisabeld, setCheckDisabeld] = useState(false);
  // '2014-08-18T21:11:54' format
  const [experience, setExperience] = useState({
    candidate_id: "",
    job_title: "",
    company_name: "",
    start_date: '',
    end_date: '',
    still_working: "0",
  });

  const [modalmsg, setModalmsg] = useState("");

  const handleClose = () => {
    setUpdatemodal(false);
  };

  const clearvalues = () => {
    // setExperience({
    //   candidate_id: "",
    //   job_title: "",
    //   company_name: "",
    //   start_date: '',
    //   end_date: '',
    //   still_working: 0,
    // });
    props.deleteCount();
    dispatch({ type: "DeleteCandidateExp", payload: props.index });
  };

  useEffect(() => {
    if(props.data){
      let data = props.data
      setExperience({
        candidate_id: "",
        job_title: data?.designation,
        company_name: data?.company,
        start_date: data?.from_date,
        end_date: data?.to_date === " Till Date" ? '': data?.to_date,
        still_working: data?.to_date === " Till Date" ? '1': '0',
      });
    }
    dispatch({ type: "AddCandidateExp", payload: props.index });
  }, []);

  useEffect(() => {
    dispatch({
      type: "UpdateCandidateExp",
      payload: [props.index, experience],
    });
  }, [experience]);

  useEffect(() => {
    let check = false;
    let checkindex = null;
    CandidateExpList.map((data, index) => {
      if (data?.still_working === "1") {
        check = true;
        checkindex = index;
      }
    });
    if (check && props.index !== checkindex) {
      setCheckDisabeld(true);
    } else {
      setCheckDisabeld(false);
    }

    let newCheck = false
    updatedCandidateExpData.map((data,index)=>{
      if(data?.still_working === '1'){
        newCheck = true
      }
    })
    if(newCheck){
      setCheckDisabeld(true)
    }
  }, [CandidateExpList,updatedCandidateExpData]);

  return (
    <>
      <div className="row px-4">
        <div className="col-6">
          <div className="row">
            <div className="col-md-12 mb-3">
              <TextField
                id="standard-search"
                label="Title *"
                type="text"
                className="w-100"
                value={experience.job_title}
                onChange={(e) => {
                  setExperience({ ...experience, job_title: e.target.value });
                }}
              />
            </div>
            <div className="col-md-12 ">
              <TextField
                id="standard-search"
                label="Company *"
                type="text"
                className="w-100"
                value={experience.company_name}
                onChange={(e) => {
                  setExperience({
                    ...experience,
                    company_name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-md-12 mb-3">
            {
              experience.start_date ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Pick From Date </label> : ""
            }
            
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              
                <KeyboardDatePicker
                disableToolbar
                id="date-picker-inline"
                  label={experience.start_date  ? "" : 'Pick From Date'}
                  views={["year", "month"]}
                  value={experience.start_date ? experience.start_date : ''}
                  error={false}
                  maxDate={moment().toDate()}
                  onOpen={() => setOpenFromData(true)}
                  onClose={() => setOpenFromData(false)}
                  open={openFromData}
                  // onError={"error"}
                  //disabled={experience.still_working === "1" ? true : false}
                  className="w-100"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(date) => {
                    setExperience({ ...experience, start_date: date });
                  }}
                  TextFieldComponent={(props) => (
                    <TextField {...props} onClick={(e) => setOpenFromData(true)} />
                  )}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-md-12 ">

            {
              experience.end_date ? <label className="MuiFormLabel-root  MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="date-picker-inline" id="date-picker-inline-label" >Pick To Date </label> : ""
            }
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  id="date-picker-inline"
                  label={experience.end_date ? '' : "Pick To Date "}
                  views={["year", "month"]}
                  error={false}
                  maxDate={moment().toDate()}
                  minDate={new Date(experience.start_date)}
                  value={experience.end_date ? experience.end_date : ''}
                  onOpen={() => setOpenEndData(true)}
                  onClose={() => setOpenEndData(false)}
                  disabled={experience.still_working === "1" ? true : false}
                  emptyLabel={""}
                  className="w-100 mt-1"
                  onChange={(date) => {
                    setExperience({ ...experience, end_date: date });
                  }}
                  TextFieldComponent={(props) => (
                    <TextField {...props} onClick={(e) => setOpenEndData(experience.still_working === "1" ? false : true)} />
                  )}
                  open={openEndData}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
              <br />
              <Checkbox
                name="checkedB"
                color="primary"
                className="p-0 m-0 font-weight-bold"
                disabled={checkDisabeld}
                checked={experience.still_working === "1" ? true : false}
                onChange={(e) => {
                  setExperience({
                    ...experience,
                    still_working: e.target.checked ? "1" : "0",
                  });
                }}
              />{" "}
              Still working here
            </div>
          </div>
        </div>
      </div>

      <div className="row px-4">
        <div className="col-md-12 d-flex justify-content-end">
          {showBts && (
            <>
              <button
                class="btn btn-xs btn-green-dark text-white"
                onClick={clearvalues}
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>

      <CommonModal
        title={"success"}
        handleClose={handleClose}
        open={updateModal}
        button={"OK"}
        btnfunc={handleClose}
      >
        <span>{modalmsg}</span>
      </CommonModal>

      <SnakBar
        error={errors}
        open={fieldserror}
        onClose={() => setFieldErros(false)}
      />
    </>
  );
};

export default React.memo(AddExperience)
