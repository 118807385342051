import React,{useEffect, useState} from 'react'
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import Checkbox from "@material-ui/core/Checkbox";
import {DELETE_CANDIDATE_EXPERIRNCE_API_URL } from '../../Configurations/ConfigApi'
import { apiPost,apiDelete } from '../../ApiActions/Actions'
import { getMonth, getYear } from 'date-fns';
import CommonModal from '../../Components/Models/commonModal';
import SnakBar from "../../Components/Models/snakbar";
import { useDispatch , useSelector} from "react-redux";
import moment from 'moment';

 const Experience = (props) => {
    
   const dispatch = useDispatch()
    const [updateModal,setUpdatemodal] = useState(false);
    const [showBts,setShowbts] = useState(true);
    const [fieldserror,setFieldErros] = useState(false)
    const [errors,setErrors] = useState({})
    const [checkDisabeld,setCheckDisabeld] = useState(false)
    const [openFromData,setOpenFromData] = useState(false)
    const [openEndData,setOpenEndData] = useState(false)
    const updatedCandidateExpData = useSelector(state=>state.Edu_Exp_Reduser.updatedCandidateExpData)
    const CandidateExpList = useSelector(
      (state) => state.Edu_Exp_Reduser.CandidateExpList
    );
   
    // '2014-08-18T21:11:54' format
    const [experience,setExperience] = useState({
      candidate_id: "",
      job_title:"",
      company_name:"",
      start_date:new Date(),
      end_date:new Date(),
      still_working:"0",
      update:false,
      encrypted_id:""
    });
    const [editEncrptId,setEditEncrptId] = useState("")
    const [modalmsg,setModalmsg] = useState("")
    const [deleteModal,setDeleteModal] = useState(false); 
  
    const handleClose = () => {
      setUpdatemodal(false);
      setDeleteModal(false)
    };

const clearvalues = ()=>{
  // setExperience({
  //   candidate_id:"",
  //   job_title:"",
  //   company_name:"",
  //   start_date: new Date(),
  //   end_date: new Date(),
  //   still_working:0,
  // })
  props.deleteCount()
}

  useEffect(()=>{
    if(props.edit){
        let data = props.data
        setExperience({
          candidate_id: data.candidate_id,
          job_title: data.job_title,
          company_name:data.company_name,
          start_date:data.start_date,
          end_date:data.end_date,
          still_working: data.still_working,
          update:false,
          encrypted_id:data.encrypted_id
        }) 
        setEditEncrptId(data.encrypted_id)
    }
  },[])

  useEffect(()=>{
    // experience.update = true
    dispatch({type:"checkUpdateCandidateExpData",payload:[props.index,experience]})
  },[experience])

  useEffect(()=>{
    let check = false
    let checkindex = null
    updatedCandidateExpData.map((data,index)=>{
      if(data?.still_working === '1'){
        check = true
        checkindex =  index
      }
    })
    if(check && (props.index !== checkindex)){
      setCheckDisabeld(true)
    }else{
      setCheckDisabeld(false)
    }
   
    
},[updatedCandidateExpData,CandidateExpList])

useEffect( ()=>{
 
  let newCheck = false
  CandidateExpList.map((data,index)=>{
    if(data?.still_working === '1'){
      newCheck = true
    }
  })
  if(newCheck){
    setCheckDisabeld(true)
  }
},[CandidateExpList])


  const deleteDetails = () =>{
   
    apiDelete(`${DELETE_CANDIDATE_EXPERIRNCE_API_URL}${editEncrptId}`, ({ status, response }) => {
      if (status) {
        setDeleteModal(false)
        // setModalmsg("Experience deleted successfully")
        // setUpdatemodal(true);
        // dispatch({type:"EditCandidateId",payload:""})
        //dispatch({type:"EditCandidateId",payload:props.id})
        dispatch({type:"DeleteUpdatedCandidateExpDataItem",payload:props.index})
      }
  })
  }



  return(
      <>
        <div className="row px-4">
          <div className="col-6">
            <div className="row">
          <div className="col-md-12 mb-3">
            <TextField
              id="standard-search"
              label="Title *"
              type="text"
              className="w-100"
              value={experience.job_title}
              onChange={(e)=>setExperience({...experience,job_title:e.target.value,update:true})}
            />
          </div>
          <div className="col-md-12 ">
            <TextField
              id="standard-search"
              label="Company *"
              type="text"
              className="w-100"
              value={experience.company_name}
              onChange={(e)=>setExperience({...experience,company_name:e.target.value,update:true})}
            />
          </div>
         
          </div>
          </div>
          <div className="col-6">
            <div className="row">
        
          <div className="col-md-12 mb-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                id="date-picker-inline"
                label="Pick From Date *"
                views={["year", "month"]}
                maxDate={moment().toDate()}
                value={experience.start_date}
                onOpen={() => setOpenFromData(true)}
                onClose={() => setOpenFromData(false)}
                error={false}
                //disabled={experience.still_working === "1" ? true : false}
                className="w-100"
                onChange={(date) => {
                  setExperience({...experience,start_date:date,update:true});
                }}
                autoOk={true}
                TextFieldComponent={(props) => (
                  <TextField {...props} onClick={(e) => setOpenFromData(true)} />
                )}
                open={openFromData}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-md-12">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                id="date-picker-inline"
                label="Pick To Date *"
                views={["year", "month"]}
                value={experience.end_date}
                maxDate={moment().toDate()}
                minDate={new Date(experience.start_date)}
                disabled={experience.still_working === "1" ? true : false}
                className="w-100"
                error={false}
                onOpen={() => setOpenEndData(true)}
                onClose={() => setOpenEndData(false)}
                onChange={(date) => {
                  setExperience({...experience,end_date:date,update:true});
                }}
                TextFieldComponent={(props) => (
                  <TextField {...props} onClick={(e) => setOpenEndData(experience.still_working === "1" ? false : true)} />
                )}
                open={openEndData}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
            <br />
            <Checkbox
              name="checkedB"
              color="primary"
              className="p-0 m-0 font-weight-bold"
              disabled={checkDisabeld}
              checked={experience.still_working === "1" ? true : false}
              onChange={(e)=>setExperience({...experience,still_working:e.target.checked ? "1": "0",update:true})}
            />{" "}
            Still working here
          </div>
          </div>
          </div></div>
      
        <div className="row px-4">
          <div className="col-md-12 d-flex justify-content-end" style={{border:"0"}}>
            {showBts && <>
              {/* <button class="btn btn-xs btn-violet text-white mx-2" onClick={props.edit ? Editdetails :addExperience}>
              {props.edit ? "save" : "Add"} 
            </button> */}
            <button class="btn btn-xs btn-green-dark text-white" onClick={props.edit ? ()=>{setDeleteModal(true)} : clearvalues}>
              Delete 
            </button>
            </>}
          
          </div>
        </div>
          
        <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"OK"} btnfunc={handleClose}>
        <span>{modalmsg}</span>
      </CommonModal>
      <CommonModal title={"Deletee"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
      </CommonModal>

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
      </>
   )
  }

  export default React.memo(Experience)
