let initialState = {
    login:false,
    userDetails:{},
    cadidateRoute:[],
    edituserId:"",
    skillsRoute:[],
    EditCandidateId:"",
    EditClientId:"",
    EditJobId:"",
    candidateprofileId:"",
    Viewjoborder:"",
    ViewClientId:"",
    EditClient:"",
    roles_data:[],
}

const PageReduser=(state= initialState,Actions)=>{
    switch (Actions.type){
        case "userdetails":
         return {
             ...state,
             userDetails:Actions.payload
         }

         case "cadidateRoute":
         return{
            ...state,
            cadidateRoute:Actions.payload
         }

         case "skillsRoute":
         return{
            ...state,
            skillsRoute:Actions.payload
         }

         case "EditUserId":
        return{
            ...state,
            edituserId:Actions.payload
         }

         case "EditCandidateId":
            return{
                ...state,
                EditCandidateId:Actions.payload
            }

         case "EditClientId":
            return{
                ...state,
                EditClientId:Actions.payload
            }

         case "EditJobId":
            return{
                ...state,
                EditJobId:Actions.payload 
            }

         case "candidateprofileId":
             return{
                 ...state,
                candidateprofileId:Actions.payload
             }
         case "Viewjoborder":
            console.log('Actions.payload',Actions.payload)
                return{
                    ...state,
                    Viewjoborder:Actions.payload
                }
        case "ViewClientId":
        return{
            ...state,
            ViewClientId:Actions.payload
        }
        case "EditClient":
        return{
            ...state,
            EditClient:Actions.payload
        }
        case "roles_data":
            return{
                ...state,
                roles_data:Actions.payload
            }
        default:
      return state
    }
}

export default PageReduser