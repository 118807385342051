import React from "react";
import "./AddCandidate.scss";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { AutocompleteSkills } from "../../Components/AutoCompletes/AutocompleteSkills";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const CandidateDetails = (props) => {
  const classes = useStyles();
  const clearValues = () => {
    props.setCandidateFields({
      country: "",
      state: "",
      city: "",
      name: "",
      primary_mobile: "",
      secondary_mobile: "",
      primary_email: "",
      secondary_email: "",
      identity_card_name: "",
      identity_card_number: "",
      address: "test",
      exp_years: "",
      exp_months: "",
      current_ctc: "",
      expected_ctc: "",
      fixed_ctc: "",
      variable_ctc: "",
      stocks: "",
      notice_period: "",
      offered_ctc: "",
      expected_joining_date: new Date(),
      resume_source: "",
      resume_subsource: "",
    })
  }

  const limitLength = (e,value,limit) => {
    if(value!= undefined && value.toString().length >= limit){
      e.preventDefault()
    }
  }
  

  return (
    <div className="row  table-custom mb-5">
      <div className="col-md-12">
        <div className="card pt-3 pb-3">
          <div className="row pb-0 px-4">
            <div className="col-12">
              <div className=" d-flex justify-content-start">
                <h4 className="text-center font-weight-bold pb-0 mb-0">
                  Details
                </h4>
              </div>
            </div>

          </div>
          <hr />

          <div className="row px-md-4">
            <div className="col-md-6">
              <table className="table table-bordered">
                <tr>
                  <td colSpan="2">
                    <TextField
                      id="standard-search"
                      label="Candidate name *"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.name}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, name: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      label="Primary Mobile No *"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.primary_mobile}
                      // onKeyPress={(e)=>limitLength(e,e.target.value,10)}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, primary_mobile: e.target.value })}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </td>
                  <td>
                    <TextField
                      label="Secondary Mobile No"
                      type="text"
                      className="w-100"
                      // onKeyPress={(e)=>limitLength(e,e.target.value,10)}
                      value={props.candidateFields.secondary_mobile}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, secondary_mobile: e.target.value })}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField

                      label="Primary Email ID *"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.primary_email}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, primary_email: e.target.value })}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </td>
                  <td>
                    <TextField

                      label="Secondary Email ID"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.secondary_email}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, secondary_email: e.target.value })}
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      id="standard-search"
                      label="Country"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.country}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, country: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextField

                      label="City "
                      type="text"
                      className="w-100"
                      value={props.candidateFields.city}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, city: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    {" "}
                    <TextField
                      label="State"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.state}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, state: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField

                      label="Identy Card Name "
                      type="text"
                      className="w-100"
                      value={props.candidateFields.identity_card_name}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, identity_card_name: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextField

                      label="Identity Card No"
                      type="text"
                      className="w-100"
                     // onKeyPress={(e)=>limitLength(e,e.target.value,30)}
                      value={props.candidateFields.identity_card_number}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, identity_card_number: e.target.value })}
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div className="col-md-6">
              <table className="table table-bordered">
                <tr>
                  <td>
                    <TextField
                      label="Experience in Years *"
                      type="number"
                      className="w-100"
                      onKeyPress={(e)=>limitLength(e,e.target.value,2)}
                      value={props.candidateFields.exp_years}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, exp_years: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextField
                      label="Experience in Months *"
                      type="number"
                      className="w-100"
                      onKeyPress={(e)=>limitLength(e,e.target.value,2)}
                      value={props.candidateFields.exp_months}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, exp_months: e.target.value })}
                      inputProps={{
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      id="standard-search"
                      label="Current CTC *"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.current_ctc}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, current_ctc: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextField
                      id="standard-search"
                      label="Expected CTC *"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.expected_ctc}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, expected_ctc: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      id="standard-search"
                      label="Fixed CTC "
                      type="text"
                      className="w-100"
                      value={props.candidateFields.fixed_ctc}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, fixed_ctc: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextField
                      id="standard-search"
                      label="Variable CTC "
                      type="text"
                      className="w-100"
                      value={props.candidateFields.variable_ctc}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, variable_ctc: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <TextField
                      id="standard-search"
                      label="Stock Options (P.A)"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.stocks}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, stocks: e.target.value })}
                    />
                  </td>
                  <td>
                    <TextField
                      id="standard-search"
                      label="Notice Period *"
                      type="text"
                      className="w-100"
                      value={props.candidateFields.notice_period}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, notice_period: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="resourseSelect" style={{paddingTop:"10px"}}>
                    {/* <strong>--Resume Source--</strong> */}
                    <FormControl className={classes.formControl}  >
                    <InputLabel id="demo-simple-select-label" style={{ margin: "0px", padding: "0px" }}>--Resume Source--</InputLabel>
                    <Select className="w-100" minRows={10} 

                    onChange={(e) => props.setCandidateFields({ ...props.candidateFields, resume_source: e.target.value })} 
                    value={props.candidateFields?.resume_source}
                    id="demo-simple-select"
                    labelId="demo-simple-select-label">
                      <MenuItem value="Naukri">Naukri</MenuItem>
                      <MenuItem value="Linkedin">Linkedin</MenuItem>
                      <MenuItem value="Referral">Referral</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                    </FormControl>
                  </td>
                  <td>

                    <TextField
                      id="standard-search"
                      label="Resume Sub Source "
                      type="text"
                      className="w-100"
                      value={props.candidateFields.resume_subsource}
                      onChange={(e) => props.setCandidateFields({ ...props.candidateFields, resume_subsource: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                  >
                    <AutocompleteSkills 
                    setSkills={props.setSkills} 
                    label={"Key Skills - Select max of 4 skills"} 
                    Skills={props.Skills} 
                    edit={props.edit}
                    isParsing={props?.isParsing}
                    />
                  </td>
                </tr>
              
              </table>
            </div>
          </div>
        </div>
      </div>
      
      {/* {props.edit ?
      <div className="text-center">
        <div className=" d-inline-block mt-3">
          <button class="btn btn-xs btn-green-dark text-white " onClick={clearValues}>
            Cancel
          </button>
        </div>
        <div className=" d-inline-block mx-2">
          <button class="btn btn-xs btn-orange text-white" onClick={props.saveCandidate}>Save</button>
        </div>
      </div>
      :""} */}

    </div>

  )
}
