import React,{useState,useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import {GET_CANDIDATE_INVOICE_PROCESS_STATUS } from '../../../Configurations/ConfigApi';
import { apiPost,apiDelete,apiEdit } from '../../../ApiActions/Actions';
import CommonModal from '../../../Components/Models/commonModal';
import SnakBar from "../../../Components/Models/snakbar";
import { useDispatch } from "react-redux";

 const Invoice = (props) => {

  const [updateModal,setUpdatemodal] = useState(false);
  const [showBts,setShowbts] = useState(true);
  const [fieldserror,setFieldErros] = useState(false)
  const [errors,setErrors] = useState({})
  const dispatch = useDispatch()

  const [invoice,setInvoice] = useState({
    client_id: "",
    steps:"",
    encrypted_id:'',
  });
  
const [editEncrptId,setEditEncrptId] = useState("")
const [modalmsg,setModalmsg] = useState("")
const [deleteModal,setDeleteModal] = useState(false); 

const handleClose = () => {
  setUpdatemodal(false);
  setDeleteModal(false)
};

const clearvalues = ()=>{

  props.deleteCount()
  dispatch({type:"DeleteClientInvoice",payload:props.index})
}

useEffect(()=>{
  if(props.edit){
    dispatch({
      type: "checkUpdateClientInvoice",
      payload: [props.index, invoice],
    });
  }else{
    dispatch({
      type: "UpdateClientInvoice",
      payload: [props.index, invoice],
    });
  }
},[invoice])

useEffect(()=>{
  if(props.edit){
      let data = props.data
      setInvoice({
        steps: data.steps,
        encrypted_id: data.encrypted_id, 
      }) 
      setEditEncrptId(data.encrypted_id)
  }else{
    dispatch({ type: "AddClientInvoice"});
  }
  },[])


const deleteDetails = () =>{
apiDelete(`${GET_CANDIDATE_INVOICE_PROCESS_STATUS}/${editEncrptId}`, ({ status, response }) => {
  if (status) {
    setDeleteModal(false)
    dispatch({type:"DeleteUpdatedClientInvoice",payload:props.index})
  }
})
}


  return(
    <>

        <div className="row px-4">
          <div className="col-md-12">
            <table className="table table-bordered">
              <tr>
                <td colSpan="2">
                  <TextField
                    id="standard-search"
                    label="Steps"
                    type="text"
                    className="w-100"
                    value={invoice.steps}
                    onChange={(e)=>setInvoice({...invoice,steps:e.target.value,update:true})}
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row px-4">
      <div class="col-md-12 d-flex justify-content-end">

      {showBts && <>
      {/* <button class="btn btn-xs btn-violet text-white mx-2" onClick={props.edit ?Editdetails : addInvoice}>
              {props.edit ?"Save":"Add"} 
        </button> */}
        <button class="btn btn-xs btn-green-dark text-white" onClick={
          props.edit ? ()=>{setDeleteModal(true)} : clearvalues}>Delete</button>
      </>}
      </div>
    </div>


    <CommonModal title={"success"} handleClose={handleClose} open={updateModal} button={"ok"} btnfunc={handleClose}>
       {modalmsg}
    </CommonModal>

    <CommonModal title={"delete"} handleClose={handleClose} open={deleteModal} button={"OK"} btnfunc={deleteDetails}>
        <span>Are you sure you want to delete?</span>
    </CommonModal>


    <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
      </>
   )
  }

  export default Invoice