import React,{useState,useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {apiPost ,fetchUrl} from '../../ApiActions/Actions'
import { GET_USER_API_URL,GET_ROLES_API_URL,UPDATE_USER_API_URL,GET_EMAILS_API_URL} from '../../Configurations/ConfigApi'
import CommonModal from '../../Components/Models/commonModal';
import ErrorModal from '../../Components/Models/errorModal';
import SnakBar from "../../Components/Models/snakbar";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
// Login css
import "./register.scss";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin:"0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Edituser = (props) => {

  const classes = useStyles();
  const [age, setAge] = useState("");
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [email,setEmail] = useState("");
  const [updateModal,setUpdatemodal] = useState(false);
  const [errorModal,setErrorModal]= useState(false);
  const [fieldserror,setFieldErros] = useState(false)
  const [errors,setErrors] = useState({})
  const [updatedUserData,setUpdatedUserData] = useState({
    first_name: "",
    last_name: "",
    password:"",
    password_confirmation:"",
    role: "",
    mobile: "",
    encrypted_id: "",
    email: "",
    status:"",
})

const edituserId = useSelector(state=>state.PageReduser.edituserId)
const [emails,setEmails] = useState([])

useEffect(()=>{
  fetchUrl(GET_ROLES_API_URL, ({ status, response }) => {
    if (status) {
        setRoles(response.data)
    }
})
  searchEmail(edituserId)
},[])

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setUpdatemodal(false);
    setErrorModal(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const searchEmail =(prop)=>{
    let data = email ? email : prop
    if(data){
    fetchUrl(`${GET_USER_API_URL}?email=${data}`,({ status, response }) => {
      if (status) {
         let userData = response.data?.user[0]
         setUpdatedUserData({
          first_name: userData.first_name,
          last_name: userData.last_name,
          password:"",
          password_confirmation:"",
          role: userData.role,
          mobile: userData.mobile,
          encrypted_id: userData.encrypted_id,
          email: userData.email,
          status: userData.status
         })
      }
    })
  }
  }

  
  const onStringCheck=(e,type)=>{
    if (!/[^a-zA-Z]/.test(e.target.value)){
      setUpdatedUserData({...updatedUserData,[type]:e.target.value})
    }
  }



  const updateUserDetails=()=>{
    const {first_name,role,mobile}=updatedUserData
   
    if(first_name && role  && mobile ){
      apiPost(UPDATE_USER_API_URL, updatedUserData, ({ status, response }) => {
        if (status) {
           setUpdatemodal(true);
        }else{
          // setErrorModal(true)
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
        }
    })
    }else{
     
    }
  }
  

  const getEmails=(value)=>{
  
      let searchData={
        email:value
      }
     apiPost(GET_EMAILS_API_URL, searchData, ({ status, response }) => {
       if (status) {
          let users = response.data.user
          for (let i = 0; i < users.length; i++) {
            users[i].title = users[i].email
          }
          setEmails(users)
       }else{
        setErrorModal(true)
      }
   })
    
  }

  const defaultProps = {
    options: emails,
    getOptionLabel: (option) => option.title,
  };


  return (
    <>
      <div className="container-fluid pr-0 pl-0">
        <div className="">
          <div className="col-md-6 ">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-9">
                      {updatedUserData.email ? <TextField
                      className="w-100"
                      id="standard-search"
                      label="Email"
                      type="text"
                      value={updatedUserData.email}
                      onChange={(e)=>setUpdatedUserData({...updatedUserData,email:e.target.value})}
                    />: <Autocomplete
                    {...defaultProps}
                    id="email"
                    debug
                    onChange={(event, newValue) => {
                      setEmail(newValue?.title);
                    }}
                    renderInput={(params) => <TextField {...params} label="Email ID search"  className="w-100 mt-0" defaultValue={"dd"} onChange={(e)=>getEmails(e.target.value)} />}
                  />}
                      </div>
                      <div className="col-md-3">
                        <button className="btn btn-xs btn-orange text-white pe-4 px-4 mb-0 my-3" onClick={searchEmail}>
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <TextField
                      className="w-100"
                      id="standard-search"
                      label="User Name"
                      type="text"
                      value={updatedUserData.first_name}
                      onChange={(e)=>onStringCheck(e,"first_name")}
                    />
                  </div>
                  <div className="col-12 ">
                    <TextField
                      className="w-100"
                      id="standard-search"
                      label="Mobile No."
                      type="number"
                      value={updatedUserData.mobile}
                      onChange={(e)=>setUpdatedUserData({...updatedUserData,mobile:e.target.value})}
                    />
                  </div>
                  <div className="col-5 py-1">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel
                      className="w-100"
                      id="demo-controlled-open-select-label"
                    >
                      Select Role
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={updatedUserData.role}
                      className="w-100"
                      onChange={(e)=>setUpdatedUserData({...updatedUserData,role:e.target.value})}
                      minRows={20}
                    >
                      {roles.map((role)=>{
                        return  <MenuItem value={role.role_id}>
                        {role.role_name}
                      </MenuItem>
                      })}
                    </Select>
                    </FormControl>
                  </div>
                  
                  <div className="clearfix"></div>

                  <div className="col-5 py-1">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel
                      className="w-100"
                      id="demo-controlled-open-select-label"
                    >
                      Select Status
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      className="w-100"
                      value={updatedUserData.status}
                      onChange={(e)=>setUpdatedUserData({...updatedUserData,status:e.target.value})}
                    >
                     <MenuItem value={"active"} >
                       Active
                      </MenuItem>
                      <MenuItem value={"inactive"} >
                       InActive
                      </MenuItem>
                    </Select>
                    </FormControl>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-5">
                    <TextField
                      id="standard-search"
                      label="Password"
                      type="password"
                      value={updatedUserData.password}
                      onChange={(e)=>setUpdatedUserData({...updatedUserData,password:e.target.value})}
                      className="w-100"
                    />
                  </div><div className="clearfix"></div>
                  <div className="col-5">
                    <TextField
                      id="standard-search"
                      label="Confirm pasword"
                      type="password"
                      className="w-100"
                      value={updatedUserData.password_confirmation}
                      onChange={(e)=>setUpdatedUserData({...updatedUserData,password_confirmation:e.target.value})}
                    />
                  </div>
                  
                </div>
          </div>
          <div className="col-12 text-center ">
                    <button className="btn btn-xs btn-green-dark text-white " onClick={updateUserDetails}>
                      Save
                    </button>
                  </div>
        </div>
      </div>
      <CommonModal title={"Success"} handleClose={handleClose} open={updateModal} button={"OK"} btnfunc={handleClose}>
        <span>User Updated Successfully</span>
      </CommonModal>
      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
      <ErrorModal handleClose={handleClose} open={errorModal}>

</ErrorModal>
    </>
  );
};

export default Edituser;
