import axios from "axios";

export const apiPost = (url, data, callBack) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };

  axios(url, {
    method: "post",
    data,
    headers
  })
    .then(response => {
      
       callBack({ status: true, response: response })
    }) // need to write another call fn
    .catch(error => {
      callBack({ status: false, response: error })
    });
};

export const fetchUrl = (url, callBack) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };

  axios
    .get(url, {
      headers
    })
    .then(response => {
      callBack({ status: true, response: response }); 
    })
    .catch(error => {
      if(error?.response?.data?.message === 'Unauthenticated.'){
        localStorage.removeItem('token')
        window.location.href='/'
      }else{
        callBack({ status: false, response: error });
      }
      
    });
};

export const apiEdit = (url, data, callBack) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };

  axios(url, {
    method:"put",
    data,
    headers
  })
    .then(response => callBack({ status: true, response: response })) // need to write another call fn
    .catch(error => callBack({ status: false, response: error }));
};


export const apiDelete = (url, callBack) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };

  axios
    .delete(url,{
    headers
  })
    .then(response => callBack({ status: true, response: response })) // need to write another call fn
    .catch(error => callBack({ status: false, response: error }));
};


export const apiPatch = (url, data, callBack) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };

  axios(url, {
    method: "patch",
    data,
    headers
  })
    .then(response => {
      
       callBack({ status: true, response: response })
    }) // need to write another call fn
    .catch(error => {
      callBack({ status: false, response: error })
    });
};