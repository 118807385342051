import React,{useState,useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import {apiPost,fetchUrl,apiDelete} from '../../ApiActions/Actions';
import {GET_JOB_ATTACHMENT_API_URL} from '../../Configurations/ConfigApi'
import SnakBar from "../../Components/Models/snakbar";
import CommonModal from '../../Components/Models/commonModal';
import { saveAs } from 'file-saver';

export const Attachments = (props) => {

    const [attachments,setAttachments] = useState(false)
    const [open,setOpen] = useState(false)
    const [file_name,setFile_name] = useState({})
    const [description,setDescription] = useState("")
    const [filepath,setFilepath] = useState("File_name.docx")
    const [fieldserror,setFieldErros] = useState(false)
    const [errors,setErrors] = useState({})
    const [deleteModal,setDeleteModal] = useState(false)
    const [updateModal,setUpdatemodal] = useState(false);
    const [attachmentsData,setAttachmentsData] = useState([]);
    const [deleteId,setDeleteId] = useState('')
    const [attachmentupdates,setAttachmentupdates] = useState(false);
    const [attachmentUrl,setAttachmentUrl] = useState("")
    const [successMsg,setSuccessMsg] = useState({})

    const addAttachfile = ()=>{
      document.getElementById("attachmentfile").click()
    }

    const getFilepath = (e)=>{
    
      setFile_name(e.target.files[0])
      setFilepath(e.target.files[0].name)
    }

    const sendAttachment = ()=>{
      let id = props.id;
      let data = new FormData()
      if(props.module === "client"){
        data.append("client_id",id)
        data.append("file_name",file_name)
        data.append("description",description)
      }else if(props.module==="candidate"){
        data.append("candidate_id",id)
        data.append("file_name",file_name)
        data.append("description",description)
      }else if(props.module ==="joborder"){
        data.append("job_id",id)
        data.append("file_name",file_name)
        data.append("description",description)
      }
     
  

      apiPost(props.api, data, ({ status, response }) => {
        if (status) {
          setSuccessMsg({notes:["Added Successfully"]})
          setUpdatemodal(true)
          setAttachmentupdates(!attachmentupdates)
          setAttachments(false)
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
      })
    }

    useEffect(()=>{
      fetchUrl(`${props.api}${props.apiext}`, ({ status, response }) => {
          if (status) {
          let data = response?.data?.data
           setAttachmentsData(data.reverse())
          }else{
              let msg = JSON.parse(response.request.response).errors
              setErrors(msg)
              setFieldErros(true)
          }
      })
  },[attachmentupdates])

  const deleteNotes = ()=>{
      apiDelete(`${props.api}/${deleteId}`, ({ status, response }) => {
          if (status) {
            setSuccessMsg({notes:["Deleted Successfully"]})
            setUpdatemodal(true)
              setDeleteModal(false)
              setAttachmentupdates(!attachmentupdates)
          }else{
              let msg = JSON.parse(response.request.response).errors
              setErrors(msg)
              setFieldErros(true)
          }
      })
  }

  return(
    <div className="job-order-section">
        <div className="row">
            <div className="col-md-8">
              <h6 className="text-underline pb-3"> Attachments</h6>
            </div>
            <div className="col-md-4 px-4">
              <button class="btn btn-xs btn-green-dark text-white btn-violet f-13" onClick={()=>setAttachments(!attachments)}>
               {attachments ? "Cancel" : "Add"} 
              </button>
            </div>
          </div>

          {attachments ? 
          <div className={`row no-gutters card-design mb-3 ${attachments ? "tabActive":""}`}>
            <div className="col-md-12">
              <div className="card box-shadow-none">
                <div className="card-header pb-0 text-left ">
                  <h5 className="text-center f-13">Attach File </h5>
                </div>
                <div className="card-body pt-1 mt-1">
                  <div className="row pt-3">
                    <div className="col-12 mb-3 text-center">
                      <input type="file" className="d-none" id="attachmentfile" onChange={(e)=>getFilepath(e)}/>
                      <button className="btn btn-xs btn-green-dark text-white p-4 pt-2 pb-2 mb-1" onClick={addAttachfile}>
                        Choose File
                      </button>
                      <br />
                      <span className="text-aqua">{filepath}</span>
                    </div>
                    <div className="col-12 mb-3 ">
                      <textarea
                        name=""
                        id=""
                        rows="1"
                        className="form-control"
                        placeholder="File Description  *"
                        onChange={(e)=>setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-12 text-center py-0 my-0 pb-0 mb-0">
                      <button class="btn btn-xs btn-orange text-white pe-4 px-4 mb-0 mx-4" onClick={sendAttachment}>
                        Add
                      </button>
                      {/* <button className="btn btn-xs btn-green-dark text-white ">
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :""}
     
          <hr className="p-0 m-0 mb-3" />
          {attachmentsData.map((res)=>{
            return <div>
            <div className="col-12 mb-3 position-relative">
              <input
                name=""
                id=""
                rows="2"
                className="form-control file-desc pt-4 pb-5"
                placeholder="File Description *"
               
                value={res.description}
              />
             

              <div className="col-md-12 justify-content-end d-flex pos-icons">
                <h6 className="f-13">
                  <i class="far fa-eye pe-3" onClick={()=>{
                    setOpen(true)
                    setAttachmentUrl(res.attachment_url)
                    }}></i>
                  <i class="fas fa-download pe-3" onClick={() => {
                      saveAs(res.attachment_url,res?.attachment_name)
                  }}></i>
                  <i class="far fa-trash-alt" onClick={()=>{
                    setDeleteModal(true)
                    setDeleteId(res.encrypted_id)
                    }}>
                  </i>
                </h6>
              </div>
            </div>
          </div>
          
          })}

    <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>
    {/* <CommonModal title={"success"} handleClose={()=>setUpdatemodal(false)} open={updateModal} button={"OK"} btnfunc={()=>setUpdatemodal(false)}>
                 <span>Attachments Added successfully</span>
    </CommonModal> */}
    <CommonModal title={"Delete"} handleClose={()=>setDeleteModal(false)} open={deleteModal} button={"DELETE"} btnfunc={deleteNotes}>
    <span>Are you sure you want to delete Attachments ?</span>
    </CommonModal>

    <SnakBar error={successMsg} open={updateModal} onClose={() => setUpdatemodal(false)} type="success"/>

    <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >

    <iframe src={attachmentUrl} width="1200px" height="600px"/>

      </Dialog>
            
        </div>
      
   )
  }
