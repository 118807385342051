import React, {  useState , useEffect} from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteSkills } from "../../Components/AutoCompletes/AutocompleteSkills";
import { AutocompleteUsers } from "../../Components/AutoCompletes/AutocompleteUsers";
import { Autocompletejobs } from "../../Components/AutoCompletes/Autocompletejobs";
import { fetchUrl } from "../../ApiActions/Actions";
import {
  GET_CLIENTS_URL,GET_QULIFICATION_API, GET_CLIENT_POINT_CONTACT_DETAILS_STATUS
} from "../../Configurations/ConfigApi";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin:"0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export const AddjobOrdersForm = (props) => {
  
  const classes = useStyles();
  const [emails, setEmails] = useState([])
  const [qulifications,setQulifications] = useState([])
  const [possitiontypes,setpossitiontypes] = useState([])
  const [openoutstationtypes,setopenoutstationtypes] = useState([])
  const [contactnames,setContactnames] = useState([])

  const getJObclients= (value) => {
    fetchUrl(`${GET_CLIENTS_URL}?q=${value}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data       
        setEmails(data)
      } else {
        // setErrorModal(true)
      }
    })
  }

  const defaultProps = {
    options: emails,
    getOptionLabel: (option) => option.client_short_name,
  };

  const setContactnamesbasedonclient = (clientid) =>{    
    fetchUrl(`${GET_CLIENT_POINT_CONTACT_DETAILS_STATUS}/${clientid}`, ({ status, response }) => {
      if (status) {
       setContactnames(response.data?.data)
      }
    })
  }

  
  useEffect(()=>{
    if(props.edit){
      let details  = [...props.contactdetails]     
      setContactnames(details);
    }  
  },[props])

  useEffect(()=>{
    if (possitiontypes.length === 0){
      possitiontypes.push({id: 1, name: 'Full Time'});
      possitiontypes.push({id: 2, name: 'Contract'});
    }

    if (openoutstationtypes.length === 0){
      openoutstationtypes.push({id: 1, name: 'Yes'});
      openoutstationtypes.push({id: 2, name: 'No'});
    }     
     
 
  },[]);

  useEffect(()=>{
    fetchUrl(`${GET_QULIFICATION_API}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data
    
      
        setQulifications(data)
      } else {
        // setErrorModal(true)
      }
    })

    fetchUrl(`${GET_CLIENTS_URL}`, ({ status, response }) => {
      if (status) {
        let data = response.data.data
        console.clear();
        setEmails(data)
      } else {
        // setErrorModal(true)
      }
    })
  },[])

  return(
    <div className="row  table-custom mb-5">
            <div className="col-md-12">
              <div className="card pt-3 pb-3">
              <div className="row pb-0 px-4">
                  <div className="col-12">
                    <div className=" d-flex justify-content-start">
                      <h4 className="text-center font-weight-bold pb-0 mb-0">
                       Job Details
                      </h4>
                    </div>
                  </div>
                  
                </div>
               <hr/>

                <div className="row px-4">
                  <div className="col-md-6">
                    <table className="table table-bordered">
                      <tr>
                        <td colSpan="2">

                      <TextField
                         id="standard-search"
                         label="Job Title *"
                         type="text"
                         className="w-100"
                         value={props.jobOrderFields.job_title}
                         onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,job_title:e.target.value})}
                       />
                        </td>
                      </tr>
                      <tr>
                        <td>
                        {props?.jobOrderFields?.client_name ? <TextField
                         id="standard-search"
                         label="Select Client *"
                         type="text"
                         className="w-100"
                         value={props?.jobOrderFields?.client_name}
                         onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,client_name:e.target.value})}
                       />: 
                       <Autocomplete
                       {...defaultProps}
                      
                      debug
                      onChange={(event, newValue) => {
                        props.setJobOrderFields({...props.jobOrderFields,client:newValue?.encrypted_id});
                        setContactnamesbasedonclient(newValue?.encrypted_id);
                      }}
                      renderInput={(params) => <TextField {...params} label="Select Client *"  className="w-100 mt-0" defaultValue={"dd"} onChange={(e)=>getJObclients(e.target.value)} />}
                    />}
                       
                       
                        </td>
                        <td>
                        <FormControl className={classes.formControl} fullWidth>
                        <InputLabel className="w-100" id="demo-controlled-open-select-label">Select Contact Name</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            className="w-100"
                            minRows={10}
                            value={props.jobOrderFields.contact}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,contact:e.target.value})}                        >
                         
                          {contactnames.map((role) => {
                            return <MenuItem value={role.encrypted_id}>
                              {role.client_contact_name}
                            </MenuItem>
                          })}
                        </Select>
                        </FormControl> 
                          {/* <TextField
                           
                            label="Select Contact Name"
                            type="text"
                            className="w-100"
                            value={props.jobOrderFields.contact}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,contact:e.target.value})}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                          
                            label="Country *"
                            type="text"
                            className="w-100"
                            value={props.jobOrderFields.country}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,country:e.target.value})}
                          />
                        </td>
                        <td>
                          <TextField
                          
                            label="City *"
                            type="text"
                            className="w-100"
                            value={props.jobOrderFields.city}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,city:e.target.value})}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td >
                          {" "}
                          <TextField
                         
                            label="State *"
                            type="text"
                            className="w-100"
                            value={props.jobOrderFields.state}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,state:e.target.value})}
                          />
                        </td>

                        <td >
                          {" "}
                          <TextField
                          
                            label="Office Name/Land mark *"
                            type="text"
                            className="w-100"
                            value={props.jobOrderFields.office_landmark}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,office_landmark:e.target.value})}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                           
                            label="Min. Experience *"
                            type="number"
                            className="w-100"
                            value={props.jobOrderFields.min_experience}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,min_experience:e.target.value})}
                          />
                        </td>
                        <td>
                          <TextField
                           
                            label="Max. Experience *"
                            type="number"
                            className="w-100"
                            value={props.jobOrderFields.max_experience}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,max_experience:e.target.value})}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TextField
                         
                            label="Maximum Budget *"
                            type="text"
                            className="w-100"
                            value={props.jobOrderFields.max_budget}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,max_budget:e.target.value})}
                          />
                        </td>
                        
                        <td>
                          <TextField
                           
                            label="No. of Positions"
                            type="numbere"
                            className="w-100"
                            value={props.jobOrderFields.no_positions}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,no_positions:e.target.value})}
                          />
                        </td>
                      </tr>
                      <tr colSpan="2">
                        <td>                         
                        <FormControl className={classes.formControl} fullWidth>
                        <InputLabel className="w-100" id="demo-controlled-open-select-label">Open for Outstation ?</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            className="w-100"
                            minRows={10}
                            value={props.jobOrderFields.open_outstation}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,open_outstation:e.target.value})}                        >
                         
                          {openoutstationtypes.map((role) => {
                            return <MenuItem value={role.name}>
                              {role.name}
                            </MenuItem>
                          })}
                        </Select>
                        </FormControl>                         
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div className="col-md-6">
                    <table className="table table-bordered">
                    <tr colSpan="2">
                        <td>                         
                        <FormControl className={classes.formControl} fullWidth>
                        <InputLabel className="w-100" id="demo-controlled-open-select-label">Position Type</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            className="w-100"
                            minRows={10}
                            value={props.jobOrderFields.position_type}
                            onChange={(e)=>props.setJobOrderFields({...props.jobOrderFields,position_type:e.target.value})}
                        >
                         
                          {possitiontypes.map((role) => {
                            return <MenuItem value={role.name}>
                              {role.name}
                            </MenuItem>
                          })}
                        </Select>
                        </FormControl>                         
                        </td>
                      </tr>


                      <tr colSpan="2">
                        <td>
                        <Autocompletejobs 
                          setQualifications={props?.setQualifications} 
                          label={"Select Qualification"}
                          edit={props?.edit}
                          updatequalifications={props?.updatequalifications}
                          />                         
                        </td>
                      </tr>

                      <tr colSpan="2">
                        <td>
                          <AutocompleteSkills 
                          setSkills={props?.setPrimaryskill} 
                          label={"Primary Skills"}
                          edit={props?.edit}
                          Skills={props?.updatePrimariSkills}
                          />
                          
                        </td>
                      </tr>
                      <tr colSpan="2">
                        <td>
                        <AutocompleteSkills 
                        setSkills={props?.setSecondaryskills} 
                        label={"Secondary Skills"}
                        edit={props?.edit}
                        Skills={props?.updateSecondarySkills}
                        />
                        </td>
                      </tr>
                      <tr colSpan="2">
                        <td>
                          <AutocompleteUsers 
                          setMethod={props.setRecruiters} 
                          label="Recruiter/s"
                          edit={props?.edit}
                          users={props?.updateRecruiters}
                          />
                          
                        </td>
                      </tr>
                      <tr colSpan="2">
                        <td>
                          <AutocompleteUsers 
                          label="Manager/s" 
                          setMethod={props.setManagers}
                          edit={props?.edit}
                          users={props?.updateManagers}
                          />
                        </td>
                      </tr>
                      <tr colSpan="2">
                        <td>
                           <AutocompleteUsers 
                           label="Coordinator/s" 
                           setMethod={props.setCoordinators}
                           edit={props?.edit}
                           users={props?.updateCoordinators}
                           />
                        </td>
                      </tr>
                      
                    </table>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
       
   )
  }
