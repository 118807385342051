import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useDispatch , useSelector} from "react-redux";

// Image Iocns
import Phone from "../../../../Assets/Images/profile-t-icons/Phone.svg";
import t7 from "../../../../Assets/Images/profile-t-icons/t7.svg";
import t2 from "../../../../Assets/Images/profile-t-icons/t2.svg";
import t3 from "../../../../Assets/Images/profile-t-icons/t3.svg";
import Email from "../../../../Assets/Images/profile-t-icons/email.svg";
import { fetchUrl, apiEdit } from "../../../../ApiActions/Actions";
import {

  GET_CANDIDATE_NOTES_API_URL,
  GET_CANDIDATE_JOB_ORDERS_STATUS,
  GET_JOB_MAINSTATUS_API_URL,
  GET_JOB_MAINSUBSTATUS_API_URL,
  GET_JOBORDER_STATUS,
  GET_JOBORDER_STATUSCOUNT,
  GET_JOB_CANDIDATES_DETAILS,
} from "../../../../Configurations/ConfigApi";
import CommonModal from '../../../../Components/Models/commonModal';
import {Notes} from "../../../../Components/sidenavTabs/notes";
import { Interview } from "../../../../pages/CandidateProfile/sideNavtabs/interview";
import { MassageCandidates } from "../../../../pages/CandidateProfile/sideNavtabs/massageCandidates";
import SnakBar from "../../../../Components/Models/snakbar";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Active = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const [JobStatuscount, setJobStatuscount] = useState([]);
  const [updateStatusModal,setUpdateStatusModal] = useState(false)
  const [updateMainStatusId,setUpdateMainStatusId] = useState('')
  const [subStatatusId,setSubStatusId] = useState('')
  const [updateEncriptedId,setUpdateEncriptedId] = useState('')
  const [mainStatusData,setMainStatusData] = useState([])
  const [mainStatus,setMainStatus] = useState('')
  const [mainSubStatusData,setMainSubStatusData] = useState([])
  const [errors, setErrors] = useState({})
  const [successMsg,setSuccessMsg] = useState({})
  const [fieldserror, setFieldErros] = useState(false)
  const [selectedcandidate, setselectedcandidate] = useState('0')
  const [JoinedStatus, setJoinedStatus] = useState([]);
  const [ActiveStatus, setActiveStatus] = useState([]);
  const [OfferedStatus, setOfferedStatus] = useState([]);
  const [ProcessedStatus, setProcessedStatus] = useState([]);
  const [ProspectiveStatus, setProspectiveStatus] = useState([]);
  const [SelectedStatus, setSelectedStatus] = useState([]);
  const [Popuptitle, setPopuptitle] = useState("");
  const [updateModal,setUpdatemodal] = useState(false);
  const [Showinterview, setShowinterview] = useState(false);
  const [Shownotes, setShownotes] = useState(false);
  const [Showmessage, setShowmessage] = useState(false);
  const [candidate_id, setcandidate_id]=useState("");
  const Viewjoborder = useSelector(state=>state.PageReduser.Viewjoborder);
  

  useEffect(() => {  
    fetchUrl(`${GET_JOBORDER_STATUSCOUNT}/${Viewjoborder}`, ({ status, response }) => {
      if (status) {
        let stautscnt =[];             
        let data = response?.data[0]; 
         if (response?.data.length > 0){         
          
          stautscnt.push({name:"Joined",count:data.Joined}); 
          stautscnt.push({name:"Active",count:data.Active}); 
          stautscnt.push({name:"Offered",count:data.Offered});  
          stautscnt.push({name:"Processed",count:data.Processed}); 
          stautscnt.push({name:"Prospective",count:data.Prospective});   
          setJobStatuscount(stautscnt);        
         }                        
                  
        }
      });
       Setcandidatedetails("Active"); 
       fetchUrl(`${GET_JOB_MAINSTATUS_API_URL}`, ({ status, response }) => {
        if (status) {
        
         setMainStatusData(response.data?.data)
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })     
  },[]);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Gotocandidateprofile = (candidate_id) => {
    sessionStorage.setItem("profileId",candidate_id)
    dispatch({type:"candidateprofileId",payload:candidate_id})    
    dispatch({type:"candidateprofileId",payload:candidate_id})
    sessionStorage.setItem("gototcandidate","true");       
  }
  const updateMainStatus = (candidate_id) => {
    fetchUrl(`${GET_CANDIDATE_JOB_ORDERS_STATUS}?candidate_id=${candidate_id}`, ({ status, response }) => {
      if (status) {       
        let filterData = response?.data?.data
        let obj = filterData.filter((res)=> res.job_id === props.jobId)      
    getsubStatus(obj[0]?.main_status_id)   
    setSubStatusId(obj[0]?.main_substatus_id)
    setUpdateEncriptedId(obj[0]?.encrypted_id) 
    setUpdateMainStatusId(obj[0]?.main_status_id)
    setUpdateStatusModal(true)                              
        }
      });    
  }
  const getsubStatus=(value)=>{
    setMainStatus(value)
    fetchUrl(`${GET_JOB_MAINSUBSTATUS_API_URL}?main_status_id=${value}`, ({ status, response }) => {
      if (status) {

       setMainSubStatusData(response.data?.data)
      }else{
          let msg = JSON.parse(response.request.response).errors
          setErrors(msg)
          setFieldErros(true)
      }
  })
  }

  const Getcurrentdatetime = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return parseDate(date + " " + time);
  };

  function parseDate(input) {
    var parts = input.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  const Getcreatedtimedetails = (sub_status_created_at) => {
    let currentdate = Getcurrentdatetime();
    let stausdate = parseDate(sub_status_created_at);
    let time_difference = currentdate.getTime() - stausdate.getTime();
    let days_difference = Math.floor(time_difference / (1000 * 60 * 60 * 24));

    if (days_difference > 0)
      return " ( " + days_difference.toString() + " Days ago )";

    if (Math.floor(time_difference) > 0)
      return " ( " + Math.floor(time_difference).toString() + " Hours ago )";

    var minutes = Math.floor(time_difference / 1000 / 60);

    if (minutes > 0) return " " + minutes.toString() + " Minutes ago )";

    return " ( Just now )";
  };

  const Binddetails = (statusname) => {    
    fetchUrl(
      `${GET_JOB_CANDIDATES_DETAILS}/${Viewjoborder}/${statusname.toLowerCase()}`,
      ({ status, response }) => {
        if (status) {           
          if (statusname === 'Joined'){
            setJoinedStatus(response.data.data);
          }
          if (statusname === 'Active'){
            setActiveStatus(response.data.data);
          }
          if (statusname === 'Offered'){
            setOfferedStatus(response.data.data);
          }
          if (statusname === 'Processed'){
            setProcessedStatus(response.data.data);
          }
          if (statusname === 'Prospective'){
            console.clear();
            setProspectiveStatus(response.data.data);
          }          
        }
      }
    ); 
  }

  const Setcandidatedetails = (statusname) => {
    setSelectedStatus(statusname);
    Binddetails(statusname);
  };

  function Composemail(emailto){
    window.location.href = "mailto:"+emailto+"?subject=Subject&body=messag";
  }

  const handleClose = () => {
    setUpdatemodal(false);
    setShowinterview(false);
    setShownotes(false);
    setShowmessage(false);
    Reloadcandidates();
  };

  const updateCandidateJobOrderStatus = () => {
    let jobOrdersData={
      encrypted_id: updateEncriptedId,
      mainstatus_id:updateMainStatusId,
      mainsubstatus_id:subStatatusId,
    }
    if(updateMainStatusId && subStatatusId){
      apiEdit(GET_CANDIDATE_JOB_ORDERS_STATUS, jobOrdersData, ({ status, response }) => {
        if (status) {          
          setUpdateStatusModal(false)
          Reloadcandidates();
        }else{
            let msg = JSON.parse(response.request.response).errors
            setErrors(msg)
            setFieldErros(true)
        }
    })
    }
   
  }

  const Reloadcandidates = () => {
    Refereshstatuscount();
    Binddetails(SelectedStatus);
      }

      function formatDate(value) {    
        if ( value === undefined || value === null || value === '' )
            return '';
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: '2-digit' });
        return day + '-' + month + '-' + year;
    }

    const Refereshstatuscount = ( ) => {
      fetchUrl(`${GET_JOBORDER_STATUSCOUNT}/${Viewjoborder}`, ({ status, response }) => {
        if (status) {
          let stautscnt =[];             
          let data = response?.data[0]; 
           if (response?.data.length > 0){         
            
            stautscnt.push({name:"Joined",count:data.Joined}); 
            stautscnt.push({name:"Active",count:data.Active}); 
            stautscnt.push({name:"Offered",count:data.Offered});  
            stautscnt.push({name:"Processed",count:data.Processed}); 
            stautscnt.push({name:"Prospective",count:data.Prospective});   
            setJobStatuscount(stautscnt);        
           }                        
                    
          }
        });
    }

    const Getnoticepeiod = (input) => {
      return isNaN(input) ?input  :input+' Days';
    }

  return (
    <>
      <div className="row g-0 bg-custom-change px-0 pe-0">
        <div className="col-md-3 p-3 ps-0 pt-0 mt-0 ">
          <div className="bg-light text-left-custom pt-0 mt-0 fix-h">
            <AppBar position="static" className="border">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                orientation="vertical"
              >
              {JobStatuscount.map((data, index) => {
                  return (
                    <Tab
                      label={
                        data.name + " (" +
                        data.count +
                        ") " 
                      }
                      {...a11yProps(index+1)}
                       onClick={() => Setcandidatedetails(data.name)}
                    />
                  );
                })}
                {/* <Tab label="Joined (2)" {...a11yProps(0)} />
                <Tab label="Offered (4)" {...a11yProps(1)} />
                <Tab label="Active (16)" {...a11yProps(2)} />
                <Tab label="Prospective (2)" {...a11yProps(3)} />
                <Tab label="Processed (42)" {...a11yProps(4)} /> */}
              </Tabs>
            </AppBar></div>
        </div>
        <div className="col-md-9 p-0 pt-0 mt-0">
          <TabPanel value={value} index={0} className="card-0 ">
            <div className="p-3 bg-custom-light">
             {JoinedStatus.map((data, index) => { 
               return (
              <div className="row candidate-list">
                <div className="col-md-9">
                  <p className="f-13 mb-1">Candidate Name :<a href ="/candidates" className="text-underline" onClick={()=>Gotocandidateprofile(data.candidate_id)} >{data.candidate_name}</a></p>
                  <p className="f-13 mb-1">Sub Status:&nbsp; 
                  <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.sub_status}{" "}
                        </a>{Getcreatedtimedetails(data.sub_status_updated)}
                        </p>
                  <p className="f-13 mb-1">Joined on <strong>{formatDate(data.expected_joining_date)}</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>{data.offered_ctc}</strong></p>
                </div>
                <div className="col-md-3">

                  <p className="f-13 mb-3"> {data.recruiter_name}</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={t2} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li title="Message to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                              src={t7}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                  </ul>
                </div>
              </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className="border">
          <div className="p-3 bg-custom-light">          
          {ActiveStatus.map((data, index) => { 
               return (
              <div className="row candidate-list">
                <div className="col-md-8">
                <p className="f-13 mb-1">Candidate Name :<a href ="/candidates" className="text-underline" onClick={()=>Gotocandidateprofile(data.candidate_id)} >{data.candidate_name}</a></p>
                <p className="f-13 mb-1">Sub Status:  <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.sub_status}{" "}
                        </a>{Getcreatedtimedetails(data.sub_status_updated)}
                        
                        </p>
                  <p className="f-13 mb-1">Joined on <strong>{formatDate(data.expected_joining_date)}</strong> <span className="px-2"> | </span> Offered CTC: <i className="fas fa-rupee"></i> <strong>{data.offered_ctc}</strong></p>
                </div>
                <div className="col-md-4">

                  <p className="f-13 mb-3"> {data.recruiter_name}</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                      <li className="me-2 cursor-pointer">
                      <a>
                        <img src={Email} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li title="Notes"  className="me-2 cursor-pointer" >
                      <a>
                        <img src={t2} onClick={()=>{ setcandidate_id(data.candidate_id); setPopuptitle("Add/View Notes"); setShownotes(true); setUpdatemodal(true); } } className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li title="Message to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                              src={t7}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                        <li title="Schedule Interview" className="me-2 cursor-pointer">
                          <a>
                          <img onClick={()=>{ sessionStorage.setItem("profileId",data.candidate_id); setcandidate_id(data.candidate_id); dispatch({type:"candidateprofileId",payload:data.candidate_id}); setPopuptitle("Interview"); setShowinterview(true); setUpdatemodal(true); } }
                              src={t3}
                              className="tab-img-h2 align-top  me-2"
                            />
                            
                          </a>
                        </li>
                  </ul>
                </div>
              </div>
              );
            })}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} className="border">
          <div className="p-3 bg-custom-light">
          {OfferedStatus.map((data, index) => { 
               return (
              <div className="row candidate-list">
                <div className="col-md-7">
                <p className="f-13 mb-1">Candidate Name :<a href ="/candidates" className="text-underline" onClick={()=>Gotocandidateprofile(data.candidate_id)} >{data.candidate_name}</a></p>
                <p className="f-13 mb-1">Sub Status:  <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.sub_status}{" "}
                        </a>{Getcreatedtimedetails(data.sub_status_updated)}                         */}
                      </p>
                  <p className="f-13 mb-1">Interview Scheduled on {formatDate(data.interview_date)} at {data.interview_time}.</p>
                  <p className="f-13 mb-1">Notice Period: {Getnoticepeiod(data.notice_period)}</p>
                  <p className="f-13 mb-1">Recent Notes: {data.recent_notes}</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">{data.recruiter_name}</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} onClick = {()=> Composemail("user@example.com")} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li title="Notes"  className="me-2 cursor-pointer" >
                      <a>
                        <img src={t2} onClick={()=>{ setcandidate_id(data.candidate_id); setPopuptitle("Add/View Notes"); setShownotes(true); setUpdatemodal(true); } } className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li title="Message to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                              src={t7}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                        <li title="Schedule Interview" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ sessionStorage.setItem("profileId",data.candidate_id); setcandidate_id(data.candidate_id); dispatch({type:"candidateprofileId",payload:data.candidate_id}); setPopuptitle("Interview"); setShowinterview(true); setUpdatemodal(true); } }
                              src={t3}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                  </ul>
                </div>
              </div>
  );
})}            </div>
          </TabPanel>
          <TabPanel value={value} index={3} className="border">
          <div className="p-3 bg-custom-light">
          {ProcessedStatus.map((data, index) => { 
               return (
          <div className="row candidate-list">
                <div className="col-md-8">
                <p className="f-13 mb-1">Candidate Name :<a href ="/candidates" className="text-underline" onClick={()=>Gotocandidateprofile(data.candidate_id)} >{data.candidate_name}</a></p>
                  {/* <p className="f-13 mb-1">Sub Status  (4 Days ago)</p> */}
                  <p className="f-13 mb-1">Sub Status:  <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.sub_status}{" "}
                        </a>{Getcreatedtimedetails(data.sub_status_updated)}  </p>
                  <p className="f-13 mb-1">Interview Scheduled on {formatDate(data.interview_date)} at {data.interview_time}.</p>
                  <p className="f-13 mb-1">Notice Period: {Getnoticepeiod(data.notice_period)}</p>
                  <p className="f-13 mb-1">Recent Notes: {data.recent_notes}</p>
                </div>
                <div className="col-md-4">

                  <p className="f-13 mb-3">{data.recruiter_name}</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email}  onClick = {()=> Composemail("user@example.com")} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li title="Notes"  className="me-2 cursor-pointer" >
                      <a>
                        <img src={t2} onClick={()=>{ setcandidate_id(data.candidate_id); setPopuptitle("Add/View Notes"); setShownotes(true); setUpdatemodal(true); } } className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li title="Message to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                              src={t7}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                        <li title="Schedule Interview" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ sessionStorage.setItem("profileId",data.candidate_id); setcandidate_id(data.candidate_id); dispatch({type:"candidateprofileId",payload:data.candidate_id}); setPopuptitle("Interview"); setShowinterview(true); setUpdatemodal(true); } }
                              src={t3}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                  </ul>
                </div>
              </div>

);
})}                    </div>
          </TabPanel>
          <TabPanel value={value} index={4} className="border">
          <div className="p-3 bg-custom-light">
          {ProspectiveStatus.map((data, index) => { 
               return (
          <div className="row candidate-list">
                <div className="col-md-7">
                <p className="f-13 mb-1">Candidate Name :<a href ="/candidates" className="text-underline" onClick={()=>Gotocandidateprofile(data.candidate_id)} >{data.candidate_name}</a></p>
                  <p className="f-13 mb-1">Main Status: <a className="text-underline" onClick={()=>updateMainStatus(data.candidate_id)} >{data.main_status}{" "}
                        </a></p>                  
                  <p className="f-13 mb-1">Notice Period: {Getnoticepeiod(data.notice_period)}</p>
                  <p className="f-13 mb-1">Recent Notes: {data.recent_notes}</p>
                </div>
                <div className="col-md-5">

                  <p className="f-13 mb-3 text-center">{data.recruiter_name}</p>
                  <ul
                    type="none"
                    className="p-0 m-0 d-flex justify-content-between flex-wrap"
                  >
                    <li className="me-2">
                      <a>
                        <img src={Email} onClick = {()=> Composemail("user@example.com")} className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li title="Notes"  className="me-2 cursor-pointer" >
                      <a>
                        <img src={t2} onClick={()=>{ setcandidate_id(data.candidate_id); setPopuptitle("Add/View Notes"); setShownotes(true); setUpdatemodal(true); } } className="tab-img-h2 align-top pe-2" />
                      </a>
                    </li>
                    <li className="me-2">
                      <a>
                        <img src={Phone} className="tab-img-h2 align-top   pe-2" />
                      </a>
                    </li>
                    <li title="Message to Candidate" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ setPopuptitle("Message"); setShowmessage(true); setUpdatemodal(true); } }
                              src={t7}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                        <li title="Schedule Interview" className="me-2 cursor-pointer">
                          <a>
                            <img onClick={()=>{ sessionStorage.setItem("profileId",data.candidate_id); setcandidate_id(data.candidate_id); dispatch({type:"candidateprofileId",payload:data.candidate_id}); setPopuptitle("Interview"); setShowinterview(true); setUpdatemodal(true); } }
                              src={t3}
                              className="tab-img-h2 align-top  me-2"
                            />
                          </a>
                        </li>
                  </ul>
                </div>
              </div>

);
})}             </div>
          </TabPanel>

          <nav>
                  <ul class="pagination d-flex justify-content-center mt-3 mb-3">
                    <li class="page-item disabled">
                      <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active" aria-current="page">
                      <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#">Next</a>
                    </li>
                  </ul>
                </nav>
        </div>
        
      
      <CommonModal title={Popuptitle} handleClose={handleClose} open={updateModal}  btnfunc={handleClose} showonlycancel={true} >      
        
         {Shownotes && <Notes id={candidate_id} Showaddnotesonly={true} handleClose={handleClose}  api={`${GET_CANDIDATE_NOTES_API_URL}`} apiext={`?candidate_id=${candidate_id}`} module={"candidate"}/>}
        {Showinterview && <Interview showaddmessageonly={true} handleClose={handleClose} />}
        {Showmessage && <MassageCandidates Showaddmessageonly={true} handleClose={handleClose} />}
      </CommonModal>
      
      <CommonModal title={"Change Status"} handleClose={() => setUpdateStatusModal(false)} open={updateStatusModal} button={"UPDATE"} btnfunc={()=> updateCandidateJobOrderStatus()}>
        <div style={{width: "200px"}}>
          <select name="" id=""  class="form-control pb-1 my-3"
            onChange={(e)=>{
              getsubStatus(e.target.value)
              setUpdateMainStatusId(e.target.value)
              setSubStatusId('')
            }} value={updateMainStatusId}>
            <option value="">-- Select Main Status  --</option>
            {mainStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>

          <select name="" id=""  class="form-control pb-1"
            onChange={(e)=>{
              setSubStatusId(e.target.value)
              }} value={subStatatusId}>
            <option value="">-- Select Sub Status --</option>
            {mainSubStatusData.map((res)=>{
              return <option value={res.fixed_id}>{res.name}</option>
            })}
          </select>
          </div>
      </CommonModal>
      </div>
      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)} />
      <SnakBar error={successMsg} open={updateModal} onClose={() => setUpdatemodal(false)} type="success"/>
    </>
  );
}
