import React, { useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CandidatesList from './CandidateList';
import AddCandidate from './AddCandidate';
import { makeStyles } from '@material-ui/core/styles';
import Details from '../CandidateProfile/Details';
import UpdateCandidate from './UpdateCandidate';
import { useDispatch , useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

 const Candidates = (props) => {
    const dispatch = useDispatch()
    const EditCandidateId = useSelector(state=>state.PageReduser.EditCandidateId)
    if (sessionStorage.getItem("gototcandidate") === "true"){
        dispatch({type:"candidateprofileId",payload:sessionStorage.getItem("profileId")})
    }
    const roles_data =  useSelector(state=>state.PageReduser.roles_data);
    
    const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Candidates')

    const candidateprofileId =  useSelector(state=>state.PageReduser.candidateprofileId);
 
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    
  return(
    <div className="container-fluid px-md-4 pl-5 pt-4 card-design">
        <div className="row px-md-4">
        <div className="col-md-12 px-4">
            <div className="row g-0">
            <div className="col-md-8 bg-white px-4 pt-3 MuiPaper-elevation4">
            <strong className="position-up">Candidates</strong>
                </div>
            <div className="col-md-4 tab-end">
          <AppBar position="static" >
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="List" {...a11yProps(0)} />
                  {(filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ?
                  <Tab label="Add" {...a11yProps(1)} /> : null
                  }
                  {EditCandidateId?
                 <Tab label="Edit" {...a11yProps(2)} />
                  :""}
                  {candidateprofileId?
                  <Tab label="Profile" {...a11yProps((filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2: 1)} />
                  :""}
              </Tabs>
          </AppBar>
          </div>
          </div>
          
          <TabPanel value={value} index={0} className="card-0">
            <CandidatesList changetab ={setValue} />
          </TabPanel>
          {(filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ?
          <TabPanel value={value} index={1} className="cand-p0" >
            <AddCandidate changetab ={setValue}/>
          </TabPanel>
          : null}
          {
             EditCandidateId ? <TabPanel value={value} index={2} className="cand-p0"> 
             <UpdateCandidate changetab ={setValue}/>
           </TabPanel>:""
          }
          {candidateprofileId ? <TabPanel value={value} index={(filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2: 1} className="cand-p02">
            <Details changetab={setValue}/>
          </TabPanel>:""}
          
          </div>
          </div>
    </div>
   )
  }

  export default Candidates