import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tasks from './task';
import {Notes} from '../../../Components/sidenavTabs/notes'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
  }));
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
  }
  

export const SideTabs = (props) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(1);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return(
    <div className="card" >
          <AppBar position="static" >
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Tasks" {...a11yProps(0)} />
                  <Tab label="Notes" {...a11yProps(1)} />
                 
              </Tabs>
          </AppBar>

          <TabPanel value={value} index={0} >
            <Tasks/>
          </TabPanel>
          <TabPanel value={value} index={1}  >
            <Notes/>
          </TabPanel>
    </div>
   )
  }
