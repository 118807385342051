import React,{useState,useEffect} from 'react'
import { useDispatch , useSelector} from "react-redux";
import {GET_CADNDIDATES_API_URL,DELETE_CANDIDATE_API_URL } from '../../Configurations/ConfigApi';
import { fetchUrl, apiDelete } from '../../ApiActions/Actions'
import {
  GroupingState,
  SortingState,
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import SnakBar from "../../Components/Models/snakbar";
import { withRouter } from 'react-router';
import CommonModal from '../../Components/Models/commonModal';

import view from "../../Assets/Images/profile-t-icons/view.svg";
import Edit from "../../Assets/Images/profile-t-icons/Edit.svg";
import Delete from "../../Assets/Images/profile-t-icons/Delete.svg";
// App Logo
import logoEmblem from "../../Assets/Images/emblem.svg";


const getRowId = (row) => {
  return row.id;
};


const CandidatesList = (props) => {
 
let gototcandidate = sessionStorage.getItem("gototcandidate");  

  const roles_data =  useSelector(state=>state.PageReduser.roles_data);
  const filter_role_data = roles_data.filter((res)=> res?.module_name === 'Candidates')

  
  const dispatch = useDispatch()
  const [columns] = useState([
    { name: "name", title: "Candidate" },
    { name: "company_name", title: "Professional" },
    { name: "current_ctc" , title: "Compensation"},
    { name: "actions", title:"Actions"}
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' },{ columnName: 'name', direction: 'asc' }]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(7);
  const [actionsColumns] = useState(["actions"]);
  const [candiDateColumns] = useState(["name"])
  const [professionalColumns] = useState(["company_name"])
  const [compensationColumns] = useState(['current_ctc'])
  const [successMsg,setSuccessMsg] = useState({})
  const [snakBarOpen,setSnakBarOpen]  = useState(false)
  const [usersData,setUsersData] = useState([])
  const [loader,setLoader] = useState(false)
  const [usersCount,setUsersCount] = useState(0)
  const [searchValue, setSearchState] = useState('');
  const [updateModal,setUpdatemodal] = useState(false);

  const [deleteUserId,setDeleteUserId] = useState("");

  const handleClose = () => {
    setUpdatemodal(false);
    setDeleteUserId("");
  };

  useEffect(()=>{
    getUserlist()
  },[currentPage,sorting,searchValue])

  useEffect(()=>{
     
    if (gototcandidate ==="true"){     
    props.changetab(2);     
    dispatch({type:"candidateprofileId",payload:sessionStorage.getItem("profileId")})    
  }
  else{
    dispatch({type:"EditCandidateId",payload:""})
    dispatch({type:"candidateprofileId",payload:""})
  }
  },[])

  const getUserlist=()=>{
    setLoader(true)
    let sortingKey = sorting[0].columnName
    let sortingDirection = sorting[0].direction

    fetchUrl(`${GET_CADNDIDATES_API_URL}?sortby=${sortingKey}&direction=${sortingDirection}&page=${currentPage}&limit=${pageSize}&q=${searchValue}`, ({ status, response }) => {
      if (status) {
        if(response?.data?.status === true){
          let {data,all_data_cnt,filter_data_cnt} = response.data
           setUsersCount(all_data_cnt)
          setUsersData(data)
        }
        setLoader(false)
      }
  })
  }


  const StatusFormatter = ({ value, row }) => (
    <>
    <div className='justify-content-center text-center'>
     <a className="link text-link cursor-pointer px-1" onClick={()=>{
        sessionStorage.setItem("profileId",row.encrypted_id)
        dispatch({type:"candidateprofileId",payload:row.encrypted_id})
        
        props.changetab((filter_role_data.length > 0 && filter_role_data[0]["m_add"]) ? 2: 1)
        }
        }>
              {/* <VisibilityIcon fontSize="small" className="text-dark me-2"/> */}
              <img src={view} className="icon-img me-1"/> 
      </a>
      
      {(filter_role_data.length > 0 && filter_role_data[0]["m_edit"]) ? 
      <a className="link text-link cursor-pointer pe-1" onClick={()=>{
        dispatch({type:"EditCandidateId",payload:row.encrypted_id})
        props.changetab(2)
        }
        }>
         <img src={Edit} className="icon-img me-1"/> 
      </a> : null
      }

    {(filter_role_data.length > 0 && filter_role_data[0]["m_delete"]) ? 
      <a className="link text-link cursor-pointer me-2" onClick={()=>{
        setUpdatemodal(true);
        setDeleteUserId(row.encrypted_id)
      }
        }>
       
        <img src={Delete} className="icon-img "/> 
      </a>: null
      }
      </div>
    </>
  );
  const candidateFormatter = ({ value, row })=>{
   
    return <>
     <h5 className='font-weight-bold'>{value}</h5>
     <h6>{row?.primary_mobile}</h6>
     <h6><a className='a-link' href="mailto:{row?.primary_email}">{row?.primary_email}</a></h6>
     <h6><strong>Created On:</strong> {row?.created_at?.slice(0,11)}</h6>
    </>
  }

  const professionalFormatter = ({ value, row })=>{
    return <>
     <h6><strong>Company:</strong> {value}</h6>
     <h6><strong>Location:</strong> {row?.city_name}</h6>
     <h6><strong>Experience:</strong> {`${row?.exp_years} yrs ${row?.exp_months} Months`}</h6>
     <h6><strong>Notice Period:</strong> {row?.notice_period}</h6>
    </>
  }

  const compensationFormatter = ({ value, row })=>{
    return <>
    <h6><strong>Current CTC:</strong> {value}</h6>
    <h6><strong>Expected CTC:</strong> {row?.expected_ctc}</h6>
    <h6><strong>Stocks:</strong> {row?.stocks}</h6>
   </>
  }

  const ActionsTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );

  const CandidateTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={candidateFormatter} {...props} />
  );

  const ProfessionalTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={professionalFormatter} {...props} />
  );

  const CompensationTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={compensationFormatter
    } {...props} />
  );


  const deleteCandidate =()=>{
    setLoader(true)
    apiDelete(`${DELETE_CANDIDATE_API_URL}${deleteUserId}`, ({ status, response }) => {
      if (status) {
        setUpdatemodal(false)
        setSuccessMsg({notes:["Deleted Successfully"]})
        setSnakBarOpen(true)
        getUserlist()
      }
      setLoader(false)
  })
  }
// preloader
  return(
    <>
      {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
      <div className="container-fluid pr-0 pl-0 px-0 ">
        <div className="row no-gutters">

          <div className="col-md-12 table-card">
            <div className="card card-shadow-none table-custom-new-w">
              <Grid rows={usersData} columns={columns} getRowId={getRowId}>
                <SortingState
                  sorting={sorting}
                  onSortingChange={setSorting} />

                <SearchState className="d-none"
                  value={searchValue}
                  onValueChange={setSearchState} />


                <PagingState
                  currentPage={currentPage} // show current page, first time it's set to 1
                  pageSize={pageSize} // set page limit to show in one page
                  onCurrentPageChange={setCurrentPage} />
                <CustomPaging totalCount={usersCount} />
                <ActionsTypeProvider for={actionsColumns} />
                <CandidateTypeProvider for={candiDateColumns} />
                <ProfessionalTypeProvider for={professionalColumns} />
                <CompensationTypeProvider for={compensationColumns} />

                <Table noData={"no data found"} className="group" />

                <Toolbar />
                <SearchPanel />
                <GroupingState />
                <TableHeaderRow
                  showSortingControls // to control the sorting in each fields
                />
                <PagingPanel />
              </Grid>

            </div>
          </div>
        </div>

        <CommonModal title={"Delete"} handleClose={handleClose} open={updateModal} button={"DELETE"} btnfunc={deleteCandidate}>
          <span>Are you sure you want to delete candidate ?</span>
        </CommonModal>
        <SnakBar error={successMsg} open={snakBarOpen} onClose={() => setSnakBarOpen(false)} type="success" />
      </div></> 
   )
  }


export default withRouter(CandidatesList)