import React from 'react';

 const Activity = (props) => {
  return(
    <div class="acticity-section">
        <div class="row">
            <div class="col-md-9">
                <h6 class="text-underline pb-3"> Activity</h6>
            </div>
        </div>
        <hr class="p-0 m-0 mb-3"/>
        <div>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed on:</strong></p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed To:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered by:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
          </div>
        <hr/>
        <div>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed on:</strong></p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed To:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered by:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
          </div>
        <hr/>
        <div>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed on:</strong></p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed To:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered by:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
          </div>
        <hr/>
        <div>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed on:</strong></p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed To:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered by:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
          </div>
        <hr/>
        <div>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed on:</strong></p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed To:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered by:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
          </div>
        <hr/>
        <div>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed on:</strong></p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Changed To:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered by:</strong> </p>
            <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
          </div>
        <hr/>
</div>
          
   )
  }

  export default Activity;