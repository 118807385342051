import React, {useCallback, useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import AddIcon from "@material-ui/icons/Add";
import CommonModal from '../Models/commonModal';
import CropImg from "../Cropimg/Cropimg";

const DragAndDrop = (props) => {

  const [imgUrl,setImgurl] = useState('')
  const [opencorpimage, setopencorpimage] = useState(false)
  const [CropimgUrl,setCropimgUrl] = useState('')
  const [data,setdata] = useState(props.data);
  const [iscroped,setiscroped] = useState(false);
  const [Isimage,setIsimage] = useState(props.id === 'profile' || props.id === 'Clientlogo' ? true : false);
  const [filename,setFilename] = useState('')

  async function getblobimagefile() {
    let blob = await fetch(imgUrl).then(r => r.blob());     
     let file = new File([blob], props.id+".jpeg", {type: 'image/jpeg', lastModified: Date.now()});
     const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result
          }
          reader.readAsArrayBuffer(file);
    return file; 
  }

  useEffect(()=>{      
    if (iscroped && Isimage === true){         
      getblobimagefile().then((users) => {console.log(users);  props.onselect(users)}).then(()=>props.setimgchanged(iscroped));            
    }
  },[iscroped])

    const onDrop = useCallback((acceptedFiles) => {
 
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result
          }
          reader.readAsArrayBuffer(file)
          props.onselect(file)           
          let fileName = URL.createObjectURL(file)
          if (Isimage === true){            
            setCropimgUrl(fileName);
            setopencorpimage(true);
          }
          else{
            setImgurl(fileName)
          }
          
          if(!props.image){
            setFilename(file.name)
          }
        })
        
      },[])
    
      const {getRootProps, getInputProps} = useDropzone({onDrop})
      const handleClose = () => {
        setopencorpimage(false);        
        props.setimgchanged(iscroped);         
        setdata( props.id === 'profile' ?'Drop photo file' : 'Drop Clients Logo file');                
      };
      
  return(
    <>
    <div {...getRootProps()} id={props.id}>
    <input {...getInputProps()} name={"resume"} accept={props.image ? 'image/*' : '.pdf, .PDF, .html, .doc, .docx, .DOC, .DOCX'}/>
   
     <div className="text-center custom-file-upload">
       <AddIcon fontSize="medium" />
     </div>
     <h6>
     {!props.image && filename}
       {
        (!imgUrl) ? ( props.url ? <div className="profile-img pe-2">
         <img
             className={`img-fluid radius-profile drag_image ${props?.id === "Clientlogo"? 'client_drag_image':""}`}
           src={props.url}
          // style={{borderRadius:'50%',height:"100px",width:"100px"}}
         />
       </div> : ( props.data ?  data :
        <> Drop <br />
       {props.header} file
      </> )) : 
     (props.image ? <img
      className={`img-fluid radius-profile drag_image ${props?.id === "Clientlogo"? 'client_drag_image':""}`}
      src={imgUrl}
     // style={{borderRadius:'50%',height:"100px",width:"100px"}}
    /> : data)
       }
   
      
     </h6>
     </div>

    <CommonModal title="Crop Image" handleClose={handleClose} open={opencorpimage}  btnfunc={handleClose} showonlycancel={true} >      
     
    <CropImg open={opencorpimage} setiscroped={setiscroped} setopencorpimage={setopencorpimage} src={CropimgUrl} setCropimgUrl={setImgurl} ></CropImg>
    </CommonModal>
    </>
   )
  }


export default DragAndDrop