import React,{useEffect, useState} from 'react'
import { fetchUrl ,apiPost,apiDelete} from "../../ApiActions/Actions";
import { GET_JOB_NOTES_API_URL } from "../../Configurations/ConfigApi";
import CommonModal from '../../Components/Models/commonModal';
import SnakBar from "../../Components/Models/snakbar";

export const Notes = (props) => {
    const [addnotes,setAddNotes] = useState(false)
    const [notes,setNotes] = useState("")
    const [notesData,setNotesData] = useState([])
    const [notesUpdate,setNotesUpdate] = useState(false)
    const [updateModal,setUpdatemodal] = useState(false);
    const [fieldserror,setFieldErros] = useState(false)
    const [errors,setErrors] = useState({})
    const [deleteModal,setDeleteModal] = useState(false)
    const [successMsg,setSuccessMsg] = useState({})
    const [deleteId,setDeleteId] = useState("")
    const [Showaddnotesonly, setShowaddnotesonly] = useState(props.Showaddnotesonly);

    useEffect(()=>{
        if(Showaddnotesonly === true){
            setAddNotes(true);
        setNotesData([]);
        }        
    });

    const addnotesApi = ()=>{
        let id = props.id;
        let notesData = {}
        if(props.module ==="client"){
             notesData={
                notes:notes,
                client_id: id,
            }
        }else if(props.module ==="joborder"){
            notesData={
                notes:notes,
                job_id: id,
            }
        }else if(props.module === "candidate"){
            notesData={
                notes:notes,
                candidate_id: id,
            }
        }
       
        apiPost(props.api, notesData, ({ status, response }) => {
            if (status) {
            setSuccessMsg({notes:["Added Successfully"]})
            setUpdatemodal(true)
            setNotesUpdate(!notesUpdate)
            setAddNotes(false)
            if(Showaddnotesonly === true)
            {
            setTimeout(() => {
                props.handleClose();
              }, 3000);
            }
            
            }else{
                let msg = JSON.parse(response.request.response).errors
                setErrors(msg)
                setFieldErros(true)
            }
        })
    }

    useEffect(()=>{
        if(props.api && props.apiext){
            fetchUrl(`${props.api}${props.apiext}`, ({ status, response }) => {
                if (status) {
                    let data = response?.data?.data
                  setNotesData(data&&data.reverse())
                }else{
                    let msg = JSON.parse(response.request.response).errors
                    setErrors(msg)
                    setFieldErros(true)
                }
            })
        }
        
    },[notesUpdate])

    const deleteNotes = ()=>{
        apiDelete(`${props.api}/${deleteId}`, ({ status, response }) => {
            if (status) {
                setSuccessMsg({notes:["Deleted Successfully"]})
                setUpdatemodal(true)
                setDeleteModal(false)
                setNotesUpdate(!notesUpdate)
            }else{
                let msg = JSON.parse(response.request.response).errors
                setErrors(msg)
                setFieldErros(true)
            }
        })
    }

    function formatDate(value) {
        if ( value === undefined || value === null || value === '' )
        return '';
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: '2-digit' });
        return day + '-' + month + '-' + year;
    }

  
  return(
    <>
          <div className="notes-section">
            {Showaddnotesonly === true ? "":
              <div className="row">
                  <div className="col-md-8">
                      <h6 className="text-underline pb-3"> Notes</h6>
                  </div>
                  <div className="col-md-4 px-4">
                      <button class="btn btn-xs btn-green-dark text-white btn-violet f-13" onClick={() => {
                          setAddNotes(!addnotes)
                      }}>
                          {addnotes ? "Cancel" : "Add"}
                      </button>
                  </div>
              </div>}

              {addnotes ? <div className={`row no-gutters card-design mb-3 ${addnotes ? "tagActive" : ""}`}>
                  <div className="col-md-12">
                      <div className="card box-shadow-none">
                          <div className="card-header pb-0 text-left ">
                              <h5 className="text-center f-13">Add Notes</h5>
                          </div>
                          <div className="card-body pt-1 mt-1">
                              <div className="row">
                                  <div className="col-12 mb-3 ">
                                      <textarea
                                          name=""
                                          id=""
                                          rows="5"
                                          className="form-control border-0"
                                          placeholder="--Type Notes Here--"
                                          onChange={(e)=>setNotes(e.target.value)}
                                      ></textarea>
                                  </div>

                                  <div className="col-12 text-center py-0 my-0 pb-0 mb-0">
                                      <button class="btn btn-xs btn-orange text-white pe-4 px-4 mb-0 mx-4" onClick={addnotesApi}>
                                          Add
                                      </button>
                                      {/* <button className="btn btn-xs btn-green-dark text-white ">
                                          Cancel
                                      </button> */}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  : ""}

          </div>
          <div className="notes-section">
              <hr className="p-0 m-0 mb-3" />
              {
                  notesData.map((data)=>{
                      let date = new Date(data?.created_at)
                      return <div>
                      <div className="row">
                          <div className="col-md-6">
                              <h6 className="f-13 ">{formatDate(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate())}</h6>
                          </div>
                          <div className="col-md-6  justify-content-end d-flex">
                              <h6 className="f-13">
                                  <i class="far fa-trash-alt" onClick={()=>{
                                      setDeleteId(data?.encrypted_id)
                                      setDeleteModal(true)
                                      }}></i>
                              </h6>
                          </div>
                      </div>
                      <p className="f-13 mb-1">
                         {data?.notes}        
                    </p>
                      <div className="text-end">
                          <h6 className="text-end f-13">{data.username}</h6>
                          <hr className="p-0 m-0 mb-2" />
                      </div>
                  </div>
    
                  })
              }
                   
              {/* <CommonModal title={"success"} handleClose={()=>setUpdatemodal(false)} open={updateModal} button={"OK"} btnfunc={()=>setUpdatemodal(false)}>
                 <span>Notes Added successfully</span>
             </CommonModal> */}
             <CommonModal title={"Delete"} handleClose={()=>setDeleteModal(false)} open={deleteModal} button={"DELETE"} btnfunc={deleteNotes}>
            <span>Are you sure you want to delete Notes ?</span>
                </CommonModal>
             <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)}/>

        <SnakBar error={successMsg} open={updateModal} onClose={() => setUpdatemodal(false)} type="success"/>
          </div>
    </>
   )
  }
  
