import React,{useEffect, useState} from 'react'


export const MassageCandidates = (props) => {
    const [msgCandidates,setMsgCandidates] = useState(false)
    const [Showaddmessageonly, setShowaddmessageonly] = useState(props.Showaddmessageonly);

    useEffect(()=>{
        if(Showaddmessageonly === true){
          setMsgCandidates(true);        
        }        
    });

  return(
    <div className="message-section">
    {Showaddmessageonly === true ? "" :<div className="row">
      <div className="col-md-8">
        <h6 className="text-underline pb-3"> Message Candidates</h6>
      </div>
      <div className="col-md-4 px-4">
        <button class="btn btn-xs btn-green-dark text-white btn-violet f-13" onClick={()=>setMsgCandidates(!msgCandidates)}>
         {msgCandidates ? "Cancel" : "Add"} 
        </button>
      </div>
    </div>}

    {/* Add Section */}
    <div className="row no-gutters card-design mb-3">
      <div className="col-md-12">
      {msgCandidates ? 
        <div className="card box-shadow-none">
          <div className="card-header pb-0 text-left ">
            <h5 className="text-center f-13">Message Candidate</h5>
          </div>
          
          <div className="card-body pt-1 mt-1">
            <div className="row">
              <div className="col-12 mb-3 ">
                <input
                  type="text"
                  class="form-control pb-1"
                  placeholder="-- Select Mobile No --"
                />
              </div>
              <div className="col-12 mb-3 ">
              <textarea
                  name=""
                  id=""
                  rows="5"
                  className="form-control"
                  placeholder="--Type Message Here--"
                ></textarea>
              </div>
              <div className="col-12 mb-3 ">
                <input
                  type="text"
                  class="form-control pb-1"
                  placeholder="-- Mode Of Message --"
                />
              </div>
             
              <div className="col-12 text-center py-0 my-0 pb-0 mb-0">
                <button class="btn btn-xs btn-orange text-white pe-4 px-4 mb-0 mx-4">
                  Add
                </button>
                {/* <button className="btn btn-xs btn-green-dark text-white ">
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        
        </div>
      :""}
      </div>
    </div>
    {Showaddmessageonly === true ? "" :<div><hr className="p-0 m-0 mb-3" />
    <div>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Mobile No:</strong>   {/*add the dynamic expression here, don't add in strong tag*/} </p> 
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message Mode: </strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered By:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
    </div>
    <hr />

    <div>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Mobile No:</strong>   {/*add the dynamic expression here, don't add in strong tag*/} </p> 
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message Mode: </strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered By:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
    </div>
    <hr />

    <div>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Mobile No:</strong>   {/*add the dynamic expression here, don't add in strong tag*/} </p> 
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message Mode: </strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered By:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
    </div>
    <hr />
    <div>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Mobile No:</strong>   {/*add the dynamic expression here, don't add in strong tag*/} </p> 
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message Mode: </strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered By:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
    </div>
    <hr />
    <div>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Mobile No:</strong>   {/*add the dynamic expression here, don't add in strong tag*/} </p> 
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message Mode: </strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Message:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Entered By:</strong> </p>
      <p className="f-13 mb-1"><strong className='prefix-tag pe-2'>Date & Time:</strong> </p>
    </div>
    
    <hr />
   
   </div>}
  </div>

   )
  }
