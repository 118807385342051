import React,{useState,useEffect} from 'react'
import {
  GroupingState,
  SortingState,
  PagingState,
  CustomPaging,
  DataTypeProvider

} from "@devexpress/dx-react-grid";

import {
  Grid,
  VirtualTable,
  Table,
  TableHeaderRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import {DASHBOARD_FUTURE_JOBINTERVIEWS_LIST_API } from '../../../Configurations/ConfigApi'
import { fetchUrl, apiDelete } from '../../../ApiActions/Actions'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import logoEmblem from "../../../Assets/Images/emblem.svg";
const getRowId = (row) => {
  return row.id;
};


const UpComingInterviews = (props) => {

  const [columns] = useState([
    { name: "candidate_name", title: "Candidates Name" },
    { name: "client_name", title: "Client Name" },
    { name: "interview_date" , title: "Date"},
    { name: "interview_time", title: "Time" },
    { name: "mode_interview", title: "Mode" },
    { name: "recruiter_name", title: "Recruiter" },

  ]);

  const [sorting, setSorting] = useState([{ columnName: 'created_at', direction: 'desc' },{ columnName: 'name', direction: 'asc' },{ columnName: 'primary_email', direction: 'asc' }]);
  const [actionsColumns] = useState(["interview_date"]);
  const [candidateNameColumns] = useState(["candidate_name"]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(20);
  const [searchValue, setSearchState] = useState('');
  const dispatch = useDispatch()
  const [list,setList] = useState([])
  const [listCount,setListCount] = useState(0)
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    getInterviewslist()
  },[currentPage,sorting,searchValue])

  const getInterviewslist=()=>{
    setLoader(true)
    let sortingKey = sorting[0].columnName
    let sortingDirection = sorting[0].direction

    fetchUrl(`${DASHBOARD_FUTURE_JOBINTERVIEWS_LIST_API}?sortby=${sortingKey}&direction=${sortingDirection}&page=${currentPage}&limit=${pageSize}&q=${searchValue}`, ({ status, response }) => {
      if (status) {
        if(response?.data?.status === true){
          let {data,all_data_cnt,filter_data_cnt} = response.data
          setListCount(all_data_cnt)
          setList(data)
        }
      }
      setLoader(false)
  })
  }

  const StatusFormatter = ({ value, row }) => (
    <>
       {value && <span>{moment(new Date(value)).format('DD-MMM-YYYY')}</span>} 
    </>
  );

  const ActionsTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );

  const candidateNameFormatter = ({ value, row }) => (
    <>
       <a className="text-underline font-weight-bold" onClick={()=>{
          sessionStorage.setItem("profileId",row?.candidate_id)
         dispatch({type:"candidateprofileId",payload:row?.candidate_id})
         sessionStorage.setItem("gototcandidate","true");
         props.history.push('/candidates')
       }}>{value}</a> 
    </>
  );

  const CandidateNameTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={candidateNameFormatter} {...props} />
  );



  return(
    <>
     {loader && 
      <div className="preloader">
      <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
      </div> }
    <div className="container-fluid pr-0 pl-0  p-0">
      <div className="row no-gutters">
        {/* <div className="col-md-1 p-0 m-0">
          <Sidenav category={"Cadidates"} />
        </div> */}
      
        <div className="col-md-12 table-card">
       <div className="card card-shadow-none">
       <Grid rows={list} columns={columns} getRowId={getRowId}>
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
        />
        <PagingState
          currentPage={currentPage} // show current page, first time it's set to 1
          pageSize={pageSize} // set page limit to show in one page
          onCurrentPageChange={setCurrentPage}
        />
         <CustomPaging totalCount={listCount} />
        <Table noData={"no data found"} className="group" />
        <Toolbar />
        <GroupingState />
        <ActionsTypeProvider className="text-center" for={actionsColumns} />
        <CandidateNameTypeProvider className="text-center" for={candidateNameColumns} />
        <TableHeaderRow
          showSortingControls // to control the sorting in each fields
        />
        <PagingPanel />
      </Grid>
    
       </div>
      </div>
      </div>
      </div> 
      </>
   )
  }


export default withRouter(UpComingInterviews)
