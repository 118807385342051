import React from 'react'


export const Footer = (props) => {
  return(
    <footer className='pt-5 mt-5'>
    <div className="container ">
     <div className="row">
       <div className="col-12 text-center">
<p className="font-weight-bold">ULTISOURCE Pvt. Ltd. | © COPYRIGHT | ALL RIGHTS RESERVED</p>
       </div>
     </div>
    </div>
    </footer>
   )
  }
