import React, { useEffect, useState } from 'react'
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchUrl } from '../../ApiActions/Actions';
import { GET_SKILLS_API_URL } from '../../Configurations/ConfigApi';


export const AutocompleteSkills = (props) => {

  const [skillname, setSkillName] = useState("")
  const [skillsList, setSkillsList] = useState([]);
  const [defaultData,setDefaultData] = useState([])


  const getSkillsList = (value) => {
    setSkillName(value)
   
    fetchUrl(`${GET_SKILLS_API_URL}?q=${value}`, ({ status, response }) => {
      if (status) {
        if (response?.data?.status === true) {
       
          let { data } = response.data
          for (let i = 0; i < data.length; i++) {
            data[i].title = data[i].name
          }
          setSkillsList(data)
        }else{
         let newData = [{title:value}]
         setSkillsList(newData)
        }
      }
    })
  }


  const addSkill = (skillList) => {
    let data = []
    for (let i = 0; i < skillList.length; i++) {
      data.push(skillList[i].title)
    }
    props.setSkills(data)

  }


  useEffect(()=>{
    if(props.edit){
      let skilldata  = [...props.Skills]
      for (let i = 0; i < skilldata.length; i++) {
        skilldata[i].title = skilldata[i].skill_name
      }
      if(skilldata.length > 0){
        // setSkillsList(skilldata)
        setDefaultData(skilldata)
      }
    } else if(props?.isParsing){
      let skilldata  = [...props.Skills]
      for (let i = 0; i < skilldata.length; i++) {
        skilldata[i].title = skilldata[i].skill_name
      }
      if(skilldata.length > 0){
        // setSkillsList(skilldata)
        setDefaultData(skilldata)
      }
    }
  },[props])

  useEffect(()=>{
    
      fetchUrl(`${GET_SKILLS_API_URL}`, ({ status, response }) => {
        if (status) {
          if (response?.data?.status === true) {
            let { data } = response.data
  
            for (let i = 0; i < data.length; i++) {
              data[i].title = data[i].name
            }
            setSkillsList(data)
          }
        }
      })
    
  },[])

  return (
    <>
      <div className="d-flex align-items-end">
        {(defaultData.length > 0 && props.edit)  && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={skillsList}
          autoSelect
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.title === value.title }
          defaultValue = {defaultData}
          onChange={(event, newValue) => {
            addSkill(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getSkillsList(e.target.value)}
            />
          )}
        />}

        {(!props.edit && !props.isParsing ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={skillsList}
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            addSkill(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getSkillsList(e.target.value)}
            />
          )}
        />}

      {(defaultData.length === 0 && props.edit ) && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={skillsList}
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => {
            addSkill(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getSkillsList(e.target.value)}
            />
          )}
        />}

        {(defaultData.length > 0 && props.isParsing)  && <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="user"
          style={{ width: "100%" }}
          options={skillsList}
          autoSelect
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.title === value.title }
          defaultValue = {defaultData}
          onChange={(event, newValue) => {
            addSkill(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder=""
              onChange={(e) => getSkillsList(e.target.value)}
            />
          )}
        />}

       
        <span >{" "}*</span>

      </div>
    </>
  )
}
