import React from 'react'

/**
* @author
* @function Datatable
**/

export const Datatable = (props) => {
    const copyallTable = () => {
        const elTable = document.querySelector('#interviewTable');

        let range, sel;

        // Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {

            range = document.createRange();
            sel = window.getSelection();
            // unselect any element in the page
            sel.removeAllRanges();

            try {
                range.selectNodeContents(elTable);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(elTable);
                sel.addRange(range);
            }

            document.execCommand('copy');
        }

        sel.removeAllRanges();
    }
    function formatDate(value) {
        if (value === undefined || value === null || value === '')
            return '';
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: '2-digit' });
        return day + '-' + month + '-' + year;
    }
    return (
        <>

            <div id="Mytable" className="card card-shadow-none table-center px-4  w-60">
                <table class="table table-bordered tpnone" id="interviewTable">
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #606162', backgroundColor: '#606162', color: '#fff', padding: '5px', textAlign: 'center', fontSize: '16px' }} >Candidate</th>
                            <th style={{ border: '1px solid #606162', backgroundColor: '#606162', color: '#fff', padding: '5px', textAlign: 'center', fontSize: '16px' }} >Interview Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.usersData.map((row) => {
                            return <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontSize: '14px' }}>
                                    <div style={{ color: '#585959', padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{ fontWeight: '600' }}>Name: </strong>{row.candidate_name}</div>
                                    <div style={{ color: '#585959', padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{ fontWeight: '600' }}>Phone: </strong> {row.candidate_mobile}</div>
                                    <div style={{ color: '#585959', padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{ fontWeight: '600' }}>Email: </strong> {row.candidate_email}</div>
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontSize: '14px' }}>
                                    <span className='text-left'>
                                        <div style={{ color: '#585959', padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{ fontWeight: '600' }}>Mode: </strong> {row.mode_interview}</div>
                                        <div style={{ color: '#585959', padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{ fontWeight: '600' }}>Interview Date: </strong>  {formatDate(row.schedule_time)}</div>
                                        <div style={{ color: '#585959', padding: '0px', margin: '5px', textAlign: 'left', fontSize: '14px' }}><strong style={{ fontWeight: '600' }}>Interview Time: </strong> {row.hour}:{row.minutes} {row.timeformat}</div>
                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            <div className="text-center mt-5 pt-3">
                <button class="btn btn-md btn-violet text-white" onClick={copyallTable}>Copy
                </button>
            </div>
        </>
    )
}
