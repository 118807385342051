import React, { useState, useEffect } from "react";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CommonModal from '../../Components/Models/commonModal';
import { fetchUrl, apiPost } from '../../ApiActions/Actions';
import { GET_JOBORDERS_API_URL, GET_SKILLS_API_URL, } from '../../Configurations/ConfigApi';
import SnakBar from "../../Components/Models/snakbar";
import logoEmblem from "../../Assets/Images/emblem.svg";

import { withRouter } from "react-router";
import { AddjobOrdersForm } from "./addJobordersForm";
import JobTextEditor from "../../Components/TextEditor/JoTextEditor";
import { useDispatch, useSelector } from "react-redux";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";

const AddCandidate = (props) => {

  let id = sessionStorage.getItem("editId");
  const dispatch = useDispatch();

  const [positionDes, setPositionDes] = useState("");
  const [jobDes, setJobDes] = useState("");
  const [qualification, setQualification] = useState("");
  const [descriptionFile, setDescriptionFile] = useState("");
  const [addSkillsModal, setAddSkillsModal] = useState(false);
  const [jonAddsuccess, setjonAddsuccess] = useState(false)
  const [primaryskills, setPrimaryskill] = useState([]);
  const [secondaryskills, setSecondaryskills] = useState([]);
  const [recruiters, setRecruiters] = useState([])
  const [coordinators, setCoordinators] = useState([])
  const [managers, setManagers] = useState([])
  const [errors, setErrors] = useState({})
  const [dropIcons, setDropIcons] = useState("fa-info-circle text-warning")
  const [successMsg, setSuccessMsg] = useState({})
  const [qualifications, setQualifications] = useState([]);
  const [loader, setLoader] = useState(false)

  const [jobOrderFields, setJobOrderFields] = useState({
    country: "",
    state: "",
    city: "",
    name: "",
    client: "",
    contact: "",
    job_title: "",
    office_landmark: "",
    min_experience: "",
    max_experience: "",
    max_budget: "",
    no_positions: "",
    qualifications: "",
    position_status: "Active",
    demo: "",
    position_type: "",
    open_outstation: "",
  });

  const [fieldserror, setFieldErros] = useState(false)
  useEffect(() => {
    dispatch({ type: "Viewjoborder", payload: '' });
  });

  const handleClose = () => {
    setAddSkillsModal(false);
    setjonAddsuccess(false);
  };
  const gotojoblist = () => {
    props.changetab(1);
  }
  const gotojoborder = () => {
    props.changetab(4)
  }
  const saveCandidate = () => {

    let data = new FormData()
    data.append("country", jobOrderFields.country)
    data.append("state", jobOrderFields.state)
    data.append("city", jobOrderFields.city)
    data.append("name", jobOrderFields.name)
    data.append("client", jobOrderFields.client)
    data.append("contact", jobOrderFields.contact)
    data.append("job_title", jobOrderFields.job_title)
    data.append("office_landmark", jobOrderFields.office_landmark)
    data.append("min_experience", jobOrderFields.min_experience)
    data.append("max_experience", jobOrderFields.max_experience)
    data.append("max_budget", jobOrderFields.max_budget)
    data.append("no_positions", jobOrderFields.no_positions)
    // data.append("qualifications",jobOrderFields.qualifications)    
    data.append("positions_description", positionDes)
    data.append("job_description", jobDes)
    data.append("qualification_description", qualification)
    data.append("position_status", jobOrderFields.position_status)
    data.append("jobdescription", descriptionFile)
    data.append("position_type", jobOrderFields.position_type)
    data.append("open_outstation", jobOrderFields.open_outstation)

    // data.append("user_recruiter[0]","61024f4feb9a120210729064847")
    // data.append("user_coordinator[0]","61024f4feb9a120210729064847")
    // data.append("user_manager[0]","61024f4feb9a120210729064847")

    for (let i = 0; i < primaryskills.length; i++) {
      data.append(`skill_primary[${i}]`, primaryskills[i])
    }

    for (let i = 0; i < secondaryskills.length; i++) {
      data.append(`skill_secondary[${i}]`, secondaryskills[i])
    }

    for (let i = 0; i < recruiters.length; i++) {

      data.append(`user_recruiter[${i}]`, recruiters[i]["user_id"])
    }

    for (let i = 0; i < coordinators.length; i++) {
      data.append(`user_coordinator[${i}]`, coordinators[i]["user_id"])
    }

    for (let i = 0; i < managers.length; i++) {
      data.append(`user_manager[${i}]`, managers[i]["user_id"])
    }
    for (let i = 0; i < qualifications.length; i++) {
      data.append(`qualifications[${i}]`, qualifications[i]['title'])
    }
    setLoader(true)
    apiPost(GET_JOBORDERS_API_URL, data, ({ status, response }) => {

      if (status) {
        if (response.data.status === true) {
          setSuccessMsg({ notes: [" Job Added Successfully"] })
          setjonAddsuccess(true)
          setDropIcons("fa-check-circle text-success")
          let orderid = response.data.data.encrypted_id;
          console.log('orderid',orderid)
          dispatch({ type: "Viewjoborder", payload: orderid });
          sessionStorage.setItem("JobOrderId", orderid);
          sessionStorage.setItem("Jobtitle", jobOrderFields.job_title);
          setTimeout(() => {
            gotojoborder()
          }, 3000);
        }
        else {
          setErrors(response.data.message);
          setFieldErros(true)
          setDropIcons("fa-times-circle text-danger")
        }

      } else {

        let msg = JSON.parse(response.request.response).errors
        setErrors(msg)
        setFieldErros(true)
        setDropIcons("fa-times-circle text-danger")
      }
      setLoader(false)
    })
  }
  return (
    <>
      {loader &&
        <div className="preloader">
          <div data-loader="dual-ring">  <img src={logoEmblem} alt="" className="logo img-fluid" /></div>
        </div>}
      <div className="container-fluid Job Orders bg-none clients px-3">
        <div className="row no-gutters">
          <div className="col-md-12 pt-3 px-0">
            <div className=" px-0">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="upload-file-section px-3">
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-5">
                        <div className="card custom-min-heght">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">Select Job Description File </h5>
                          </div>
                          <div className="card-body text-center">
                            {/* <input type="file" onChange={(e)=>console.log(e.target.value)}/> */}
                            <DragAndDrop
                              id={"Description"}
                              onselect={setDescriptionFile}
                              header={"Description"}
                              data={descriptionFile?.name}
                            />
                            <div className="text-center">
                              <i className={`fas ${dropIcons} fa-2x `}></i>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="card custom-min-heght">
                          <div className="card-header text-center">
                            <h5 className="pb-0 mb-0">Select Position Status</h5>
                          </div>
                          <div className="card-body text-center ">
                            <Select className="w-100" minRows={20} value={jobOrderFields.position_status} onChange={(e) => setJobOrderFields({ ...jobOrderFields, position_status: e.target.value })}>
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                            <div className="text-center mt-5">
                              <i className={`fas ${dropIcons} fa-2x `}></i>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <AddjobOrdersForm
                jobOrderFields={jobOrderFields}
                setJobOrderFields={setJobOrderFields}
                setPrimaryskill={setPrimaryskill}
                setSecondaryskills={setSecondaryskills}
                setRecruiters={setRecruiters}
                setCoordinators={setCoordinators}
                setManagers={setManagers}
                setQualifications={setQualifications}
              />
              <WysiwygEditor editDetails={setPositionDes} title={"Position Inputs"} ></WysiwygEditor>

              <WysiwygEditor editDetails={setJobDes} title={"Job Description"} />

              {/* <JobTextEditor editDetails={setQualification} title={"Qualification"}/> */}

              <div className="text-center mb-2 pt-2">
                <div className=" d-inline-block me-3">
                  <button class="btn btn-sm btn-green-dark text-white " onClick={gotojoblist} >
                    Cancel
                  </button>
                </div>
                <div className=" d-inline-block mx-2">
                  <button class="btn btn-sm btn-orange text-white" onClick={saveCandidate}>Save</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      {/* <CommonModal title={"Success"} handleClose={handleClose} open={jonAddsuccess} button={"ok"} btnfunc={handleClose}>
        Job Added Successfully
      </CommonModal> */}
      <SnakBar error={successMsg} open={jonAddsuccess} onClose={handleClose} type="success" />

      <SnakBar error={errors} open={fieldserror} onClose={() => setFieldErros(false)} />
    </>
  );
};

export default withRouter(AddCandidate);
