import React, { useState, useEffect } from "react";
import "./header.scss";
import Menulinks from "./menu";
import DehazeIcon from "@material-ui/icons/Dehaze";
import MobileMenu from "./mobileMenu";
// App Logo
import logoEmblem from "../../Assets/Images/emblem.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import { fetchUrl } from '../../ApiActions/Actions'
import { ROLES_PERMISSION_API_URL } from '../../Configurations/ConfigApi'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { withRouter } from "react-router-dom";


const userAdminkeys = [{ name: "Manage Logins", link: "/managelogins" },
{ name: "Manage Roles", link: "/manageroles" },
{ name: "Login as User", link: "/userLogin" },
// {name:"My Account",link:"/myaccount"},
{ name: "Logout", link: "/joborders" }]

const userNonAdminkeys = [
  { name: "My Account", link: "/myaccount" },
  { name: "Logout", link: "/joborders" }]

const settingsRoute = [
  // {name:"Roles",link:"/settings/roles"},
  { name: "Skills", link: "/settings/skills" }
]



const Header = (props) => {
  const [settings, setSettings] = useState(null);
  const [userlog, setUserlog] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [userData, setUserData] = useState({})
  const userDetails = useSelector(state => state.PageReduser.userDetails)
  const location = useLocation();
  const dispatch = useDispatch()


  const toggleDrawer = () => {
    setDrawer(false);
  };

  const settingsHandleClick = (event) => {
    setSettings(event.currentTarget);
  };

  const userLogHandleClick = (event) => {
    setUserlog(event.currentTarget);
  };

  const handleClose = () => {
    setSettings(null);
    setUserlog(null);
  };

  const userdetails = JSON.parse(localStorage.getItem("user"))
  useEffect(() => {
    // if(!JSON.parse(sessionStorage.getItem("user"))){
    //   props.history.push('/')
    // }
    setUserData(userdetails)
    fetchUrl(`${ROLES_PERMISSION_API_URL}?role_id=admin`, ({ status, response }) => {
      if (status) {
        let data = response?.data.filter((res) => res.role_id === (userdetails?.role))
        dispatch({ type: "roles_data", payload: data })
      }
    })

  }, [userDetails])

  useEffect(()=>{
    if(!localStorage.getItem("token")){
      props.history.push("/")
    }
  },[])

  return (
    <>
      <div className="container-fluid">
        {/* Font Awesome icons */}
        <div className="row ">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg nav-custom-menu d-none d-lg-block">
              <div className="container-fluid">
                <a className="navbar-brand ms-3 me-5" href="/dashboard">
                  <img src={logoEmblem} alt="" className="logo img-fluid" width="40px" height="40px" />
                </a>
                <div className="collapse navbar-collapse  pe-0 me-0" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item" key="dashboard">
                      <div className="dropdown-custom-menu">
                        <Link
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          variant="contained"
                          id="Dashboard"
                          className={`nav-link ${location.pathname === '/dashboard' && 'nav-link-active'}`}
                          to="/dashboard"
                          endicon={<ChevronRightIcon />}
                        >
                          Dashboard
                        </Link>

                      </div>
                    </li>
                    <li className="nav-item" key="joborders">
                      <Link
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        id="Dashboard"
                        className={`nav-link ${location.pathname === '/joborders' && 'nav-link-active'}`}
                        to="/joborders"
                        endicon={<ChevronRightIcon />}
                      >
                        Job Orders
                      </Link>
                    </li>
                    <li className="nav-item" key="candidtes">

                      <Link
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        id="Dashboard"
                        className={`nav-link ${location.pathname === '/candidates' && 'nav-link-active'}`}
                        to="/candidates"
                        endicon={<ChevronRightIcon />}

                      >
                        Candidates

                      </Link>
                    </li>
                    <li className="nav-item" key="compenies">

                      <Link
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        className={`nav-link ${location.pathname === '/clients' && 'nav-link-active'}`}
                        to="/clients"
                        endicon={<ChevronRightIcon />}
                      >
                        Clients

                      </Link>
                    </li>
                    <li className="nav-item" key="reports">

                      <Link
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        id="Dashboard"
                        className={`nav-link ${location.pathname === '/Datatables' && 'nav-link-active'}`}
                        to="/Datatables"
                        endicon={<ChevronRightIcon />}
                      >
                        Data Tables

                      </Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/settings/skills' && 'nav-link-active'}`} key="setting">
                      <Menulinks anchorEl={settings} elements={settingsRoute} handleClose={handleClose} title={"Settings"} handleClick={settingsHandleClick} />
                    </li>
                    <li className="nav-item ps-xl-5 ms-xl-5" key="greyicon">
                      <span className="nav-link d-inline-block pe-3">
                        <i className="fas fa-bell gray-icon"></i>
                      </span>
                      <span className="nav-link d-inline-block">
                        <i className="far fa-calendar-plus gray-icon"></i>
                      </span>
                    </li>

                  </ul>
                  <div className="d-flex justify-content-end">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                      <li className="border-nav d-flex justify-content-end ms-3 p-0 pe-4" key="userblock">
                        <Menulinks anchorEl={userlog} elements={(userData?.role === "admin") ? userAdminkeys : userNonAdminkeys} handleClose={handleClose} title={userData?.first_name} handleClick={userLogHandleClick} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>

            <div className="d-block d-lg-none">
              <div className="row">
                <div className="col-6 my-2">
                  <img src={logoEmblem} alt="logo" width="40px" height="40px" />
                </div>
                <div className="col-6 d-flex justify-content-end my-3 ">
                  <DehazeIcon onClick={() => setDrawer(true)} />
                  <MobileMenu open={drawer} toggleDrawer={toggleDrawer} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Header);
